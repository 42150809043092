/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// import { type UserAction, UserType, type AppStateAction, AppStateType } from '../actions';
// import { type EventType } from '../../common/types';
// import { type upcomingSessionType } from '../../types/upcoming-sessions';
// export interface appStateType {
//   upcomingEvents: EventType[];
//   upcomingSessions: upcomingSessionType[];
import { type upcomingSessionType } from '../../types/upcoming-sessions';
import { BadgeVariants } from '../../ui-kit/Badges/Badge';
import { type JoinTokenType } from '../../common/types/join-token';
import { type SentimentGraphResponseType } from '../../http/sentiment-graph';

export const emptySession: upcomingSessionType = {
  Speaker: {
    CommunicatorId: 0,
    UserKey: '',
    FullName: '',
    Email: '',
    Rating: {
      Rate: 0,
      RatingTitleId: 0,
      RatingTitle: ''
    },
    Organization: {
      Id: 0,
      OrgKey: '',
      OrgName: '',
      OrgLogo: '',
      ShortDesc: '',
      FullDesc: '',
      DateModified: '',
      IsDeleted: false,
      VsForum360: false,
      VsTeams: false,
      VsZoom: false,
      MarketOwner: false,
      SubscriptionLevel: 0,
      IsDistributor: false,
      HasDistributors: false,
      OnsiteSupport: false,
      AnF360Production: false,
      RestrictMonitorSharing: false,
      ApiStatus: 0,
      Distributors: []
    }
  },
  Date: '',
  Time: '',
  Title: '',
  SpeakerName: '',
  Product: '',
  Status: BadgeVariants.InProgress,
  Type: '',
  HoldingImage: '',
  SpeakerImage: '',
  DProducer: {
    CommunicatorId: 0,
    UserKey: '',
    FullName: '',
    Email: '',
    Rating: {
      Rate: 0,
      RatingTitleId: 0,
      RatingTitle: ''
    },
    Organization: {
      Id: 0,
      OrgKey: '',
      OrgName: '',
      OrgLogo: '',
      ShortDesc: '',
      FullDesc: '',
      DateModified: '',
      IsDeleted: false,
      VsForum360: false,
      VsTeams: false,
      VsZoom: false,
      MarketOwner: false,
      SubscriptionLevel: 0,
      IsDistributor: false,
      HasDistributors: false,
      OnsiteSupport: false,
      AnF360Production: false,
      RestrictMonitorSharing: false,
      ApiStatus: 0,
      Distributors: []
    }
  },
  SessionId: 0,
  EventDetails: {
    EventTypeID: 401,
    EventId: 0,
    EventKey: '',
    Title: '',
    EventDate: '',
    TimezoneId: '',
    Timezone: {
      Id: '',
      DisplayName: '',
      StandardName: '',
      DaylightName: '',
      BaseUtcOffset: '',
      AdjustmentRules: undefined,
      SupportsDaylightSavingTime: false
    },
    Status: 0,
    ShortDesc: '',
    FullDesc: '',
    OrgId: 0,
    OrgKey: '',
    OrgName: '',
    OrgLogo: '',
    SpeakerId: 0,
    HoldingImage: '',
    AreaOfInterest: '',
    Sessions: [],
    EventProducts: [],
    Organisations: [],
    Products: [],
    SessionNumber: 0,
    MeetingFormat: 0
  }
};

export interface signalRStateType {
  showDebugAutoSwitchSpeaker:boolean;
  chat: any[];
  toggle: boolean;
  userData: any;
  activeSession: upcomingSessionType;
  muteAll: boolean;
  screenShare: boolean;
  activeScreen: string;
  agendaQuestions: any[];
  commandFromGroup: any;
  roomCredentials: JoinTokenType;
  loader: string[];
  isSideBarOpen: boolean;
  hubConnected: boolean;
  startForWL: boolean;
  isCheckListShown: boolean;
  producerConnected: boolean;
  isLocalScreenShared: boolean;
  errorPopup: string;
  sentimentGraph: SentimentGraphResponseType[];
  automaticPresentationSwitch: boolean;
  loudestParticipant: any;
  selectedLocalMicrophone: any;
  selectedLocalSpeaker: any;
  selectedLocalCamera: any;
  joinedUsersList: any[];
  teamsContext: any;
  carbonSaved: number;
  changeRenderId: boolean;
  isCCon: boolean;
}

const signalRState: signalRStateType = {
  showDebugAutoSwitchSpeaker: false,
  hubConnected: false,
  chat: [],
  toggle: false,
  userData: undefined,
  muteAll: false,
  activeSession: emptySession,
  screenShare: false,
  activeScreen: 'empty',
  agendaQuestions: [],
  commandFromGroup: {},
  roomCredentials: {
    Success: false,
    VidyoToken: '',
    UserId: '',
    roomKey: '',
    roomPin: ''
  },
  loader: [],
  isSideBarOpen: false,
  startForWL: false,
  isCheckListShown: false,
  producerConnected: true,
  isLocalScreenShared: false,
  errorPopup: '',
  sentimentGraph: [],
  automaticPresentationSwitch: false,
  loudestParticipant: null,
  selectedLocalMicrophone: {
    name: 'No Mic detected'
  },
  selectedLocalSpeaker: {
    name: 'No Speaker detected'
  },
  selectedLocalCamera: {
    name: 'No Camera detected'
  },
  joinedUsersList: [],
  teamsContext: null,
  carbonSaved: 0,
  changeRenderId: false,
  isCCon: false
};

// reducer return new state
export const signalRReducer = (state = signalRState, action: any): signalRStateType => {
  switch (action.type) {
    case 'SET_SHOW_DEBUG_AUTO_SS':
      return {
        ...state,
        showDebugAutoSwitchSpeaker:action.payload
      };
    case 'SET_LOADER':
      // eslint-disable-next-line no-case-declarations
      let newLoaderArr = [...state.loader, action.payload.val];
      if (!action.payload.add) {
        newLoaderArr = [...state.loader.filter((item) => item !== action.payload.val)];
      }
      return {
        ...state,
        loader: newLoaderArr
      };
    case 'SET_CHAT':
      console.log('set chat called from reducer', action.payload);
      return {
        ...state,
        chat: [...state.chat, action.payload]
      };
    case 'SET_USER_DATA':
      console.log('set user data called from reducer', action.payload);
      return {
        ...state,
        userData: action.payload
      };
    case 'SET_ACTIVE_SESSION':
      console.log('set active session called from reducer', action.payload);
      return {
        ...state,
        activeSession: { ...state.activeSession, ...action.payload }
      };
    case 'SET_TOGGLE':
      return {
        ...state,
        toggle: !state.toggle
      };
    case '[RECEIVE_MUTE_ALL]':
      return {
        ...state,
        muteAll: action.payload
      };
    case 'SET_SCREENSHARE':
      return {
        ...state,
        screenShare: action.payload
      };
    case 'SET_ACTIVE_SCREEN':
      console.log('set active screen called from reducer', action.payload);
      return {
        ...state,
        activeScreen: action.payload
      };
    case 'SET_AGENDA_QUESTIONS':
      return {
        ...state,
        agendaQuestions: action.payload
      };
    case 'SET_COMMAND_FROM_GROUP':
      console.log('set command from group called from reducer', action.payload);
      return {
        ...state,
        commandFromGroup: action.payload
      };
    case 'SET_ROOM_CREDENTIALS':
      return {
        ...state,
        roomCredentials: action.payload
      };
    case 'SET_SIDEBAR_OPEN':
      return {
        ...state,
        isSideBarOpen: action.payload
      };
    case 'SET_HUB_CONNECTED':
      return {
        ...state,
        hubConnected: action.payload
      };
    case 'SET_START_FOR_WL':
      return {
        ...state,
        startForWL: action.payload
      };
    case 'SET_CHECKLIST_SHOWN':
      return {
        ...state,
        isCheckListShown: action.payload
      };
    case 'SET_PRODUCER_CONNECTED':
      return {
        ...state,
        producerConnected: action.payload
      };
    case 'SET_IS_LOCAL_SCREEN_SHARED':
      return {
        ...state,
        isLocalScreenShared: action.payload
      };
    case 'SET_ERROR_POPUP':
      return {
        ...state,
        errorPopup: action.payload.message
      };
    case 'UPDATE_SENTIMENT_GRAPH':
      return {
        ...state,
        sentimentGraph: [...state.sentimentGraph, action.payload]
      };
    case 'SET_SENTIMENT_GRAPH':
      return {
        ...state,
        sentimentGraph: action.payload
      };
    case 'SET_AUTOMATIC_PRESENTATION_SWITCH':
      return {
        ...state,
        automaticPresentationSwitch: action.payload
      };
    case 'SET_LOUDEST_PARTICIPANT':
      return {
        ...state,
        loudestParticipant: action.payload
      };
    case 'SET_SELECTED_LOCAL_MICROPHONE':
      return {
        ...state,
        selectedLocalMicrophone: action.payload
      };
    case 'SET_SELECTED_LOCAL_SPEAKER':
      return {
        ...state,
        selectedLocalSpeaker: action.payload
      };
    case 'SET_SELECTED_LOCAL_CAMERA':
      return {
        ...state,
        selectedLocalCamera: action.payload
      };
    case 'SET_JOINED_USERS_LIST':
      return {
        ...state,
        joinedUsersList: action.payload
      };
    case 'UPDATE_JOINED_USERS_LIST':
      return {
        ...state,
        joinedUsersList: [...state.joinedUsersList, action.payload]
      };
    case 'SET_TEAMS_CONTEXT':
      return {
        ...state,
        teamsContext: action.payload
      };
    case 'SET_CARBON_SAVED':
      return {
        ...state,
        carbonSaved: action.payload
      };
    case 'SET_CHANGE_RENDER_ID':
      return {
        ...state,
        changeRenderId: action.payload
      };
    case 'SET_CC':
      return {
        ...state,
        isCCon: action.payload
      };
    default:
      return state;
  }
};
