/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react';
import './toggle.scss';

interface ToggleSwitchProps {
  id: string;
  checked: boolean;
  onChange: (arg0: boolean) => unknown;
  name?: string;
  optionLabels?: unknown[];
  small?: boolean;
  disabled?: boolean;
}

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled
}: ToggleSwitchProps) => {
  function handleKeyPress(e: React.KeyboardEvent<HTMLLabelElement>): void {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  }

  return (
    <div className={'toggle-switch' + (small ? ' small-switch' : '')}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => {
          // console.log(e) ;
          // e.preventDefault();
          // e.stopPropagation();
          onChange(e.target.checked);
        }}
        disabled={disabled}
      />
      {id ? (
        <label
          className="toggle-switch-label"
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => {
            handleKeyPress(e);
          }}
          htmlFor={id}
        >
          <span
            className={
              disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'
            }
            data-yes={optionLabels?.[0]}
            data-no={optionLabels?.[1]}
            tabIndex={-1}
          />
          <span
            className={
              disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'
            }
            style={{ background: checked ? '#FFFFFF' : '#202228' }}
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
  optionLabels: ['Yes', 'No']
};

export default ToggleSwitch;
