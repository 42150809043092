/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable  @typescript-eslint/consistent-type-imports */
import { call, put, takeLatest } from 'redux-saga/effects';
import { userDurationCreate, CreateDurationResponseType } from '../http/createDuration';
import { AppStateType } from '../redux/actions';
function* createUserDuration({ payload }: any): any {
  try {
    const createDuration: CreateDurationResponseType = yield call(
      userDurationCreate,
      localStorage.accessToken,
      payload.SessionId
    );
    yield put({ type: 'UPDATE_USER_ID', payload: createDuration });
  } catch (e) {
    console.log('error in get room credentials', e);
  }
}

export function* startUserDuration() {
  yield takeLatest(AppStateType.CREATE_USER, createUserDuration);
}
