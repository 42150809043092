import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';

export const getJoinToken = async (
  eventKey: string,
  sessionId: number
): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/Session/JoinToken/${eventKey}/${sessionId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
      AppId: '4'
    }
  });
};
