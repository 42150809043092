// import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import { Namespaces } from '../namespaces';

import { localeReducer } from './locale';
import { userReducer } from './user';
import { appStateReducer } from './app-state';
import { vidyoConnectorReducer } from './vidyoConnectorReducer';
import { signalRReducer } from './signalr';
import { meetingReducer } from './meeting-state';

export const rootReducer = combineReducers({
  // [Namespaces.ROUTER]: connectRouter(createBrowserHistory()),
  [Namespaces.LOCALE]: localeReducer,
  [Namespaces.USER]: userReducer,
  [Namespaces.VIDYOCONNECTOR]: vidyoConnectorReducer,
  //   [Namespaces.USER]: userAuthenticationReducer,
  //   [Namespaces.UI]: uiReducer,
  [Namespaces.APPSTATE]: appStateReducer,
  SIGNALR: signalRReducer,
  MEETING: meetingReducer
});

export type RootState = ReturnType<typeof rootReducer>;
