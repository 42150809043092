import React, { useEffect, useState } from 'react';
import { Header } from '../../common/components/Header/Header';
import FooterForum from '../FooterForum';
import { useParams } from 'react-router-dom';
import styles from './custom-streaming-teams.module.scss';
import { copyIcon } from '../../assets';
import { getEventDetail } from '../../http/events';
import { ACCESS_TOKEN } from '../../common/constants';
import { removeLocalStorageItem, setLocalStorageItem } from '../../utils';

export const CustomStreamingTeams: React.FC = () => {
  const { token } = useParams();
  const [url, setUrl] = useState('');
  const [key, setKey] = useState('');
  const [err, setErr] = useState<null | string>(null);

  useEffect(() => {
    const decodedString = atob(token?.replace('Sa___', '') ?? '');
    const dataArr = decodedString.split(':');
    const eventKey = dataArr[0];
    const accessToken = dataArr[1];
    const sessionId = parseInt(dataArr[2]);
    setLocalStorageItem(ACCESS_TOKEN, accessToken); // not required ??

    getEventDetail(eventKey)
      .then((data) => {
        console.log('event details', data);
        const sessions = data?.Payload?.Sessions?.filter((s: any) => s.SessionId === sessionId);
        if (sessions.length === 0) {
          setErr('session not found');
          return;
        }
        // check for null undefined or empty string.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!sessions[0]?.StreamUrl) {
          setErr('Stream url not set through admin portal');
          return;
        }
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!sessions[0]?.StreamKey) {
          setErr('Stream Key not set through admin portal');
          return;
        }
        setUrl(sessions[0].StreamUrl);
        setKey(sessions[0].StreamKey);
      })
      .catch((err) => {
        console.log(err);
        setErr(`Unable to get Stream info`);
        //  Err: + ${typeof err?.data?.Message === "string" ? err.data.Message : ' '}`);
      })
      .finally(() => {
        // once we have gottent the key and url we can safely remove the access token.
        removeLocalStorageItem(ACCESS_TOKEN);
      });
  }, []);

  return (
    <div>
      <Header />
      <div className={styles.mdContainer}>
        <h1>Customised Streaming Setup</h1>

        <h2>Adding Customised Streaming App to Teams</h2>

        <p>At the top of the Teams window, click the Plus Icon labelled Apps</p>

        <p>
          <img
            alt="image"
            src="https://github.com/Sa-So/Zoom/assets/60461699/044ba2d4-cfee-4ba4-8855-aa7c7b8d2d55"
          />
        </p>

        <p>Search for Customised Streaming app and add the app to Teams</p>

        <p style={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap' }}>
          <img
            alt="image 27"
            src="https://github.com/Sa-So/Zoom/assets/60461699/9e2a40d0-5c29-4c8c-b19d-66a0aab03d30"
          />
          <img
            alt="image 28"
            src="https://github.com/Sa-So/Zoom/assets/60461699/1f0227a4-214f-49d5-ad7f-c2c122b26c7f"
          />
        </p>

        {/* <p>
          &lt;!--
          <img
            alt="image"
            src="https://github.com/Sa-So/Zoom/assets/60461699/f8386f0e-1f7c-434f-b11b-d40b78ac85f7"
          />
          --&gt;
        </p> */}

        <p>Open the Customised Streaming App within Teams</p>

        <p>
          <img
            alt="image 29"
            src="https://github.com/Sa-So/Zoom/assets/60461699/4efd5434-a2d7-4437-aef6-13c1004a6f19"
          />
        </p>

        <p>Copy and Paste the following Stream URL and Stream Key</p>

        {err != null && <div className="red">{err}</div>}

        <div className={styles.keyContainer}>
          <div>Stream URL:</div>
          <div>
            <input readOnly type="password" value={url} />
          </div>
          <div>
            <button
              className={styles.copyButton}
              onClick={() => {
                navigator.clipboard
                  .writeText(url)
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              <img src={copyIcon} />
            </button>
          </div>
        </div>

        <div className={styles.keyContainer}>
          <div>Stream Key:</div>
          <div>
            <input readOnly type="password" value={key} />
          </div>
          <div>
            <button
              className={styles.copyButton}
              onClick={() => {
                navigator.clipboard
                  .writeText(key)
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              <img src={copyIcon} />
            </button>
          </div>
        </div>

        <p>Click Start streaming and you are set to return to the Relate App for Teams</p>
      </div>
      <FooterForum />
    </div>
  );
};
