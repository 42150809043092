import { useSelector } from 'react-redux';
import { cross } from '../../assets';
import styles from './popup.module.scss';
import React, { useEffect, useState } from 'react';
import { type RootState } from '../../redux/reducers';
export const Popup: React.FC = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { isCCon } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const role = useSelector(({ USER }: RootState) => USER.role);
  // const how2TurnOffText = role === 'host' ? "To turn off transcription, click on the toggle in the Resources tab" : "";
  const firstUpdate = React.useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setPopupVisible(true);
    const timeoutId = setTimeout(() => {
      setPopupVisible(false);
    }, 30000); // 30 seconds in milliseconds

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCCon]);

  // useEffect(()=>{
  // }[isCCon])

  const closePopup = (): void => {
    setPopupVisible(false);
  };

  return isPopupVisible ? (
    <div className={styles.popupContainer}>
      <div className={styles.popupText}>
        {isCCon
          ? 'This meeting is being transcribed.'
          : // + how2TurnOffText
            'Transcription has been turned off'}
      </div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          closePopup();
        }}
      >
        <img src={cross} />
      </div>
    </div>
  ) : (
    <></>
  );
};
