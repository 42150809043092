import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import ClosedCaptionsView from "./ClosedCaptionsView";
// import showNotification from "components/Notifications/Notifications";
// import { Logger } from "utils/logger";
import { ccProvider } from "./providers/ClosedCaptionsProvider";
// import { test } from "utils/helpers";
// import {
//   setCcBtnIsActive,
//   setCcInialized,
//   setCcRequestInProgress,
//   setCcSendAnalytics,
// } from "store/actions/call";
// import { isSafari } from "react-device-detect";

// const ccLogger = new Logger("CC");

let msgSubcriber = () => {};

const msgCallback = (msg) => {
  msgSubcriber(msg);
};

const subscribeOnMesaages = (callback) => {
  msgSubcriber = callback;
};

const unsubscribeFromMessages = () => {
  msgSubcriber = () => {};
};

const ClosedCaptionsWebController = () => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  const [isCCInitialized, setIsCCInitialized] = useState(false);
  const [error, setError] = useState(false);
  const subscriptionToken = useRef(null);
  const isCCon = useSelector(({ SIGNALR }) => SIGNALR.isCCon);
  // const config = useSelector((state) => state.config);
  // const call = useSelector((state) => state.call);
  // const { listOfGCPServices } = useSelector((state) => state.config);
  // const subscriptionUrl = listOfGCPServices.closedCaption.url;
  // let subscriptionUrl = null;
  const [subscriptionUrl, setSubscriptionUrl] = useState(null);
  const stopCC = async () => {
    try {
      console.log("subURL",subscriptionUrl);
      if (!subscriptionToken.current) {
        console.log(
          "Feature wan't started, don't need to send STOP request"
        );
        return;
      }
      console.log("Request to stop");
      // dispatch(setCcRequestInProgress(true));
      // dispatch(setCcInialized(false));
      const stopRes = await ccProvider.stopCaptions(subscriptionUrl);

      if (stopRes) {
        console.log("Request to stop successfull");
      } else {
        console.error("Request to stop failed");
      }

      console.log("Unsubscribe from topic");

      try {
        await ccProvider.unsubscribeFromTopic(
            subscriptionToken.current
        );
      } catch(_) {
        // this promise is rejected only in case client not in call
        // at that moment there are not any active subscriptions
        // so, we can just ignore
      }

      subscriptionToken.current = null;
      console.log(
        "Unsubscribe from topic successfull. Feature stopped."
      );
      // dispatch(setCcRequestInProgress(false));
    } catch (error) {
      // dispatch(setCcRequestInProgress(false));
      console.error(error);
    }
  };

  const startCC = async () => {
    try {
      const response = await fetch("https://getservices.platform.vidyo.io");
      const closedCaption = (await response.json()).closedCaption;
      const closedCaptionEnabled = closedCaption.isServiceAvailable;
      const closedCaptionServiceUrl = closedCaption.url;
      // let subscriptionUrlLocal = closedCaptionServiceUrl;
      setSubscriptionUrl(closedCaptionServiceUrl);
      // subscriptionUrl = closedCaptionServiceUrl;
      if (!closedCaptionEnabled) {
        console.warn("CC: Service is not available")
        return;
      }

      console.log("Start closed captioning");
      subscriptionToken.current = await ccProvider.startCaptions(
        closedCaptionServiceUrl
      );

      if (!subscriptionToken.current) {
        throw new Error("failed to get subscriptionToken");
      }

      console.log("Subscribe on topic");
      await ccProvider.subscribeOnTopic(
        subscriptionToken.current,
        msgCallback,
        (status, message, code) => {
          console.log(
            `Subscription to topic. Status = ${status}, message = ${message}, code = ${code}`
          );
          switch (status) {
            case "SUBSCRIPTION_SUBSCRIBED":
              console.log(
                "Subscribe on topic successfull. Feature initialized."
              );
              setIsCCInitialized(true);
              // dispatch(setCcInialized(true));
              // dispatch(setCcSendAnalytics(true));
              // dispatch(setCcRequestInProgress(false));
              break;
            case "SUBSCRIPTION_ERROR":
              setError(true);
              // dispatch(setCcRequestInProgress(false));
              break;
            case "SUBSCRIPTION_INOPERATIVE":
              // dispatch(setCcRequestInProgress(false));
              // dispatch(setCcBtnIsActive(false));
              break;
            case "SUBSCRIPTION_SUBSCRIBING":
            case "SUBSCRIPTION_UNSUBSCRIBED":
            case "SUBSCRIPTION_Resubscribing":
              break;
            default:
              break;
          }
        }
      );
    } catch (error) {
      console.error(error);
      setError(true);
      // dispatch(setCcRequestInProgress(false));
    }
  };
  useEffect(() => {
    // dispatch(setCcRequestInProgress(true));
    // if(isCCon)
    //   startCC();

    return () => {
      stopCC();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionUrl]);

  // const firstUpdate = useRef(true);
  useEffect(()=>{
    // if (firstUpdate.current) {
    //   firstUpdate.current = false;
    //   return;
    // }

    if(isCCon){
      startCC();
    }else{
      stopCC();
    }
  },[isCCon])


  // useEffect(() => {
  //   if (error) {
  //     showNotification("bannerWithBtns", {
  //       type: "banner",
  //       showFor: 10000,
  //       message: t("ERROR_CC"),
  //       buttons: [
  //         {
  //           autoClickAfterNSeconds: 10,
  //           text: t("HIDE"),
  //         },
  //       ],
  //     });
  //     setError(false);
  //     dispatch(setCcBtnIsActive(false));
  //   }
  // }, [dispatch, error, t]);

  return (<>
  
  {isCCInitialized ? (<ClosedCaptionsView
    subscribeOnMesaages={subscribeOnMesaages}
    unsubscribeFromMessages={unsubscribeFromMessages}
    uiOptions={{
      fontSize: "14px",
      backColor: "transparent",
      // test,
      // isSafari,
    }}/> )
    : <div>{error ? 'Error while initializing CC' : 'Initializing Transcription..'}</div>}
  </>
  );
};

export default ClosedCaptionsWebController;
