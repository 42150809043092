import qs from 'qs';
import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';

export interface ITokenForZoomRes {
  Success: boolean;
  Payload: ITokenForZoomPayload;
  ResponseCode: number;
}

export interface ITokenForZoomPayload {
  Token: string;
  OrgKey: string;
  EventKey: string;
}

export const getTokenforZoom = async (
  chatId: string,
  email: string,
  API_URL: string = BASE_URL
): Promise<ITokenForZoomRes | undefined> => {
  const data = qs.stringify({
    ZoomMeetinguuid: chatId,
    Email: email
  });

  return await httpRequest({
    url: `${API_URL}/Session/ZoomToken`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
