import React, {useState, useEffect} from 'react'
import {useVidyo} from './../../common/hooks/useVidyo'
// import SideBar from '../../components/Sidebar/Sidebar';
// import VideoConnector from './VideoConnector'
import './VideoCall.scss'
import { ActiveScreen } from '../WaitingRoom/ActiveScreen';
import Footer from '../../components/Footer/Footer';
import { Participant } from '../../common/components/Participant/Participant';
// import StartVidyoConnector from './VideoConnector1'

export const VideoCall = () => {

    const [connector, setConnector] = useState(false)
    const [hideSideBar, setHideSideBar] = useState(false)


    const {
      onVidyoClientLoaded,
      startVideoCall, 
      shareScreen, 
      videoConnectorCopy, 
      start,
      particpantsArr,
      showSelf,
      registerLocalSpeaker,
      registerLocalMic,
      setCamera, 
      leaveCall, 
      handleMute, 
      handleSpeakerPrivacy,
      handleCameraPrivacy,
      micPrivacy,
      cameraPrivacy,
      speakerPrivacy,
      microPhoneMute,
      audioDevices, 
      videoDevices, 
      speakerDevices,
      muteParticipant,
      handleDivSwitch
    } = useVidyo("https://test.platform.vidyo.io", `name+${Math.random()}`, "oLeVLfNufz", "0mhY4K1jCr", 'host')
    console.log(videoConnectorCopy)
    function tool() {
      setHideSideBar(!hideSideBar);
      console.log('mmm', hideSideBar);
    }



      useEffect(()=>{
        if(!connector){
        // loadNativeWebRTCVidyoClientLibrary()
        console.log("use Effect")
        onVidyoClientLoaded({ state: 'READY', description: 'Native SCIP + WebRTC' });
        setConnector(true)
        }
      }, [])



    return(<>
        <button onClick={()=>handleDivSwitch()}>Swich users</button>
          <div className="waiting-screen">
              <ActiveScreen 
               callStarted={start}
               userName={'userName'}
              />
          </div>

    <div className="participantContainer">
      {!start && <Participant participant={{}} participantName={"name"} divId={'user-init'} handleParticipantMute={()=>{console.log("Participant mute clicked1")}} fixToMain={()=>{console.log("fix to main")}}/>}
      {particpantsArr.map(item=>{
          return !item.isLocal || showSelf 
          ? <Participant participant={item} participantName={item.name} divId={item.userId} key={item.id} handleParticipantMute={muteParticipant} fixToMain={handleDivSwitch}/>
          : null
      })}
    </div>
    <Footer 
      tools={tool}
      mics={audioDevices} 
      camera={videoDevices} 
      speaker={speakerDevices}
      muted={microPhoneMute}
      speakerPrivacy={speakerPrivacy}
      micPrivacy={micPrivacy}
      cameraPrivacy={cameraPrivacy}
      handleShareScreen={shareScreen}
      handleStartCall={start ? leaveCall :startVideoCall}
      text={start ? "Leave Meeting" : "Join Waiting Room"}
      callStarted={start}
      registerLocalMic={registerLocalMic}
      handleMute={handleMute}
      registerLocalCamera={setCamera}
      registerLocalSpeaker={registerLocalSpeaker}
      handleCameraPrivacy={handleCameraPrivacy}
      handleSpeakerPrivacy={handleSpeakerPrivacy}
    />
    </>)
}