import React from 'react';
import './NoSession.css';
import prev from '../Pagination/Prev.png';
import next from '../Pagination/Next.png';
import search from '../Pagination/search.svg';
// export const Comments: React.FC = () =>
export const NoSession: React.FC = () => {
  return (
    <div className="noScreen">
      <div>
        <div className="heading-noscreen">
          <div className="noChange">
            <span className="upcoming">Upcoming meetings</span>
            <p className="meeting-scheduled">Scheduled meeting and calls</p>
          </div>
          <div className="searchicon">
            <img className="iconSearch" src={search} />
            <input placeholder="Search" className="search" />
          </div>
        </div>
        <div>
          <input />
        </div>
      </div>
      <div className="no-meeting-info">No meetings scheduled</div>
      <div className="no-meeting-footer">
        <img src={prev} />
        <div className="change-button">1</div>
        <img src={next} />
      </div>
    </div>
  );
};
