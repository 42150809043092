export const quickMessageOptions1 = [
  { value: 'Prospecting', label: 'Prospecting' },
  { value: 'Qualification', label: 'Qualification' },
  { value: 'C-Level', label: 'C-Level' },
  { value: 'Product Team Insights', label: 'Product Team Insights' },
  { value: 'Account Review', label: 'Account Review' },
  { value: 'Demonstration', label: 'Demonstration' },
  { value: 'Training', label: 'Training' },
  { value: 'Product Customization', label: 'Product Customization' },
  { value: 'Negotiating Terms', label: 'Negotiating Terms' },
  { value: 'Procurement', label: 'Procurement' },
  { value: 'Onboarding/Compliance', label: 'Onboarding/Compliance' }
];
