import qs from 'qs';
import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';
import { type UserDetailResponseType, type UpcomingEventResponse } from '../common/types';
import { type SessionDetailsResponse } from '../common/types/session';

export const getUpcomingEvents = async (): Promise<UpcomingEventResponse | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/Event/UpcomingEvent`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
        AppId: '4'
      }
    },
    false
  );
};

export const getUserDetails = async (key: string): Promise<UserDetailResponseType | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/User/UserDetail/${key}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
        AppId: '4'
      }
    },
    false
  );
};

export const getSessionDetails = async (
  sessionId: number
): Promise<SessionDetailsResponse | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/Session/Detail/${sessionId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
        AppId: '4'
      }
    },
    false
  );
};

export const getProductsByOrgKey = async (orgKey: string): Promise<any> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/Product/GetProductsByOrg/${orgKey}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
        AppId: '4'
      }
    },
    false
  );
};

export const getEventsByProductKey = async (orgKey: string, productKey: string): Promise<any> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/PA_Event/EventByProductExtended/${orgKey}/${productKey}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
        AppId: '4'
      }
    },
    false
  );
};

export const getEventDetail = async (eventKey: string): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/Event/Detail/${eventKey}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
