// import { type ExceptionDataType } from '@redux/actions';
// import { type Practitioner, type Site, type UserData } from '@types';
import { type EventType } from '../../common/types';
import { type upcomingSessionType } from '../../types/upcoming-sessions';
import { type ModalVariants } from '../reducers/app-state';
import { setAgendaQuestions } from './app-state';

export enum LocaleType {
  SWITCH_LOCALE = 'SWITCH_LOCALE'
}
export enum AppStateType {
  SHOW_SPINNER = 'SHOW_SPINNER',
  HIDE_SPINNER = 'HIDE_SPINNER',
  GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS',
  SET_UPCOMING_EVENTS = 'SET_UPCOMING_EVENTS',
  SET_UPCOMING_SESSIONS = 'SET_UPCOMING_SESSIONS',
  SET_MODAL_SHOWN = 'SET_MODAL_SHOWN',
  SET_AGENDA = 'SET_AGENDA',
  SET_MODAL_MESSAGE = 'SET_MODAL_MESSAGE',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER_ID = 'UPDATE_USER_ID'
  // SHOW_EXCEPTIONS_MODAL = 'SHOW_EXCEPTIONS_MODAL',
  // HIDE_EXCEPTIONS_MODAL = 'HIDE_EXCEPTIONS_MODAL',
  // SET_FONT_SIZE = 'SET_FONT_SIZE',
  // SHOW_SCHEDULE_APPOINTMENT_ERROR = 'SHOW_SCHEDULE_APPOINTMENT_ERROR',
  // HIDE_SCHEDULE_APPOINTMENT_ERROR = 'HIDE_SCHEDULE_APPOINTMENT_ERROR',
  // REFRESH_DATA = 'REFRESH_DATA',
}

export enum VidyoConnectorTypes {
  SET_VIDYO_CONNECTOR = 'SET_VIDYO_CONNECTOR',
  REGISTER_LOCAL_CAMERA = 'REGISTER_LOCAL_CAMERA',
  REGISTER_LOCAL_MIC = 'REGISTER_LOCAL_MIC',
  REGISTER_LOCAL_SPEAKER = 'REGISTER_LOCAL_SPEAKER',
  REGISTER_REMOTE_CAMERA = ' REGISTER_REMOTE_CAMERA',
  REGISTER_REMOTE_PARTICIPANT = 'REGISTER_REMOTE_PARTICIPANT',
  REGISTER_SCREEN_SHARE = 'REGISTER_SCREEN_SHARE',
  JOIN_ROOM_AS_GUEST = 'JOIN_ROOM_AS_GUEST',
  SET_MIC_PRIVACY = 'SET_MIC_PRIVACY',
  SET_CAMERA_PRIVACY = 'SET_CAMERA_PRIVACY',
  SET_SPEAKER_PRIVACY = 'SET_SPEAKER_PRIVACY'
}

// export enum UserType {
//   AUTHENTICATE_USER = 'AUTHENTICATE_USER',
//   INITIALIZE_AUTHENTICATION = 'INITIALIZE_AUTHENTICATION',
//   SET_USER_ROLE = 'SET_USER_ROLE',
//   SET_USER_DATA = 'SET_USER_DATA',
//   SHOW_LOGOUT_MODAL = 'SHOW_LOGOUT_MODAL',
//   HIDE_LOGOUT_MODAL = 'HIDE_LOGOUT_MODAL',
//   LOGOUT = 'LOGOUT',
// 	GET_ACTIVE_USER = 'GET_ACTIVE_USER'
// }

// export enum AppStateType {
//   SHOW_SPINNER = 'SHOW_SPINNER',
//   HIDE_SPINNER = 'HIDE_SPINNER',
//   SHOW_EXCEPTIONS_MODAL = 'SHOW_EXCEPTIONS_MODAL',
//   HIDE_EXCEPTIONS_MODAL = 'HIDE_EXCEPTIONS_MODAL',
//   SET_FONT_SIZE = 'SET_FONT_SIZE',
//   SHOW_SCHEDULE_APPOINTMENT_ERROR = 'SHOW_SCHEDULE_APPOINTMENT_ERROR',
//   HIDE_SCHEDULE_APPOINTMENT_ERROR = 'HIDE_SCHEDULE_APPOINTMENT_ERROR',
//   REFRESH_DATA = 'REFRESH_DATA',
// }

// export enum SiteStateType {
//   SHOW_LOCATION_PICKER_MODAL = 'SHOW_LOCATION_PICKER_MODAL',
//   HIDE_LOCATION_PICKER_MODAL = 'HIDE_LOCATION_PICKER_MODAL',
//   SET_SITES = 'SET_SITES',
//   GET_SITES = 'GET_SITES',
//   GET_CURRENT_SITE = 'GET_CURRENT_SITE',
//   SET_CURRENT_SITE = 'SET_CURRENT_SITE',
// }

export interface LocaleAction {
  type: typeof LocaleType.SWITCH_LOCALE;
  payload: { locale: string };
}
export interface GetUpcomingEvents {
  type: typeof AppStateType.GET_UPCOMING_EVENTS;
  // payload: { upcomingEvents: EventType[] };
}
export interface SetUpcomingEvents {
  type: typeof AppStateType.SET_UPCOMING_EVENTS;
  payload: { upcomingEvents: EventType[] };
}

export interface SetUpcomingSessions {
  type: typeof AppStateType.SET_UPCOMING_SESSIONS;
  payload: { upcomingSessions: upcomingSessionType[] };
}

export interface SetVidyoConnector {
  type: typeof VidyoConnectorTypes.SET_VIDYO_CONNECTOR;
  payload: { vidyoConnector: any };
}

export interface CreateUser {
  type: typeof AppStateType.CREATE_USER;
  payload: { sessionId: number };
}

export interface UpdateUserId {
  type: typeof AppStateType.UPDATE_USER_ID;
  payload: { Id: number };
}
export interface RegisterLocalCamera {
  type: typeof VidyoConnectorTypes.REGISTER_LOCAL_CAMERA;
  payload: { localCameraObj: any };
}

export interface RegisterLocalMic {
  type: typeof VidyoConnectorTypes.REGISTER_LOCAL_MIC;
  payload: { localMicObj: any };
}

export interface RegisterLocalSpeaker {
  type: typeof VidyoConnectorTypes.REGISTER_LOCAL_SPEAKER;
  payload: { localSpeakerObj: any };
}

// export type AppStateAction = GetUpcomingEvents | SetUpcomingEvents | SetUpcomingSessions;
export type VidyoConnectorAction =
  | SetVidyoConnector
  | RegisterLocalCamera
  | RegisterLocalMic
  | RegisterLocalSpeaker;
export interface SetModalShown {
  type: typeof AppStateType.SET_MODAL_SHOWN;
  payload: { modalShown: ModalVariants };
}

export interface SetAgendaQuestions {
  type: typeof AppStateType.SET_AGENDA;
  payload: { agendaQuestions: any };
}

export interface SetModalMessage {
  type: typeof AppStateType.SET_MODAL_MESSAGE;
  payload: { modalToShow: any };
}

export type AppStateAction =
  | GetUpcomingEvents
  | SetUpcomingEvents
  | SetUpcomingSessions
  | SetModalShown
  | SetAgendaQuestions
  | SetModalMessage
  | CreateUser;
// export interface UserAuthenticationAction {
//   type: typeof UserType.AUTHENTICATE_USER;
//   payload: { isAuthenticated: boolean };
// }

// export interface UserAuthenticationInitAction {
//   type: typeof UserType.INITIALIZE_AUTHENTICATION;
//   payload: { isAuthenticationInitiated: boolean };
// }

// export interface UserRoleAction {
//   type: typeof UserType.SET_USER_ROLE;
//   payload: { userRole: string | null };
// }

// export interface UserDataAction {
//   type: typeof UserType.SET_USER_DATA;
//   payload: { userData: Practitioner | null };
// }

// interface GetActiveUserAction {
//   type: typeof UserType.GET_ACTIVE_USER;
// }

// interface ShowLogoutModalAction {
//   type: typeof UserType.SHOW_LOGOUT_MODAL;
//   payload: { isLogoutModalShown: boolean };
// }

// interface HideLogoutModalAction {
//   type: typeof UserType.HIDE_LOGOUT_MODAL;
//   payload: { isLogoutModalShown: boolean };
// }

// interface LogoutAction {
//   type: typeof UserType.LOGOUT;
//   payload: {
//     isAuthenticated: boolean;
//     userRole: string | null;
//     userData: UserData | null;
//   };
// }

// export type UserAction = UserAuthenticationAction |
//   UserAuthenticationInitAction |
//   UserRoleAction |
//   UserDataAction |
//   ShowLogoutModalAction |
//   HideLogoutModalAction |
//   LogoutAction |
// 	GetActiveUserAction;

// interface ShowSpinner {
//   type: typeof AppStateType.SHOW_SPINNER;
// }

// interface HideSpinner {
//   type: typeof AppStateType.HIDE_SPINNER;
// }

// interface ShowExceptionsModalAction {
//   type: typeof AppStateType.SHOW_EXCEPTIONS_MODAL;
//   payload: ExceptionDataType;
// }

// interface HideExceptionsModalAction {
//   type: typeof AppStateType.HIDE_EXCEPTIONS_MODAL;
// }

// export interface HideScheduleAppointmentError {
//   type: typeof AppStateType.HIDE_SCHEDULE_APPOINTMENT_ERROR;
// }

// export interface CarePlanExceptionType {
//   conditions?: boolean;
//   selectedConditions?: boolean;
//   targets?: boolean;
//   patientTargets?: boolean;
//   targetGraph?: boolean,
//   vitalsGraph?: boolean,
//   healthPlanConditionsAndTarget?: boolean,
//   lifeGoals?:boolean,
//   flourishStatement?: boolean,
//   targetActions?: boolean,
//   patientActions?: boolean,
//   targetLifeGoals?: boolean,
//   healthPlanLifeGoalActions?: boolean,
// }

// export interface ErrorDataType {
//   icon: string;
//   isScheduleAppointmentErrorShown : boolean;
//   errorHeader: string;
//   errorTitle : string;
//   errorMessage : string;
//   errorButtonText : string;
//   onAction:()=>HideScheduleAppointmentError;
// }
// export interface RefreshDataType {
//   isRefreshRequired:boolean;
//   refreshLocation:string|null;
// }
// interface ShowScheduleAppointmentError {
//   type: typeof AppStateType.SHOW_SCHEDULE_APPOINTMENT_ERROR;
//   payload: ErrorDataType;
// }
// interface RefreshDataAction {
//   type: typeof AppStateType.REFRESH_DATA;
//   payload: RefreshDataType;
// }

// interface ShowLocationPickerModal {
//   type: typeof SiteStateType.SHOW_LOCATION_PICKER_MODAL;
// }

// interface HideLocationPickerModal {
//   type: typeof SiteStateType.HIDE_LOCATION_PICKER_MODAL;
// }

// interface SetSites {
//   type: typeof SiteStateType.SET_SITES;
//   payload: { sites: Site[] };
// }

// interface GetSites {
//   type: typeof SiteStateType.GET_SITES;
// }
// interface SetFontSize {
//   type: typeof AppStateType.SET_FONT_SIZE;
//   payload: string;
// }

// export interface GetCurrentSite {
//   type: typeof SiteStateType.GET_CURRENT_SITE;
//   payload: {
//     siteId: string;
//   };
// }

// export interface SetCurrentSite {
//   type: typeof SiteStateType.SET_CURRENT_SITE;
//   payload: { currentSite: Site };
// }

// export type AppStateAction = ShowSpinner |
//   HideSpinner |
//   ShowExceptionsModalAction |
//   HideExceptionsModalAction |
//   ShowScheduleAppointmentError |
//   HideScheduleAppointmentError |
//   RefreshDataAction |
//   SetFontSize;

// export type SiteStateAction = ShowLocationPickerModal |
//   HideLocationPickerModal |
//   SetSites |
//   GetSites |
//   GetCurrentSite |
//   SetCurrentSite;
