/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable  @typescript-eslint/strict-boolean-expressions */
import logo from './logo.svg';
import settings from './settings.png';
import './Footer.scss';
import footerToolTip from './footerTooltip.svg';
import speakerMute from './speakerMute.svg';
import screenshare from './screenshare.svg';
import toolsimage from './tools.svg';
import cameraOff from './cameraOff.svg';
import speakerImg from './speaker.svg';
import cameraImg from './camera.svg';
import { Tooltip, TooltipVariants } from '../../ui-kit/Tooltip/Tooltip';
import { useEffect, useRef, useState } from 'react';
import { Button, ButtonVariants } from '../../ui-kit/Button/Button';
import ToggleSwitch from '../../ui-kit/Toggle/Toggle';
import { Settings } from '../../common/components/Settings/Settings';
import { useOutside } from '../../common/hooks/useOutside';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../redux/reducers';
import { UserRole } from '../../redux/reducers/user';
import { noop } from '@babel/types';
import { speakerOff, mic, micMute } from '../../assets';
import { BASE_URL } from '../../http/api/services';
import { TrimTooltip } from '../../ui-kit/TrimTooltip/TrimTooltip';
export const saveCarbon = async (token: string, eventId: number): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/Carbon/CarbonSave`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      EventId: eventId
    })
  });
};
export interface FooterProps {
  tools: any;
  camera: any;
  mics: any;
  speaker: any;
  muted: boolean;
  handleShareScreen: any;
  handleStartCall: any;
  text: string;
  registerLocalCamera: any;
  handleMute: any;
  registerLocalMic: any;
  registerLocalSpeaker: any;
  callStarted: boolean;
  handleCameraPrivacy: any;
  handleSpeakerPrivacy: any;
  speakerPrivacy: boolean;
  micPrivacy: boolean;
  cameraPrivacy: boolean;
  handleStopShareScreen: any;
}
const fields = [
  { label: "James's Airpods" },
  { label: "James's iPhone Microphone" },
  { label: 'Macbook Pro Microphone' }
];

// const settingsFields = [
//   { label: "James's Airpods" },
//   { label: "James's iPhone Microphone" },
//   { label: 'Macbook Pro Microphone' },
//   { node : }
// ];
const fields2 = [{ name: 'Test Microphone' }];

// const newTooltipObj = (tooltips:Record<string,boolean> ,key :string):Record<string,boolean> => {

//   const newTooltips = tooltips;
//   Object.keys(newTooltips).forEach(
//     v => {
//       if(v===key) newTooltips[v] = !newTooltips[v];
//       else newTooltips[v] = false

//     });
//   return newTooltips;
// };

export enum FooterTooltips {
  Settings = 'settings',
  Speaker = 'speaker',
  Video = 'video',
  Audio = 'audio',
  MeetingControls = 'meetingControls',
  None = 'none'
}

const changeTooltip = (currValue: FooterTooltips, newValue: FooterTooltips): FooterTooltips => {
  if (currValue === newValue) return FooterTooltips.None;
  return newValue;
};

export const Footer: React.FC<FooterProps> = ({
  tools,
  camera,
  mics,
  muted,
  speaker,
  handleShareScreen,
  handleMute,
  handleStartCall,
  text,
  registerLocalCamera,
  registerLocalMic,
  registerLocalSpeaker,
  callStarted,
  handleCameraPrivacy,
  handleSpeakerPrivacy,
  speakerPrivacy,
  micPrivacy,
  cameraPrivacy,
  handleStopShareScreen
}: FooterProps) => {
  // console.log('props', tools);
  const isSideBarOpen = useSelector(({ SIGNALR }: RootState) => SIGNALR.isSideBarOpen);
  const role = useSelector(({ USER }: RootState) => USER.role);
  const screenShare = useSelector(({ SIGNALR }: RootState) => SIGNALR.screenShare);
  const dispatch = useDispatch();
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  // console.log('screenShare', screenShare);
  // const [isLocalScreenShare, setIsLocalScreenShare] = useState<boolean>(false);
  const isLocalScreenShared = useSelector(({ SIGNALR }: RootState) => SIGNALR.isLocalScreenShared);
  function callCarbon(): void {
    void saveCarbon(localStorage.accessToken, activeSession.EventDetails.EventId);
  }
  const getClassNameForScreenShare = (): string => {
    console.log('role from get classname', role);
    if (role === UserRole.host || (role === UserRole.cohost && screenShare)) return 'enabled';
    return 'disabled';
  };
  const handleShare = (): any => {
    if (role === UserRole.host || (role === UserRole.cohost && screenShare)) {
      if (!isLocalScreenShared) {
        handleShareScreen();
        // setIsLocalScreenShare(true);
      } else {
        handleStopShareScreen();
        // setIsLocalScreenShare(false);
      }
    }
  };

  const [shownTooltip, setShownTooltip] = useState<FooterTooltips>(FooterTooltips.None);
  const toggle = useSelector(({ SIGNALR }) => SIGNALR.toggle);
  useEffect(() => {
    handleCameraPrivacy();
  }, [toggle]);
  const muteAll = useSelector(({ SIGNALR }: RootState) => SIGNALR.muteAll);

  const { selectedLocalMicrophone, selectedLocalSpeaker, selectedLocalCamera } = useSelector(
    ({ SIGNALR }: RootState) => SIGNALR
  );

  useEffect(() => {
    console.log('mute all var changed to', muteAll, 'micPrivacy', micPrivacy);
    if (micPrivacy) return; // do nothing if already muted
    if (muteAll) {
      handleMute();
      // dispatch({ type: '[RECEIVE_MUTE_ALL]', payload: false });
    }
  }, [muteAll]);
  const settingsTooltip = useRef(null);
  const speakerTooltip = useRef(null);
  const videoTooltip = useRef(null);
  const micTooltip = useRef(null);
  // const
  useOutside([settingsTooltip, speakerTooltip, videoTooltip, micTooltip], () => {
    console.log('hey');
    setShownTooltip(FooterTooltips.None);
  });
  return (
    <div id="bottom">
      {true && (
        <div
          className="tasks-option"
          style={role !== UserRole.host ? { visibility: 'hidden' } : {}}
          ref={settingsTooltip}
        >
          <div
            onClick={() => {
              setShownTooltip((ct) => changeTooltip(ct, FooterTooltips.Settings));
            }}
            // onBlur={() => {setShownTooltip((ct) => changeTooltip(ct,FooterTooltips.None))}}
          >
            <img src={settings} alt="settings" className="settings" />
            <br />
            <span>Settings</span>
            <img src={footerToolTip} className="options" alt="settings" />
          </div>
          {shownTooltip === FooterTooltips.Settings && (
            <Tooltip
              variant={TooltipVariants.Footer}
              show
              // Sections={[{ fields }]}
              css={{ bottom: '79%', left: '25%', minWidth: '24rem' }}
              handleClick={() => {
                console.log('Settings tooltips');
              }}
            >
              <Settings />
            </Tooltip>
          )}
        </div>
      )}
      <div className="options-tools">
        <div className="tasks-option-tool" ref={speakerTooltip}>
          <img
            src={speakerPrivacy ? speakerOff : speakerImg}
            className="access-icon"
            alt="speaker"
            onClick={handleSpeakerPrivacy}
          />
          <br />
          <div
            style={{ display: 'flex' }}
            onClick={() => {
              setShownTooltip((ct) => changeTooltip(ct, FooterTooltips.Speaker));
            }}
          >
            <span>
              <TrimTooltip text={selectedLocalSpeaker?.name} length={9} />
            </span>
            <img src={footerToolTip} className="options" alt="option-icon" />
          </div>
          {shownTooltip === FooterTooltips.Speaker && (
            <Tooltip
              variant={TooltipVariants.Footer}
              show
              Sections={[
                {
                  fields: speaker.map((spk: any) => ({
                    ...spk,
                    highlighted: selectedLocalSpeaker?.name === spk?.name
                  }))
                },

                { fields: [{ name: 'Test Speaker' }] }
              ]}
              css={{ bottom: '69%', left: '50%', transform: 'translate(-50%)', minWidth: '14rem' }}
              handleClick={registerLocalSpeaker}
            />
          )}
        </div>
        {role !== UserRole.moderator && (
          <>
            <div className="tasks-option-tool" ref={videoTooltip}>
              <img
                src={cameraPrivacy ? cameraOff : cameraImg}
                alt="camera"
                className="access-icon"
                onClick={handleCameraPrivacy}
              />
              <br />
              <div
                style={{ display: 'flex' }}
                onClick={() => {
                  setShownTooltip((ct) => changeTooltip(ct, FooterTooltips.Video));
                }}
              >
                <span>
                  <TrimTooltip text={selectedLocalCamera?.name} length={9} />
                </span>
                <img src={footerToolTip} className="options" alt="option-icon" />
              </div>
              {shownTooltip === FooterTooltips.Video && (
                <Tooltip
                  variant={TooltipVariants.Footer}
                  show
                  Sections={[
                    {
                      fields: camera.map((cam: any) => ({
                        ...cam,
                        highlighted: selectedLocalCamera?.name === cam?.name
                      }))
                    },
                    { fields: [{ name: 'Video Preview Test' }] }
                  ]}
                  css={{
                    bottom: '69%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    minWidth: '14rem'
                  }}
                  handleClick={registerLocalCamera}
                />
              )}
            </div>
            <div className="tasks-option-tool" ref={micTooltip}>
              {shownTooltip === FooterTooltips.Audio && (
                <Tooltip
                  variant={TooltipVariants.Footer}
                  show
                  Sections={[
                    {
                      fields: mics.map((obj: any) => {
                        // console.log('hello',selectedLocalMicrophone?.name,obj?.name,selectedLocalCamera?.name === obj?.name);
                        return {
                          ...obj,
                          highlighted: selectedLocalMicrophone?.name === obj?.name
                        };
                      })
                    },
                    { fields: fields2 }
                  ]}
                  css={{
                    bottom: '69%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    minWidth: '14rem'
                  }}
                  handleClick={registerLocalMic}
                />
              )}
              <img
                src={micPrivacy ? micMute : mic}
                alt="mic"
                className="access-icon"
                onClick={handleMute}
              />
              <br />
              <div
                style={{ display: 'flex' }}
                onClick={() => {
                  setShownTooltip((ct) => changeTooltip(ct, FooterTooltips.Audio));
                }}
              >
                <span>
                  <TrimTooltip text={selectedLocalMicrophone?.name} length={9} />
                </span>
                <img alt="option-icon" src={footerToolTip} className="options" />
              </div>
            </div>
            {callStarted && (
              <div className={getClassNameForScreenShare()}>
                <div className="tasks-option-tool" onClick={handleShare}>
                  <img src={screenshare} alt="screenshare" className="access-icon" />
                  <br />
                  <span>{isLocalScreenShared ? 'Stop Sharing' : 'Screen Share'}</span>
                  {/* <img src={footerToolTip} className="options" /> */}
                </div>
              </div>
            )}
          </>
        )}
        {callStarted && (
          <div
            data-automation-id="footer-tools"
            className="tools"
            onClick={tools}
            style={isSideBarOpen ? { borderBottom: '0.35rem solid blue',paddingTop:"1.5rem" } : {paddingTop:"1.5rem"}}
          >
            <img src={toolsimage} alt="tools"  className="access-icon"/>
            <br />
            <span>Tools</span>
            {/* <img src={footerToolTip} className="options" /> */}
          </div>
        )}
      </div>
      <div
        className="start-meeting-grp"
        onClick={() => {
          setShownTooltip((ct) => changeTooltip(ct, FooterTooltips.MeetingControls));
        }}
      >
        <Button
          pointer="footer-join-meeting"
          text={text}
          variant={
            !callStarted || text === 'Start Meeting' ? ButtonVariants.Basic : ButtonVariants.Alert
          }
          // style={{ minWidth: '15rem' }}
          onClick={() => {
            handleStartCall();
            callCarbon();
          }}
        />
        {/* Start Meeting */}

        {/* {shownTooltip === FooterTooltips.MeetingControls && (
          <Tooltip
            variant={TooltipVariants.MeetingControls}
            show
            css={{ bottom: '110%', right: '0', minWidth: '110%' }}
            handleClick={() => {
              console.log('handle click');
            }}
          >
            <Button text={'Start Meeting for all'} variant={ButtonVariants.Basic} />
          </Tooltip>
        )} */}
      </div>
    </div>
  );
};

export default Footer;
