/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */

import styles from './MSLogin.module.scss';
import MicrosoftLogin from 'react-microsoft-login';
import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '../../ui-kit/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../utils';
import {
  ACCESS_TOKEN,
  EVENT_KEY,
  ORG_ID,
  ORG_LOGO,
  ORG_NAME,
  OrganisationId
} from '../../common/constants/local-storage-keys';
import { useDispatch } from 'react-redux';
import { type OrgType } from '../Login/Login';
import { Button, ButtonVariants } from '../../ui-kit/Button/Button';
import { getOrgDetail } from '../../http/organisation';
import { logo } from '../../assets';
import FooterForum from '../FooterForum';
import { getUserOrgs } from '../../http/user';
import { msLoginToken } from '../../http/ms-login';
import { type AuthError } from '@azure/msal-common';
import { useAzureADAuth } from '../../config/use-azure-ad';

export const MSLogin: React.FC = () => {
  const { loginAzureADPopup } = useAzureADAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msalInstance, onMsalInstanceChange] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const buttonRef = useRef<any>(null);

  //   const onSuccessFullUserDetails = (res: any): any => {
  //     setLocalStorageItem(USER_KEY, res.userKey);
  //     dispatch({ type: 'SET_USER_DATA', payload: res });
  //   };

  //   const onSuccessFullLogin = (access_token: string): any => {
  //     setLocalStorageItem(ACCESS_TOKEN, access_token);
  //     removeLocalStorageItem(EVENT_KEY); // remove logged in as cohost
  //     setLocalStorageItem('new-login', `login ${Math.random()}`);
  //     navigate('/dashboard');
  //   };
  const loginHandler = (err: AuthError | null, data: any, msal: any): void => {
    // console.log(err?.errorMessage, data);
    // some actions
    if (!err && data) {
      console.log(data);
      onMsalInstanceChange(msal);
      setLocalStorageItem('MsAccessToken', data?.accessToken);
      // The API will be called twice here as well ?!
      // msLoginToken(data?.accessToken).then((res)=>{console.log(res)}).catch((err)=>{console.log(err)});
      // No.
      const email = data?.account?.username;
      getUserOrgs(email)
        .then((res) => {
          if (!res.Payload) {
            setError('500 Server Error');
            return;
          }
          if (res.Payload?.length === 0) {
            setError('User Not Found');
            return;
          }
          // if(res.Payload?.length === 1){
          //   // make api call to verify ms token + store Bearer token + navigate to dashboard
          //   return;
          // }
          const orgKey = res.Payload[0].OrgKey;
          setLocalStorageItem(ORG_ID, orgKey);

          getOrgDetail(orgKey)
            .then((res) => {
              // if user is in a single org , we will not see the org selection screen so we do this here
              setLocalStorageItem(OrganisationId, res?.Payload.Id.toString() ?? '');
              setLocalStorageItem(ORG_LOGO, res?.Payload.OrgLogo ?? '');
              setLocalStorageItem(ORG_NAME, res?.Payload.OrgName ?? '');
            })
            .catch((err) => {
              console.log(err);
            });
          // if(res.Payload?.length === 1){
          //   // make api call to verify ms token + store Bearer token + navigate to dashboard
          //   msLoginToken(data?.accessToken,orgKey)
          //   .then((res)=>{

          //     // do something.

          //     // setLocalStorageItem(ACCESS_TOKEN, res.access_token);
          //     // navigate('/dashboard');

          //     console.log(res)
          //   })
          //   .catch((err)=>{
          //     console.log(err)
          //     setError('500 Server Error');
          //   });
          //   return;
          // }
          setOrganisations(res.Payload);

          msLoginToken(data?.accessToken, orgKey)
            .then((res) => {
              console.log(res);
              setError('success.');
              setOrganisationSelectionScreen(true);
            })
            .catch((err) => {
              console.log(err);
              setError('500 Server Error');
            });
        })
        .catch((err) => {
          console.log(err);
        });

      // make an api call to get orgs for a user ?

      // show all orgs in a dropdown ?
      // if single org, then directly to dashboard

      // else, show org selection screen

      //   setOrganisationSelectionScreen(true);
    }
    if (err) {
      setError(err.message);
      // console.log(err.message)
    }
  };
  const logoutHandler = (): any => {
    msalInstance.logout();
  };
  useEffect(() => {
    setTimeout(() => {
      if (buttonRef.current) {
        // console.log(buttonRef.current);
        buttonRef.current.click();
      }
    }, 3000);
  }, []);

  const [organisations, setOrganisations] = React.useState<OrgType[]>([]);
  const [organisationSelectionScreen, setOrganisationSelectionScreen] = React.useState(false);

  if (organisationSelectionScreen) {
    return (
      <>
        <div className={styles.login_container} style={{ color: 'white', gap: '1rem' }}>
          <div></div>
          <div className={styles.login_containerMid}>
            <img className={styles.login_logo} src={logo} width={215} height={122} alt="logo" />
            <div className={styles.login_heading}> Please Select your Organisation </div>
            <div className="w-100 pt-3 mb-5">
              <select
                className={styles.login_select_org}
                onChange={(e) => {
                  setError('');
                  setLocalStorageItem(ORG_ID, e.target.value.split(' ')[0]);
                  // setLocalStorageItem(OrganisationId, e.target.value.split(' ')[1]);
                  // setOrganisationSelectionScreen(false);
                }}
              >
                {organisations.map((org) => {
                  return (
                    <option key={org.OrgKey} value={`${org.OrgKey} ${org.UserId}`}>
                      {org.OrgName}
                    </option>
                  );
                })}
              </select>
            </div>
            {error?.length > 0 && <div className={styles.login_error}>{error}</div>}
            <Button
              variant={ButtonVariants.Basic}
              onClick={async () => {
                try {
                  // if(loc.pathname === '/login'){
                  // }
                  const orgKey = getLocalStorageItem(ORG_ID);
                  const res = await getOrgDetail(orgKey);
                  setLocalStorageItem(OrganisationId, res?.Payload.Id.toString() ?? '');
                  setLocalStorageItem(ORG_LOGO, res?.Payload.OrgLogo ?? '');
                  setLocalStorageItem(ORG_NAME, res?.Payload.OrgName ?? '');

                  msLoginToken(getLocalStorageItem('MSaccessToken'), orgKey)
                    .then((res) => {
                      console.log(res);
                      setError('success.');
                      // setLocalStorageItem(USER_KEY, res.userKey);
                      // dispatch({ type: 'SET_USER_DATA', payload: res });

                      // removeLocalStorageItem(EVENT_KEY); // remove logged in as cohost
                      // setLocalStorageItem('new-login', `login ${Math.random()}`);

                      // setLocalStorageItem(ACCESS_TOKEN, res.access_token);
                      // navigate('/dashboard');
                    })
                    .catch((err) => {
                      console.log(err);
                      setError('You do not have access to this organisation.');
                    });

                  //   setLocalStorageItem(ACCESS_TOKEN, accessToken);
                  //   await login(
                  //     getLocalStorageItem('MsAccessToken'),
                  //     (res: any) => {
                  //       setLocalStorageItem(ACCESS_TOKEN, res.access_token);
                  //       // if (organisations.length === 1) {
                  //       // setLocalStorageItem(ACCESS_TOKEN, res.access_token);
                  //       //   // navigate('/dashboard');
                  //       // } else {
                  //       //   // setAccessToken(res.access_token);
                  //       //   // setOrganisationSelectionScreen(true);
                  //       // }
                  //       setLocalStorageItem(USER_KEY, res.userKey);
                  //       dispatch({ type: 'SET_USER_DATA', payload: res });

                  //       removeLocalStorageItem(EVENT_KEY); // remove logged in as cohost
                  //       setLocalStorageItem('new-login', `login ${Math.random()}`);
                  //       navigate('/dashboard');
                  //     },
                  //     (err:any) => {
                  //       console.log(err);
                  //       if (err.status === 400) {
                  //         setError('You do not have F360 Producer role in this organisation');
                  //       }
                  //     },
                  //     '7'
                  //   );
                } catch (err) {
                  console.log(err);
                }
              }}
              text={'Continue'}
              css={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}
            />
          </div>
          <FooterForum />
        </div>
      </>
    );
  }
  // return <button>Yes</button>;
  return msalInstance ? (
    <>
      <button className={styles.button} onClick={logoutHandler}>
        Logout
      </button>
      <div className="d-flex align-items-center justify-content-center h-100">
        {error && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <div className={styles.error}>Err: {error}</div>
            <Button
              text="Back to Login"
              variant={ButtonVariants.Basic}
              onClick={() => {
                navigate('/login');
              }}
            />
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="d-flex align-items-center justify-content-center h-100">
      {error ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <div className={styles.error}>Err: {error}</div>
          <Button
            text="Back to Login"
            variant={ButtonVariants.Basic}
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
      ) : (
        <div className={styles.error}>Logging in with Microsoft...</div>
      )}
      <MicrosoftLogin
        // debug
        // tenantId='1d47411a-fa5b-4797-814c-22af4475c2b5'
        tenantUrl={'https://login.microsoftonline.com/1d47411a-fa5b-4797-814c-22af4475c2b5/'}
        clientId="6e3d5a60-f6e9-4b10-ae7f-8cd4dea9eaf9"
        graphScopes={['api://6e3d5a60-f6e9-4b10-ae7f-8cd4dea9eaf9/All']}
        redirectUri={window.location.href}
        // forceRedirectStrategy
        // withUserData

        authCallback={loginHandler}
      >
        <button className={styles.button} ref={buttonRef} style={{ color: 'white' }}>
          Microsoft Login
        </button>
      </MicrosoftLogin>

      <button
        className={styles.button}
        onClick={() => {
          loginAzureADPopup();
        }}
      >
        Login
      </button>
    </div>
  );
  // return (
  //     <div>
  //         <h1>Microsoft Login</h1>
  //         <MicrosoftLogin clientId={YOUR_CLIENT_ID} authCallback={authHandler} />
  //     </div>
  // );
};
