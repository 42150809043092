/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';

import styles from './Analytics.module.scss';
import pin from '../Resources/pin.svg';
import { AnalyticsCard } from './AnalyticsCard';
import { Sentiment } from './Sentiment';
import { Retention } from './Retention';
import { ActiveAttendees } from './ActiveAttendees';
import { ActiveViewers } from './ActiveViewers';
import { BASE_URL } from '../../../../http/api/services';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import people from './people.svg';
import { Session } from 'inspector';
import { Participant } from '../../../../common/components/Participant/Participant';
import { CurrentParticipants } from '../../../CurrentParticipantsTable/CurrentParticipants';
// import { BASE_URL } from '../../../../http/api/services';
export const getParticipants = async (
  token: string,
  sessionId: number,
  orgKey: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/Participant/OnlineParticipant`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      OrgKey: orgKey
    },
    body: JSON.stringify({
      SessionId: sessionId
    })
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export const analyticsMetricData = async (
  token: string,
  eventKey: string,
  sessionId: number,
  orgKey: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/EventAnalytic/Metric`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      OrgKey: orgKey
    },
    body: JSON.stringify({
      EventKey: eventKey,
      SessionId: sessionId
    })
  })
    .then(async (response: { json: () => any }) => response.json())
    .then((json: { Payload: any }) => json.Payload)
    .catch((error: any) => {
      console.error(error);
      return [];
    });
};
export const getmetricanalytics = async (
  token: string,
  eventKey: string,
  sessionId: number,
  orgKey: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/EventAnalytic/MetricReport`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      OrgKey: orgKey,
      AppId: '5'
    },
    body: JSON.stringify({
      SessionId: `${sessionId}`,
      EventKey: `${eventKey}`
    })
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export const Analytics: React.FC = () => {
  const dispatch = useDispatch();
  const { teamsContext, carbonSaved } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const [participants, setParticipants] = useState<any[]>([]);
  const [showParticipants, setShowParticipants] = useState<boolean>(false);
  const [questionAsk, setQuestionAsk] = useState(0.0);
  const [rating, setRating] = useState(0.0);
  const [meetingInvest, setMeetingInvest] = useState('0');
  const [meetingAction, setMeetingAction] = useState('0');
  const [meetingFeedback, setMeetingBack] = useState(0.0);
  const [timeSaved, setTimeSaved] = useState(0.0);
  const [attentiveRates, setAttentiveRates] = useState(0.0);
  const [isFollowUpHovered, setIsFollowUpHovered] = useState(false);
  const [timeIsHovered, setTimeIsHovered] = useState(false);
  const [questionIsHovered, setQuestionIsHovered] = useState(false);
  const [ratingIsHovered, setRatingIsHovered] = useState(false);
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const joinedUsersList = useSelector(({ SIGNALR }: RootState) => SIGNALR.joinedUsersList);
  const handleMouseEnterQuestion = () => {
    setQuestionIsHovered(true);
    setRatingIsHovered(false);
  };
  const handleMouseLeaveQuestion = () => {
    setQuestionIsHovered(false);
  };
  const handleMouseEnterRating = () => {
    setRatingIsHovered(true);
    setQuestionIsHovered(false);
  };
  const handleMouseLeaveRating = () => {
    setRatingIsHovered(false);
  };
  const handleFollowUpEnter = () => {
    // setIsFollowUpHovered(true);

    setIsFollowUpHovered(true);
  };
  const handleFollowUpLeave = () => {
    setIsFollowUpHovered(false);
  };
  const handleMouseEnterTimeSaved = () => {
    setTimeIsHovered(true);
  };
  const handleMouseLeaveTimeSaved = () => {
    setTimeIsHovered(false);
  };
  useEffect(() => {
    const fetchAnalyticsData = (): any => {
      void getParticipants(
        localStorage.accessToken,
        activeSession.SessionId,
        localStorage.orgId
      ).then((data) => {
        // setParticipants(0)
        // setParticipants(data.Payload.length);
        // console.log('data for participants', data);
        // console.log("participants",data?.length);
        setParticipants(data ?? []);
      });
      void analyticsMetricData(
        localStorage.accessToken,
        activeSession.EventDetails.EventKey,
        activeSession.SessionId,
        localStorage.orgId
      ).then((data) => {
        console.log('data', data);
        dispatch({ type: 'SET_CARBON_SAVED', payload: data.CostofSalesSavings.CarbonSaved });
        setTimeSaved(data.CostofSalesSavings.TransportationTimeSaved.toString().concat(' hrs'));
      });
      void getmetricanalytics(
        localStorage.accessToken,
        activeSession.EventDetails.EventKey,
        activeSession.SessionId,
        localStorage.orgId
      ).then((data) => {
        console.log('data', data);
        if (Boolean(data?.EventQuestionAsk)) setQuestionAsk(data?.EventQuestionAsk);
        if (Boolean(data?.EventRating)) setRating(data?.EventRating.toString());
        if (Boolean(data?.MeetingInvest)) setMeetingInvest(data?.MeetingInvest.toString());
        if (Boolean(data?.MeetingAction)) setMeetingAction(data?.MeetingAction.toString());
        if (Boolean(data?.MeetingFeedback)) setMeetingBack(data?.MeetingFeedback);
        if (Boolean(data?.AttendUser)) setAttentiveRates(data?.AttendUser.toString());
      });
    };
    fetchAnalyticsData();
    const interval = setInterval(() => {
      fetchAnalyticsData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className={styles.analytics}>Analytics</div>
      {teamsContext == null && (
        <div className={styles.count}>
          <div className={styles.participants}>
            <img src={people} alt="people" /> {participants.length + joinedUsersList.length} Current
            Participants
          </div>
          <div
            onClick={() => {
              setShowParticipants((scp) => !scp);
            }}
            style={{ cursor: 'pointer' }}
            className={styles.participants}
          >
            {showParticipants ? 'Hide' : 'View'} All
          </div>
        </div>
      )}
      <div className={styles.showAnalytics} style={{ overflowY: 'scroll' }}>
        {showParticipants && (
          <>
            <div className={styles.sentiment}>
              <CurrentParticipants
                heading={'Co-Hosts'}
                userList={joinedUsersList}
                usersPerPage={5}
              />
            </div>
            {participants.length > 0 && (
              <div className={styles.sentiment}>
                <CurrentParticipants
                  heading={'Attendees'}
                  userList={participants.map((p) => ({ ...p, name: p.NameOfParticipant }))}
                  usersPerPage={5}
                />
              </div>
            )}
          </>
        )}
        <div className={styles.sentiment}>
          <Sentiment />
        </div>
        <div className={styles.carbontime}>
          <div className={styles.carbon}>
            <AnalyticsCard
              title={'Carbon saved'}
              description={'In this meet'}
              count={carbonSaved.toString().concat(' lbs')}
            />
          </div>
          <div className={styles.carbon}>
            <AnalyticsCard title={'Time saved'} description={'vs travelling'} count={timeSaved} />
          </div>
        </div>
        {/* <div className={styles.sentiment}>
          <Retention />
        </div> */}

        <div className={`${styles.carbontime} `}>
          <div
            className={`${styles.carbon}`}
            onMouseOver={handleMouseEnterTimeSaved}
            onMouseLeave={handleMouseLeaveTimeSaved}
          >
            <AnalyticsCard
              title={`Fulfilment`}
              description={`"How to invest"`}
              count={meetingInvest}
            />
          </div>
          <div
            className={styles.carbon}
            onMouseOver={handleFollowUpEnter}
            onMouseLeave={handleFollowUpLeave}
          >
            <AnalyticsCard
              title={`Follow Up`}
              description={`"Active sales and..."`}
              count={meetingAction}
            />
          </div>
        </div>
        <div className={styles.carbontime}>
          <div
            className={styles.carbon}
            onMouseEnter={handleMouseEnterQuestion}
            onMouseLeave={handleMouseLeaveQuestion}
          >
            <AnalyticsCard
              title={`Questions asked`}
              description={`View Feedback`}
              count={questionAsk}
            />
          </div>
          <div
            className={styles.carbon}
            onMouseEnter={handleMouseEnterRating}
            onMouseLeave={handleMouseLeaveRating}
          >
            <AnalyticsCard title={`Ratings`} description={`View Ratings`} count={rating} />
          </div>
        </div>
        <div className={styles.activeattendees}>
          <ActiveAttendees data={meetingFeedback} />
        </div>
        <div className={styles.carbontime}>
          <div className={styles.carbon}>
            <AnalyticsCard
              title={`Front of Mind Time`}
              description={`Time spend in meeting focussed on the meeting tab`}
              count={`12:31`}
            />
          </div>
          <div className={styles.carbon}>
            <AnalyticsCard
              title={`Attentive Rates`}
              description={`Watching Now`}
              count={attentiveRates}
            />
          </div>
        </div>
        {/* <div className={styles.viewerdetails}>
          <ActiveViewers />
        </div> */}
      </div>
    </>
  );
};
