import axios, { type AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { v4 } from 'uuid';

import { ACCESS_TOKEN, LOADER, ORG_ID } from '../common/constants';
import { HttpMethod } from '../common/enums';

import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from './local-storage';
import { BASE_URL } from '../http/api/services';
import qs from 'qs';
// type for Window interface
// declare global {
//     interface Window {
//         env: {
//             OCP_APIM_TRACE: string;
//             SUBSCRIPTION_KEY: string;
//         };
//     }
// }
export const httpRequest = async <T>(
  options: AxiosRequestConfig,
  showGlobalLoader = true,
  overrideHeaders = false,
  redirectOn401 = true,
  isSubscriptionKey = true
): Promise<T | undefined> => {
  try {
    if (showGlobalLoader) {
      setLocalStorageItem(LOADER, 'T');
    }
    const isMethodGet = options.method === HttpMethod.GET;

    const accessToken = getLocalStorageItem(ACCESS_TOKEN);
    const orgId = getLocalStorageItem(ORG_ID);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const authorization = accessToken ? `Bearer ${accessToken}` : '';
    const isOcpApimTrace =
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      (window?.env?.OCP_APIM_TRACE || '').toLowerCase() === 'true';
    let headers;
    if (overrideHeaders) {
      headers = {
        OrgId: orgId,
        Authorization: authorization,
        ...options.headers
      };
    } else {
      headers = {
        ...options.headers,
        // 'x-correlation-id': v4(),
        ...(isOcpApimTrace && { 'Ocp-Apim-Trace': 'True' }),
        OrgId: orgId,
        Authorization: authorization
      };
    }
    // headers = {
    //   ...headers,
    //   'Content-Type': 'application/json'
    // }
    const response: AxiosResponse<T> = await axios({
      ...options,
      params: isMethodGet ? { ...options.params, timestamp: Date.now() } : options.params,
      headers: isSubscriptionKey
        ? {
            ...headers,
            'subscription-key': window?.env?.SUBSCRIPTION_KEY
          }
        : headers,
      withCredentials: false
    });

    return response.data;
  } catch (error) {
    const { response } = error as AxiosError<T>;
    if (redirectOn401 && response?.status === 401) {
      // unauthorized
      removeLocalStorageItem(ACCESS_TOKEN);
      // removeLocalStorageItem(ORG_ID);
    }
    return await Promise.reject(response);
  } finally {
    if (showGlobalLoader) {
      setLocalStorageItem(LOADER, 'F');
    }
  }
};

export const fetchQuestions = async (
  token: string,
  evntKey: string,
  sessionId: number
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/PA_Event/InProgressQuestion/${evntKey}/${sessionId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(async (response) => await response.json())
    .then((json) => {
      return json.Payload;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export const liveAnswer = async (
  token: string,
  question: any,
  eventKey: string,
  sessionId: number
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/Question/LiveAnswer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      EventKey: eventKey,
      QuestionId: question.QuestionId,
      QuestionStatus: '902',
      SessionId: sessionId,
      SpeakerId: '0'
    })
  })
    .then(async (response) => await response.json())
    .then((json) => {
      return json.Payload;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export const liveAgenda = async (
  token: string,
  question: any,
  eventKey: string,
  sessionId: number
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/Question/LiveAnswer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      // EventKey: '84573623-aa87-402c-b28d-24d1e181ecbe',
      EventKey: eventKey,
      QuestionId: question.QuestionId,
      QuestionStatus: '901',
      SessionId: sessionId,
      SpeakerId: '0'
    })
  })
    .then(async (response) => await response.json())
    .then((json) => {
      return json.Payload;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export const getCommentData = async (
  token: string,
  eventKey: string,
  sessionId: number,
  orgId: string
): Promise<any> => {
  return await fetch(`${BASE_URL}/EventAnalytic/GetCommentData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      OrgKey: orgId
    },
    body: JSON.stringify({
      EventKey: eventKey,
      SessionId: sessionId
    })
  })
    .then(async (response) => await response.json())
    .then((json) => {
      return json.Payload;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export const Submit = async (
  sessionId: number,
  token: string,
  id: number,
  value: string = 'None'
): Promise<any> => {
  try {
    const resp = await fetch(`${BASE_URL}/Enact/Submit`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        OrgId: localStorage.orgId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        SessionId: sessionId,
        EnactIds: [id],
        WebUrl: value
      })
    });
    const json = await resp.json();
    return json.Payload;
  } catch (error) {
    return [];
  }
};
export const sendAnswers = async (
  eventKey: string,
  userKey: string,
  answers: any,
  token: string
): Promise<any> => {
  try {
    const response = await fetch(`${BASE_URL}/WLEvent/EventRegistrationOptionsSave`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        OrgId: localStorage.orgId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        EventKey: eventKey,
        ReqUserKey: userKey,
        RegistrationOptions: answers
      })
    });
    const json = await response.json();
    return json.Payload;
  } catch (error) {
    return [];
  }
};
export const getQuestions = async (
  eventKey: string,
  meetingFormat: string,
  token: string
): Promise<any> => {
  try {
    const response = await fetch(
      `${BASE_URL}/WLEvent/EventRegistrationOptions/${eventKey}/${meetingFormat}`,
      {
        headers: {
          Authorizaton: `Bearer ${token}`,
          OrgId: localStorage.orgId
        }
      }
    );
    const json = await response.json();
    return json.Payload;
  } catch (error) {
    return [];
  }
};
export const getProductTypeLinks = async (token: string, eventKey: string): Promise<any> => {
  try {
    const response = await fetch(`${BASE_URL}/Enact/Enacts/${eventKey}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        OrgId: localStorage.orgId
      }
    });
    const json = await response.json();
    return json.Payload;
  } catch (error) {
    return [];
  }
};
export const getAllProductTypeLinks = async (token: string, eventKey: string): Promise<any> => {
  // change to new api ...
  try {
    const response = await fetch(`${BASE_URL}/Enact/Enactsave/${eventKey}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        OrgId: localStorage.orgId
      }
    });
    const json = await response.json();
    return json.Payload;
  } catch (error) {
    return [];
  }
};

export const getproductlinks = async (token: string, eventKey: string): Promise<any> => {
  try {
    const response = await fetch(`${BASE_URL}/SalesMeetingNotes/OrgProductSetupLinks/${eventKey}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        OrgId: localStorage.orgId
      }
    });
    const json = await response.json();
    return json.Payload;
  } catch (error) {
    return [];
  }
};
export const sendFollowUpEmail = async (
  token: string,
  userkey: string,
  purpose: string,
  date: any,
  requserkey: string,
  sessionId: number,
  orgName: string
): Promise<any> => {
  return await httpRequest({
    url: `${BASE_URL}/Email/GenerateMeetingReqEmail`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      AllIRMangers: false,
      CoHostUserKey: userkey,
      MeetingPurpose: purpose,
      OrgName: orgName,
      ReqUserKey: requserkey,
      RequestedDate: date,
      SessionId: sessionId,
      userKey: userkey
    }
  });
};
export const UserList = async (token: string, orgId: string): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/User/UserByOrg/${orgId}/25`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export const productLink = async (
  token: string,
  pathName: string,
  userKey: string,
  sessionId: number
): Promise<any | undefined> => {
  await fetch(`${BASE_URL}/EventAnalytic/HitVisitedFulfilment`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      OrgId: getLocalStorageItem(ORG_ID)
    },
    body: JSON.stringify({
      SessionId: sessionId,
      ReqUserKey: userKey,
      PathName: `${pathName}`,
      EventUrl: null
    })
  })
    .then(async (response) => await response.json())
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export const userDurationCreate = async (
  token: string,
  sessionId: number
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/UserDurationUsage/Create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      SessionId: sessionId
    })
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export const userDurationUpdate = async (
  token: string,
  sessionId: number
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/UserDurationUsage/Update`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      Id: sessionId
    })
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};
