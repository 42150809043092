/* eslint-disable react/prop-types */
import './Pagination.scss';
import { useNavigate } from 'react-router-dom';
import React,{useState,useEffect} from 'react';
import Chat from './Chat.png';
import Prev from './Prev.png';
import Next from './Next.png';
import SeatchIcon from './search.svg';
import { Badge } from '../../ui-kit/Badges/Badge';
import {trimToLength} from '../../utils/string-manip';
import { TrimTooltip } from '../../ui-kit/TrimTooltip/TrimTooltip';
const Pagination = (props)=>{
    // const [search , setSearch] = useState('');
    const navigate = useNavigate();
    const handleSearch = (event) => {
        // setSearch(event.target.value);
        if(event.target.value === ''){
            setUserData(props.data);
        }else{
            const filteredData = props.data.filter((val)=>{
                return val.Name.toLowerCase().includes(event.target.value.toLowerCase()) 
                    || val.Host.toLowerCase().includes(event.target.value.toLowerCase()) 
                    || val.Product.toLowerCase().includes(event.target.value.toLowerCase()) 
                    || val.Type.toLowerCase().includes(event.target.value.toLowerCase())
                    || val.Date.toLowerCase().includes(event.target.value.toLowerCase())
                    || val.Time.toLowerCase().includes(event.target.value.toLowerCase())
                    || val.Status.toLowerCase().includes(event.target.value.toLowerCase());
            });
            setUserData(filteredData);
        }
    };
    // const pagesCount = Math.ceil(props.total/props.limit);
    // const pages = range(1,pagesCount);
    // console.log("pagesCount",pagesCount,pages);
    // console.log('props.data',props.data);

    // const {page,nbPages,getPreviousPage,getNextPage} = props;
    const [userData,setUserData] = useState([]);
    const [currentPage,setCurrentPage] = useState([1]);
    const [totalPages,setTotalPages] = useState(0);


    // const APIURL = ''
    useEffect(()=>{
        setUserData(props.data);
        // setTotalPages(props.totalPages);
        setCurrentPage(props.currentPage);
    },[props.data,props.currentPage]);

    useEffect(()=>{
        setTotalPages(Math.ceil(userData.length/itemsPerPage));
    },[userData]);

    const handlePageChange = (newPage)=>{
        setCurrentPage(newPage);
    }
    const handleNextClick = (newPage)=>{
        if(currentPage < totalPages){
            setCurrentPage(currentPage+1);
        }
    }
    const handlePrevClick = (newPage)=>{
        if(currentPage > 1){
            setCurrentPage(currentPage-1);
        }
    }

    const preDisabled = currentPage === 1;
    const nextDisabled = currentPage === totalPages;

    const itemsPerPage = 5;
    const startIndex = (currentPage-1)*itemsPerPage;
    console.log("currentPage",currentPage);
    // console.log("startIndex",startIndex);
    const endIndex = startIndex + itemsPerPage;
    // console.log("endIndex",endIndex);
    // const itemsToDisplay = [];
    const [itemsToDisplay,setItemsToDisplay] = useState([...userData.slice(startIndex,endIndex)]);
    // // setItemsToDisplay(userData.slice(startIndex,endIndex));

    useEffect(()=>{
        setItemsToDisplay(userData.slice(startIndex,endIndex));
    },[userData,currentPage])
    // console.log("itemsToDisplay",itemsToDisplay);
    return (
        <>
        <div className="pagination_btn">
           <div className="main_heading table-fonts">
                    <div>
                        <div className='table-header'>
                            Upcoming Meetings
                        </div>
                        <div className="description">
                            Scheduled Meetings and Calls
                        </div>
                    </div> 
                    <div className='search-container'>
                        <img style={{height:'1.5rem'}} src={SeatchIcon} className='icon-search' alt='search'/>
                        <input placeholder="Search" onChange={handleSearch} className='search_input'/>
                    </div>
                </div>  
                    
           <table className="main_events_table ">
            {/* {itemsToDisplay.length === 0 && <div className="no-meeting">
                    <div className="scheduled">No Meetings Scheduled</div>
                </div>} */}
               <tbody >
              
           {
            itemsToDisplay && itemsToDisplay.length > 0 ? itemsToDisplay.map((val, key) => {
                return (
                    
                    <tr key={key} onClick={()=>{
                        navigate(
                            `/waitingroom/${val.EventDetails.EventProducts[0].ProductKey}/${val.EventDetails.EventKey}/${val.SessionId}`
                          );
                    }}>
                        <td className="Events-row img-table"><img src={Chat} className='icon'/></td>
                        <td className="date-time">
                            <div className="table-fonts Events-row-new">{val.Date}</div>
                            <div className="description ">{val.Time}</div></td>
                        <td className="meeting-names">
                            <div className="table-fonts Events-row-new ">{<TrimTooltip text={val.Name} length={29}/>}</div>
                            <div className="description ">{<TrimTooltip text={val.Host+ ' - ' + val.EventName} length={25}/>} </div></td>
                        <td className="type Events-row product">{val.Product}</td>
                        <td className="type Events-row status">{val.Type}</td>
                        {/* <td className="type Events-row product">{val.Role}</td> */}
                        <td className="status-badge"><Badge badgeType={val.Status}/></td>
                    </tr>
                )
            }):''
        }
          {/* { itemsToDisplay.length === 0 &&  <tr>                    
                <td><div className="no-meeting">
                    <div className="scheduled">No Meetings Scheduled</div>
                </div>
                </td>
            </tr>

                } */}
        </tbody>
        
           </table>
            { itemsToDisplay.length === 0 &&  <>                    
                    <div className="no-meeting">
                        <div className="scheduled">No Meetings Scheduled </div>
                    </div>
                    
                </>

                    }
           <div className={`page-control ${(currentPage > 1 && currentPage < totalPages)?'page-top':'page-down'}`}>
            <button style={currentPage > 1 ? {} : {visibility:'hidden'}} onClick={handlePrevClick} disabled={preDisabled} className="page-no-prev-next">
                <img style={{height:'3.5rem'}} src={Prev} alt='prev'/>
                </button>
                <div className="paging">
           {
            Array.from({length:totalPages},(_,i)=>{
                return (
                    <button className={`page-no ${i+1===currentPage ? 'selected-page' : ''}`} onClick={()=>handlePageChange(i+1) } disabled={i+1===currentPage} key={i}>
                        {i+1}
                    </button>
                )
            })
           }
              </div>

              {<button style={currentPage < totalPages ? {} : {visibility:'hidden'}} onClick={handleNextClick} disabled={nextDisabled} className="page-no-prev-next">
                {<img style={{height:'3.5rem'}} src={Next} alt='next'/>}
                </button>}
            </div>
        </div>
        </>
    )
}

export default Pagination;