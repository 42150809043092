/* eslint-disable react/jsx-key */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import './MeetingDetails.scss';
import { httpRequest } from '../../../../utils';
import greentickimage from './greentick.svg';
import tickimage from './tick.svg';
import { BASE_URL } from '../../../../http/api/services';
import { DropDownUser } from './DropDownUser';
import Select, { StylesConfig } from 'react-select';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import { Multiselect } from 'multiselect-react-dropdown';
// import 'react-select/dist/react-select.css';
export const sendAllMessage = async (
  token: string,
  sessionId: string | number,
  reqUserKey: string,
  userKey: string
): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/Email/GenerateMeetingReqEmail`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      AllIRMangers: true,
      // AllIRMangers: false,
      CoHostUserKey: userKey,
      // OrgName: null,
      // ReqUserKey: "64c927df-9835-45bf-af78-30c8788bc560",
      SessionId: sessionId,
      // AllIRMangers: false
      // CoHostUserKey
      // :
      // "2db8cce6-2c34-4885-be3a-325ed1c15e46"
      // MeetingPurpose: ,
      OrgName: 'Veersa Tech',
      ReqUserKey: reqUserKey,
      // :
      // "64c927df-9835-45bf-af78-30c8788bc560"
      // RequestedDate: "2023-07-31T18:30:00.000Z",

      userKey
    }
  });
};

export const sendIndividual = async (
  token: string,
  sessionId: string | number,
  reqUserKey: string,
  userKey: string
): Promise<any | undefined> => {
  console.log('check send ind');
  return await httpRequest({
    url: `${BASE_URL}/Email/GenerateMeetingReqEmail`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      AllIRMangers: false,
      CoHostUserKey: userKey,
      // OrgName: null,
      ReqUserKey: reqUserKey,
      SessionId: sessionId,
      // AllIRMangers: false
      // CoHostUserKey
      // :
      // "2db8cce6-2c34-4885-be3a-325ed1c15e46"
      // MeetingPurpose: ,
      OrgName: 'Veersa Tech',
      // ReqUserKey
      // :
      // "64c927df-9835-45bf-af78-30c8788bc560"
      // RequestedDate: "2023-07-31T18:30:00.000Z",

      userKey
    }
  });
};
export const UserList = async (token: string, orgKey: string): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/User/UserByOrg/${orgKey}/15`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export const MeetingDetails: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  function onSelect(selectedList: any, selectedItem: any): void {
    // ...
  }

  function onRemove(selectedList: any, removedItem: any): void {
    // ...
  }
  const [sendToAll, setSendToAll] = useState(false);
  function check(): void {
    if (!sendToAll) {
      void sendAllMessage(
        localStorage.accessToken,
        activeSession.SessionId,
        userDetails.UserKey,
        userDetails.UserKey
      );
      setSendToAll(true);
    } else {
      setSendToAll(false);
    }
  }
  const [user, SetUser] = useState('');
  const [questiontick, setQuestionTick] = useState(false);
  const [userList, setUserList] = useState([{}]);
  function sendLink(): void {
    console.log('Send Meeting API check');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
    for (let i = 0; i < selectedOptions.length; i++) {
      void sendIndividual(
        localStorage.accessToken,
        activeSession.SessionId,
        selectedOptions[i].value,
        userDetails.UserKey
      );
    }
    setTimeout(() => {
      setQuestionTick(false);
      setSelectedOptions([]);
    }, 1000);

    if (user.length > 0) {
      console.log('Send Meeting API check function');

      setQuestionTick(true);
      void sendIndividual(
        localStorage.accessToken,
        activeSession.SessionId,
        user,
        userDetails.UserKey
      );
      setTimeout(() => {
        setQuestionTick(false);
        SetUser('');
      }, 1000);
    }
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const temp_data: any[] = [];
  useEffect(() => {
    void UserList(localStorage.accessToken, localStorage.orgId).then((data) => {
      setUserList(data);
      console.log('data', data);

      data.forEach((element: any) => {
        const a = {
          label: element.FirstName,
          id: element.UserId,
          value: element.UserKey,
          userkey: element.UserName
        };
        temp_data.push(a);
      });

      setUserList(temp_data);
      // for( var i = 0; i < data.length;i++){

      // }
    });
  }, []);

  // const options = [
  //   { name: 'Option 1️⃣', id: 1 },
  //   { name: 'Option 2️⃣', id: 2 }
  // ];

  // const aquaticCreatures = [
  //   { label: 'Shark', value: 'Shark' },
  //   { label: 'Dolphin', value: 'Dolphin' },
  //   { label: 'Whale', value: 'Whale' },
  //   { label: 'Octopus', value: 'Octopus' },
  //   { label: 'Crab', value: 'Crab' },
  //   { label: 'Lobster', value: 'Lobster' }
  // ];
  const customStyles = {
    menu: (provided: any, state: any) => ({
      backgroundColor: 'black'
    }),
    control: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      minHeight: 'unset',
      backgroundColor: 'transparent'

      // border: 'none',
      // borderBottom: 'white'
    }),
    IndicatorsContainer: (provided: any, state: { isFocused: boolean }) => ({
      display: 'none'
    }),

    clearIndicator: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      display: 'none' // Hide the clear indicator (x icon)
    }),
    dropdownIndicator: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      display: 'none' // Hide the dropdown arrow
    }),

    option: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      // backgroundColor: (state.isSelected) ? 'blue' : 'white', // Set your desired color here
      color: state.isFocused ? 'black' : 'white' // Set text color based on selection
    }),
    multiValue: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      backgroundColor: 'rgba(10, 14, 23, 0.60)',
      color: '#F8F8F8',
      /* subheading */
      fontFamily: 'Albert Sans',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '600'

      // Add other custom styles as needed
    }),
    multiValueLabel: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      color: 'white'
    })
    // menu:(provided:any, state:{ isFocused: boolean })=>({
    //   ...provided,
    //   background: 'rgba(10, 14, 23, 0.60)'
    // })
  };
  return (
    <div className="sharemeeting">
      <div className="share-head">Share Meeting Details</div>

      <div className="team">Individual Team Members</div>

      <div style={{ position: 'relative' }} className="sendHost">
        {/* <select 
          placeholder="Start Typing..."
          className="details-input"
          // value={user}
          // multiple={true} 
          onChange={(e) => {
            SetUser(e.target.value);
          }}
        >
          <option value="" className="share">
            Start Typing...
          </option>
          {userList.map((question: any, index: React.Key | null | undefined) => (
            <option value={question.value}>{question.label}</option>
          ))}
        </select>
   */}
        <div>
          <Select
            className="change"
            isMulti
            value={selectedOptions}
            options={userList}
            onChange={(v) => {
              setSelectedOptions([...v]);
            }}
            styles={customStyles}
            // className=
            // value={user}
          />
          <img
            alt="tick"
            src={questiontick ? greentickimage : tickimage}
            onClick={sendLink}
            style={{
              position: 'absolute',
              right: '0.7rem',
              top: '50%',
              transform: 'translateY(-50%)',
              // bottom: '15px',
              width: '1.69rem'
              // height: '16px'
            }}
          />
        </div>
        {/* <div className="dropdownuser">
          <DropDownUser user={userList}/>
        </div> */}
        {/* <Multiselect
        isObject={false}
// options={userList} // Options to display in the dropdown
// selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
// onSelect={onSelect} // Function will trigger on select event
        onRemove={(event)=>{console.log(event)}}
        onSelect={(event)=>{console.log(event)}}
        options={["Option1","Option2","Option3","Option4"]}// Function will trigger on remove event
// displayValue="name" // Property name to display in the dropdown options
/> */}
      </div>
      <div className="d-flex team">
        <input
          type="checkbox"
          id="team"
          name="team"
          value="all"
          className="check"
          onClick={check}
        />
        <label htmlFor="team" className="team-members">
          {' '}
          All Team Members
        </label>
        <br />
      </div>
    </div>
  );
};
