import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Participant } from '../../common/components/Participant/Participant';
import { Carousel } from '../../common/components/Carousel/Carousel';
import { ActiveScreen } from './ActiveScreen';
import { Window } from './Window/Window';
import styles from './waitingRoom.module.scss';
import { useVidyo } from '../../common/hooks/useVidyo';
import './../VideoCall/VideoCall.scss'
import { useSelector , useDispatch } from 'react-redux';
import { UserRole } from '../../redux/reducers/user';
import { getUserRole } from '../../utils/user-role';
import { debounce } from '../../utils/extend';
import { useParams , useNavigate } from 'react-router-dom';
import { emptySession } from '../../redux/reducers/signalr';
import { endSession, startSession } from '../../http/meeting';
import { getButtonText } from '../../utils/button-text';
import { getLocalStorageItem,userDurationCreate,userDurationUpdate } from '../../utils';
import { ORG_ID } from '../../common/constants';
import { commonGroupId } from '../../signalr/redux-middleware-signalr';
import ClosedCaptions from '../../components/ClosedCaptions/ClosedCaptions';
import { Popup } from '../../components/Popup/Popup';

// import './../VideoCall/VideoConnector.css'
// const roomCredentials = {
//   host: "https://apidemo.staging.vidyoplatform.io",
//   roomKey: "96lGVOxOM3",
//   userPin: "0mhY4K1jCr"
// }

export const meetingType = {
  401 : '1x1',
  402 : 'FTM'
};

export const meetingFormat = {
  1: 'FTM',
  2: '1x1'
}

export const WaitingRoom = () => {
  // function greeter(fn: (a: string) => void) {
  //   fn("Hello, World");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPop, setShowPop] = useState('Yes');
  const [joinButtonText,setJoinButtonText] = useState("");
  const [id,setId] = useState();
  const [
    isParticipantConnectedCommandSend,
    setisParticipantConnectedCommandSend,
  ] = useState(false)

  const userDetails = useSelector(({ USER }) => USER.userDetails);
  const role = useSelector(({ USER }) => USER.role);
  const activeScreen = useSelector(({ SIGNALR }) => SIGNALR.activeScreen);
  const activeSession = useSelector(({ SIGNALR }) => SIGNALR.activeSession);
  const roomCredentials = useSelector(({ SIGNALR }) => SIGNALR.roomCredentials);
  // const hubConnected = useSelector(({SIGNALR})=> SIGNALR.hubConnected);
  const startForWL = useSelector(({SIGNALR})=> SIGNALR.startForWL);
  const commandFromGroup = useSelector(({SIGNALR})=> SIGNALR.commandFromGroup);
  const screenShare = useSelector(({SIGNALR})=> SIGNALR.screenShare);
  const producerConnected = useSelector(({SIGNALR})=> SIGNALR.producerConnected);
  const userId = useSelector(({ APPSTATE }) => APPSTATE.Id);
  const { isCCon } = useSelector(({ SIGNALR }) => SIGNALR);

  let {productKey,eventKey,sessionId} = useParams();
  sessionId = parseInt(sessionId);
  console.log(productKey,eventKey,sessionId);
  
  useEffect(()=>{
    if(activeSession.SessionId === 0){
      dispatch({type:'GET_WAITING_ROOM_DETAILS',payload:{productKey,eventKey,sessionId,userDetails}});
    } else{

      dispatch({type: 'GET_ROOM_CREDENTIALS',payload:{eventKey, sessionId}});
      if(userDetails.UserKey !== ''){
        const role = getUserRole(activeSession, userDetails.UserKey);
        dispatch({ type: 'SET_ROLE', payload: { role } });
      }
      // if(hubConnected){
        const groupId = "room_" + eventKey + "_" + sessionId;
        dispatch({type: 'CONNECT_TO_GROUP',payload:{groupId,role}});
      // }

      if(activeSession.Status === 'In Progress'){
        dispatch({type:'SET_START_FOR_WL', payload: true});
      }
    }
  },[activeSession,userDetails]);
  
  useEffect(()=>{
    return () => {
      dispatch({type:'SET_SIDEBAR_OPEN', payload: false});
      dispatch({type: 'SET_ACTIVE_SESSION', payload: emptySession});
      dispatch({type: 'SET_START_FOR_WL',payload: false});
      dispatch({type:'DESTROY_HUB_CON'});
      dispatch({type:'SET_CHECKLIST_SHOWN',payload:false});
      dispatch({type:'SET_PRODUCER_CONNECTED',payload:true});
      dispatch({type:'SET_ACTIVE_SCREEN',payload:'empty'});
      dispatch({type:'SET_SENTIMENT_GRAPH',payload:[]});
      dispatch({type: 'SET_AUTOMATIC_PRESENTATION_SWITCH',payload: false});
      dispatch({type:'SET_IS_LOCAL_SCREEN_SHARED',payload:false}); // is local screen shared should be turned off after leaving the meeting.
      dispatch({ type: 'SET_SCREENSHARE', payload: false }); // state for new cohost joiners to know if screen share is enabled for them 
      dispatch({type:'SET_CC',payload:false});
    }
  },[]);

  useEffect(()=>{
 
  })
  // useEffect(()=>{
    // dispatch({type: 'GET_ROOM_CREDENTIALS',payload:{eventKey:activeSession.EventDetails .EventKey, sessionId:activeSession.SessionId}});
  // },[])

  // useEffect(()=>{
  //   // const groupId = "room_" + activeSession.EventDetails .EventKey + "_" + activeSession.SessionId;
  // if(hubConnected){
  //   const groupId = "room_" + eventKey + "_" + sessionId;
  //   dispatch({type: 'CONNECT_TO_GROUP',payload:{groupId}});
  // }
  // },[hubConnected])

  // const [participantsList, setParticipantList] = [{}]
  
  // useEffect(()=>{
  //   return () => {
  //     dispatch({type:'SET_SIDEBAR_OPEN', payload: false});
  //   }
  // },[]);

  useEffect(() => {
    // disp
    if(activeScreen === 'empty'){
      return;
    }
    console.log('compare',activeScreen,localParticipant);
    if(start && activeScreen !== undefined && activeScreen !== ""){
      setActiveUser(activeScreen,false)
    }
    if(start && activeScreen===""){
      setActiveUser("",false)
    }
    dispatch({type:'SET_ACTIVE_SCREEN',payload:'empty'})
    
    // if(activeScreen === localParticipant[0]?.userId){
    //   // handleDivSwitch(localParticipant[0]?.userId)
    //   // setActiveScreen('');
      // dispatch({type:'SET_ACTIVE_SCREEN',payload:''})
    // }
  }, [activeScreen]);

  // useEffect(() => {
  //   if(role === UserRole.host){
  //     // handleDivSwitch()
  //   }
  // },[]);
  // const [audoDevices, setAudioDevices] = useState([])
  // const [videoDevices, setVideoDevices] = useState([])
  const toggle = useSelector(({SIGNALR}) => SIGNALR.toggle);
  // const [speakerDevices, setSpeakerDevices] = useState([])
  // const [connector, setConnector] = useState(false)
  // }
  const [hideSideBar, setHideSideBar] = useState(false);
  const {
    onVidyoClientLoaded,
    startVidyoConnector2, 
    startVideoCall, 
    shareScreen, 
    videoConnectorCopy, 
    localCameraObj,
    start,
    particpantsArr,
    showSelf,
    registerLocalSpeaker,
    registerLocalMic,
    setCamera, 
    leaveCall, 
    handleMute, 
    handleSpeakerPrivacy,
    handleCameraPrivacy,
    micPrivacy,
    cameraPrivacy,
    speakerPrivacy,
    microPhoneMute,
    audioDevices,
    videoDevices,
    speakerDevices,
    // handleDivSwitch,
    localParticipant,
    setActiveUser,
    activeUser,
    isPpt,
    setPositionActive,
    stopShareScreen,
    remoteWindowShare,
    isJWTReady
  } = useVidyo('forum360.platform.vidyo.io', userDetails.FullName, roomCredentials.roomKey, roomCredentials.roomPin, role)
  // useVidyo('test.platform.vidyo.io', userDetails.FullName, 'oLeVLfNufz', roomCredentials.roomPin, role)
  // let hideSideBar = 0;
  function tool() {
    setHideSideBar(!hideSideBar);
    dispatch({type:'SET_SIDEBAR_OPEN', payload: !hideSideBar});
    // console.log('mmm', hideSideBar);
    // setPositionActive(activeUser)
    // setActiveUser(activeUser)
  }

  useEffect(() => {
    const handleResize = 
    debounce(
      () => {
        console.log('resize called');
      setPositionActive(activeUser)
    }
    , 100);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () =>
      window.removeEventListener('resize', handleResize);
  }, [activeUser]);

  useEffect(()=>{
    setPositionActive(activeUser)
  }, [hideSideBar])

  useEffect(()=>{
    if(activeSession.SessionId!==0){
      // console.log('mf',activeSession,activeSession.EventDetails.MeetingFormat);
      setJoinButtonText(getButtonText(meetingFormat[activeSession.EventDetails.MeetingFormat], start, startForWL, role))
    }
  },[activeSession,start,startForWL,role]);

  useEffect(()=>{
    let interval= 0;
    if(start && startForWL && role !== UserRole.cohost){
      dispatch({type:'GET_SENTIMENT_GRAPH_DATA',payload:{sessionId}});
      interval = setInterval(()=>{
        dispatch({type:'GET_SENTIMENT_GRAPH_DATA',payload:{sessionId}});
      },60000)
    }
    return () => {
      clearInterval(interval);
    }
  },[startForWL,start,role])

  
  useEffect(()=>{
    if(start){
      if(role=== UserRole.host){
        dispatch({type:'SET_CHECKLIST_SHOWN',payload:true});

      }
      dispatch({type:'CREATE_USER',payload:{SessionId:activeSession.SessionId}})
    }
  },[start])

  useEffect(() => {
    if (particpantsArr.length > 0) {
      console.count('pArr called')
      if (role === UserRole.host) {
        dispatch({
          type: 'SET_PRODUCER_CONNECTED',
          payload: true,
        })
      } else if (!isParticipantConnectedCommandSend) {
        dispatch({
          type: 'SEND_COMMAND_TO_GROUP',
          payload: {
            command: '[PARTICIPANT-CONNECTED]',
            message: `${userDetails.FullName}`,
          },
        })
        setisParticipantConnectedCommandSend(true)
      }
    } else {
      setisParticipantConnectedCommandSend(false)
    }
  }, [particpantsArr])

  useEffect(()=>{
    if(role!==UserRole.host){
      if(start && !producerConnected){
        // navigate('/dashboard');
        // window.location.reload();
      }
    }
  },[producerConnected])
  
  
  useEffect(()=>{
    if(start && role===UserRole.host && commandFromGroup.command === '[PARTICIPANT-CONNECTED]'){
      console.log('activeUser is',activeUser);
      dispatch({
        type:'SEND_COMMAND_TO_GROUP',
        payload:{
          command:'[MEETING-METADATA]',
          message: JSON.stringify({activeScreen:activeUser, screenShare,name:commandFromGroup.message,isHostCCOn:isCCon})
        }
      })
    }else if(start && role!==UserRole.host && commandFromGroup.command === '[MEETING-METADATA]'){
      const {activeScreen, screenShare,name,isHostCCOn} = JSON.parse(commandFromGroup.message);
      if(isHostCCOn){
        dispatch({type:'SET_CC',payload:true});
      }
      if(name === userDetails.FullName){
        dispatch({type:'SET_SCREEN_SHARE',payload:screenShare});
        // setTimeout(()=>{
          // console.log('called after 1.75 seconds');
        dispatch({type:'SET_ACTIVE_SCREEN',payload:activeScreen});
        // },1750)
      }
    }
    if(start && role!==UserRole.host && commandFromGroup.command === '[PRODUCER-CONNECTED]'){
      // dispatch({type:'SET_PRODUCER_CONNECTED',payload:true});
      // dispatch({type:'GET_WAITING_ROOM_DETAILS',payload:{productKey,eventKey,sessionId,userDetails}});
      
      // TODO : change it to call saga (where status of meeting will be in progress and this variable will be set automatically)
      dispatch({type:'SET_START_FOR_WL', payload: true});
    }
    if(commandFromGroup.command === '[GET-USER-METADATA]'){
      dispatch({
        type:'SEND_COMMAND_TO_GROUP',
        payload:{
          command:'[USER-METADATA]',
          groupId: commonGroupId,
          message: JSON.stringify({start,role,userDetails,orgKey:getLocalStorageItem(ORG_ID),path:window.location.pathname,
            productKey,eventKey,sessionId
          })
        }
      })
    }
    if(commandFromGroup.command === '[ENDMEETING]'){
      navigate('/dashboard');
    }
  },[commandFromGroup])

  useEffect(()=>{
    let update = 0;
    if(userId !== 0){
    update = setInterval(()=>{
      userDurationUpdate(localStorage.accessToken,userId).then((data)=>{
        // console.log("data",data)
      })
      .catch((e)=>{
        console.log("Error in Creating UserId");
      })
    },15000)
    }
    return ()=>{clearInterval(update)};
  },[userId])
  function discardMessage() {
    setShowPop('No');
  }
  function sendMessagePopup() {
    // if (sentMessage === 'Welcome') {
    //   void Welcome();
    // }
    // if (sentMessage === 'Rate and Review') {
    //   void Rate();
    // }
    // if (sentMessage === 'Meeting Delay') {
    //   void Delay();
    // }
    // if (sentMessage === 'Technical Difficulties') {
    //   void TechnicalDifficulties();
    // }

    setShowPop('No');
  }

  // const tool = 1;
  useEffect(()=>{
    console.log('role in useffect is',role);
    if(role !== UserRole.empty){
    // loadNativeWebRTCVidyoClientLibrary()
    console.log("use Effect")
    onVidyoClientLoaded({ state: 'READY', description: 'Native SCIP + WebRTC' });
    // setConnector(true)
    }

    return () => {
      console.log('clean up waiting room', start);
      // if(start){
        // leaveCall()
      // }
      dispatch({type:'SET_AGENDA_QUESTIONS', payload: []});
    }
  }, [role])
  // useEffect(()=>{
    
  // }, [particpantsArr])

  const startAsHostFTM = async () => {
    try {
      await startVideoCall();
      await startSession(activeSession.EventDetails.EventKey, activeSession.SessionId);
      dispatch(
      { type:'SEND_COMMAND_TO_GROUP', 
        payload: {command: '[PRODUCER-CONNECTED]'}
      });
      // letting this happen for 1x1 meetings as well (we never use this variable for 1x1) 
      dispatch({type:'SET_START_FOR_WL', payload: true});
    } catch (error) {
      console.log(error);
    }
  };

  const endAsHost = async () => {
    try {
      endSession(activeSession.EventDetails.EventKey, activeSession.SessionId).then(()=>{
        console.log('session ended');
      }).catch((e)=>{
        console.log('error in ending session',e);
      });
      dispatch({
        type:'SEND_COMMAND_TO_GROUP',
        payload: {command:'[ENDMEETING]'}
      });
      // dispatch(
      //   { type:'SEND_COMMAND_TO_GROUP', 
      //   payload: {command: '[PRODUCER-DISCONNECTED]'}
      // });
      // dispatch({type:'DESTROY_HUB_CON'});
      await leaveCall();
      // we reset the startForWL variable on destroy
      // dispatch({type:'SET_START_FOR_WL', payload: false});
    } catch (error) {
      console.log(error);
    }
  };

  const getFunForButton = (text) => {
    
    // userDurationCreate(localStorage.accessToken,activeSession.SessionId).then((data)=>{
    //   console.log("data Create",data);
    //   setId(data);

    // })

    console.log("function created");
    if(text === 'Start Meeting'){
      return startAsHostFTM; 
    }else if(text === 'Join Waiting Room'){
      return startVideoCall;
    }else if(text === 'Join Meeting'){
      return startVideoCall;
    }else if(text === 'End'){
      return endAsHost;
    }else if(text === 'Leave Meeting'){
      return leaveCall;
    }
    
  }

  return (
    <>
      <div className={styles.showPop}>
        {showPop === 'Yes' && false && (
          <div className={styles.messagepop}>
            <div className={styles.poptext}>
              Send your message “Welcome to Investing in Inflationary Times” to all attendees?
            </div>
            <div className={styles.popButtons}>
              <button data-automation-id="button-no" className={styles.no} onClick={discardMessage}>
                No
              </button>
              <button data-automation-id="button-yes" className={styles.yes} onClick={sendMessagePopup}>
                Yes
              </button>
            </div>
          </div>
        )}
      </div>
      <div className={styles.root} style={toggle ? {display:'none'}:{}}>
        {hideSideBar && (
          <div className={styles.showSideBar}>
            <Sidebar tools={tool} />
          </div>
        )}
        {/* <div style={{ position: 'relative' }}> */}
          {/* <div> */}
            {/* {' '} */}
            <div className={hideSideBar ? styles.waitingScreen : styles.waitingScreenSide}>
              <div className={hideSideBar ? styles.activeScreenSide : styles.activeScreenCenter}>
                <ActiveScreen callStarted={start} userName={'userName'} />
              </div>
              {/* <br /> */}
              <div className={!hideSideBar ? styles.participantContainer: styles.participantContainerSide}>
                {/* <div> PPT here </div> */}
                {/* <Participant isPPT/> */}
                {isPpt && <Participant style={{marginRight:'1rem'}} isPPT={isPpt} participant={'ppt'} participantName='Presentation' divId='ppt' active={activeUser === 'ppt'} setActive={role === "host" ? setActiveUser: ()=>{console.log('hi')}}/>}
                <Carousel maxWidth='90%'>
                {!start && role!== UserRole.moderator && <Participant isMuted={micPrivacy} participant={{}} participantName={userDetails.FullName} divId={'user-init'} active={false} setActive={()=>{console.log('user set')}}/>}
                {particpantsArr.map(item=>{
                    // return !item.isLocal || showSelf 
                    // ? <Participant participant={item} participantName={item.name} divId={item.userId} key={item.id} />
                    // : null
                    
                    return <Participant 
                      // if the isMuted property is not present the code should return true
                      // as this is the case where the user hasn't given permission of mic
                      isMuted={item.isLocal ? micPrivacy : (item?.isMuted ?? true)}
                      participant={item} 
                      participantName={item.name} 
                      divId={item.userId} 
                      key={item.id} 
                      active={activeUser === item.userId} 
                      setActive={role === "host" ? setActiveUser: ()=>{console.log('hi')}}
                    />
                    
                })}
                </Carousel>
              </div>
            </div>
            {/* <div className="waiting-screen">
              <ActiveScreen 
               callStarted={start}
               userName={'userName'}
              />
          </div> */}
            {/* <div>
              <Window />
            </div> */}
          {/* </div> */}
          {/* <div className="participantContainer">
          {!start && <Participant participant={{}} participantName={"USER NAME"} divId={'user-init'} />}
          {particpantsArr.map(item=>{
              return !item.isLocal || showSelf 
              ? <Participant participant={item} participantName={item.name} divId={item.userId} key={item.id} />
              : null
          })}
        </div> */}
        {/* </div> */}
        {/* <button onClick={()=>{console.log('activeUser is',activeUser);}}>ACTIVE USER</button> */}
        <div id='hide-cc' style={{display:'none'}}>
          {isJWTReady && <ClosedCaptions />}
        </div>
        <Footer
          tools={tool}
          mics={audioDevices} 
          camera={videoDevices} 
          speaker={speakerDevices}
          muted={microPhoneMute}
          speakerPrivacy={speakerPrivacy}
          micPrivacy={micPrivacy}
          cameraPrivacy={cameraPrivacy}
          handleShareScreen={shareScreen}
          handleStopShareScreen={stopShareScreen}
          handleStartCall={
            // getFunForButton(getButtonText(meetingType[activeSession.EventDetails.EventTypeId], start, startForWL, role))
            () =>{
              // !start && startVideoCall()
              // console.log(getButtonText(meetingFormat[activeSession.EventDetails.MeetingFormat], start, startForWL, role));
              getFunForButton(joinButtonText)();
            }
          }
          text={joinButtonText}
          callStarted={start}
          registerLocalMic={registerLocalMic}
          handleMute={handleMute}
          registerLocalCamera={setCamera}
          registerLocalSpeaker={registerLocalSpeaker}
          handleCameraPrivacy={handleCameraPrivacy}
          handleSpeakerPrivacy={handleSpeakerPrivacy}
          activeUser={activeUser}
          setActiveUser={setActiveUser}
        />
      </div>
    </>
  );
};
