export const LOADER = 'loader';
export const ACCESS_TOKEN = 'accessToken';
export const EXPIRES_ON = 'expiresOn';
export const ACCOUNT = 'account';
export const TIME_OF_AUTO_LOGOUT = 'timeOfAutoLogout';
export const LOGOUT_IN_PROCESS = 'logoutInProcess';
export const SITE_ID = 'siteId';
export const ORG_ID = 'orgId'; // also known as orgKey
export const OrganisationId = 'organisationId';
export const USER_KEY = 'userKey';
export const EVENT_KEY = 'eventKey';
export const SESSION_ID_LIST = 'sessionIdsList';
export const ORG_NAME = 'orgName';
export const ORG_LOGO = 'orgLogo';
export const TEAMS_CHAT_ID = 'teamsChatId';
export const TEAMS_EMAIL_ID = 'teamsEmailId';
