import React, { useEffect, useState } from 'react';
import './Questions/Answered.scss';
import { AgendaCard } from './AgendaCard';
export interface AnsweredQuestionProps {
  answeredQuestions: any;
  liveAnswer: any;
}
export const Answered: React.FC<AnsweredQuestionProps> = ({
  answeredQuestions,
  liveAnswer
}: AnsweredQuestionProps) => {
  const [newQuestions, setNewQuestions] = useState<any>([]);
  useEffect(() => {
    setNewQuestions([...answeredQuestions]);
  }, [answeredQuestions]);
  return (
    <>
      <div>
        {newQuestions.length > 0 &&
          newQuestions.map((question: any, key: any) => (
            <div className="questiontabs" key={question?.QuestionId}>
              <AgendaCard question={question} liveAnswer={liveAnswer} checked={true} />
            </div>
          ))}
      </div>
    </>
  );
};
