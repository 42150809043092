export const processCommand = (storeAPI: any, req: any): any => {
  switch (req.command) {
    case '[ALLOW-SCREENSHARE]':
      storeAPI.dispatch({
        type: 'SET_SCREENSHARE',
        payload: true
      });
      break;

    case '[DISALLOW-SCREENSHARE]':
      storeAPI.dispatch({
        type: 'SET_SCREENSHARE',
        payload: false
      });
      break;
    case '[SWITCH-ACTIVESCREEN]':
      console.log('switch active screen called from process command', req.message);
      storeAPI.dispatch({
        type: 'SET_ACTIVE_SCREEN',
        payload: req.message
      });
      break;

    default:
      console.log(' default block called from process command', req);
      storeAPI.dispatch({
        type: 'SET_COMMAND_FROM_GROUP',
        payload: req
      });
      setTimeout(() => {
        storeAPI.dispatch({
          type: 'SET_COMMAND_FROM_GROUP',
          payload: {}
          // clear the command so that if the same command is sent again, it will be processed
          // but shouldn't it be processed anyway because it will be a new object?
          // and new objects are always different from each other ?
        });
      }, 750);
      break;
  }
};
