import React from 'react';
import styles from './badge.module.scss';

export enum BadgeVariants {
  InProgress = 'In Progress',
  StartingSoon = 'Starting Soon',
  Upcoming = 'Upcoming',
  HappeningNow = 'Happening Now',
  Completed = 'Completed',
  Unknown = 'Unknown'
}
export interface BadgeProps {
  badgeType?: BadgeVariants;
  text?: string;
  css?: React.CSSProperties;
}

const getClassName = (badgeType: BadgeVariants): string => {
  switch (badgeType) {
    case BadgeVariants.InProgress:
      return `${styles.inProgress}`;
    case BadgeVariants.StartingSoon:
      return `${styles.startingSoon}`;
    case BadgeVariants.Upcoming:
      return `${styles.upcoming}`;
    case BadgeVariants.HappeningNow:
      return `${styles.happeningNow}`;
    default:
      return '';
  }
};

export const Badge: React.FC<BadgeProps> = ({ badgeType, css, text }: BadgeProps) => {
  if (badgeType) {
    return <div className={`${styles.badge} ${getClassName(badgeType)}`}>{badgeType}</div>;
  }
  return (
    <div className={styles.badge} style={css}>
      {text}
    </div>
  );
};
