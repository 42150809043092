import { useMsal } from "@azure/msal-react";
import { loginRequest, logoutRequest } from "./authConfig";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { removeLocalStorageItem, setLocalStorageItem } from "../utils";
import { ACCESS_TOKEN, ACCOUNT, EXPIRES_ON, TIME_OF_AUTO_LOGOUT } from "../common/constants/local-storage-keys";
import { msLoginToken } from "../http/ms-login";
// import { dashboardURL, indexURL} from "../routes/routes";
// import Cookies from 'universal-cookie';

export const useAzureADAuth = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
//   const cookies = new Cookies();

  const loginAzureADPopup = useCallback(() => {
    instance
      .loginPopup(loginRequest)
      .then((e) => {
        console.log(e);
        console.log(e.accessToken);
        msLoginToken(e.accessToken,'795d68b3-49a8-4747-befd-17addcde0844')
            .then((res)=>{
              console.log(res)
              // setError('success.')
              // setOrganisationSelectionScreen(true);
            })
            .catch((err)=>{
              console.log(err)
              // setError('500 Server Error');
            });
        setLocalStorageItem(ACCESS_TOKEN, e.accessToken);
        sessionStorage.setItem("userInformation", JSON.stringify(e));
        setLocalStorageItem("userInformation", JSON.stringify(e));
        // navigate(dashboardURL);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [instance]);

  const logoutAzureAD = useCallback(() => {
    instance
    .logoutPopup(logoutRequest)
    .then(() => {
    //   navigate(indexURL);
      localStorage.clear();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [instance]);

  return {
    loginAzureADPopup,
    logoutAzureAD,
  };
};