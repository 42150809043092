/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';

import styles from './AnalyticsCard.module.scss';
import pin from '../Resources/pin.svg';
export interface analyticsCardProps {
  title: any;
  description: any;
  count: any;
}
export const AnalyticsCard: React.FC<analyticsCardProps> = ({
  title,
  description,
  count
}: analyticsCardProps) => {
  return (
    <div className={styles.myDIV}>
      {title === 'Fulfilment' && (
        <div className={styles.hide}>
          <div className={styles.carbonHover}>
            <div className={styles.textHover}>
              The number of registered participants who clicked the How to Invest button during the
              meeting.
            </div>
            <div className={styles.triangleDown}></div>
          </div>
        </div>
      )}
      {title === 'Follow Up' && (
        <div className={styles.hidefollow}>
          <div className={styles.followUpHover}>
            <div className={styles.textHover}>
              The number of registered participants who clicked the Seek Advice / Contact Sales /
              Contact IR / Vote button during the meeting.
            </div>
            <div className={styles.triangleFollowDown}></div>
          </div>
        </div>
      )}
      {title === 'Questions asked' && (
        <div className={styles.questions}>
          <div className={styles.carbonHover}>
            <div className={styles.textHover}>
              The number of questions that have been asked before or during the meeting.
            </div>
            <div className={styles.triangleDown}></div>
          </div>
        </div>
      )}
      {title === 'Ratings' && (
        <div className={styles.ratings}>
          <div className={styles.ratingHover}>
            <div className={styles.textHover}>
              The number of ratings that have been submitted for this meeting.
            </div>
            <div className={styles.triangleRatingDown}></div>
          </div>
        </div>
      )}
      <div>
        <div className={styles.card}>
          <div className={styles.carbon}>
            <div>{title}</div>
            <img alt="pin" className={styles.pinImage} src={pin}></img>
          </div>
          <div className={styles.description}>
            {description}{' '}
            {title === 'Front of Mind Time' && (
              <span className={styles.upcoming}>(Upcoming Feature)</span>
            )}
          </div>
          <div className={title === 'Carbon saved' ? styles.carboncount : styles.count}>
            {count}
          </div>
        </div>
      </div>
    </div>
  );
};
