/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { call, put, takeLatest } from 'redux-saga/effects';
import { UserType, setUserDetails } from '../redux/actions';
import { type UserDetailResponseType, type UserDetailType } from '../common/types/user';
import { getUserDetails } from '../http/events';
import { getLocalStorageItem } from '../utils';
import { USER_KEY } from '../common/constants';
import { store } from '../redux/store';

function* workerGetUserDetails({ payload }: any): any {
  try {
    // To reduce unnecessary api calls

    // where we already have the details of the user.
    if (store.getState().USER.userDetails.UserKey === getLocalStorageItem(USER_KEY)) {
      return;
    }

    // where we have empty userKey
    if (getLocalStorageItem(USER_KEY) === '') {
      return;
    }

    const userKey = getLocalStorageItem(USER_KEY);
    const userDetailsRes: UserDetailResponseType = yield call(getUserDetails, userKey);
    //   console.log('udet',userDetails);
    yield put(setUserDetails(userDetailsRes.Payload));
  } catch (e) {
    console.log('error in workerGetUserDetails', e);
  }
}
export function* getUserDetailsSaga() {
  yield takeLatest(UserType.GET_USER_DETAILS, workerGetUserDetails);
}
