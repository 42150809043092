import axios from "axios"
import * as qs from 'qs';
import { httpRequest } from "../utils";
import { BASE_URL } from "./api/services";
// export const getClientAdminDetails = async () => {
//     const data = await axios.get("https://newsapi.org/v2/everything?q=tesla&from=2023-01-22&sortBy=publishedAt&apiKey=fb6f90e95cca448fbb29f560dcd78ec6")
//     return data;
// }

// export const getTokenFetch = async () => {
//     // const data = await axios.get(`${BASE_URL}/Token`);
//     // return data;
//     const myHeaders = new Headers();
//     myHeaders.append("OrgId", "795D68B3-49A8-4747-BEFD-17ADDCDE0844");
//     myHeaders.append("AppId", "4");
//     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
//     myHeaders.append("Cookie", ".AspNet.Cookies=3Mt0d1kHOq9QJhySGd2NKH-jzywLLxg8rvXDAdPpJG47JHELQTnug0wNSWjh9Scex9qqYS94p7zlesZBcIMYwzDHQJS64xDA6-dfn_5IuDDMLMqO4xIt370HrVMurwaqLSDJJr59PwjUe0waN47Ys1DFkNckmVck75-6jY_oSWizpTtnOUd5Lg_zqQHaRdQPFfm6A3Q2K7u4czt7rTqlHTIrl9a5IMJUZ7IgDk6q-hhFS1E0R9uyuH5_ITFjScVM7dAk5PR2lrF1D8Zukibxz0eORz2moxtRVTEHdVFz3dCXbNkWXaYyx7K9sokO7JXzgN9i_EDsa-geCh_GTHtTkA_N1Fng7oxNF13YOF-kx6M1G3KbMr7LQNDEckEQaIKeLvAthpInAF_g4GWudvGcKg");
    
//     const urlencoded = new URLSearchParams();
//     urlencoded.append("username", "gaurav.goyal@veersatech.com");
//     urlencoded.append("password", "Gauravgoyal$2");
//     urlencoded.append("grant_type", "password");
    
//     const requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: urlencoded,
//       redirect: 'follow'
//     };
    
//     return fetch("https://mgmt-test.forum360.co/api/Token", requestOptions)
//       .then(response => response.text())
//       .then(result => console.log(result))
//       .catch(error => console.log('error', error));
// }
// export const getTokenAxios = async () => {
//     // const axios = require('axios');
// // const qs = require('qs');
// const data = qs.stringify({
//   'username': 'gaurav.goyal@veersatech.com',
//   'password': 'Gauravgoyal$2',
//   'grant_type': 'password' 
// });

// const config = {
//   method: 'post',
//   maxBodyLength: Infinity,
//   url: 'https://mgmt-test.forum360.co/api/Token',
//   headers: { 
//     'OrgId': '795D68B3-49A8-4747-BEFD-17ADDCDE0844', 
//     'AppId': '4', 
//     'Content-Type': 'application/x-www-form-urlencoded', 
//     'Cookie': '.AspNet.Cookies=3Mt0d1kHOq9QJhySGd2NKH-jzywLLxg8rvXDAdPpJG47JHELQTnug0wNSWjh9Scex9qqYS94p7zlesZBcIMYwzDHQJS64xDA6-dfn_5IuDDMLMqO4xIt370HrVMurwaqLSDJJr59PwjUe0waN47Ys1DFkNckmVck75-6jY_oSWizpTtnOUd5Lg_zqQHaRdQPFfm6A3Q2K7u4czt7rTqlHTIrl9a5IMJUZ7IgDk6q-hhFS1E0R9uyuH5_ITFjScVM7dAk5PR2lrF1D8Zukibxz0eORz2moxtRVTEHdVFz3dCXbNkWXaYyx7K9sokO7JXzgN9i_EDsa-geCh_GTHtTkA_N1Fng7oxNF13YOF-kx6M1G3KbMr7LQNDEckEQaIKeLvAthpInAF_g4GWudvGcKg'
//   },
//   data
// };

// return axios.request(config)
// .then((response) => {
//   console.log(JSON.stringify(response.data));
// })
// .catch((error) => {
//   console.log(error);
// });

// };

// TODO: Convert to TypeScript & Redux Saga
export const getToken = async (un,pw,appId = '6') => {
  const data = qs.stringify({
    'username': un,
    'password': pw,
    'grant_type': 'password' 
  });
    return httpRequest({
        url: `${BASE_URL}/Token`,
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'OrgId': '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
            'AppId': appId,
        },
    },true);
};

export const getUserOrgs = async (un) => {
    const data = qs.stringify({
      'username': un,
    });
  return httpRequest({
      url: `${BASE_URL}/Admin_Organisation/GetOrgData`,
      method: 'POST',
      data,
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'AppId': '6',
      },
  },true);
};


// export const getUpcomingEvents = async () => {
//     const data = await axios.get(`${BASE_URL}/Event/UpcomingEvent`);
//     return data;
// }
