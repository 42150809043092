export const getKeywordByProductType = (productType: number): string => {
  switch (productType) {
    case 1: // regular Listed??
      return 'Invest in';
    case 2: // regular Unlisted
      return 'Invest in';
    case 3: // other products
      return 'Buy';
    case 4: // other services
      return 'Apply for';
    default:
      return '';
  }
};

export const getCategoryByProductType = (productType: number): string => {
  switch (productType) {
    case 1: // regular Listed??
      return 'Product'; // 'Listed Stocks - Regular Product';
    case 2: // regular Unlisted
      return 'Product'; // 'Unlisted Stocks - Regular Product';
    case 3: // other products
      return 'Product';
    case 4: // other services
      return 'Service';
    default:
      return '';
  }
};
