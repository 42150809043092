/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { throttle } from "throttle-debounce";
import "./ClosedCaptionsView.scss";
import { useSelector } from "react-redux";
import { putTranscriptionEveryMinute } from "../../http/transcription";

// eslint-disable-next-line no-unused-vars
const MSG_BEGAN = "B";
const MSG_UPDATED = "U";
const MSG_FINNISHED = "F";
const DEFAULT_BLACK_COLOR = "#000000";
const DEFAULT_WHITE_COLOR = "#ffffff";
const DEFAULT_FONT_SIZE = "18px";
const LINE_HEIGHT_COEFICIENT = 1.5;
const LINES_IN_HISTORY = 69000;

// TODO remove after testing!!!!
let showCCinConsole = true;
window.switchCCMessagesInLogs = () => (showCCinConsole = !showCCinConsole);

const parseCaptionMessage = (message) => {
  const isMessageTextEmpty = message.trim().endsWith("]");
  const messagePropertyarray = message.split("]");
  // take everything between 1 index and the last index as a username
  const userNameArr = messagePropertyarray
    .slice(2, -1)
    .join("]")
    ?.trim()
    .replace("[", "")
    .split(" ");
  const firstName = userNameArr[0];
  const lastName = userNameArr[1] || "";
  const text = isMessageTextEmpty
    ? ""
    : messagePropertyarray[messagePropertyarray.length - 1].trim();
  return {
    index: messagePropertyarray[0].trim().replace("[", ""),
    flag: messagePropertyarray[1].trim().replace("[", ""),
    userName: `${firstName} ${lastName}`.trim(),
    text,
    timestamp: Date.now(),
  };
};

const isElementScrolledToBottom = (element) => {
  if (element) {
    return (
      Math.abs(
        element.scrollHeight - element.clientHeight - element.scrollTop
      ) < 5
    ); // not always corecct but near to 90-95% and this is the only way to detect
  } // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
};

const getLineHeight = () => parseInt(_fontSize) * LINE_HEIGHT_COEFICIENT;

let _messagesQueue = [];
let _mesagges = [];
let _fontSize = parseInt(DEFAULT_FONT_SIZE);

const ClosedCaptionsView = ({
  subscribeOnMesaages = () => {},
  unsubscribeFromMessages = () => {},
  uiOptions = {},
  scrolltrigger,
}) => {
  const ccContainerRef = useRef();
  const isCCon = useSelector(({ SIGNALR }) => SIGNALR.isCCon);
  const { changeRenderId } = useSelector(({ SIGNALR }) => SIGNALR);
  const [triggerScroll, setTriggerScroll] = useState(false);
  const { activeSession } = useSelector(({ SIGNALR }) => SIGNALR);
  const [lastFinishedSavedIndex, setLastFinishedSavedIndex] = useState(-1); // Saved in DB
  const role = useSelector(({ USER }) => USER.role);
  
  useEffect(() => {
    if(role !== 'host') return;

    const intervalID = setInterval(() => {

      const endIndex = _mesagges.findLastIndex((msg) => msg.flag === MSG_FINNISHED); 

      if(endIndex === -1) return; // no finished message found.

      const startIndex = lastFinishedSavedIndex + 1; // maybe a B or U message
      if(startIndex > _mesagges.length - 1) return; // no new messages not even B / U since last save.

      
      // if(startIndex === endIndex){ // only one message
      if(endIndex < startIndex){ // no F message found since last save
        return;
      }
      // inclusive of last index
      const transcriptString = _mesagges.slice(startIndex,endIndex+1).map((msg)=>`[${msg.userName}] ${msg.text}`).join(' ');
      // console.log('inside interval render',activeSession)
      putTranscriptionEveryMinute(activeSession.SessionId, transcriptString).then((res) => {
        if(res?.Success === true){
          setLastFinishedSavedIndex(endIndex);
        }else{
          console.log('Error saving transcription', res);
        }
      }).catch((err)=>{
        console.log('Error Catch block', err);
      })
    }, 60000); // call every minute

    return () => {
        clearInterval(intervalID);
    }
  }, [lastFinishedSavedIndex, activeSession , role]);

  useEffect(()=>{
    if(ccContainerRef.current === null) return;
    if(changeRenderId){
      document.getElementById('render-cc')?.appendChild(ccContainerRef.current);
      setTriggerScroll(true);
    }else{
      document.getElementById('hide-cc')?.appendChild(ccContainerRef.current);
    }
  },[changeRenderId])
  const {
    fontSize = DEFAULT_FONT_SIZE,
    backColor = DEFAULT_BLACK_COLOR,
    fontColor,
    test = () => {},
    isSafari = false,
  } = uiOptions;
  const _fontColor =
    fontColor || backColor === DEFAULT_WHITE_COLOR
      ? DEFAULT_BLACK_COLOR
      : DEFAULT_WHITE_COLOR;
  const [mesagges, _setMessages] = useState([]);
  // useEffect(()=>{
  //   console.log('mesagges',mesagges,_mesagges);
  // },[mesagges])
  const ccLinesRef = useRef();
  const isDocumentVisible = useRef(true);
  _fontSize = parseInt(fontSize);

  const setMessages = useCallback(
    (msgArrProp) => {
      let msgArr = msgArrProp;
      if (!isDocumentVisible.current) {
        // if browser not visible, keep ony last few lines in memory for performance in case if user long time have minimized browser
        msgArr = msgArr.slice(-LINES_IN_HISTORY - 2);
      }
      _setMessages([...msgArr]);
      _mesagges = [...msgArr];
    },
    [_setMessages]
  );

  useEffect(() => {
    const getTime = () => {
      const fontCof = _fontSize === 18 ? 1 : _fontSize > 18 ? 0.7 : 1.3;
      if (ccLinesRef.current?.clientWidth) {
        return Math.min(
          Math.max(ccLinesRef.current.clientWidth * 2 * fontCof, 1000),
          2500
        );
      }
    };
    let timeout = null;
    const scroll = () => {
      if (
        ccLinesRef.current &&
        isElementScrolledToBottom(ccLinesRef.current)
      ) {
        ccLinesRef.current?.scrollBy(0, getLineHeight());
      }
      timeout = setTimeout(scroll, getTime());
    };
    timeout = setTimeout(scroll, getTime());

    return () => {
      clearTimeout(timeout);
    };
  }, [fontSize]);

  useEffect(() => {
    if (ccLinesRef.current) {
      // scroll to bottom if font changed
      console.log('scrolled to', ccLinesRef.current.scrollHeight);
      ccLinesRef.current.scrollTo({
        top: ccLinesRef.current.scrollHeight,
        behavior: "instant",
      });
    }
  }, [fontSize, scrolltrigger]);

  // TODO : change it to trigger on change of parent element.
  // because somehow this is not triggered when we change parent element but
  // is triggered when we change parent element and then get new message (transcript)
  useEffect(()=>{

    // console.log('scrollHeight', ccLinesRef.current?.scrollHeight);
      if(!triggerScroll) return; // no need to scroll on hide
      ccLinesRef.current.scrollTo({
        top: ccLinesRef.current.scrollHeight,
        behavior: "smooth",
      });
      if(ccLinesRef.current.scrollHeight > 0){ // only if it actually scrolled.
        setTriggerScroll(false);
      }
  },[ccLinesRef.current?.scrollHeight , triggerScroll])

  useEffect(() => {
    const scrollOnVissibilityChange = () => {
      if (ccLinesRef.current && document.visibilityState === "visible") {
        isDocumentVisible.current = true;
        _mesagges = _mesagges.slice(-LINES_IN_HISTORY);
        ccLinesRef.current.scrollTo({
          top: ccLinesRef.current.scrollHeight,
          behavior: "instant",
        });
      } else {
        isDocumentVisible.current = false;
      }
    };
    document.addEventListener("visibilitychange", scrollOnVissibilityChange);

    return () => {
      document.removeEventListener(
        "visibilitychange",
        scrollOnVissibilityChange
      );
      _messagesQueue = [];
      _mesagges = [];
    };
  }, []);

  useEffect(() => {
    const handeMsg = (parsedMsg) => {
      console.log(parsedMsg);
      const lastMessage = _mesagges[_mesagges.length - 1];

      if (!_mesagges.length) {
        setMessages([parsedMsg]);
      } else if (lastMessage?.index === parsedMsg.index) {
        if (parsedMsg.flag === MSG_FINNISHED) {
          if (isElementScrolledToBottom(ccLinesRef.current)) {
            // add messages from queue and clear prvious msgs
            if (lastMessage?.flag === MSG_FINNISHED) {
              const lastFewMsgs = _mesagges.slice(-LINES_IN_HISTORY);
              setMessages([...lastFewMsgs, parsedMsg, ..._messagesQueue]);
            } else {
              const lastFewMsgs = _mesagges.slice(-LINES_IN_HISTORY - 1);
              lastFewMsgs.pop(); // remove last massge in updated state
              setMessages([...lastFewMsgs, parsedMsg, ..._messagesQueue]);
            }
            _messagesQueue = [];
          } else {
            // add messages from queue
            if (lastMessage?.flag !== MSG_FINNISHED) {
              // replase last message with flag Updated or Begun for the same speaker
              _mesagges.pop();
            }
            setMessages([..._mesagges, parsedMsg, ..._messagesQueue]);
            _messagesQueue = [];
          }
        } else {
          if (lastMessage?.flag !== MSG_FINNISHED) {
            // replase last message with flag Updated or Begun for the same speaker
            _mesagges.pop();
          }
          setMessages([..._mesagges, parsedMsg]);

          const distanceToBottom = Math.abs(
            ccLinesRef.current.scrollHeight -
              ccLinesRef.current.clientHeight -
              ccLinesRef.current.scrollTop
          );
          if (
            distanceToBottom > 0 &&
            distanceToBottom < getLineHeight() * 1.2 // 20% margin for possible measurement error
          ) {
            // scroll to bottom if we show current speaker cc and next line is hedden
            // this shoud not impact scrolling messegas from queue, they will be scroled with interval
            ccLinesRef.current.scrollTo({
              top: ccLinesRef.current.scrollHeight,
              behavior: "smooth",
            });
          }
        }
      } else {
        if (
          lastMessage.flag === MSG_FINNISHED ||
          (Date.now() - lastMessage.timestamp > 5000 && // sometimes we dont receive finish message, in that case wait adn procced with next msgs
            isElementScrolledToBottom(ccLinesRef.current))
        ) {
          setMessages([..._mesagges, parsedMsg]);
        } else if (parsedMsg.flag === MSG_FINNISHED) {
          _messagesQueue.push(parsedMsg);
        }
      }
    };

    const handeMsgWithThrottling = throttle(250, handeMsg, {
      noTrailing: true,
    });

    subscribeOnMesaages((msg) => {
      if (!ccLinesRef?.current) return;
      if (showCCinConsole) {
        console.warn(msg);
      }
      const parsedMsg = parseCaptionMessage(msg);
      const lastMsg = _mesagges[_mesagges.length - 1];

      // sometime we receive message without text
      if (!parsedMsg.text) {
        if (
          parsedMsg?.flag === MSG_FINNISHED &&
          lastMsg?.index === parsedMsg.index &&
          lastMsg?.flag !== MSG_FINNISHED
        ) {
          lastMsg.flag = MSG_FINNISHED;
          return;
        } else {
          return;
        }
      }

      if (
        lastMsg?.index === parsedMsg.index &&
        lastMsg?.flag === parsedMsg.flag &&
        parsedMsg.flag === MSG_UPDATED
      ) {
        // if it is update for curent speaker, try throttle
        handeMsgWithThrottling(parsedMsg);
      } else {
        handeMsg(parsedMsg);
      }
    });
    return () => {
      unsubscribeFromMessages();
    };
  }, [setMessages, subscribeOnMesaages, unsubscribeFromMessages]);

  const sizeOfFont =
    parseInt(fontSize) === 18
      ? "normal"
      : parseInt(fontSize) > 18
      ? "big"
      : "small";

  return (
    <div
        ref={ccContainerRef}
        className="caption-container"
        aria-hidden
        style={{
          backgroundColor: backColor,
          color: _fontColor,
          fontSize: fontSize,
          display: isCCon ? "block" : "none",
        }}
        {...test("CAPTION_BAR")}
      >
        <div
          ref={ccLinesRef}
          className={`cc-lines-wrapper font-${sizeOfFont}${
            isSafari ? " safari" : ""
          }`}
        >
          {mesagges.map((msg) => (
            <div className="cc-line-wrapper" key={msg.timestamp}>
              <div className="cc-line" {...test("CAPTION_MRSSAGE_AREA")}>
                <div className="cc-name-wrapper">
                  <div className="cc-name">{msg.userName}</div>
                  <div>:</div>
                </div>
                <div className="cc-text">{msg.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default ClosedCaptionsView;
