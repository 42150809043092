import React, { useState } from 'react';
import styles from './Messaging.module.scss';
import Icon from './Icon.png';
import { QuickMessage } from './QuickMessage';
import { httpRequest } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ModalVariants } from '../../../../redux/reducers/app-state';
import { setModalShown } from '../../../../redux/actions/app-state';
import { BASE_URL } from '../../../../http/api/services';
import { type RootState } from '../../../../redux/reducers';

export const Messaging: React.FC = () => {
  const [quickMessage, setQuickMessage] = useState(false);
  const { teamsContext } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const [sentMessage, setSentMessage] = useState('Quick Message');
  const [subject, setSubject] = useState('');
  const [text, setTextMessage] = useState('');
  const [showPop, setShowPop] = useState('No');
  const dispatch = useDispatch();
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);

  function discardMessage(): void {
    setShowPop('No');
  }
  function sendMessage(): void {
    if (sentMessage === 'Welcome') {
      // void Welcome();
      dispatch(setModalShown(ModalVariants.Welcome));
    }
    if (sentMessage === 'Rate and Review') {
      // void Rate();
      dispatch(setModalShown(ModalVariants.Rate));
    }
    if (sentMessage === 'Meeting Delay') {
      // void Delay();
      dispatch(setModalShown(ModalVariants.MeetingDelay));
    }
    if (sentMessage === 'Technical Difficulties') {
      // void TechnicalDifficulties();
      dispatch(setModalShown(ModalVariants.TechnicalDifficulty));
    }

    setShowPop('Yes');
  }
  function showMessage(): void {
    setQuickMessage(!quickMessage);
    console.log('mmm', quickMessage);
  }
  function setText(message: string): void {
    if (message === 'Welcome') {
      setSubject('Reminder from [Org Name]: Your Meeting will start soon');
      setSentMessage('Welcome');
      setTextMessage(
        'Hello [First Name], This is a reminder that your meeting with [Presenter Full Name] from [Product Name] is due to start on [Event Date, Start Time, Time Zone].'
      );
    }
    if (message === 'Rate') {
      setSubject('[Org Name] Meeting Follow Up');
      setSentMessage('Rate and Review');
      setTextMessage(`Hello [First Name],
 How did the meeting go?
      
 Click on the link below to share your feedback if you have not done so already.
      
 Rate and Review
      
 Can we be of further assistance with your enquiries? Please visit us at [Product Link] for more information or contact us at google@google.com.`);
    }
    if (message === 'TechnicalDifficulty') {
      setSubject('[Org Name] Alert: Technical Difficulties');
      setSentMessage('Technical Difficulties');
      setTextMessage(
        `We are currently experience technical difficulties. We are working to resolve this as quickly as possible and will provide an update shortly.`
      );
    }
    if (message === 'MeetingDelay') {
      setSubject('[Org Name] - Attention - [Meeting Name] has been delayed');
      setSentMessage('Meeting Delay');
      setTextMessage(
        `
        Hello [First Name], We would like to inform you that [Event Name - Meeting Name] has been delayed. Please kindly wait and we will notify you once the meeting has started. We apologise for any inconvenience this may cause. Kind Regards, Team [Org Name]
        `
      );
    }
  }

  return (
    <div className={styles.messagingtab}>
      {/* {showPop === 'Yes' && (
        <div className={styles.messagepop}>
          <div className={styles.poptext}>
            Send your message “Welcome to Investing in Inflationary Times” to all attendees?
          </div>
          <div className={styles.popButtons}>
            <button className={styles.no} onClick={discardMessage}>
              No
            </button>
            <button className={styles.yes} onClick={sendMessagePopup}>
              Yes
            </button>
          </div>
        </div>
      )} */}
      <div style={{ position: 'relative' }}>
        <p className={styles.eventmessaging} onClick={showMessage}>
          Event Messaging
        </p>

        {/* <div className={styles.messagedropdown}>
          {quickMessage && <QuickMessage text={setText} message={showMessage} />}
        </div> */}
      </div>
      <p className={styles.subject}>Subject</p>
      <input placeholder="" value={subject} className={styles.subjectbox} disabled />

      <div className={styles.quickmessageheading}>
        <span>Message</span>
        <div onClick={showMessage} style={{ position: 'relative' }}>
          <span className={styles.quickMessage}>{sentMessage} </span>
          <img src={Icon} />
          <div className={styles.messagedropdown}>
            {quickMessage && <QuickMessage text={setText} message={showMessage} />}
          </div>
        </div>
      </div>
      <div className={styles.messagebox}>
        {sentMessage === 'Welcome' && (
          <p>
            Hello [First Name],
            <br /> <br></br> This is a reminder that your meeting with [Presenter Full Name] from
            [Product Name] is due to start on [Event Date, Start Time, Time Zone].
          </p>
        )}
        {sentMessage === 'Rate and Review' && (
          <p>
            {' '}
            Hello [First Name],<br></br>
            <br></br>
            How did the meeting go?<br></br>
            <br></br>
            Click on the link below to share your feedback if you have not done so already.<br></br>
            <br></br>
            Rate and Review<br></br>
            <br></br>
            Can we be of further assistance with your enquiries? Please visit us at [Product Link]
            for more information or contact us at [Email].
          </p>
        )}
        {sentMessage === 'Meeting Delay' && (
          <p>
            Hello [First Name],<br></br> <br></br>
            We would like to inform you that [Event Name - Meeting Name] has been delayed. Please
            kindly wait and we will notify you once the meeting has started.<br></br> <br></br>We
            apologise for any inconvenience this may cause.<br></br>
            <br></br> Kind Regards, <br></br>Team [Org Name]
          </p>
        )}

        {sentMessage === 'Technical Difficulties' && (
          <p>
            We are currently experience technical difficulties. We are working to resolve this as
            quickly as possible and will provide an update shortly.
          </p>
        )}
      </div>
      {/* <textarea
        className={styles.messagebox}
        
        // onChange={(e) => {
        //   setTextMessage(e.target.value);

        // }}
        value={text}
      /> */}
      <div className={teamsContext == null ? styles.messagebutton : styles.messagebuttonTeams}>
        <button
          data-automation-id="button-send-message"
          className={styles.sendMessage}
          disabled={Number.isNaN(text.length) || text.length === 0}
          onClick={sendMessage}
        >
          Send Message
        </button>
      </div>
    </div>
  );
};
