/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import styles from './AgendaCard.module.scss';
import cardoption from './cardoption.svg';
import { Value } from 'sass';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import { UserRole } from '../../../../redux/reducers/user';
export interface AgendaCardProps {
  question: any;
  liveAnswer: any;
  checked: any;
}
export const AgendaCard: React.FC<AgendaCardProps> = ({
  question,
  liveAnswer
}: AgendaCardProps) => {
  const [value, setValue] = useState('uncheck');
  const questionsChanged = useSelector(({ APPSTATE }: RootState) => APPSTATE.questionsChanged);
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const dispatch = useDispatch();

  const role = useSelector(({ USER }: RootState) => USER.role);
  async function check(question: any, text: string) {
    if (value === 'check') {
      setValue('uncheck');
    } else {
      setValue('check');
      await liveAnswer(
        localStorage.accessToken,
        question,
        activeSession.EventDetails.EventKey,
        activeSession.SessionId
      );
      dispatch({ type: 'SET_QUESTIONS_CHANGED', payload: true });
      dispatch({ type: 'SEND_COMMAND_TO_GROUP', payload: { command: '[QUESTION-NEW]' } });
      setValue('uncheck');
      if (text === 'agenda') {
        const a: HTMLElement | null = document.getElementById(`question-${question.QuestionId}`);
        a?.click();
      }
    }
  }
  return (
    <>
      <div className={styles.commonCard}>
        <div className={styles.agendainfo}>
          <div className="d-flex">
            <div>
              <span>{question.FullName}</span>
              <span className={styles.time}>{question.QuestionTime}</span>
            </div>
          </div>

          <div className={styles.check}>
            {question.QuestionStatus === 909 && <span className={styles.IRP}>Sent to IRP</span>}
            {question.QuestionStatus === 902 && (
              <input
                type="checkbox"
                className={styles.checkbox}
                value={value}
                onClick={() => {
                  void check(question, 'answered');
                }}
                checked
              />
            )}
            {(question.QuestionStatus === 900 || question.QuestionStatus === 901) &&
              role !== UserRole.cohost && (
                // {question.}
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  id={`question-${question.QuestionId}`}
                  value={value}
                  onClick={() => {
                    void check(question, 'agenda');
                  }}
                />
              )}
          </div>
        </div>

        <div className={styles.agendaContent}>
          <div className={styles.agendaquestion}>
            <img alt=":" style={{ height: '1.5rem' }} src={cardoption}></img>
          </div>
          {question.Question}
        </div>
      </div>
    </>
  );
};
