import React, { useEffect } from "react";
import ClosedCaptionsDesktopController from "./ClosedCaptionsDesktopController";
import ClosedCaptionsWebController from "./ClosedCaptionsWebController";
import {useSelector,useDispatch} from "react-redux";

const ClosedCaptions = () => {
  const appType = window.vidyoApp?.getMode?.() === 0 ? "DESKTOP" : "WEB";
  const commandFromGroup = useSelector(({ SIGNALR }) => SIGNALR.commandFromGroup);
  const dispatch = useDispatch();

  // writing it here because this is always rendered in Waiting room , once we have the jwt token
  useEffect(()=>{
    if(commandFromGroup.command === "[SET-CC]"){
      if(commandFromGroup.message.toLowerCase() === 'true'){
        dispatch({
          type: "SET_CC",
          payload: true
        })
      }else{
        dispatch({
          type: "SET_CC",
          payload: false
        })
      }
      // Now isCCon is in sync with host's isCCon
    }
  },[commandFromGroup])

  return (
    <>
      {appType === "DESKTOP" ? (
        <ClosedCaptionsDesktopController />
      ) : (
        <ClosedCaptionsWebController />
      )}
    </>
  );
};

export default ClosedCaptions;
