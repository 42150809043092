import styles from './Dashboard.module.scss';
import React, { useEffect, useState } from 'react';

import { Event } from '../../ui-kit/Event/Event';
import { hostPic } from '../../mock-data';
import Events from '../../components/Events/Events';
import { Carousel } from '../../common/components/Carousel/Carousel';
import { BadgeVariants } from '../../ui-kit/Badges/Badge';
import { poweredByImg, unfadedThumb, unfadedThumbnail, user } from '../../assets';
import poweredBy from '../../assets/poweredBy.png';
import relateLogo from '../../assets/relateLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import { getUpcomingEvents } from '../../redux/actions/app-state';
import { type EventType } from '../../common/types';
import { type upcomingSessionType } from '../../types/upcoming-sessions';
import { type RootState } from '../../redux/reducers';
import { UserType } from '../../redux/actions';
import { getUserRole } from '../../utils/user-role';
import { NoSession } from '../../components/Events/NoSession';
import { LOADER, currentYear } from '../../common/constants';
import { getLocalStorageItem } from '../../utils';
import { useNavigate } from 'react-router-dom';
import imgLogo from '../../assets/ForumLogo.png';
import FooterForum from '../FooterForum';
export const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [showNegScreen,setShowNegScreen] = useState(false);

  useEffect(() => {
    dispatch(getUpcomingEvents());
    // const checkUserData = (ev:any):any => {
    //   if (ev.detail.key === LOADER) {
    //     const loaderState = getLocalStorageItem(LOADER);

    //     if (loaderState === 'T') {
    //       // setLoader(true);

    //     } else {
    //       // setLoader(false);
    //     }
    //     // return;
    //   }
    // }
    // window.addEventListener('localStorageEvent', checkUserData);
    // return () => {
    //   window.removeEventListener('localStorageEvent', checkUserData);
    // };
    // dispatch(getUserDetails());
    // dispatch({ type: UserType.GET_USER_DETAILS });
  }, [dispatch]);

  const events = useSelector(({ APPSTATE }) => APPSTATE.upcomingEvents);
  const sessions = useSelector(({ APPSTATE }: RootState) => APPSTATE.upcomingSessions);
  const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
  const loader = useSelector(({ SIGNALR }: RootState) => SIGNALR.loader);

  const [primarySession, setPrimarySession] = useState<(typeof sessions)[0] | null>(null);
  const [otherSessions, setOtherSessions] = useState<typeof sessions | null>(null);

  useEffect(() => {
    // console.log('1st st',sessions?.[0]?.Status);
    if (
      sessions.length > 0 &&
      (sessions[0].Status === BadgeVariants.InProgress ||
        sessions[0].Status === BadgeVariants.HappeningNow)
    ) {
      setPrimarySession(sessions[0]);
      setOtherSessions(sessions.slice(1, 5));
    } else {
      setOtherSessions(sessions.slice(0, 5));
    }
  }, [sessions]);
  // console.log('ds', primarySession?.DateStarted,'s', primarySession?.Status);

  // const handleClick = (session: upcomingSessionType) => {

  if (loader.length === 0 && sessions.length === 0) {
    return (
      <div style={{ minWidth: '69%' }}>
        <NoSession />
      </div>
    );
  } else if (loader.length > 0) {
    return <></>;
  }

  return (
    <>
      <div className={`d-flex align-items-start ${styles.dashboardroom}`}>
        {primarySession !== null && (
          <div className="me-3">
            <Event
              badgeType={primarySession.Status}
              thumbnail={primarySession.HoldingImage}
              eventInfo={{
                hostPic: primarySession.SpeakerImage,
                title: primarySession.Title,
                host: primarySession.SpeakerName,
                date: primarySession.Date,
                time: primarySession.Time,
                eventName: primarySession.EventDetails.Title
              }}
              timestamp={
                primarySession.Status === BadgeVariants.InProgress
                  ? primarySession.DateStarted
                  : undefined
              }
              withFooter
              handleClick={() => {
                // const role = getUserRole(primarySession, userDetails.UserKey);
                // console.log('role', role);
                // dispatch({ type: 'SET_ACTIVE_SESSION', payload: primarySession });
                // dispatch({ type: UserType.SET_ROLE, payload: { role } });
                navigate(
                  `/waitingroom/${primarySession.EventDetails.EventProducts[0].ProductKey}/${primarySession.EventDetails.EventKey}/${primarySession.SessionId}`
                );
              }}
            />
          </div>
        )}
        <div className={styles.carouselAndTable}>
          <Carousel>
            {otherSessions?.map((session: upcomingSessionType, idx: number) => {
              return (
                <Event
                  key={idx}
                  badgeType={session.Status}
                  thumbnail={session.HoldingImage}
                  eventInfo={{
                    hostPic: session.SpeakerImage,
                    title: session.Title,
                    host: session.SpeakerName,
                    date: session.Date,
                    time: session.Time,
                    eventName: session.EventDetails.Title
                  }}
                  handleClick={() => {
                    // const role = getUserRole(session, userDetails.UserKey);
                    // console.log('role', role);
                    // dispatch({ type: 'SET_ACTIVE_SESSION', payload: session });
                    // dispatch({ type: UserType.SET_ROLE, payload: { role } });
                    navigate(
                      `/waitingroom/${session.EventDetails.EventProducts[0].ProductKey}/${session.EventDetails.EventKey}/${session.SessionId}`
                    );
                  }}
                />
              );
            })}
            {/* <div className='d-flex justify-content-between'> */}
            {/* {events.map((event:EventType, index:number) => {
            return (
              <Event
                key={index}
                badgeType={BadgeVariants.StartingSoon}
                thumbnail={unfadedThumb}
                eventInfo={{
                  hostPic: user,
                  title: event.Title,
                  host: `${event.SpeakerId}`,
                  date: event.EventDate,
                  time: '1:00-2:00 pm PST'
                }}
              />
            );
          })} */}
            {/* <Event badgeType={BadgeVariants.InProgress}
            thumbnail={unfadedThumb}
            eventInfo={{
              hostPic: user,
              title: 'Effective Forms Advertising',
              host: 'James Albert',
              date: 'April 16, 2023',
              time: '1:00-2:00 pm PST'
            }}
          />
          <Event badgeType={BadgeVariants.InProgress}
            thumbnail={unfadedThumb}
            eventInfo={{
              hostPic: user,
              title: 'Effective Forms Advertising',
              host: 'James Albert',
              date: 'April 16, 2023',
              time: '1:00-2:00 pm PST'
            }}
          /> */}
          </Carousel>
          {sessions.length > 0 && <Events />}
        </div>
        {/* </div> */}
      </div>
      <div
        className={`${styles.login_subHeading} ${styles.dashboard_footer}`}
        // style={{ position: 'fixed', bottom: '0', width: '100%' }}
      >
        <div className={styles.logo}>
          {/* <span className={styles.powered}>Powered by</span> */}
          <img alt="powered by" style={{ width: '5.5rem' }} src={poweredBy} />
          <img alt="relate" style={{ height: '3rem' }} src={relateLogo} />
        </div>
        © Copyright Relate Research and Technology {currentYear}
      </div>
    </>
  );
};
