import React, { useState, type ReactNode, useEffect } from 'react';
import { TeamsLogin } from '../components/TeamsLogin/TeamsLogin';
import { app } from '@microsoft/teams-js';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../redux/reducers';
import { getUserRole } from '../utils/user-role';
import { UserRole } from '../redux/reducers/user';
import './teams-adapter.css';
import { startSession } from '../http/meeting';
import { saveCarbon } from '../components/Footer/Footer';
import { getLocalStorageItem } from '../utils';
import { ACCESS_TOKEN } from '../common/constants';
import { getSentimentGraphDataForPastXMin } from '../http/sentiment-graph';

export interface ITeamsAdapterProps {
  children: ReactNode;
}

export const TeamsAdapter: React.FC<ITeamsAdapterProps> = (props: ITeamsAdapterProps) => {
  const { children } = props;
  const [readyToConnect, setReadyToConnect] = useState(false);
  const dispatch = useDispatch();
  const { activeSession, startForWL } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const { role, userDetails } = useSelector(({ USER }: RootState) => USER);
  const commandFromGroup = useSelector(({ SIGNALR }) => SIGNALR.commandFromGroup);

  useEffect(() => {
    const htmlEl = document.querySelector('html');
    // if(htmlEl?.style?.fontSize !== undefined){
    //   htmlEl.style.fontSize = "unset";
    // }
    if (htmlEl?.style !== undefined) {
      htmlEl.style.fontSize = '14.7px';
      // htmlEl.style.overflow = "hidden";
    }
    // document.head.style.fontSize = "unset";
  }, []);

  useEffect(() => {
    if (activeSession.SessionId !== 0 && role !== UserRole.empty) {
      if (role === UserRole.host) {
        startSession(activeSession.EventDetails.EventKey, activeSession.SessionId)
          .then(() => {
            console.log('session started successfully');
            dispatch({ type: 'SET_START_FOR_WL', payload: true });
            setReadyToConnect(true);
          })
          .catch(() => {
            console.log('could not start session');
          });
      } else {
        setReadyToConnect(true);
      }
    }
  }, [activeSession, role]);

  useEffect(() => {
    if (activeSession.SessionId !== 0 && userDetails.UserKey !== '') {
      // eslint-disable-next-line no-debugger
      // debugger;
      const role = getUserRole(activeSession, userDetails.UserKey);
      dispatch({ type: 'SET_ROLE', payload: { role } });

      const groupId =
        'room_' + activeSession.EventDetails.EventKey + '_' + activeSession.SessionId.toString();
      dispatch({ type: 'CONNECT_TO_GROUP', payload: { groupId, role } });

      saveCarbon(getLocalStorageItem(ACCESS_TOKEN), activeSession.EventDetails.EventId)
        .then(() => {})
        .catch(() => {});

      if (activeSession.Status === 'In Progress') {
        dispatch({ type: 'SET_START_FOR_WL', payload: true });
      }
    }
  }, [activeSession, userDetails]);

  useEffect(() => {
    let interval: any = 0;
    if (activeSession.SessionId !== 0 && startForWL && role !== UserRole.cohost) {
      dispatch({
        type: 'GET_SENTIMENT_GRAPH_DATA',
        payload: { sessionId: activeSession.SessionId }
      });
      interval = setInterval(() => {
        dispatch({
          type: 'GET_SENTIMENT_GRAPH_DATA',
          payload: { sessionId: activeSession.SessionId }
        });
      }, 60000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [startForWL, role, activeSession]);

  useEffect(() => {
    getSentimentGraphDataForPastXMin('3300', 1)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    app
      .initialize()
      .then(() => {
        app
          .getContext()
          .then((context) => {
            dispatch({
              type: 'SET_TEAMS_CONTEXT',
              payload: context
            });
          })
          .catch(() => {
            console.log('err teams : could not get context');
          });
      })
      .catch(() => {
        console.log('err teams: could not initialize app');
      });
  }, []);

  useEffect(() => {
    if (commandFromGroup.command === '[ENDMEETING]') {
      localStorage.clear();
      window.location.reload();
    }
  }, [commandFromGroup]);

  return <>{readyToConnect ? children : <TeamsLogin />}</>;
};
