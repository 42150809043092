import { type EventType } from '../../common/types';
// import { type VidyoConnectorTypes,  VidyoConnectorAction} from '../actions';

export interface vidyoStateType {
  particpants: any[];
  vidyoConnector: any;
  localCameraObj: any;
  localSpeakerObj: any;
  localMicObj: any;
  localScreenObj: any;
}
const vidyoState: vidyoStateType = {
  particpants: [],
  vidyoConnector: {},
  localCameraObj: {},
  localSpeakerObj: {},
  localMicObj: {},
  localScreenObj: {}
};

export const vidyoConnectorReducer = (state = vidyoState, action: any): vidyoStateType => {
  console.log('ac1', action);
  switch (action.type) {
    case action.SET_VIDYO_CONNECTOR:
      console.log(action);
      return {
        ...state,
        ...action.payload
      };
    case action.REGISTER_LOCAL_CAMERA:
      return {
        ...state,
        ...action.payload
      };
    case action.REGISTER_LOCAL_MIC:
      return {
        ...state,
        ...action.payload
      };
    case action.REGISTER_LOCAL_SPEAKER:
      return {
        ...state,
        ...action.payload
      };
    case action.JOIN_ROOM_AS_GUEST:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
