import React, { useEffect, useState } from 'react';

import styles from './Sentiment.module.scss';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  Filler,
  type ChartOptions
} from 'chart.js';
import emote from './emote.svg';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import { type SentimentGraphResponseType } from '../../../../http/sentiment-graph';
import pin from '../Resources/pin.svg';
// import {faker} from 'faker';
// //
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom' as const,
      // borderRadius: '50%',
      labels: {
        color: 'white',
        font: {
          size: 7.5
        },
        usePointStyle: true,
        pointStyle: 'circle'
        // borderRadius:'50%'// Customize label color
      }
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart'
    }
  },
  scales: {
    x: {
      min: 0,
      // max: null,
      ticks: {
        display: false,
        color: 'white'
        // beginAtZero: true // Customize x-axis label color
      },
      grid: {
        display: false,
        color: 'white'
        // color: 'rgba(10, 14, 23, 0.6)',
        // borderColor: 'red' // <-- this line is answer to initial question
      },
      border: {
        color: 'white'
      }
    },
    y: {
      max: 1,
      min: -1,
      ticks: {
        // values: [-1, 0, 1],
        stepSize: 1,
        color: 'white'
        // display: false,
        // beginAtZero: false // Customize y-axis label color
      },
      grid: {
        display: false,
        // drawOnChartArea: false,
        color: 'white'
        // borderColor: 'red' // <-- this line is answer to initial question
      },
      border: {
        color: 'white'
      }
    }
    // xAxes: [{
    //   ticks: {
    //     beginAtZero: true,
    //     callback: function(value:number, index:number,values:number[]) {
    //       // where 3 is the line index you want to display
    //       return (index === 0) ? "" : null;
    //     }
    //   }
    // }]
  }
};

// const labels = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const Sentiment: React.FC = () => {
  const sentimentGraph = useSelector(({ SIGNALR }: RootState) => SIGNALR.sentimentGraph);
  const startForWL = useSelector(({ SIGNALR }: RootState) => SIGNALR.startForWL);

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Comments',
        data: [],
        borderColor: '#E3BF00',
        backgroundColor: '#E3BF00',
        color: 'white'
      },
      {
        label: 'Rating',
        data: [],
        borderColor: '#7CC6FA',
        borderRadius: '50%',
        backgroundColor: '#7CC6FA'
      }
    ]
  });

  useEffect(() => {
    setData({
      labels: sentimentGraph.map((el: SentimentGraphResponseType, idx: number) => idx) as any,
      datasets: data.datasets.map((el: any, idx: number) => {
        if (el.label === 'Comments') {
          return {
            ...el,
            data: sentimentGraph.map((el: SentimentGraphResponseType) => el.CommentSentiment)
          };
        } else {
          return {
            ...el,
            data: sentimentGraph.map((el: SentimentGraphResponseType) => el.RatingSentiment)
          };
        }
      })
    });
  }, [sentimentGraph]);

  // if(!startForWL){
  //   return (
  //     <>
  //       <div className={styles.Sentiment}>
  //         Waiting for start of meeting by the host
  //       </div>
  //     </>
  //   );
  // }

  return (
    <>
      <div></div>
      <div className={styles.Sentiment}>
        <div className={styles.sentimentHead}>
          <div className={styles.headingtext}>Sentiment</div>
          <div className={styles.newWork}>
            <img style={{ height: '1.75rem' }} alt="emote" src={emote} />
            <div className={styles.emoteStatus}>Neutral</div>
            <div>
              <img style={{ height: '1.75rem' }} alt="pin" src={pin} />
            </div>
          </div>
        </div>
        <Line options={options} data={data} />
      </div>
    </>
  );
};
