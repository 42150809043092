export interface meetingStateType {
  cq: any[];
}

const meetingState: meetingStateType = {
  cq: []
};

// reducer return new state

export const meetingReducer = (state = meetingState, action: any): meetingStateType => {
  switch (action.type) {
    case 'SET_CUSTOM_FULLFILLMENT_QUESTIONS': {
      return {
        ...state,
        cq: action.payload
      };
    }
    default:
      return state;
  }
};
