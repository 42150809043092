/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useDispatch } from 'react-redux';
import { httpRequest } from '../../../utils';
import './Card.css';
import React from 'react';
import { BASE_URL } from '../../../http/api/services';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../redux/reducers';

export interface questionProps {
  question: any;
  moveToAgenda: any;
}

export const sendToAgenda = async (
  question: any,
  sessionId: string | number
): Promise<any | undefined> => {
  console.log('question', question);
  return await httpRequest({
    url: `${BASE_URL}/PA_Event/PlanQuestion`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      QuestionKey: question.QuestionKey,
      Position: question.Ordering,
      IsMoveBackToIncoming: false,
      SessionId: sessionId
    }
  });
};

export const sendToIRP = async (
  question: any,
  eventKey: string,
  sessionId: string | number
): Promise<any | undefined> => {
  await sendToAgenda(question, sessionId);
  return await httpRequest({
    url: `${BASE_URL}/Question/LiveAnswer`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      SpeakerId: '0',
      QuestionStatus: '909',
      EventKey: eventKey,
      QuestionId: question.QuestionId,
      SessionId: sessionId
    }
  });
};
export const Card: React.FC<questionProps> = ({ question, moveToAgenda }: questionProps) => {
  const dispatch = useDispatch();
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  return (
    <>
      <div style={{ color: 'white' }} className="question">
        <div className="question-details">
          <div className="d-flex">
            <div className="name">{question.FirstName} </div>
            <div className="date">{question.QuestionTime}</div>
          </div>
          <div className="d-flex">
            <div className="d-flex">
              <div
                className="agenda"
                onClick={async () => {
                  // await moveToAgenda(question);
                  await sendToAgenda(question, activeSession.SessionId);
                  dispatch({ type: 'SET_QUESTIONS_CHANGED', payload: true });
                  dispatch({
                    type: 'SEND_COMMAND_TO_GROUP',
                    payload: { command: '[QUESTION-NEW]' }
                  });
                }}
              >
                Move to Agenda
              </div>
              <div
                className="irp"
                onClick={async () => {
                  await sendToIRP(
                    question,
                    activeSession.EventDetails.EventKey,
                    activeSession.SessionId
                  );
                  dispatch({ type: 'SET_QUESTIONS_CHANGED', payload: true });
                  dispatch({
                    type: 'SEND_COMMAND_TO_GROUP',
                    payload: { command: '[QUESTION-NEW]' }
                  });
                }}
              >
                Send to IRP
              </div>
            </div>
          </div>
        </div>
        <div>
          <p style={{ wordBreak: 'break-word' }}>{question.Question}</p>
        </div>
      </div>
    </>
  );
};
