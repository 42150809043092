import React from 'react';
import styles from './current-participants.module.scss';
import { useSelector } from 'react-redux';
import { type RootState } from '../../redux/reducers';
import { user } from '../../assets';
import { usePaginate } from '../../common/hooks/usePaginate';
import ReactPaginate from 'react-paginate';

export interface CurrentParticipantsPropType {
  usersPerPage: number;
  userList: any[];
  heading: string;
  // wlUserList: any[];
}

export const CurrentParticipants: React.FC<CurrentParticipantsPropType> = ({
  usersPerPage,
  userList,
  heading
  // wlUserList
}) => {
  //   const {joinedUsersList} = useSelector(({SIGNALR}:RootState)=>SIGNALR)
  const { pageCount, currentItems, handlePageClick } = usePaginate(userList, usersPerPage);
  // console.log('userList', userList);
  return (
    <div className={styles.container}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.subHeading}>Relate</div>
      <div>
        {currentItems.map((usr: any, index: number) => {
          return (
            <div key={index} className={styles.userInfo}>
              <div className={styles.user}>
                <div className={styles.userLeft}>
                  <img alt="avatar" src={user} className={styles.avatar} />
                  <div className={styles.username}>{usr.name}</div>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div className={styles.subHeading}>WL</div>
        {wlUserList.map((usr: any, index: number) => {
          return (
            <div key={index} className={styles.userInfo}>
              <div className={styles.user}>
                <div className={styles.userLeft}>
                  <img alt="avatar" src={user} className={styles.avatar} />
                  <div className={styles.username}>{usr.NameOfParticipant}</div>
                </div>
              </div>
            </div>
          );
        })} */}
      </div>
      <div className={styles['pagination-container']}>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={0}
          pageClassName={styles['page-item']}
          pageLinkClassName={styles['page-link']}
          previousClassName={styles.prev}
          previousLinkClassName={styles['prev-link']}
          nextClassName={styles.next}
          nextLinkClassName={styles['next-link']}
          breakClassName={styles['page-item']}
          breakLinkClassName={styles['page-link']}
          containerClassName={styles.pagination}
          activeClassName={styles.active}
          pageCount={pageCount}
          previousLabel="< Prev"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};
