/* eslint-disable no-debugger */
// export {};
import { type CoHost } from '../common/types';
import { UserRole } from '../redux/reducers/user';
import { type upcomingSessionType } from '../types/upcoming-sessions';

export const getUserRole = (session: upcomingSessionType, userKey: string): UserRole => {
  // console.log('getUserRole', session.CoHosts, userKey);
  if (session.DProducer.UserKey === userKey) return UserRole.host;
  // debugger;
  if (session.CoHosts !== undefined) {
    for (const cohost of session.CoHosts) {
      if (cohost.UserKey === userKey) {
        return UserRole.cohost;
      }
    }
  }
  // if (session.Speaker.UserKey === userKey) return UserRole.cohost;
  return UserRole.moderator;
};

export const isGUIDValid = (s: string): boolean => {
  // Regular expression is taken from here: https://stackoverflow.com/questions/11040707/c-sharp-regex-for-guid/11040993
  const regex = /^([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})$/;

  const result = regex.test(s);

  return result;
};
