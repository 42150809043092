import React from 'react';
import styles from './header.module.scss';
import { relateLogo } from '../../../assets';

export const Header: React.FC = () => {
  return (
    <div className={styles.headerContainer}>
      <img className={styles.logo} src={relateLogo} alt="logo" />
    </div>
  );
};
