import React, { useEffect, useState } from 'react';
import styles from './AgendaCoHost.module.css';
import { httpRequest } from '../../utils/http-request';
import { BASE_URL } from '../../http/api/services';
import { useParams, useSearchParams } from 'react-router-dom';
import { getDataFromMagicToken, type magicTokenResponseType } from '../../http/cohost';
import { setLocalStorageItem } from '../../utils';
import { ACCESS_TOKEN, ORG_ID } from '../constants';
import { useDispatch } from 'react-redux';
export const askQuestion = async (
  question: string,
  eventKey: string,
  sessionId: string | number,
  userKey: string,
  orgId: string,
  accessToken: string
): Promise<any | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/Question/AskQuestion`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        OrgId: orgId,
        Authorization: `Bearer ${accessToken}`
        // Appid: '5'
      },
      data: {
        EventKey: eventKey,
        SessionId: sessionId,
        Question: question,
        UserKey: userKey,
        Appid: '5'
      }
    },
    true,
    true
  );
  // eslint-disable-next-line no-unreachable
};
export const AgendCoHost: React.FC = () => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState('');
  const [mlRes, setMlRes] = useState<magicTokenResponseType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [completed, setCompleted] = useState(false);
  const ml = searchParams.get('ml');
  // console.log('ml',ml);
  const decodedString = atob(ml?.replace('SEE___', '') ?? '');
  const dataArr = decodedString.split(':');
  const sessionId = dataArr[3];
  // const oldLocalStorage = localStorage;

  useEffect(() => {
    const newDataArr = dataArr.filter((el, idx) => idx !== 3);
    const newDecodedString = newDataArr.join(':');
    const newEncodedString = 'SEE___' + btoa(newDecodedString);
    // console.log({ml, newEncodedString});
    if (newEncodedString != null) {
      getDataFromMagicToken(newEncodedString)
        .then((res) => {
          console.log('res', res);
          if (res.Success === false) {
            throw new Error('Link has Expired');
          }
          const response: magicTokenResponseType = res.Payload;
          console.log('response', response);

          // setLocalStorageItem(ACCESS_TOKEN, response.AccessToken);
          // setLocalStorageItem(ORG_ID, response.OrgKey);

          setMlRes(response);
        })
        .catch((err) => {
          console.log('err', err);
          dispatch({
            type: 'SET_ERROR_POPUP',
            payload: { message: err.message ?? 'Something went wrong' }
          });
          // setError(true);
        });
    }
    // return () => {
    //   setLocalStorageItem(ACCESS_TOKEN, oldLocalStorage[ACCESS_TOKEN]);
    //   setLocalStorageItem(ORG_ID, oldLocalStorage[ORG_ID]);
    // }
  }, []);

  const askQuestions = (): void => {
    void askQuestion(
      question,
      mlRes?.EventKey ?? '',
      sessionId,
      mlRes?.userKey ?? '',
      mlRes?.OrgKey ?? '',
      mlRes?.AccessToken ?? ''
    )
      .then(() => {
        // setLocalStorageItem(ACCESS_TOKEN, oldLocalStorage[ACCESS_TOKEN]);
        // setLocalStorageItem(ORG_ID, oldLocalStorage[ORG_ID]);
        // setThanksScreen(true);
        setQuestion('');
        setCompleted(true);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  return (
    <>
      <div className={styles.setagenda}>
        <div className={styles.agenda}>
          <div className={styles.alignment}>
            <div>SET THE AGENDA</div>
            {!completed ? (
              <>
                <div className={styles.box}>
                  <input
                    placeholder="What would you like to discuss?"
                    value={question}
                    onChange={(e) => {
                      setQuestion(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <button className={styles.submit} onClick={askQuestions}>
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <div className={styles.thanks}>Your response has been recorded</div>
            )}

            <div className={styles.thanks}>Thank you for helping us prepare for your meeting.</div>
          </div>
        </div>
      </div>
    </>
  );
};
