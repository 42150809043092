import { all, fork } from 'redux-saga/effects';
import { getUpcomingEventsSaga } from './get-upcoming-events-saga';
import { getUserDetailsSaga } from './get-user-details-saga';
import { getRoomCredentialsSaga } from './get-room-credentials-saga';
import { getWaitingRoomDetialsSaga } from './get-waiting-room-details';
import { getSentimentGraphDataSaga } from './get-sentiment-graph-data';
import { messageUsers } from './send-messaging';
import { startUserDuration } from './create-user-duration';
// TODO: refactor sagas and workers within DPGECLOF-1216
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* rootSaga() {
  yield all([
    fork(getUpcomingEventsSaga),
    fork(getUserDetailsSaga),
    fork(getRoomCredentialsSaga),
    fork(getWaitingRoomDetialsSaga),
    fork(getSentimentGraphDataSaga),
    fork(messageUsers),
    fork(startUserDuration)
  ]);
}
