import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  type CohostSessionListResponseType,
  getCohostSessionList,
  getDataFromMagicToken,
  type magicTokenResponseType
} from '../../http/cohost';
import { setLocalStorageItem } from '../../utils';
import {
  ACCESS_TOKEN,
  EVENT_KEY,
  ORG_ID,
  ORG_LOGO,
  ORG_NAME,
  OrganisationId,
  SESSION_ID_LIST,
  USER_KEY
} from '../../common/constants';
import { Navbar } from 'react-bootstrap';
import { Dashboard } from '../Dashboard/Dashboard';
import { Loader } from '../../ui-kit/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../redux/reducers';
import { getOrgDetail } from '../../http/organisation';

export const CoHostPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const ml = searchParams.get('ml');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   console.log('ml', ml);
  const errorPopup = useSelector(({ SIGNALR }: RootState) => SIGNALR.errorPopup);
  const [error, setError] = useState(false);

  useEffect(() => {
    console.log('CoHostPage');
    if (ml != null) {
      getDataFromMagicToken(ml)
        .then((res) => {
          console.log('res', res);
          if (res.Success === false) {
            throw new Error('Link has Expired');
          }
          const response: magicTokenResponseType = res.Payload;
          console.log('response', response);

          setLocalStorageItem(ACCESS_TOKEN, response.AccessToken);
          setLocalStorageItem(USER_KEY, response.userKey);
          setLocalStorageItem(ORG_ID, response.OrgKey);

          setLocalStorageItem(EVENT_KEY, response.EventKey);

          setLocalStorageItem('new-login', `login ${Math.random()}`);

          getCohostSessionList(response.EventKey, response.userKey)
            .then((res) => {
              console.log('res', res);
              if (res.Success === false) {
                throw new Error('Could not get Session List');
              }
              const response: CohostSessionListResponseType = res.Payload;
              const sessionIdsList = response.SessionCoHost.map((session) => {
                return session.Id;
              });
              setLocalStorageItem(SESSION_ID_LIST, JSON.stringify(sessionIdsList));
              navigate('/dashboard');
            })
            .catch((err) => {
              console.log('err', err);
              dispatch({
                type: 'SET_ERROR_POPUP',
                payload: { message: err.message ?? 'Something went while fetching session' }
              });
              setError(true);
            });

          getOrgDetail(response.OrgKey)
            .then((res) => {
              console.log(res);
              // setLocalStorageItem(OrganisationId, res?.Payload.Id.toString() ?? '');
              setLocalStorageItem(ORG_LOGO, res?.Payload.OrgLogo ?? '');
              setLocalStorageItem(ORG_NAME, res?.Payload.OrgName ?? '');
            })
            .catch(() => {
              console.error('could not get org details');
            });
        })
        .catch((err) => {
          console.log('err', err);
          dispatch({
            type: 'SET_ERROR_POPUP',
            payload: { message: err.message ?? 'Something went wrong' }
          });
          setError(true);
        });
    }
  }, []);

  return <>{!error && <Loader />}</>;
};
