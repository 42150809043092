import qs from 'qs';
import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';

export const putTranscriptionEveryMinute = async (
  sessionId: number,
  transcript: string
): Promise<unknown> => {
  const data = qs.stringify({
    sessionId,
    transcript
  });

  return await httpRequest(
    {
      url: `${BASE_URL}/Session/RelateTranscript`,
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },
    false
  );
};
