import React, { useEffect } from 'react';
import styles from './navbar.module.scss';
import { logo, back2, user } from '../../../assets';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonVariants } from '../../../ui-kit/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../../redux/reducers';
import { setRole, setUserDetails } from '../../../redux/actions';
import { UserRole, emptyUserDetails } from '../../../redux/reducers/user';
import { Tooltip, TooltipVariants } from '../../../ui-kit/Tooltip/Tooltip';
import { getLocalStorageItem, removeLocalStorageItem } from '../../../utils';
import { ACCESS_TOKEN, ORG_ID, ORG_LOGO, ORG_NAME, USER_KEY } from '../../constants';
import { relative } from 'path';
import { setModalShown } from '../../../redux/actions/app-state';
import { ModalVariants } from '../../../redux/reducers/app-state';
// import back from '../../../assets';

export const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const activeMeeting = {
  //   title: 'Investing in Inflationary Times',
  //   startTime: '10:00am AEDT',
  //   dateString: '27 Feb 2023'
  // };
  const isSideBarOpen = useSelector(({ SIGNALR }: RootState) => SIGNALR.isSideBarOpen);
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
  const [dropDownShow, setDropDownShow] = React.useState(false);

  const { id } = useParams();
  const loc = useLocation();

  // both of the below items will be present or both will be absent
  const orgLogo =
    getLocalStorageItem(ORG_LOGO).length > 0 ? getLocalStorageItem(ORG_LOGO) : undefined;
  const orgName =
    getLocalStorageItem(ORG_NAME).length > 0 ? getLocalStorageItem(ORG_NAME) : undefined;

  return (
    <header className={styles.navbar}>
      {/* <div className='d-flex'> */}
      {loc.pathname !== '/dashboard' && (
        <div className={styles.back}>
          <Button
            img={back2}
            text="back"
            variant={ButtonVariants.Navigation}
            onClick={() => {
              dispatch(setModalShown(ModalVariants.LeaveMeeting));
            }}
          />
        </div>
      )}
      <div
        onClick={() => {
          loc.pathname !== '/dashboard' && dispatch(setModalShown(ModalVariants.LeaveMeeting));
        }}
        style={{ cursor: 'pointer' }}
      >
        <img className={styles.logo} src={orgLogo ?? logo} alt={orgName ?? 'Forum360 logo'} />
      </div>
      {/* </div> */}
      {activeSession !== undefined && loc.pathname !== '/dashboard' && (
        <div className={styles.nav_meeting} style={isSideBarOpen ? { marginLeft: '19.6%' } : {}}>
          <div className={styles.nav_meetingTitle}>{activeSession.Title}</div>
          <div className={styles.nav_meetingDesc}>
            {activeSession.Date} | {activeSession.Time}
          </div>
        </div>
      )}
      {/* <div></div> */}
      {/* <button onClick={() => {
        dispatch(setRole(UserRole.host));
      }}></button> */}
      {loc.pathname === '/dashboard' && (
        <div
          className={styles.nav_logout_container}
          onClick={() => {
            setDropDownShow(!dropDownShow);
          }}
        >
          {userDetails.Avatar.length > 0 ? (
            <img className={styles.nav_logout_userIcon} src={userDetails.Avatar} alt="user" />
          ) : (
            <img className={styles.nav_logout_userIcon} src={user} alt="user" />
          )}
          <div className={styles.nav_logout_dropdown} style={{ position: 'relative' }}>
            <Tooltip
              variant={TooltipVariants.Footer}
              show={dropDownShow}
              css={{
                top: '0',
                right: '0',
                // transform: 'translate(-50%)',
                minWidth: '6.9rem'
              }}
              Sections={[
                {
                  fields: [
                    {
                      label: 'Profile',
                      name: userDetails.Email,
                      handleClick: () => {}
                    }
                    // ,{
                    //   label: 'OrgName',
                    //   name: getLocalStorageItem(ORG_NAME),
                    //   handleClick: () => {console.log('orgName clicked');}
                    // }
                  ]
                },
                {
                  fields: [
                    {
                      label: 'Logout',
                      name: 'Logout',
                      handleClick: () => {
                        // removeLocalStorageItem(USER_KEY);
                        // removeLocalStorageItem(ORG_ID);
                        // removeLocalStorageItem(ACCESS_TOKEN);
                        localStorage.clear();
                        dispatch(setUserDetails(emptyUserDetails));
                        navigate('/login');
                        // console.log('logout');
                      }
                    }
                  ]
                }
              ]}
              handleClick={function (field: any) {
                console.log(field);
              }}
            />
          </div>
        </div>
      )}
    </header>
  );
};
