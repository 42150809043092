import React, { useEffect, useState } from 'react';
import ToggleSwitch from '../../../ui-kit/Toggle/Toggle';
import './settings.scss';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../../redux/reducers';

export const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const aps = useSelector(({ SIGNALR }: RootState) => SIGNALR.automaticPresentationSwitch);
  const commandFromGroup = useSelector(({ SIGNALR }: RootState) => SIGNALR.commandFromGroup);
  // const muteAll = useSelector(({SIGNALR}:RootState)=>SIGNALR.muteAll);

  // const [aps, setAps] = useState<boolean>(false);
  const [blurBgAll, setBlurBgALL] = useState<boolean>(false);
  const [muteAll, setMuteAll] = useState<boolean>(false);
  const [enablePresentationControl, setEnablePresentationControl] = useState<boolean>(false);

  useEffect(() => {
    if (commandFromGroup.command === '[PARTICIPANT-UNMUTED]') {
      setMuteAll(false); // for host.
      // dispatch({ type: '[RECEIVE_MUTE_ALL]', payload: false });
    }
  }, [commandFromGroup]);

  return (
    <div className="settings-container">
      <div className="d-flex justify-content-between align-items-baseline">
        <div>
          <label htmlFor="aps">Auto-Switch Speaker</label>
        </div>
        <div>
          <ToggleSwitch
            id="aps"
            checked={aps}
            small
            onChange={(c) => {
              // setAps(c);
              dispatch({
                type: 'SET_AUTOMATIC_PRESENTATION_SWITCH',
                payload: c
              });
            }}
          />
        </div>
      </div>
      {/* <div className="d-flex justify-content-between align-items-baseline">
        <div>
          <label htmlFor="aps">Blur Background for All</label>
        </div>
        <div>
          <ToggleSwitch
            id="blurbgAll"
            checked={blurBgAll}
            small
            onChange={(c) => {
              setBlurBgALL(c);
            }}
          />
        </div>
      </div> */}
      <div className="d-flex justify-content-between align-items-baseline">
        <div>
          <label htmlFor="aps">Mute All</label>
        </div>
        <div>
          <ToggleSwitch
            id="muteAll"
            checked={muteAll}
            small
            onChange={(c) => {
              setMuteAll(c);
              dispatch({
                type: 'SEND_COMMAND_TO_GROUP',
                payload: { command: '[MUTE_ALL]', message: c }
              });
              // if (c) {
              // setTimeout(() => {
              // setMuteAll(false);
              // dispatch({ type: '[RECEIVE_MUTE_ALL]', payload: false });
              // }, 6900);
              // }
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-baseline">
        <div>
          <label htmlFor="aps">Enable Presentation Control</label>
        </div>
        <div>
          <ToggleSwitch
            id="epc"
            checked={enablePresentationControl}
            small
            onChange={(c) => {
              setEnablePresentationControl(c);
              if (c) {
                dispatch({
                  type: 'SEND_COMMAND_TO_GROUP',
                  payload: { command: '[ALLOW-SCREENSHARE]', message: '' }
                });
              } else {
                dispatch({
                  type: 'SEND_COMMAND_TO_GROUP',
                  payload: { command: '[DISALLOW-SCREENSHARE]', message: '' }
                });
              }
              // SET FOR SELF
              dispatch({ type: 'SET_SCREENSHARE', payload: c });
            }}
          />
        </div>
      </div>
    </div>
  );
};
