import React, { useEffect } from 'react';
import './Transcription.scss';
import ai from './ai.svg';
import expand from './expand.svg';
import ClosedCaptions from '../../../ClosedCaptions/ClosedCaptions';
import ToggleSwitch from '../../../../ui-kit/Toggle/Toggle';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
export const Transcription: React.FC = () => {
  const dispatch = useDispatch();
  const { isCCon } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const role = useSelector(({ USER }) => USER.role);

  useEffect(() => {
    dispatch({ type: 'SET_CHANGE_RENDER_ID', payload: true });
    return () => {
      dispatch({ type: 'SET_CHANGE_RENDER_ID', payload: false });
    };
  }, []);

  return (
    <div className="cryption">
      <div className="cryption-heading">
        {/* <div className="script"> */}
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex gap-2">
            <img src={ai} />
            <div className="data">Transcription</div>
          </div>
          {role === 'host' ? (
            <ToggleSwitch
              id="isCCon"
              checked={isCCon}
              small
              onChange={(c) => {
                dispatch({
                  type: 'SET_CC',
                  payload: c
                });

                dispatch({
                  type: 'SEND_COMMAND_TO_GROUP',
                  payload: {
                    command: '[SET-CC]',
                    message: c // message can only be a string so will have to req.message.toLowerCase() === 'true'
                  }
                });
              }}
            />
          ) : isCCon ? (
            '(Enabled)'
          ) : (
            '(Disabled)'
          )}
        </div>
        {/* </div> */}

        {/* <div className="expand">
          <img src={expand} />
        </div> */}
      </div>
      <div id="render-cc"></div>
    </div>
  );
};
