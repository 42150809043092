/* eslint-disable react/react-in-jsx-scope */
import './Events.css';
import Pagination from '../Pagination/Pagination';
import { useSelector } from 'react-redux';
import { useState , useEffect } from 'react';
import { NoSession } from './NoSession';
import { getUserRole } from '../../utils/user-role';
import { getLocalStorageItem } from '../../utils';
import { USER_KEY } from '../../common/constants';
// const data = [
//     { Date: "April 16, 2023", Time:"1:00-2:00pm PST", Name:"Investing in Inflationary Times" ,Host: "James Albert",Product:"Product",Type:"Public",Status:"In Progress"},
//     { Date: "April 17, 2023", Time:"1:00-2:00pm PST", Name:"How To Create True Value", Host:"Lewis Santos", Product:"Product",Type:"Public",Status:"Starting Soon"},
//     { Date: "April 17, 2023", Time:"3:00-4:30pm PST", Name:"Get Best Advertise In Your", Host:"Eunice Nunez", Product:"Product",Type:"Public",Status:"Starting Soon"},
//     { Date: "April 17, 2023", Time:"4:00-5:00pm PST", Name:"What is the Big R for Marketing",Host:"Lucas Malone",Product:"Product",Type:"Public",Status:"Upcoming"},
//     { Date: "April 17, 2023", Time:"5:00-7:00pm PST", Name:"Branding Do you Know Who You Are",Host:"Essie Herrera",Product:"Product",Type:"Private",Status:"Upcoming"},
//     { Date: "April 18, 2023", Time:"5:00-7:00pm PST", Name:"Why Good Branding is Critical",Host:"Essie Herrera",Product:"Product",Type:"Public",Status:"Upcoming"}
// ]
function Events(){
    const sessions = useSelector(({APPSTATE})=>APPSTATE.upcomingSessions);
    const [data,setData] = useState([]);
    const data1 = [];
    useEffect(() => {
        const data = sessions.map((s)=>{
            return {
                Date: s.Date,
                Time: s.Time,
                Name: s.Title,
                Host: s.SpeakerName,
                Product: s.Product,
                Type: s.Type,
                Status: s.Status,
                EventName: s.EventDetails.Title,
                EventDetails: s.EventDetails,
                SessionId: s.SessionId,
                Role: getUserRole(s,getLocalStorageItem(USER_KEY))
            };
        });
        console.log('sess',sessions);
        setData(data);
    }, [sessions])
    // const [currentPage,setCurrentPage]= useState(1);
    // <div className="App">
    // console.log('data',data);
    return (
        <>
        {data.length> 0 && <Pagination
            data={data} 
            currentPage={1} 
            totalPages={Math.ceil((data1.length)/5)}
            // totalPages={1}
            />}

        </>
    );
    // return (
    //         {/* <div className="heading">
    //                 <div>
    //                     Upcoming Events<br/>
    //                     Scheduled Meetings and Calls
    //                     </div> 
                    
    //                 <input type="text" class="form-control form-control-lg rounded" placeholder="Type Keywords"
    //                     aria-label="Type Keywords" aria-describedby="basic-addon2" />
    //             </div>  
            
    //         <table className="Events-table">

    //             {data.map((val, key) => {
    //                 return (
    //                     <tr key={key} className="Events-table">
    //                         <td><img src={Chat}/></td>
    //                         <td><div>{val.Date}</div>
    //                             <div>{val.Time}</div></td>
    //                         <td><div>{val.Name}</div>
    //                             <div>{val.Host}</div></td>
    //                         <td>{val.Product}</td>
    //                         <td>{val.Type}</td>
    //                         <td><button className="badge">In Progress
    //                             </button></td>
    //                     </tr>
    //                 )
    //             })}
    //         </table> */}

    //         <Pagination
    //         data={data} currentPage={1} totalPages={1}/>
    //         )
            // </div>
}

export default Events;