/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';
import pin from './pin.svg';
import user1 from './user1.png';
import user2 from './user2.png';
import './Carbon.scss';
import expand from './expand.svg';
import edit from './edit.svg';
import { BASE_URL } from '../../../../http/api/services';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import { Questions } from '../Feedback/Questions/Questions';
import { UserRole } from '../../../../redux/reducers/user';
import user from '../../../../assets/user.svg';
import { getLocalStorageItem, httpRequest } from '../../../../utils';
import Select from 'react-select';
import { act } from '@testing-library/react';
import { DropDownUser } from './DropDownUser';
import { options } from '../Analytics/Sentiment';
import tick from './tick.svg';
import { cross } from '../../../../assets';
import { updateUserAddress } from '../../../../http/Admin_User';
import { saveCarbon } from '../../../Footer/Footer';
import { ACCESS_TOKEN } from '../../../../common/constants';
import { analyticsMetricData } from '../Analytics/Analytics';
export const getUpdatedCohost = async (
  eventKey: string,
  token: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/IR_Event/Detail/${eventKey}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      OrgId: localStorage.organisationId
    }
  })
    .then(async (response: { json: () => any }) => response.json())
    .catch((error: any) => {
      console.error(error);
      return [];
    });
};

export const getCity = async (
  token: string,
  orgId: string,
  city: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/api/CityList/?keyword=${city}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
      // OrgId: orgId,
      // keyword: city
    }
  })
    .then(async (response: { json: () => any }) => response.json())
    .catch((error: any) => {
      console.error(error);
      return [];
    });

  //   return await httpRequest({
  //     url: `${BASE_URL}/api/City`
  //   })
  //   await httpRequest({
  //     url: `${BASE_URL}/PA_Event/SendWelcome`,
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       OrgId: localStorage.orgId
  //     },
  //     data: JSON.stringify({
  //       SessionId: sessionId
  //     })
  //   });
  // };
};
export const saveCity = async (
  token: string,
  eventKey: string,
  cohostUserKey: string,
  location: string,
  city: string,
  orgKey: string,
  sessionId: number
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/SalesMeetingNotes/SaveCity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      Location: location,
      SessionId: sessionId,
      EventKey: eventKey,
      CoHostUserKey: cohostUserKey,
      City: city,
      OrgKey: orgKey
    })
  });
};
export const CarbonSaved = async (
  token: string,
  eventKey: string,
  sessionId: number,
  orgKey: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/EventAnalytic/Metric`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      OrgKey: orgKey
    },
    body: JSON.stringify({
      EventKey: eventKey,
      SessionId: sessionId
    })
  })
    .then(async (response: { json: () => any }) => response.json())
    .then((json: { Payload: any }) => json.Payload)
    .catch((error: any) => {
      console.error(error);
      return [];
    });
};
export const UserList = async (token: string, orgKey: string): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/User/UserByOrg/${orgKey}/15`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export const listOfUsers = async (
  token: string,
  UserKey: string,
  orgId: string,
  orgKey: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/api/User/UserDetail/${orgKey}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      userKey: UserKey,
      OrgId: orgId
    }
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export const fetchCoHost = async (
  token: string,
  sessionId: number,
  orgId: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/Session/Detail/${sessionId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      OrgId: orgId
    }
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export const adminUser = async (
  token: string,
  reqUserKey: string,
  orgId: string
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/Admin_User/Detail/${reqUserKey}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      OrgId: orgId
    }
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export const Carbon: React.FC = () => {
  const dispatch = useDispatch();
  const { commandFromGroup } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const [userState, setUserState] = useState<any[]>([]);
  const [userLocation, setUserLocation] = useState('location');
  const [nameFilter, setNameFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [editLocVal, setEditLocVal] = useState('');
  const [err, setErr] = useState('');

  const recalculateAndUpdateCarbonSavings = (): void => {
    saveCarbon(getLocalStorageItem(ACCESS_TOKEN), activeSession.EventDetails.EventId)
      .then(() => {
        void analyticsMetricData(
          localStorage.accessToken,
          activeSession.EventDetails.EventKey,
          activeSession.SessionId,
          localStorage.orgId
        ).then((data) => {
          // console.log('data', data);
          dispatch({ type: 'SET_CARBON_SAVED', payload: data.CostofSalesSavings.CarbonSaved });
          // setTimeSaved(data.CostofSalesSavings.TransportationTimeSaved.toString().concat(' hrs'));
        });
      })
      .catch(() => {});
  };

  function findName(value: string): void {
    // let tempUserData = [...userData];
    setNameFilter(value);
  }
  const setValue = (value: any, index: number): void => {
    const tempcohost = [...userData];
    console.log('values in text', value);
    tempcohost[index][2] = value;
    console.log('tempCost ......', tempcohost);
    // setTempUserData(tempcohost);
    setUserData(tempcohost);
  };
  function findCity(value: string): void {
    // setTempUserData(
    //   userData.filter((item) => {
    //     return item[2].toString().toLowerCase().includes(value.toLowerCase());
    //   })
    // );
    setCityFilter(value);
  }
  //   function myFunction() : void{
  //     var input, filter, ul, a, i, txtValue;
  //     input =(document?.getElementById("myInput") as HTMLInputElement);
  //     filter = input?.value?.toUpperCase();
  //     ul = document.getElementById("myUL");
  //     let atag = ul?.getElementsByTagName("li");
  //     let v = [...atag]
  //     for (i = 0; i < v.length; i++) {
  //         a = v[i].getElementsByTagName("a")[0];
  //         txtValue = a.textContent || a.innerText;
  //         if (v.toUpperCase().indexOf(filter) > -1) {
  //             v[i].style.display = "";
  //         } else {
  //             v[i].style.display = "none";
  //         }
  //     }
  // }
  const toggleEditState = (key: number): void => {
    const tempCohost = [...cohost];
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    tempCohost[key] = !tempCohost[key];
    setCohost(tempCohost);
  };
  // function changeLocation(location: string, key: number, question: any): void {
  //   console.log('location', location);
  //   console.log('key in Carbon', key);
  //   let tempCohost = [...cohost];
  //   console.log('list cohost', cohost);
  //   if (cohost[key] === false) {
  //     tempCohost[key] = true;
  //     // console.log('cohost', cohost[key]);
  //   } else {
  //     console.log('questions', question);
  //     void saveCity(
  //       localStorage.accessToken,
  //       activeSession.EventDetails.EventKey,
  //       question[3],
  //       question[2].toString(),
  //       question[2].toString(),
  //       localStorage.orgId,
  //       activeSession.SessionId
  //     );
  //     tempCohost[key] = false;
  //     // console.log("local",localStorage);
  //     // console.log('cohost', cohost[key]);
  //   }
  //   setCohost(tempCohost);
  // }
  const updateAddress = (loc: string, key: number, question: any): void => {
    updateUserAddress(question[3], loc)
      .then((data) => {
        setValue(editLocVal, key);
        toggleEditState(key);
        return data;
      })
      .then((data) => {
        dispatch({
          type: 'SEND_COMMAND_TO_GROUP',
          payload: { command: '[UPDATE-ADDRESS]' }
        });
        recalculateAndUpdateCarbonSavings();
      })
      .catch((err) => {
        setErr('Failed to update city');
        console.log(err);
      });
  };
  const [cohost, setCohost] = useState<any[]>([]);
  // const [cohostlocation,setcohostlocation] = useState<any[]>([]);
  const [userData, setUserData] = useState<any[]>([]);
  // const [tempUserData, setTempUserData] = useState<any[]>([]);
  const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
  const role = useSelector(({ USER }: RootState) => USER.role);
  const { activeSession, carbonSaved } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  // useEffect(()=>{
  //   setTempUserData(
  //     userData.filter((item) => {
  //       return item[1].toLowerCase().includes(filter.toLowerCase());
  //     })
  //   );
  // },[filter, userData])
  function setLocation(value: string, key: number): void {
    console.log('value', value);

    const tempcohost = [...userData];

    tempcohost[key][2] = value;
    console.log('tempcohost', tempcohost);

    setUserData(tempcohost);
    // setTempUserData(tempcohost);
  }
  const citiesAutoComplete = (value: string): void => {
    if (value.length >= 4) {
      setUserState([]);
      void getCity(localStorage.accessToken, localStorage.organisationId, value).then((data) => {
        console.log('data changes', data);

        let tempData: any[] = [];
        data.forEach((option: { code: number; cityName: string }, key: string) => {
          tempData.push({ label: option.cityName, value: option.cityName });
        });
        setUserState(tempData);
      });
    }
  };
  useEffect(() => {
    void getUpdatedCohost(activeSession.EventDetails.EventKey, localStorage.accessToken).then(
      (data) => {
        const temp = [];
        const a = data.Payload.City;
        const b = data.Payload.CoHostUserKey;

        if (a === '' || b === '') {
          return;
        }

        const Cities = a.split(';');
        const UserKey = b.split(',');

        const dataValue = new Array(UserKey.length);
        dataValue.fill(false);
        setCohost(dataValue);

        UserKey.forEach((userKey: string, index: number) => {
          void adminUser(localStorage.accessToken, userKey, localStorage.organisationId).then(
            (data) => {
              setUserData((prevData) => [
                ...prevData,
                [
                  data.UserDetail.Avatar,
                  data.UserDetail.FirstName + ' ' + data.UserDetail.LastName,
                  Cities[index],
                  UserKey[index]
                ]
              ]);
            }
          );
        });
      }
    );
    // void fetchCoHost(
    //   localStorage.accessToken,
    //   activeSession.SessionId,
    //   localStorage.organisationId
    // ).then((data) => {
    //   const dataValue = new Array(data.CoHosts.length);
    //   dataValue.fill(false);
    //   setCohost(dataValue);
    //   let userDataDetails: any[] = [];
    // });

    // void UserList(localStorage.accessToken, localStorage.orgId).then((data) => {
    //   console.log('data for carbon', data);
    // data.forEach((element: any) => {
    //   void listOfUsers(localStorage.accessToken, element.UserKey).then((data1) => {
    //     console.log('data1', data1);
    //   });
    // });
    // });
  }, []);

  useEffect(() => {
    if (commandFromGroup.command === '[UPDATE-ADDRESS]') {
      void getUpdatedCohost(activeSession.EventDetails.EventKey, localStorage.accessToken).then(
        (data) => {
          const temp = [];
          const a = data.Payload.City;
          const b = data.Payload.CoHostUserKey;

          if (a === '' || b === '') {
            return;
          }

          const Cities = a.split(';');
          const UserKey = b.split(',');

          const dataValue = new Array(UserKey.length);
          dataValue.fill(false);
          setCohost(dataValue);
          setUserData([]);
          UserKey.forEach((userKey: string, index: number) => {
            void adminUser(localStorage.accessToken, userKey, localStorage.organisationId)
              .then((data) => {
                setUserData((prevData) => [
                  ...prevData,
                  [
                    data.UserDetail.Avatar,
                    data.UserDetail.FirstName + ' ' + data.UserDetail.LastName,
                    Cities[index],
                    UserKey[index]
                  ]
                ]);
              })
              .catch(() => {
                console.log('failed');
              });
          });
        }
      );
      recalculateAndUpdateCarbonSavings();
    }
  }, [commandFromGroup]);
  // const cellRef = useRef([]);
  const customStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'black'
      // zIndex:'30000'
      // overflow:'scroll'
      // zIndex:3000000
    }),
    container: (provided: any, state: any) => ({
      ...provided
      // backgroundColor: 'black',
      // zIndex:'30000'
      // overflow:'scroll'
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      // backgroundColor: 'black',
      scrollbarWidth: 'thin',
      maxHeight: '200px'
      // zIndex:3000000
      // overflow:'scroll'
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: 'none',
      minHeight: 'unset',
      // borderBottom:'1px solid white',
      // minWidth:'30px',
      // width:'50%',
      // outline: 'none',
      color: '#F8F8F8',
      // background:'transparent'
      backgroundColor: 'rgba(10, 14, 23, 0.6)' // Change the border style
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: 'white'
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided
      // display: 'none'
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      display: 'none'
    }),
    input: (provided: any) => ({
      ...provided,
      color: '#F8F8F8'
    }),
    option: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      // whiteSpace: "nowrap",
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      color: state.isFocused ? 'black' : 'white',
      backgroundColor: state.isFocused ? 'white' : 'black'
    })
  };
  // setUserData([['','Himanshu','Faridabad','1'],['','Sahil','Delhi','2']]);
  return (
    <div className="carbon">
      <div className="save">
        <div className="carbon-head">Calculate your Carbon saving</div>
        <div className="carbon-head-icons">
          <img alt="expand" style={{ height: '1.75rem' }} src={expand} />
          <img alt="pin" style={{ height: '1.25rem' }} src={pin} />
        </div>
      </div>
      {/* <div className="carbon-head">Calculate your Carbon saving</div> */}
      <div className="cities-head">You are joining us from {userLocation}.You are not emitting</div>

      <div className="count">{carbonSaved} lbs</div>

      {(role === UserRole.host || role === UserRole.moderator) && (
        <div className="details">
          <div className="attendee">
            <div>Attendee Name</div>
            <input
              placeholder="Filter Attendee Name"
              className="filter-attendee"
              onChange={(e) => {
                findName(e.target.value);
              }}
            />
          </div>
          <div className="city">
            <div>City Name</div>
            <input
              placeholder="Filter City Name"
              className="filter-city"
              onChange={(e) => {
                findCity(e.target.value);
              }}
            />
          </div>
        </div>
      )}
      <div className="userlist">
        {(role === UserRole.host || role === UserRole.moderator) &&
          userData.length > 0 &&
          userData.map((question: any, index: number) => {
            return question[1].toLowerCase().includes(nameFilter.toLowerCase()) &&
              question[2].toString().toLowerCase().includes(cityFilter.toLowerCase()) ? (
              <div key={index} className="user-info">
                <div className="user">
                  <div className="user-left">
                    <img
                      alt="avatar"
                      src={question[0].length > 0 ? question[0] : user}
                      className="avatar"
                    />
                    <div className="username">{question[1]}</div>
                  </div>
                  <div className="user-right">
                    <div className="name"></div>
                    <div className="user-location">
                      {Boolean(cohost[index]) && (
                        <>
                          <div className="location">
                            {/* <input
                          list="cityname"
                          value={question[2]}
                          onChange={(e) => {
                            setLocation(e.target.value, index);
                          }}
                        /> */}
                            <Select
                              placeholder="Search..."
                              options={userState}
                              styles={customStyles}
                              onInputChange={(e, actionMeta) => {
                                if (actionMeta.action === 'input-change') {
                                  citiesAutoComplete(e);
                                } else if (actionMeta.action === 'input-blur') {
                                  // toggleEditState(index)
                                }
                                console.log('on1 inp change e', e);
                              }}
                              onChange={(e) => {
                                setEditLocVal(e.value);
                                setUserState([]);
                              }}
                            />
                            {err && <div style={{ color: 'red' }}>{err}</div>}
                          </div>
                          {editLocVal && (
                            <img
                              src={tick}
                              alt="save"
                              className="edit"
                              onClick={(e) => {
                                updateAddress(editLocVal, index, question);
                              }}
                            />
                          )}
                        </>
                      )}

                      {cohost[index] === false && <div className="location">{question[2]}</div>}
                      <img
                        src={cohost[index] ? cross : edit}
                        alt="edit"
                        className="edit"
                        onClick={(e) => {
                          toggleEditState(index);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            );
          })}
        {role === UserRole.cohost &&
          userData.map((question: any, index: number) => {
            return question[3] === userDetails.UserKey ? (
              <div key={index} className="user-info">
                <div className="d-flex">
                  <img src={question[0].length > 0 ? question[0] : user} className="avatar" />
                  <div className="username">{question[1]}</div>
                </div>
                <div className="place">
                  {Boolean(cohost[index]) && (
                    <div className="location">
                      <input
                        value={question[2]}
                        onChange={(e) => {
                          setLocation(e.target.value, index);
                        }}
                      />
                    </div>
                  )}
                  {/* {state.length > 0 && state.map(value:any,key:number)=>{
                    <div>
                      value.cityName
                    </div>
                  }} */}
                  {cohost[index] === false && <div className="location">{question[2]}</div>}
                  {/* <img
                    src={edit}
                    alt='edit'
                    className="edit"
                    onClick={() => {
                      changeLocation('faridabad', index, question);
                    }}
                  /> */}
                </div>
              </div>
            ) : (
              ''
            );
          })}
      </div>
    </div>
  );
};
