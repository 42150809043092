import qs from 'qs';
import { getLocalStorageItem, httpRequest } from '../utils';
import { BASE_URL } from './api/services';
import { OrganisationId } from '../common/constants';

export const getGlobalChecklist = async (
  sessionId: number
  // userKey: string
): Promise<any | undefined> => {
  const orgId = getLocalStorageItem(OrganisationId);
  const data = qs.stringify({
    //   UserKey: userKey,
    SessionId: sessionId?.toString(),
    OrganisationId: orgId
  });
  return await httpRequest({
    url: `${BASE_URL}/CheckList/GetGlobalCheckList`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
      //   AppId: '4'
    }
  });
};

export const getCustomChecklist = async (
  sessionId: number,
  userKey: string
): Promise<any | undefined> => {
  const orgId = getLocalStorageItem(OrganisationId);
  const data = qs.stringify({
    UserKey: userKey,
    SessionId: sessionId?.toString(),
    OrganisationId: orgId
  });
  return await httpRequest({
    url: `${BASE_URL}/CheckList/GetCustomCheckList`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
      //   AppId: '4'
    }
  });
};

export const saveCustomCheckListItem = async (
  sessionId: number,
  userKey: string,
  isChecked: boolean,
  title: string,
  itemId?: number
): Promise<any | undefined> => {
  const orgId = getLocalStorageItem(OrganisationId);
  const data = qs.stringify({
    UserKey: userKey,
    SessionId: sessionId?.toString(),
    OrganisationId: orgId,
    ItemId: itemId,
    Title: title,
    IsChecked: isChecked
  });
  return await httpRequest({
    url: `${BASE_URL}/CheckList/SaveCustomCheckListItem`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      AppId: '4'
    }
  });
};

export const saveGlobalCheckListItem = async (
  sessionId: number,
  userKey: string,
  isChecked: boolean,
  title: string,
  itemId?: number
): Promise<any | undefined> => {
  const orgId = getLocalStorageItem(OrganisationId);
  const data = qs.stringify({
    UserKey: userKey,
    SessionId: sessionId?.toString(),
    OrganisationId: orgId,
    ItemId: itemId,
    Title: title,
    IsChecked: isChecked
  });
  return await httpRequest({
    url: `${BASE_URL}/CheckList/SaveGlobalCheckListItem`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      AppId: '4'
    }
  });
};
