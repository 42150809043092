import React, { useEffect, useState } from 'react';
import { Header } from '../../common/components/Header/Header';
import FooterForum from '../FooterForum';
import { useParams } from 'react-router-dom';
import styles from './custom-streaming-teams.module.scss';
import { copyIcon } from '../../assets';
import { getEventDetail } from '../../http/events';
import { ACCESS_TOKEN } from '../../common/constants';
import { removeLocalStorageItem, setLocalStorageItem } from '../../utils';

export const CustomStreamingZoom: React.FC = () => {
  const { token } = useParams();
  const [url, setUrl] = useState('');
  const [key, setKey] = useState('');
  const [lsPage, setLsPage] = useState('');
  const [err, setErr] = useState<null | string>(null);

  useEffect(() => {
    const decodedString = atob(token?.replace('So___', '') ?? '');
    const dataArr = decodedString.split(':');
    const eventKey = dataArr[0];
    const accessToken = dataArr[1];
    const sessionId = parseInt(dataArr[2]);
    setLocalStorageItem(ACCESS_TOKEN, accessToken); // not required ??

    getEventDetail(eventKey)
      .then((data) => {
        console.log('event details', data);
        const sessions = data?.Payload?.Sessions?.filter((s: any) => s.SessionId === sessionId);
        if (sessions.length === 0) {
          setErr('session not found');
          return;
        }
        // check for null undefined or empty string.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!sessions[0]?.StreamUrl) {
          setErr('Stream url not set through admin portal');
          return;
        }
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!sessions[0]?.StreamKey) {
          setErr('Stream Key not set through admin portal');
          return;
        }
        setUrl(sessions[0].StreamUrl);
        setKey(sessions[0].StreamKey);
        setLsPage(sessions[0].MediaFile);
      })
      .catch((err) => {
        console.log(err);
        setErr(`Unable to get Stream info`);
        //  Err: + ${typeof err?.data?.Message === "string" ? err.data.Message : ' '}`);
      })
      .finally(() => {
        // once we have gottent the key and url we can safely remove the access token.
        removeLocalStorageItem(ACCESS_TOKEN);
      });
  }, []);

  return (
    <div>
      <Header />
      <div className={styles.mdContainer}>
        <h1>Customised Streaming Setup for Zoom</h1>

        <br />

        <p>
          At the top of the Zoom window, click on More, Live Broadcasting and then Live on Custom
          Live Streaming Service
        </p>

        <p>
          <img
            alt="zoom app"
            src="https://github.com/user-attachments/assets/03e7ac1b-ca72-4511-917d-c9d4c102955b"
          />
        </p>

        <p>You will be redirected to a webpage</p>

        <p>
          <img
            alt="webpage"
            src="https://github.com/user-attachments/assets/5c1a0450-90b4-460f-a188-a61be81bdc55"
          />
        </p>

        <p>Copy and Paste the following Stream URL and Stream Key</p>

        {err != null && <div className="red">{err}</div>}

        <div className={styles.keyContainer}>
          <div>Stream URL:</div>
          <div>
            <input readOnly type="password" value={url} />
          </div>
          <div>
            <button
              className={styles.copyButton}
              onClick={() => {
                navigator.clipboard
                  .writeText(url)
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              <img src={copyIcon} />
            </button>
          </div>
        </div>

        <div className={styles.keyContainer}>
          <div>Stream Key:</div>
          <div>
            <input readOnly type="password" value={key} />
          </div>
          <div>
            <button
              className={styles.copyButton}
              onClick={() => {
                navigator.clipboard
                  .writeText(key)
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              <img src={copyIcon} />
            </button>
          </div>
        </div>

        <div className={styles.keyContainer}>
          <div>Live Streaming Page:</div>
          <div>
            <input readOnly type="password" value={lsPage} />
          </div>
          <div>
            <button
              className={styles.copyButton}
              onClick={() => {
                navigator.clipboard
                  .writeText(lsPage)
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              <img src={copyIcon} />
            </button>
          </div>
        </div>

        {/* <p>
          Note: Live Streaming page url can be any url you want but ideally should be the url where
          users can go to watch the stream (it is shown to other users in the zoom meeting){' '}
        </p> */}
        <p>Leave the checkbox un-ticked</p>
        <p>Click Go Live and you are set to return to the Zoom App</p>
      </div>
      <FooterForum />
    </div>
  );
};
