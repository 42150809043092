import React, { useEffect, useState } from 'react';
import styles from './Comments.module.scss';
import { Card } from '../../../../../common/components/Card/Card';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../../../redux/reducers';
import { CommentsCard } from './CommentsCard';
import { BASE_URL } from '../../../../../http/api/services';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface countComments {
  countComments: any;
}
export const Comments: React.FC<countComments> = ({ countComments }: countComments) => {
  async function getCommentData(
    token: string,
    eventKey: string,
    sessionId: number,
    orgKey: string
  ): Promise<any> {
    return await fetch(`${BASE_URL}/EventAnalytic/GetCommentData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`,
        OrgKey: orgKey
      },
      body: JSON.stringify({
        EventKey: eventKey,
        SessionId: sessionId
      })
    })
      .then(async (response) => await response.json())
      .then((json) => {
        return json.Payload;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }
  // async function moveToAgenda(question: any): Promise<any> {
  //   return await fetch(`https://mgmt-test.forum360.co/api//PA_Event/PlanQuestion`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.accessToken}`
  //     },
  //     body: JSON.stringify({
  //       QuestionKey: question.QuestionKey,
  //       Position: question.Ordering,
  //       IsMoveBackToIncoming: true,
  //       SessionId: 2560
  //     })
  //   });
  // }

  const [comments, setComments] = useState([
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
    // {
    //   UserName: 'Roger Vaccaro',
    //   createdDate: '20:19:20',
    //   comment: 'Do fixed income investments on a 30 year period have higher returns?'
    // },
  ]);
  const [load, setLoad] = useState(false);
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const commandFromGroup = useSelector(({ SIGNALR }: RootState) => SIGNALR.commandFromGroup);
  useEffect(() => {
    if (commandFromGroup.command === '[COMMENT-NEW]') {
      console.log('new comment');
      setTimeout(() => {
        void getCommentData(
          localStorage.accessToken,
          activeSession.EventDetails.EventKey,
          activeSession.SessionId,
          localStorage.orgId
        )
          .then((comments: any) => {
            // console.log('comments', comments.GetCommentDetail);
            console.log('comments', comments?.GetCommentDetail);
            setComments(comments?.GetCommentDetail);
            countComments(comments?.GetCommentDetail?.length);
          })
          .catch((err) => {
            console.log('err', err);
          });
      }, 1000);
    }
  }, [commandFromGroup]);

  useEffect(() => {
    void getCommentData(
      localStorage.accessToken,
      activeSession.EventDetails.EventKey,
      activeSession.SessionId,
      localStorage.orgId
    )
      .then((comments: any) => {
        console.log('comments', comments.GetCommentDetail);
        setComments(comments.GetCommentDetail);
        countComments(comments.GetCommentDetail.length);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  function loadMore(): void {
    console.log('load', load);
    if (load) {
      setLoad(false);
    } else {
      setLoad(true);
    }
  }
  return (
    <>
      <div className={load ? styles.loadMore : styles.loadLess}>
        {
          /* <div className={styles.questiontabs}>
        <Card />
      </div>
      <div className={styles.questiontabs}>
        <Card />
      </div>
      <div className={styles.questiontabs}>
        <Card />
      </div>
      <div className={styles.questiontabs}>
        <Card />
      </div> */
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          comments &&
            comments.length > 0 &&
            comments.map((question: any, index: React.Key | null | undefined) => (
              <div className={styles.questiontabs} key={index}>
                <CommentsCard commentdetail={question} />
              </div>
            ))
        }
      </div>
      {/* <div className={styles.load}>
        <Button className={styles.comments} onClick={loadMore}>
          Load More
        </Button>
      </div> */}
    </>
  );
};
