import React from 'react';

import styles from './ActiveAttendees.module.scss';
import pin from '../Resources/pin.svg';
import ProgressBar from 'react-bootstrap/ProgressBar';

export interface rating {
  data: any;
}
export const ActiveAttendees: React.FC<rating> = ({ data }: rating) => {
  return (
    <div className={styles.attendees}>
      <div className={styles.attendeesheading}>
        <div>Active attendees</div>
        <div className={styles.metric}>
          <div className={styles.change}>{data}%</div>
          <div>
            <img alt="pin" style={{ height: '1.5rem' }} src={pin} />
          </div>
        </div>
      </div>
      <div className={styles.progressdes}>
        <div className={styles.description}>Viewers who are giving feedback, researching, etc</div>
        <div className={styles.progressrow}>
          <ProgressBar now={data} label={data} visuallyHidden style={{ height: '0.3rem' }} />;
        </div>
      </div>
    </div>
  );
};
