/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import './App.css';
// import { Button, ButtonVariants } from './ui-kit/Button/Button';
// import { Event } from './ui-kit/Event/Event';
// // import { BadgeVariants } from './ui-kit/Badges/Badge';
// import { thumbnail, unfadedThumb, unfadedThumbnail, user } from './assets';
// import { Badge, BadgeVariants } from './ui-kit/Badges/Badge';
// import { hostPic } from './mock-data';
// import { Carousel } from './common/components/Carousel/Carousel';
// import Events from './components/Events/Events';
import { Navbar } from './common/components/navbar/Navbar';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Login } from './pages/Login/Login';
import { WaitingRoom } from './pages/WaitingRoom/WaitingRoom';
import { VideoCall } from './pages/VideoCall/VideoCall';
import { getLocalStorageItem, setLocalStorageItem } from './utils';
import { ACCESS_TOKEN, LOADER, ORG_ID, USER_KEY } from './common/constants';
import { SignalRTest } from './common/components/SignalRTest/SignalRTest';
import { Modal } from './common/components/Modal/Modal';
import { Loader } from './ui-kit/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from './redux/actions';
import { type RootState } from './redux/reducers';
import Checklist from './common/components/Checklist/Checklist';
import { CoHostPage } from './pages/CoHostPage/CoHostPage';
import { ErrorPopup } from './ui-kit/ErrorPopup/ErrorPopup';
import imgLogo from './assets/ForumLogo.png';
import { AgendCoHost } from './common/components/AgendaCoHost';
import { Sidebar } from './components/Sidebar/Sidebar';
import { TeamsAdapter } from './adapters/TeamsAdapter';
import TabConfig from './components/TabConfig/TabConfig';
import { ForgotPassword } from './pages/ForgotPassword/ForgotPassword';
import { MSLogin } from './pages/MicrosoftLogin/MSLogin';
// import ExamplePage from './pages/MicrosoftLogin/Example';
import { ZoomAdapter } from './adapters/ZoomAdapter';
import ZoomAuth from './components/ZoomAuth/ZoomAuth';
import { CustomStreamingTeams } from './pages/CustomStreaming/CustomStreamingTeams';
import { CustomStreamingZoom } from './pages/CustomStreaming/CustomStreamingZoom';
import { DebugAutoSwitch } from './common/components/DebugAutoSwitch/DebugAutoSwitch';
function App(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const loaderArr = useSelector(({ SIGNALR }: RootState) => SIGNALR.loader);
  const isCheckListShown = useSelector(({ SIGNALR }: RootState) => SIGNALR.isCheckListShown);
  const { errorPopup, teamsContext, showDebugAutoSwitchSpeaker } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  // useEffect(() => {
  //   const htmlEl = document.querySelector('html');
  //   // if(htmlEl?.style?.fontSize !== undefined){
  //   //   htmlEl.style.fontSize = "unset";
  //   // }
  //   if (htmlEl?.style !== undefined) {
  //     htmlEl.style.fontSize = '14.7px';
  //     // htmlEl.style.overflow = "hidden";
  //   }
  //   // document.head.style.fontSize = "unset";
  // }, []);
  useEffect(() => {
    // setLocalStorageItem(ORG_ID, '795D68B3-49A8-4747-BEFD-17ADDCDE0844');
    if (getLocalStorageItem(ACCESS_TOKEN) !== '') {
      dispatch({ type: UserType.GET_USER_DETAILS });
      setIsAuthenticated(true);
    } else {
      localStorage.clear();
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (loaderArr.length === 0) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [loaderArr]);

  useEffect(() => {
    const checkNewLogin = (ev: any): void => {
      // !location.pathname.includes('teams') // this is not a good way to not execute it in teams
      if (teamsContext === null && ev.key === 'new-login') {
        navigate('/dashboard');
        window.location.reload();
      }
    };

    window.addEventListener('storage', checkNewLogin);

    return () => {
      window.removeEventListener('storage', checkNewLogin);
    };
  }, [navigate, teamsContext]);

  useEffect(() => {
    function checkUserData(ev: any): void {
      if (ev.detail.key === LOADER) {
        const loaderState = getLocalStorageItem(LOADER);

        if (loaderState === 'T') {
          setLoader(true);
        } else if (loaderArr.length === 0) {
          setLoader(false);
        }
        return;
      }
      if (ev.detail.key === USER_KEY) {
        const token = getLocalStorageItem(ACCESS_TOKEN);
        if (token !== '') {
          dispatch({ type: UserType.GET_USER_DETAILS });
        }
      }
      if (ev.detail.key === ACCESS_TOKEN) {
        const token = getLocalStorageItem(ACCESS_TOKEN);
        // const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
        if (token !== '') {
          setIsAuthenticated(true);
          dispatch({ type: UserType.GET_USER_DETAILS });
        } else {
          localStorage.clear();
          setIsAuthenticated(false);
        }
      }
      if (ev.detail.key === ORG_ID) {
        const token = getLocalStorageItem(ACCESS_TOKEN);
        if (token !== '') {
          dispatch({ type: UserType.GET_USER_DETAILS });
        }
      }
    }

    window.addEventListener('localStorageEvent', checkUserData);

    return () => {
      window.removeEventListener('localStorageEvent', checkUserData);
    };
  }, []);
  useEffect(() => {
    // This code has been added only for testing & debugging purposes
    // to logout the user in teams app , since there is no logout button
    // TODO: Remove this when we get a logout button
    const keyLog: string[] = [];
    let clearLogID: any = 0;
    document.addEventListener('keypress', (e) => {
      keyLog.push(e.key);
      const word = keyLog.join('');
      if (word.includes('logmeoutplease')) {
        keyLog.splice(0, keyLog.length);
        localStorage.clear();
        window.location.reload();
      }
      if (word.includes('loglocalstorage')) {
        keyLog.splice(0, keyLog.length);
        console.log(localStorage);
      }
      if (word.includes('debugautoss')){
        dispatch({
          type:"SET_SHOW_DEBUG_AUTO_SS",
          payload: !showDebugAutoSwitchSpeaker
        })
      }
      clearTimeout(clearLogID);
      clearLogID = setTimeout(() => {
        keyLog.splice(0, keyLog.length);
      }, 6900);
    });
  }, []);

  // useEffect(() => {
  //   console.log('18th feb', isAuthenticated);
  // }, [isAuthenticated]);

  if (isAuthenticated === null) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <div className="App">
        <div className="main-container">
          {/* <Navbar /> */}
          {/* <div style={{ position: 'relative' }}> */}
          <Modal />
          {showDebugAutoSwitchSpeaker && <DebugAutoSwitch />}
          {isCheckListShown && <Checklist />}
          {loader && <Loader />}
          {errorPopup.length > 0 && <ErrorPopup />}
          {/* </div> */}
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/signalr" element={<SignalRTest />} />
            <Route path="/login" element={<Login />} />
            <Route path="/agenda" element={<AgendCoHost />} />
            <Route path="/events" element={<CoHostPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/ms-login" element={<MSLogin />} />
            <Route path="/custom-streaming-teams/:token" element={<CustomStreamingTeams />} />
            <Route path="/custom-streaming-zoom/:token" element={<CustomStreamingZoom />} />
            <Route
              path="/dashboard"
              element={
                !(isAuthenticated ?? false) ? (
                  <Navigate to="/login" />
                ) : (
                  <div className="d-flex align-items-center flex-column">
                    <Navbar />
                    <Dashboard />
                  </div>
                )
              }
            />
            <Route
              path="/waitingroom/:id"
              element={
                !(isAuthenticated ?? false) ? (
                  <Navigate to="/login" />
                ) : (
                  <>
                    <Navbar />
                    <WaitingRoom />
                  </>
                )
              }
            />
            <Route
              path="/waitingroom/:productKey/:eventKey/:sessionId"
              element={
                !(isAuthenticated ?? false) ? (
                  <Navigate to="/login" />
                ) : (
                  <>
                    <Navbar />
                    <WaitingRoom />
                  </>
                )
              }
            />
            <Route
              path="/videocall"
              element={
                <>
                  <VideoCall />
                </>
              }
            />
            <Route
              path="/teams/sidebar"
              element={
                <>
                  <TeamsAdapter>
                    <Sidebar />
                  </TeamsAdapter>
                </>
              }
            />

            <Route
              path="/teams/config"
              element={
                <>
                  <TabConfig />
                </>
              }
            />
            <Route
              path="/zoom/auth"
              element={
                <>
                  <ZoomAuth />
                </>
              }
            />

            <Route
              path="/zoom/sidebar"
              element={
                <>
                  <ZoomAdapter>
                    <Sidebar />
                  </ZoomAdapter>
                </>
              }
            />

            {/* <Route path="/meet:meetId" element={<Dashboard />} /> */}
          </Routes>
        </div>
        {/* <Button text={'Headline'} variant={ButtonVariants.Basic} onClick={() => { console.log('it works') }} /> */}
        {/* <Button text={'Headline'} variant={ButtonVariants.Modal} onClick={() => { console.log('it works') }} /> */}
        {/* <Event badgeType={BadgeVariants.InProgress} 
      thumbnail={thumbnail}
      eventInfo={{
        hostPic: user,
        title: '',
        host: '',
        date: '',
        time: ''
      }} /> */}
        {/* <Badge css={{color:'#D50D55',background: '#FFF',padding:'4.62px 9.24px'}} text='Happening Now'/> */}
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default App;
