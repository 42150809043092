import { BadgeVariants } from '../../ui-kit/Badges/Badge';

// 2023-07-10T07:00:00+00:00 -> July 10, 2023
export const getFormattedDate = (date: string | Date): string => {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString('default', { month: 'long' });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month} ${day}, ${year}`;
};

// 2023-07-10T07:00:00+00:00 , 1800 -> 7:00-7:30 AM

export const getFormattedTime = (date: string, timeZone: string, duration: number): string => {
  // 2023-07-10T07:00:00+00:00 , India Standard Time , 1800 -> 7:00-7:30 AM IST
  const time = formatTime(date, duration);

  // not using the given timezone !
  // instead using the user's timezone.

  const timeZoneAbbreviation = getTimeZoneAbbreviation(
    new Date()
      .toLocaleDateString('en-US', {
        day: '2-digit',
        timeZoneName: 'long'
      })
      .slice(4)
  );

  return `${time} ${timeZoneAbbreviation}`;
};

export const getTimeZoneAbbreviation = (timeZone: string): string => {
  // Indian Standard Time -> IST
  return timeZone
    .split(' ')
    .map((word) => word[0])
    .join('');
};

const formatTime = (time: string, duration: number): string => {
  const startTime = new Date(time);
  const endTime = new Date(startTime.getTime() + duration * 1000);
  const startHour = startTime.getHours();
  const startMinute = startTime.getMinutes();
  const endHour = endTime.getHours();
  const endMinute = endTime.getMinutes();
  const startPeriod = startHour >= 12 ? 'pm' : 'am';
  const endPeriod = endHour >= 12 ? 'pm' : 'am';
  const formattedStartHour = startHour % 12 === 0 ? 12 : startHour % 12;
  const formattedEndHour = endHour % 12 === 0 ? 12 : endHour % 12;
  const formattedStartMinute = startMinute < 10 ? `0${startMinute}` : startMinute;
  const formattedEndMinute = endMinute < 10 ? `0${endMinute}` : endMinute;
  const formattedStartTime = `${formattedStartHour}:${formattedStartMinute} ${startPeriod}`;
  const formattedEndTime = `${formattedEndHour}:${formattedEndMinute} ${endPeriod}`;
  return `${formattedStartTime}-${formattedEndTime}`;
};

// export const getStatusOfMeeting = (dateTimeString: string): BadgeVariants => {
//   const dateTime = new Date(dateTimeString);
//   const now = new Date();
//   const timeDifference = (dateTime.getTime() - now.getTime()) / 1000; // convert to seconds
//   if (timeDifference < 0) {
//     return BadgeVariants.InProgress; // time is in the past
//   } else if (timeDifference <= 1200) {
//     return BadgeVariants.StartingSoon; // time is in the future but comes in less than 20 mins
//   } else {
//     return BadgeVariants.Upcoming; // time is farther than 20 minutes in the future
//   }
// };

export const getActiveEventStatus = (
  startDateTimeString: string,
  durationInSeconds: number
): BadgeVariants => {
  const startDateTime = new Date(startDateTimeString);
  const endDateTime = new Date(startDateTime.getTime() + durationInSeconds * 1000); // convert seconds to milliseconds
  const now = new Date();
  const timeDifference = (startDateTime.getTime() - now.getTime()) / 1000; // convert to seconds
  const timeDifferenceInMinutes = timeDifference / 60; // convert to minutes
  const timeDifferenceInHours = timeDifference / 3600; // convert to hours

  if (timeDifferenceInMinutes <= 10) {
    //  endDateTime.getTime() > now.getTime()
    return BadgeVariants.HappeningNow; // event is currently in progress
  } else if (timeDifferenceInMinutes > 10 && timeDifferenceInHours <= 24) {
    return BadgeVariants.StartingSoon; // event starts within 24 hours
  } else {
    return BadgeVariants.Upcoming; // event is farther than 24 hours in the future
  }
};

export const getEventStatus = (
  status: number,
  startDateTimeString: string,
  durationInSeconds: number
): BadgeVariants => {
  if (status === 2) {
    return BadgeVariants.InProgress;
  } else if (status === 3) {
    return BadgeVariants.Completed; // will be filtered.
  } else if (status === 1) {
    // || status === 0){
    // return BadgeVariants.Upcoming;
    return getActiveEventStatus(startDateTimeString, durationInSeconds);
  }
  // console.log('status = ', status);
  return BadgeVariants.Unknown;
};

export const renderTime = (seconds: number): string => {
  // 1800 -> 00:30:00
  // console.log(seconds);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secondsLeft = seconds - hours * 3600 - minutes * 60;
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsString = secondsLeft < 10 ? `0${secondsLeft}` : `${secondsLeft}`;
  return `${hoursString}:${minutesString}:${secondsString}`;
};
