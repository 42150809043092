import { type UserAction, UserType, type AppStateAction, AppStateType } from '../actions';
import { type EventType } from '../../common/types';
import { type upcomingSessionType } from '../../types/upcoming-sessions';

export enum ModalVariants {
  None = 'None',
  Welcome = 'Welcome',
  Rate = 'Rate',
  MeetingDelay = 'MeetingDelay',
  TechnicalDifficulty = 'TechnicalDifficulty',
  LeaveMeeting = 'LeaveMeeting',
  EndMeeting = 'EndMeeting'
}
export interface appStateType {
  upcomingEvents: EventType[];
  upcomingSessions: upcomingSessionType[];
  modalShown: ModalVariants;
  agendaQuestions: any;
  questionsChanged: boolean;
  modalToShow: ModalVariants;
  Id: number | undefined;
}

const appState: appStateType = {
  upcomingEvents: [],
  upcomingSessions: [],
  modalShown: ModalVariants.None,
  agendaQuestions: [],
  questionsChanged: false,
  modalToShow: ModalVariants.None,
  Id: 0
};

export const appStateReducer = (state = appState, action: AppStateAction | any): appStateType => {
  switch (action.type) {
    case AppStateType.SET_UPCOMING_EVENTS:
      return {
        ...state,
        ...action.payload
      };
    case AppStateType.SET_UPCOMING_SESSIONS:
      return {
        ...state,
        ...action.payload
      };
    case AppStateType.SET_MODAL_SHOWN:
      return {
        ...state,
        ...action.payload
      };
    case AppStateType.SET_AGENDA:
      return {
        ...state,
        ...action.payload
      };
    case AppStateType.CREATE_USER:
      return {
        ...state,
        ...action.payload
      };
    case 'SET_QUESTIONS_CHANGED':
      return {
        ...state,
        questionsChanged: !state.questionsChanged
      };
    case AppStateType.SET_MODAL_MESSAGE:
      return {
        ...state,
        ...action.payload
      };
    case AppStateType.UPDATE_USER_ID:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
