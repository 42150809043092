/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useState } from 'react';

export interface IUsePaginateReturn {
  pageCount: number;
  currentItems: any[];
  handlePageClick: (event: { selected: number }) => void;
}

export const usePaginate = (items: any[], itemsPerPage: number): any => {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)

  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }): void => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return {
    pageCount,
    currentItems,
    handlePageClick
  };
};
