import qs from 'qs';
import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';

export const msLoginToken = async (MStoken: string, orgKey: string): Promise<any | undefined> => {
  const data = qs.stringify({
    JwtToken: MStoken,
    OrgKey: orgKey
  });
  // const data = {
  //     JwtToken: MStoken,
  //     OrgKey: orgKey,
  // }
  return await httpRequest(
    {
      url: `${BASE_URL}/Account/MicrosoftLogin`,
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
        //   AppId: '4'
      },
      data
    },
    false
  );
};
