// TODO: convert to TS
export const getButtonText = (meetingFormat , start , startForWL, role) => {
    console.log('buttonTextLog',{meetingFormat,start,startForWL,role});
    // return "hello";
    // for few to many : start =  waiting room start , startforWL = start in db
    // for 1x1 : start = both 
    if(meetingFormat === '1x1'){
      if(role === 'host'){
        if(start){
          return "End"
        }else{
          return "Start Meeting"
        }
      }
      else if(role === 'cohost'){
        if(start){
          return "Leave Meeting"
        }else{
          return "Join Meeting"
        }
      }else{
        if(start){
          return "Leave Meeting"
        }else{
          return "Join Meeting"
        }
      }
    }else if (meetingFormat === 'FTM'){
      if(role === 'host'){
        if(start){
          if(!startForWL){
            return "Start Meeting"
          }else{
            return "End"
          }
        }else{
          if(startForWL){
            // impossible case : occurs only if host joins again after starting the meeting once
            // (but if host is disconnected meeting is ended)
            return "Join Meeting";
          } else {
            return "Join Waiting Room"
          }
        }
      }else if(role === 'cohost'){
        if(start){
          // if(!startForWL){
            return "Leave Meeting";
        }else {
          if(!startForWL){
            return "Join Waiting Room";
          }else{
            return "Join Meeting"
          }
        }
      }else {
        if(start){
          return "Leave Meeting"
        } else{
          if(!startForWL){
            return "Join Waiting Room"
          }
          else{
            return "Join Meeting"
          }
        }
      }
    }
  };