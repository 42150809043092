/* eslint-disable  @typescript-eslint/strict-boolean-expressions  */
/* eslint-disable  @typescript-eslint/restrict-template-expressions  */
/* eslint-disable @typescript-eslint/explicit-function-return-type  */
import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AgendaCard } from './AgendaCard';
import styles from './Agenda.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequest } from '../../../../utils';
import { BASE_URL } from '../../../../http/api/services';
import { type RootState } from '../../../../redux/reducers';
export const setPriority = async (
  sessionId: number,
  eventKey: any,
  newQuestions: any,
  orgId: string
): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/IR_Event/MeetingSetting`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      OrgId: orgId
    },
    data: JSON.stringify({
      EventKey: eventKey,
      MeetingQuestions: [
        {
          SessionId: sessionId,
          QuestionIds: newQuestions
        }
      ]
    })
  });
};
export interface AgendaQuestionTabProps {
  agendaQuestions: any[];
  liveAnswer: any;
}

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  ...draggableStyle,
  // userSelect: 'none',
  position: 'static',
  outline: isDragging ? '2px solid #232CFF' : ''
  // padding: 8 * 2,
  // margin: `0 0 8px 0`,
  // background: isDragging ? 'transparent' : 'tran',
});

export const Agenda: React.FC<AgendaQuestionTabProps> = ({
  agendaQuestions,
  liveAnswer
}: AgendaQuestionTabProps) => {
  const [newQuestions, setNewQuestions] = useState([...agendaQuestions]);
  const dispatch = useDispatch();
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  function handleDragEnd(result: any): void {
    if (!result.destination) return;

    const { source, destination } = result;
    const updatedAgendaList = [...newQuestions];
    if (source.index !== destination.index) {
      const [draggedItem] = updatedAgendaList.splice(source.index, 1);
      updatedAgendaList.splice(destination.index, 0, draggedItem);
      console.log('updatedAgendaList', updatedAgendaList);
      setNewQuestions(updatedAgendaList);
      localStorage.setItem('agendaList', JSON.stringify(updatedAgendaList));
      dispatch({
        type: 'SET_AGENDA_QUESTIONS',
        payload: updatedAgendaList
      });
    }
    // console.log('updatedAgendaList', newQuestions);
    const newList: Array<{ QuestionId: any; Position: number }> = [];

    updatedAgendaList.forEach((item, index) => {
      newList.push({ QuestionId: item.QuestionId, Position: index });
      console.log('new', newList);
    });
    // console.log("newList",newList)
    void setPriority(
      activeSession.SessionId,
      activeSession.EventDetails.EventKey,
      newList,
      localStorage.orgId
    )
      .then(() => {
        dispatch({
          type: 'SEND_COMMAND_TO_GROUP',
          payload: {
            command: '[PRIORITY-UPDATE]'
          }
        });
        dispatch({ type: 'SET_QUESTIONS_CHANGED', payload: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setNewQuestions(agendaQuestions);
    dispatch({
      type: 'SET_AGENDA_QUESTIONS',
      payload: agendaQuestions
    });
  }, [agendaQuestions]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="agenda">
        {(provided) => (
          <div
            className={styles.agendacontainer}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {newQuestions.map((agendaItem, index) => (
              <Draggable key={index} draggableId={`agendaItem_${index}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    className="agendaquestions"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <AgendaCard question={agendaItem} liveAnswer={liveAnswer} checked={false} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
