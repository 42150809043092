import React from 'react';
import styles from './carousel.module.scss';

export interface CarouselProps {
  children?: React.ReactNode;
  maxWidth?: string;
}

export const Carousel: React.FC<CarouselProps> = ({ children, maxWidth }: CarouselProps) => {
  return (
    <div className={styles.carousel} style={{ maxWidth }}>
      {children}
    </div>
  );
};
