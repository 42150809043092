/* eslint-disable react/no-unescaped-entities */
import React from "react";
import "./zoom-auth.css";
/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */
class ZoomAuth extends React.Component {
  // will get code as a query param eg. ?code=ddMufUb2QFgiCp1DfWAQ7C45EJZCOLiuQ
  render() {
    // Initialize the Microsoft Teams SDK
    // app.initialize().then(() => {
    //   /**
    //    * When the user clicks "Save", save the url for your configured tab.
    //    * This allows for the addition of query string parameters based on
    //    * the settings selected by the user.
    //    */
    //   pages.config.registerOnSaveHandler((saveEvent) => {
    //     const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
    //     pages.config
    //       .setConfig({
    //         suggestedDisplayName: "Forum360",
    //         entityId: "Test",
    //         contentUrl: baseUrl + "/teams/sidebar",
    //         // contentUrl: "https://relate-teams-test.forum360.co/#/tab",
    //         // contentUrl: "https://localhost:44365/sidebar",
    //         websiteUrl: baseUrl + "/teams/sidebar",
    //       })
    //       .then(() => {
    //         saveEvent.notifySuccess();
    //       });
    //   });

    //   /**
    //    * After verifying that the settings for your tab are correctly
    //    * filled in by the user you need to set the state of the dialog
    //    * to be valid.  This will enable the save button in the configuration
    //    * dialog.
    //    */
    //   pages.config.setValidityState(true);
    // });

    return (
      <div>
        {/* <h1 className="config-heading">Forum360</h1> */}
        <div className="config-text">
          Please open your Zoom Client to access this app.
        </div>
      </div>
    );
  }
}

export default ZoomAuth;
