import qs from 'qs';
import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';

const streamTypeDict: Record<string, string> = {
  '[SWITCH-CAMERA]': 'main',
  '[SWITCH-TO-REMOTE-CAM]': 'co-host',
  '[SWITCH-SHAREDWINDOW]': 'share'
};

export const SwitchStream = (
  isMainStream: boolean,
  sessionId: number,
  streamType: string,
  id: string,
  showLoader: boolean
): any => {
  const ActiveStream = streamTypeDict[streamType];
  const data = qs.stringify({
    isMainStream,
    SessionId: sessionId,
    ActiveStream,
    CohostId: id
  });

  return httpRequest(
    {
      url: `${BASE_URL}/Session/SwitchStream`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },
    showLoader
  );
};

export const getActiveStreamFromDB = (sessionId: number): any => {
  return httpRequest({
    url: `${BASE_URL}/Session/MainStream/${sessionId}`,
    method: 'GET'
  });
};
