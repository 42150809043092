import React from 'react';
import styles from './error-popup.module.scss';
import stylesTeams from './error-popup-teams.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../redux/reducers';

export const ErrorPopup: React.FC = () => {
  const dispatch = useDispatch();
  const errorPopup = useSelector(({ SIGNALR }: RootState) => SIGNALR.errorPopup);
  return (
    <div className={styles.modalContainer}>
      <div className={`${styles.messagepop} ${stylesTeams.messagepop}`}>
        <div className={styles.poptext}>{errorPopup}</div>
        <div className="d-flex justify-content-center mt-2">
          <button
            className={styles.yes}
            onClick={() => {
              dispatch({
                type: 'SET_ERROR_POPUP',
                payload: { message: '' }
              });
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
