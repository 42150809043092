import styles from './button.module.scss';

// import { Button as RBButton, type ButtonProps as RBButtonProps } from 'react-bootstrap';
import React, { type ButtonHTMLAttributes, type FC } from 'react';

// import styles from './button.scss';

export enum ButtonVariants {
  Basic = 'Basic',
  Modal = 'Modal',
  Navigation = 'Navigation',
  Alert = 'Alert'
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  // & RBButtonProps
  text: string;
  variant?: ButtonVariants;
  img?: string;
  css?: React.CSSProperties;
  pointer?: string;
};

const getClassName = (variant?: ButtonVariants): string => {
  if (!variant) {
    return '';
  }
  if (variant === ButtonVariants.Basic) {
    return `${styles.btn} ${styles.basic}`;
  }
  if (variant === ButtonVariants.Modal) {
    return `${styles.btn} ${styles.modal}`;
  }
  if (variant === ButtonVariants.Navigation) {
    return `${styles.btn} ${styles.navigation}`;
  }
  if (variant === ButtonVariants.Alert) {
    return `${styles.btn} ${styles.alert}`;
  }
  return '';
};

const getClassNameWrapper = (variant?: ButtonVariants): string => {
  if (!variant) {
    return '';
  }
  if (variant === ButtonVariants.Basic) {
    return `${styles.basicWrapper}`;
  }
  if (variant === ButtonVariants.Modal) {
    return `${styles.modalWrapper}`;
  }
  if (variant === ButtonVariants.Navigation) {
    return `${styles.navigationWrapper}`;
  }
  if (variant === ButtonVariants.Alert) {
    return `${styles.alertWrapper}`;
  }
  return '';
};

export const Button: FC<ButtonProps> = ({ text, variant, img, css, pointer, ...props }) => {
  if (variant === ButtonVariants.Navigation)
    return (
      <button
        data-automation-id={pointer ?? `button-${text}`}
        className={getClassName(variant)}
        {...props}
      >
        {/* <div className={getClassName(variant)}> */}
        {img !== undefined ? (
          <img
            src={img}
            alt={text}
            // height={'40px'} width={'40px'}
          />
        ) : (
          text
        )}
        {/* </div> */}
      </button>
    );

  return (
    //   <RBButton
    //     // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    //     // className={variant && variant === ButtonVariants.Basic ? styles.basic : ''}
    //     {...props}
    // >
    <button
      className={getClassNameWrapper(variant)}
      {...props}
      data-automation-id={pointer ?? `button-${text}`}
    >
      <div className={getClassName(variant)} style={css}>
        {text}
      </div>
    </button>
    // </RBButton>
  );
};
