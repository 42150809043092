import React, { useState } from 'react';
// import styles from './waitingRoom.module.scss';
import styleScreen from './ActiveScreen.module.scss';
import active from './active.svg';
import { chevronDown, helpCircle } from '../../assets';
import { useSelector } from 'react-redux';
import chevronup from '../../assets/chevron-up.png';
import { Popup } from '../../components/Popup/Popup';
export interface ActiveScreenProps {
  callStarted: boolean;
  userName: string;
}
export const ActiveScreen: React.FC<ActiveScreenProps> = ({ callStarted, userName }) => {
  const agendaQuestions = useSelector(({ SIGNALR }) => SIGNALR.agendaQuestions);
  const getRootClassName = (callStarted: boolean): string => {
    if (callStarted) {
      return `${styleScreen.root} ${styleScreen.rootCall}`;
    } else {
      return `${styleScreen.root}`;
    }
  };
  const [expand, setExpand] = useState(false);
  return (
    <div
      className={getRootClassName(callStarted)}
      style={{ height: '32rem', position: 'relative' }}
    >
      {/* <div className={styles.screen}>
        <div className={styles.activescreen} id="activeScreen">
          <img src={active} alt="active-show" />
          Active Screen
        </div>
        <div className={styles.onair}>{callStarted ? 'ON AIR' : 'OFF LINE'}</div>
        <div className={styles.hostname}>{userName}</div>
      </div> */}
      {/* <div className={styleScreen.activeScreenHeaderContainer}>
        <div>
          <img src={helpCircle} alt="help" />
        </div>
        <div className={styleScreen.activeQuestionQuery}>
          <div className={styleScreen.activeQuestionAsker}>
          Craig Schleifer
          </div>  
          <div className={styleScreen.activeQuestionQues}>
          Do fixed income investments on a 30 year period have higher returns? Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </div>
        </div>
        <img src={chevronDown} alt="more" />
      </div> */}
      {callStarted && (
        <div
          style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: '30' }}
        >
          <Popup />
        </div>
      )}
      <div className={styleScreen.vidyoContainer} id="vidyoContainer"></div>
      {callStarted && agendaQuestions.length > 0 && (
        <div className={styleScreen.activeQuestionContainer}>
          <div>
            <img src={helpCircle} alt="help" />
          </div>
          <div className={styleScreen.activeQuestionQuery}>
            {/* <div className={styleScreen.activeQuestionAsker}>Craig Schleifer</div> */}
            <div className={styleScreen.activeQuestionAsker}>{agendaQuestions?.[0].FullName}</div>
            {/* <div
              className={`${styleScreen.activeQuestionQues} ${expand ? styleScreen.expand : ''}`}
            >
              Do fixed income investments on a 30 year period have higher returns? Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </div> */}
            <div
              className={`${styleScreen.activeQuestionQues} ${expand ? styleScreen.expand : ''}`}
            >
              {agendaQuestions?.[0].Question}
            </div>
          </div>
          <img
            onClick={() => {
              setExpand(!expand);
            }}
            src={chevronDown}
            alt="more"
            style={expand ? {} : { transform: 'rotate(180deg)' }}
            // className={expand ? styles.chevronUp : ''}
          />
        </div>
      )}
    </div>
  );
};
