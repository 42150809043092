import { AppStateType, type AppStateAction } from './types';
import { type EventType } from '../../common/types';
import { type upcomingSessionType } from '../../types/upcoming-sessions';
import { type ModalVariants } from '../reducers/app-state';

export const setUpcomingEvents = (upcomingEvents: EventType[]): AppStateAction => ({
  type: AppStateType.SET_UPCOMING_EVENTS,
  payload: { upcomingEvents }
});

export const setUpcomingSessions = (upcomingSessions: upcomingSessionType[]): AppStateAction => ({
  type: AppStateType.SET_UPCOMING_SESSIONS,
  payload: { upcomingSessions }
});

export const getUpcomingEvents = (): AppStateAction => ({
  type: AppStateType.GET_UPCOMING_EVENTS
  // payload: { upcomingEvents }
});

export const setModalShown = (modalShown: ModalVariants): AppStateAction => ({
  type: AppStateType.SET_MODAL_SHOWN,
  payload: { modalShown }
});

export const setModalMessage = (modalToShow: ModalVariants): AppStateAction => ({
  type: AppStateType.SET_MODAL_MESSAGE,
  payload: { modalToShow }
});
export const setAgendaQuestions = (agendaQuestions: any): AppStateAction => ({
  type: AppStateType.SET_AGENDA,
  payload: { agendaQuestions }
});
export const createMessage = (sessionId: number): AppStateAction => ({
  type: AppStateType.CREATE_USER,
  payload: { sessionId }
});
