import React from 'react';
import './QuickMessage.scss';
import welcome from './messaging.svg';
import info from './RateandReview.svg';
import meetingdelay from './meetingdelay.svg';
import technicaldifficulties from './TechnicalDifficulties.svg';
import { httpRequest } from '../../../../utils';
import { BASE_URL } from '../../../../http/api/services';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';

export interface MessageProps {
  text: any;
  message: any;
}
export const QuickMessage: React.FC<MessageProps> = ({ text, message }: MessageProps) => {
  const { startForWL, teamsContext } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  function sendWelcome(): void {
    text('Welcome');
    message();
  }

  function sendRate(): void {
    text('Rate');
    message();
  }

  function sendDelay(): void {
    text('MeetingDelay');
    message();
  }

  function sendTech(): void {
    text('TechnicalDifficulty');
    message();
  }

  return (
    <div className="quickmessageoptions">
      <div className="d-flex" onClick={sendWelcome}>
        <img className="icon-small" alt="welcom" src={welcome} />
        <span>Welcome</span>
      </div>
      {startForWL && (
        <div className="d-flex" onClick={sendRate}>
          <img className="icon-small" alt="info" src={info} />
          <span>Rate & Review </span>
        </div>
      )}
      {teamsContext === null && (
        <div className="d-flex" onClick={sendDelay}>
          <img className="icon-small" alt="delay" src={meetingdelay} />
          <span>Meeting delay</span>
        </div>
      )}
      <div className="d-flex" onClick={sendTech}>
        <img className="icon-small" alt="technical difficulty" src={technicaldifficulties} />
        <span>Technical Difficulties</span>
      </div>
    </div>
  );
};
