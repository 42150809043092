import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './forgot-password.module.scss';
import { Button, ButtonVariants } from '../../ui-kit/Button/Button';
import { logo } from '../../assets';
import FooterForum from '../../pages/FooterForum';
import { sendforgotPasswordEmail } from '../../http/Admin_User';

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  const [username, setUsername] = React.useState('');
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  const onFail = (err: any): void => {
    setError(err !== undefined ? err?.data?.error_description : 'server error');
  };

  useEffect(() => {
    // forgot password should log out the user if not already logged out
    localStorage.clear();
  }, []);

  return (
    <div className={styles.login_container} style={{ color: 'white', gap: '1rem' }}>
      <div></div>
      <div className={styles.login_containerMid}>
        <img className={styles.login_logo} src={logo} width={215} height={122} alt="logo" />
        <div className={styles.login_heading}> Forgotten your Password ? </div>
        <div className={styles.login_or}>
          {' '}
          Enter your email address below and we&apos;ll send you a magic link to reset your password{' '}
        </div>

        <input
          id="username"
          type="email"
          placeholder="Enter your Email address"
          className={styles.login_username}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setError('');
          }}
        />
        {error.length > 0 && <div className={styles.login_error}>{error}</div>}
        {success.length > 0 && <div className={styles.login_success}>{success}</div>}
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            variant={ButtonVariants.Basic}
            onClick={() => {
              setError('');
              setSuccess('');
              sendforgotPasswordEmail(username)
                .then((data) => {
                  // console.log(data)
                  if (data.Success === false) {
                    setError('Invalid Email');
                  } else {
                    data.Payload?.Success === true
                      ? setSuccess('Email has been sent !')
                      : setError('something went wrong!');
                  }
                })
                .catch(onFail);
            }}
            text={'Request'}
            css={{ paddingTop: '9px', paddingBottom: '9px' }}
          />
          <Button
            variant={ButtonVariants.Basic}
            onClick={() => {
              navigate('/login');
            }}
            text={'Cancel'}
            css={{
              paddingTop: '9px',
              paddingBottom: '9px',
              backgroundColor: 'white',
              color: 'blue'
            }}
          />
        </div>
      </div>
      <FooterForum />
    </div>
  );
};
