/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react';
import styles from './DebugAutoSwitch.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { cross } from '../../../assets';
import { type RootState } from '../../../redux/reducers';
// import {
//   getCustomChecklist,
//   getGlobalChecklist,
//   saveCustomCheckListItem,
//   saveGlobalCheckListItem
// } from '../../../http/checklist';
// import { normalizeEnergyLevel } from '../../utils/microphone';
// import { useOldLoudestSpeaker } from '../../hooks/useOldLoudestSpeaker';
import { useLoudestSpeaker } from '../../hooks/useLoudestSpeaker';

// export interface CheckListItem {
//   ItemId: number;
//   Title: string;
//   OrganisationId: number;
//   UserId: number;
//   UserName: string;
//   SessionId: number;
//   IsChecked: boolean;
// }

export const DebugAutoSwitch: React.FC = () => {
    const dispatch = useDispatch();
    const {activeSession,showDebugAutoSwitchSpeaker} = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  //   const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
  const [threshold, setThreshold] = useState(0.5);
  const [noOfConsequentEventsToSwitch, setnoOfConsequentEventsToSwitch] = useState(5);
  const [thresholdTemp, setThresholdTemp] = useState(0.5);
  const [noOfConsequentEventsToSwitchTemp, setnoOfConsequentEventsToSwitchTemp] = useState(5);

  //   const [loudestSpeaker, energyEventsBuffer] = useOldLoudestSpeaker(
  //     threshold,
  //     noOfConsequentEventsToSwitch
  //   );
  const [loudestSpeaker, energyEventsBuffer, speakers] = useLoudestSpeaker(
    threshold,
    noOfConsequentEventsToSwitch,
    true,
    {userId:"localId",name:'You'}
  );
  const [eventsBufferMap, seteventsBufferMap] = useState<Record<string, number>>();
  useEffect(() => {
    const countMap: Record<string, number> = {};
    energyEventsBuffer.forEach((val) => {
      // if(!countMap[val]) countMap[val]=1;

      if (countMap[val] === undefined) {
        countMap[val] = 0;
      }
      countMap[val]++;
      //  = countMap[val];
    });
    seteventsBufferMap(countMap);
  }, [energyEventsBuffer]);

  return (
    <div className={styles.blurBg}>
      <div className={styles.modalContainer}>
        <div className={styles.checklistContainer}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              {/* <img src={checkListIcon} alt="checklisticon" /> */}
              <div className={styles.checklistHeading}>Debug Auto Switch Speaker</div>
            </div>
            <div>
              <img
                src={cross}
                alt="close"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    dispatch({
                        type:"SET_SHOW_DEBUG_AUTO_SS",
                        payload: !showDebugAutoSwitchSpeaker
                      })
                }}
              />
            </div>
          </div>
          <div>
            <br />
            <div className="d-flex gap-3">
              <div>Threshold:</div>
              <input
                type="number"
                value={thresholdTemp}
                onChange={(e) => {
                  setThresholdTemp(parseFloat(e.target.value));
                }}
              />
              <button
                onClick={() => {
                  setThreshold(thresholdTemp);
                }}>
                Set
              </button>
            </div>
            <div className="d-flex gap-3">
              <div>No of consecutive events to switch: </div>
              <input
                type="number"
                value={noOfConsequentEventsToSwitchTemp}
                onChange={(e) => {
                  setnoOfConsequentEventsToSwitchTemp(parseFloat(e.target.value));
                }}
              />
              <button
                onClick={() => {
                  setnoOfConsequentEventsToSwitch(noOfConsequentEventsToSwitchTemp);
                }}>
                Set
              </button>
            </div>
          </div>
          <br></br>
          <div>
            On Active Screen : {loudestSpeaker?.name} ({loudestSpeaker?.userId})
          </div>
          <br />
          <div>
            <div>Events Buffer :</div>
            <div>Compact</div>
            <div>
              {Object.entries(eventsBufferMap ?? {}).map(([key, val], idx) => {
                return `${key}: ${val}  `;
              })}
            </div>
            <div>Expanded</div>
            <div className="d-flex">
              {energyEventsBuffer.map((e,idx) => {
                return <div key={idx}>{e}, &nbsp;</div>;
              })}
            </div>
          </div>
          <br />
          <div>Individual Normalized & Sorted Energy Levels:</div>
          <div>
            {speakers.map((sp) => {
              return (
                <div className="d-flex" key={sp.userId}>
                  <div>
                    {sp?.name} ({sp.userId}) :{' '}
                  </div>
                  <div>{sp.normalizedEnergyLevel}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
