
import {useState, useEffect, createElement, useRef, useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch , useSelector } from 'react-redux';
import {isGUIDValid} from '../../utils/user-role';
import { SwitchStream } from '../../http/stream';
import { UserRole } from '../../redux/reducers/user';
import { debounce } from '../../utils/extend';
import { useLoudestSpeaker } from './useLoudestSpeaker';
export const useVidyo = (host, displayName, roomKey, userKey, role) => {

    // console.log()
    useEffect(()=>{
      console.log('changed room key',roomKey)
    },[roomKey])

    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const activeSession = useSelector(({SIGNALR})=>SIGNALR.activeSession);
    // const aps = useSelector(({ SIGNALR }) => SIGNALR .automaticPresentationSwitch);
    // const commandFromGroup = useSelector(({ SIGNALR }) => SIGNALR.commandFromGroup);
    const [isJWTReady, setIsJWTReady] = useState(false)
    const {activeSession , automaticPresentationSwitch:aps, commandFromGroup , loudestParticipant} = useSelector(({ SIGNALR }) => SIGNALR);
    const [videoConnectorCopy, setVideoConnector] = useState(null)
    // const videoConnector = useRef({})
    const [audioDevices, setAudioDevices] = useState([])
    const [videoDevices, setVideoDevices] = useState([])
    const [speakerDevices, setSpeakerDevices] = useState([])
    const [participantCamera, setparticipantCamera] = useState([])   // to store all participant camera
    const [mainContainerObj, setMainContainerObj] = useState({})  // to store the main container view
    const [swapContentObj, setSwapContentObj] = useState({})  // to store swap content
    const [connector, setConnector] = useState(false)
    const [hideSideBar, setHideSideBar] = useState(false)
    const [microPhoneMute, setMicrophoneMute] = useState(false)
    const [localCameraObj, setLocalCamera] = useState({})
    const [localMicObj, setLocalMicObj] = useState({})
    const [particpantsArr, setParticipants] = useState([])
    const [localParticipant, setLocalParticipant] = useState({});
    const [err, setErr] = useState(false)
    const [start, setStart] = useState(false)
    const [showSelf, setShowSelf] = useState(false)
    const [cameraPrivacy, setCameraPrivacy] = useState(false)
    const [speakerPrivacy, setSpeakerPrivacy] = useState(false)
    const [micPrivacy, setMicPrivacy] = useState(false)
    const [activeUser, _setActive] = useState("")
    const [isPpt, setIsPpt] = useState(false)
    const [remoteWindowShare, setRemoteWindowShare] = useState(null);
    
    const hostOnActiveScreenSignalSent = useRef(false);
    const isDefaultCameraSelected = useRef(false);
    
    const activeUserRef = useRef(activeUser);
    const setActive = data => {
      activeUserRef.current = data;
      _setActive(data);
    }
    
    // const loudestSpeaker = useRef({
    //   userId: null,
    //   audioEnergy:-130
    // });

    // const setLoudestSpeaker = (data) => {
    //   loudestSpeaker.current = data;
    //   // state?
    // }

    // useEffect(()=>{
    //     if(commandFromGroup.command === '[LOUDEST-CHANGED]'){
    //         if(role === UserRole.host){
    //             if(commandFromGroup.message === localParticipant.userId){ 
    //                 if(activeUser !== localParticipant.userId){ // if not already active
    //           // setActiveUser(localParticipant.userId,true,false)
    //           debouncedHandleSwitchLoudest(localParticipant);
    //         }
    //       }
    //     }
    //   }
    // },[commandFromGroup])

    const debouncedHandleSwitchLoudest = useCallback(debounce((participant)=>{
      console.log('prev1 debounce loudest called with ',{participant,aps,role,activeUser,realActiveUser:activeUserRef.current});
      // we don't use this redux state now and is only here for debugging now.
      dispatch({type:'SET_LOUDEST_PARTICIPANT',payload:participant});
      if(role === UserRole.host && aps && activeUserRef.current!=='ppt') { // user role host is redundant here as aps is only for host
        setActiveUser(participant.userId,true,false)
      }
      
      // else if(role !== UserRole.host){
      //   // TODO: optimize above if so that only 
      //   // one user other than host in the meeting
      //   // does this operation instead of all of them 
      //   // sending signals to host that he is the loudest. 
        
      //   if(participant.name.includes('Main Presenter')){
      //     // the above if condition is redundant 
      //     // because we check for role & localParticipant in the useEffect.
      //     // but we keep it , to avoid unnecessary 
      //     // loudest changed signals for other users.
      //     dispatch(
      //       {
      //         type:'SEND_COMMAND_TO_GROUP',
      //         payload:{
      //           command:'[LOUDEST-CHANGED]',
      //           message:participant.userId
      //         }
      //       }
      //     );
      //   }
      // }
    },690),[role,aps]);

    // useEffect(()=>{
    //   // when the host turns on the switch, 
    //   // this is to trigger the switch to loudest 
    //   // this won't happen automatically if the loudestParticipant is same 
    //   // before and after the switch has been turned on.
    //   if(aps){
    //     if(loudestParticipant!==null && activeUser !== loudestParticipant.userId){
    //       debouncedHandleSwitchLoudest(loudestParticipant);
    //     }
    //   }
    // },[aps])

    function onVidyoClientLoaded(status) {
      switch (status.state) {
        case "READY":    // The library is operating normally
          // After the VidyoClient/VidyoConnector is successfully initialized, populate a global VC object
          // console.log(window.VidyoClientLib)
          // if(window.VidyoClientLib !== undefined){
            console.log("inside vidyoCLient onload")
            window.VC = new window.VidyoClientLib.VidyoClient('', () => {
              // startVidyoConnector(window.VC, window.VCUtils ? window.VCUtils.params.webrtc : 'true');
              // console.log('ac2',startVidyoConnector1(window.VC, window.VCUtils ? window.VCUtils.params.webrtc : 'true'));
              startVidyoConnector2(window.VC, window.VCUtils ? window.VCUtils.params.webrtc : 'true')
              console.log(window.VC)
            });
          // }
           break;
      }
      return true;
    }

    const startVidyoConnector2 = (vc, webRTC) => {
        vc.CreateVidyoConnector({
          viewId: null,                            // Div ID where the composited video will be rendered, see VidyoConnector.html
          viewStyle: "VIDYO_CONNECTORVIEWSTYLE_Default", // Visual style of the composited renderer
          remoteParticipants: 15,                        // Maximum number of participants
          logFileFilter: "warning all@VidyoConnector info@VidyoClient",
          logFileName:"",
          userData:89,
          constraints : {
            disableGoogleAnalytics:true,
            // mediaConstraints: {
            //   audio: role !== "moderator",
            //   video: role !== "moderator",
            // }
          },
        }).then(function(vidyoConnector) {
          setVideoConnector(vidyoConnector)
          window.vidyoConnector = vidyoConnector;
          // vidyoConnector.SetAdvancedConfiguration({showStatisticsOverlay: true});
          // console.log(vidyoConnector.GetConnectionProperties())
          // vidyoConnector.GetConnectionProperties().then(res=>{
          //   console.log(res)
          // })
          console.log(vidyoConnector)
          if(role !== 'moderator'){
            vidyoConnector.RegisterLocalCameraEventListener({
              onAdded: function(localCamera) {
                  // if(role === 'moderator'){
                  //   return;
                  // }
                  /* New camera is available. */
                  const localCameraTmp = videoDevices
                  localCameraTmp.push(localCamera)
                  setVideoDevices(localCameraTmp)
                  if(!start){ // call has not started

                    if(isDefaultCameraSelected.current === false){
                      isDefaultCameraSelected.current = true;

                      setLocalCamera(localCamera)
                      assignCameraToId('user-init', localCamera, true, vidyoConnector)
                      vidyoConnector.SelectLocalCamera({localCamera: localCamera});
                      dispatch({
                        type: 'SET_SELECTED_LOCAL_CAMERA',
                        payload: localCamera
                      });
                    }
                  }
                  // if(start){  // if the call has started and user is host
                  //   assignCameraToId('vidyoContainer', localCamera, true, vidyoConnector)
                  // }
              },
              onRemoved: function(localCamera) {
                  /* Existing camera became unavailable. */
                  // vidyoConnector.HideView({ viewId: "vidyoContainer" });
                  // vidyoConnector.HideView({viewId: "user-init"})
                  // vidyoConnector.UnregisterLocalCameraEventListener()
              },
              onSelected: function(localCamera) {
                // if (localCamera !== null) {
                //     /* Camera was selected by user or automatically */
                //     vidyoConnector.HideView({viewId: "vidyoContainer"})
                //     vidyoConnector.HideView({viewId: "user-init"})
                //     vidyoConnector.AssignViewToLocalCamera({
                //       viewId: !start ? "user-init": 'vidyoContainer',
                //       localCamera: localCamera,
                //       displayCropped: true,
                //       allowZoom: false
                //     });
                    
                // } else {
                //     vidyoConnector.HideView({ viewId: "vidyoContainer" });
                //     vidyoConnector.HideView({viewId: "user-init"})
                // }

                // Camera was selected/unselected by you or automatically
                console.log('on selected camera',localCamera);
                /* code for resolution fix */
                // window.lc = localCamera;

                // if(localCamera) {
                //   var maxWidth = 540;
                //   var maxHeight = 350;
                //   var maxFps = 30;

                //   localCamera.SetMaxConstraint({ width: parseInt(maxWidth), height: parseInt(maxHeight), frameInterval: parseInt(maxFps) })
                //   .then(function () {
                //       console.log('SetMaxConstraint success: ' + maxWidth + 'x' + maxHeight + ' @' + maxFps);
                //   }).catch(function () {
                //       console.error('SetMaxConstraint failed');
                //   });
                // }
              },
              onStateUpdated: function(localCamera, state) { /* Camera state was updated */
              console.log(state)
             }
            }).then(function() {
              console.log("RegisterLocalCameraEventListener Success");
            }).catch(function() {
              console.error("RegisterLocalCameraEventListener Failed");
            });

            // if(role)
                          /* Microphone event listener */
            vidyoConnector.RegisterLocalMicrophoneEventListener({
              onAdded: function(localMicrophone) { /* New microphone is available */
                console.log('for mic,  role is',role);
                // if(role === 'moderator'){
                //   return;
                // }
                if (localMicrophone !== null) {/* This is the microphone that is desired */
                  const audioDevicesTemp = audioDevices
                  audioDevicesTemp.push(localMicrophone)
                  setAudioDevices(audioDevicesTemp)
                  // console.log(localMicrophone)
                  // console.log(audioDevices)
                  if(localMicrophone.name.includes('Default')){
                    setLocalMicObj(localMicrophone)
                    vidyoConnector.SelectLocalMicrophone({localMicrophone:localMicrophone});
                    dispatch({
                      type: 'SET_SELECTED_LOCAL_MICROPHONE',
                      payload: localMicrophone
                    });
                  }
                }
              },
              onRemoved:  function(localMicrophone) { /* Existing microphone became unavailable */ },
              onSelected: function(localMicrophone) { /* Microphone was selected by user or automatically */ },
              onStateUpdated: function(localMicrophone, state) { /* Microphone state was updated */
                // VIDYO_DEVICESTATE_Stopped
                // VIDYO_DEVICESTATE_Started
                setParticipants((particpantsArr)=>{
                  return particpantsArr.map((p)=>{
                    if(p.isLocal){
                      return { ...p, isMuted: state !== 'VIDYO_DEVICESTATE_Started'};
                    }
                    return p;
                  });
                });
              }
            }).then(function() {
              console.log("RegisterLocalMicrophoneEventListener Success");
            }).catch(function() {
              console.error("RegisterLocalMicrophoneEventListener Failed");
            });
          }

            /* Speaker event listener */
          vidyoConnector.RegisterLocalSpeakerEventListener({
            onAdded: function(localSpeaker) { /* New speaker is available */
              if (localSpeaker !== null) {/* This is the speaker that is desired */
                const localSpeakerTmp = speakerDevices
                localSpeakerTmp.push(localSpeaker)
                setSpeakerDevices(localSpeakerTmp)
                if(localSpeaker.name.includes('Default')){
                  vidyoConnector.SelectLocalSpeaker({localSpeaker:localSpeaker});
                  dispatch({
                    type: 'SET_SELECTED_LOCAL_SPEAKER',
                    payload: localSpeaker
                  });
                }
              }
            },
            onRemoved:  function(localSpeaker) { /* Existing speaker became unavailable */ },
            onSelected: function(localSpeaker) { /* Speaker was selected by user or automatically */ },
            onStateUpdated: function(localSpeaker, state) { /* Speaker state was updated */ }
          }).then(function() {
            console.log("RegisterLocalSpeakerEventListener Success");
          }).catch(function() {
            console.error("RegisterLocalSpeakerEventListener Failed");
          });

          }).catch(function(err) {
          console.log(err)
          console.error("CreateVidyoConnector Failed");
        });
      }
      const startVideoCall = () =>{
        console.log(videoConnectorCopy)
        videoConnectorCopy.ConnectToRoomAsGuest({
               host: host, // designated cloud or on-premises portal of customer     
               roomKey: roomKey,
               displayName: 
               role === 'host' 
                ? 'Main Presenter' 
                : role === 'moderator' 
                  ? displayName 
                  + " (Moderator)" 
                  : displayName,     
               roomPin: userKey,
              //  constraints :{
              //   userKey: 'abcdef'
              //  },
              //  token: "abcdef",
               // Define handlers for connection events.
               onSuccess: function()            {/* Connected */},
               onFailure: function(reason)      {/* Failed */},
               onDisconnected: function(reason) {/* Disconnected */}
              }).then(function(status) {
                  if (status) {
                      setStart(true)
                      // videoConnectorCopy.SetAdvancedConfiguration({showStatisticsOverlay: true});
                      console.log("ConnectCall Success");
                      console.log(videoConnectorCopy)
                      videoConnectorCopy.GetConnectionProperties().then(res=>{
                        console.log('connprop',res)
                      })

                      console.log('role1', role)
                      if(role === 'host'){
                        console.log('role')
                        // handleDivSwitch()
                      }
                      
                      videoConnectorCopy.ReportLocalParticipantOnJoined({
                        reportLocalParticipant: true
                      });

                      // videoConnectorCopy.ReportLocalParticipantOnLoudestChanged({
                      //   reportLocalParticipant: true
                      // });

                      // for remote Camera
                      // videoConnectorCopy.RegisterParticipantEventListener(
                      //   {
                      //     onJoined: function(participant) { 
                      //       /* Participant Joined */ 
                      //       console.log(participant,'joined')
                      //       if(participant.name.includes('Moderator')){
                      //         return;
                      //       }
                      //       if(isGUIDValid(participant.name)){
                      //         return;
                      //       }
                      //       // console.log("A participant joined")
                      //       if(participant.isLocal){
                      //         console.log('local participant',participant,'joined ');
                      //         setLocalParticipant(participant);
                      //         // videoConnectorCopy.HideView({viewId: "user-init"})
                      //         // videoConnectorCopy.HideView({viewId: participant.userId})
                      //         assignCameraToId(participant.userId, localCameraObj, true, videoConnectorCopy)
                      //         // if(role === 'host'){
                      //         //   setActiveUser(participant.userId);
                      //         // }
                      //       }
                      //       setParticipants((parr)=>[...parr,participant]);
                      //     },
                      //     onLeft: function(participant)   { 
                      //       if(participant.isLocal){
                      //         // leaveCall();
                      //         // window.location.reload();
                      //         // navigate("/dashboard")
                      //       }
                      //       console.log(participant, 'left')
                      //       // console.log("participant left")
                      //       setParticipants((pArr)=>pArr.filter((p)=>p.userId!==participant.userId));
                      //     },
                      //     onDynamicChanged: function(participants) { 
                      //       /* Ordered array of participants according to rank */
                      //       console.log(participants)
                      //       // setParticipants(participants)
                      //       // participants.forEach(item=>{
                      //       //   if(item.isLocal){
                      //       //     videoConnectorCopy.HideView({viewId: "user-init"})
                      //       //     videoConnectorCopy.HideView({viewId: item.userId})
                      //       //     assignCameraToId(item.userId, localCameraObj, true, videoConnectorCopy)
                      //       //   }
                      //       // })
                      //       // const localParticipant = participants.filter(item=>item.isLocal === true)
                      //       // setLocalParticipant(localParticipant)
                      //       // console.log(localParticipant)
                      //      },
                      //     onLoudestChanged: function(participant, audioOnly) {
                      //        /* Current loudest speaker */
                      //        console.log('prev loudest activeUser',activeUser);
                      //       //  console.log('loudest',participant);
                      //       debouncedHandleSwitchLoudest(participant);
                      //       //  console.log('loudest')
                      //       //  if auto presentation is on
                      //      }
                      //   }).then(function() {
                      //     console.log("RegisterParticipantEventListener Success");
                      //     // registerRemoteCamera()
                          
                      //   }).catch(function() {
                      //     console.log("RegisterParticipantEventListener Failed");
                      //   });
                      registerParticipantEventListenerUtil();

                                     /* Register to receive chat messages */
                        //  this Part of the code to be used to trigger different actions 
                        videoConnectorCopy.RegisterMessageEventListener({
                          onChatMessageReceived: function(participant, chatMessage) { 
                            /* Message received from other participant */ 
                            console.log(chatMessage)

                            // message to share video
                             if(chatMessage.body === "SHARE_CAMERA"){
                              // console.log("")
                              videoConnectorCopy.SelectVideoContentShare({localCamera: localCameraObj})
                             }
                          }
                        }).then(function() {
                          console.log("RegisterMessageEventListener Success");
                        }).catch(function() {
                          console.err("RegisterMessageEventListener Failed");
                        });

                      /* custom remote participant's source view */
                      videoConnectorCopy.RegisterRemoteCameraEventListener({
                            onAdded: function(remoteCamera, participant) {
                              console.log('remote camera added')
                              // console.log(document.getElementById(participant.userId))
                              /* New camera is available. */
                              if (remoteCamera) {/* This camera is desired to be viewed */
                              // setTimeout(()=>{
                                assignCameraToId(participant.userId, remoteCamera, participant.isLocal, videoConnectorCopy)
                              // },1000)
                              // videoConnectorCopy.AssignViewToRemoteCamera({
                              //     viewId: participant.userId, // div.id,
                              //     remoteCamera: remoteCamera,
                              //     displayCropped: true,
                              //     allowZoom: false
                              //   });
                              }
                            },
                            onRemoved: function(remoteCamera, participant) {
                              /* Existing camera became unavailable. */
                              if (remoteCamera) {/* This camera was being viewed */
                              videoConnectorCopy.HideView({
                                  viewId: participant.userId
                                });
                              }
                            },
                            onStateUpdated: function(remoteCamera, participant, state) { 
                              /* Camera state was updated */ 
                              console.log(state)
                              console.log('when camera state updates')
                            }
                          }).then(function() {
                            console.log("RegisterRemoteCameraEventListener Success");
                          }).catch(function() {
                            console.error("RegisterRemoteCameraEventListener Failed");
                          });

                          videoConnectorCopy.RegisterRemoteMicrophoneEventListener({
                             onAdded: function(remoteMicrophone, participant){
                                console.log('mic on added',remoteMicrophone,participant)
                             },
                            onRemoved: function(remoteMicrophone, participant){
                              console.log('mic on removed',remoteMicrophone,participant);
                            }, 
                            onStateUpdated: function(remoteMicrophone, participant, state){
                              // VIDYO_DEVICESTATE_Paused
                              // VIDYO_DEVICESTATE_Resumed
                              setParticipants((particpantsArr)=>{
                                return particpantsArr.map((p)=>{
                                  if(p.userId === participant.userId){
                                    return { ...p, isMuted: state !== 'VIDYO_DEVICESTATE_Resumed'};
                                  }
                                  return p;
                                });
                              });
                              console.log('mic on state updated',remoteMicrophone,participant,state);
                            }
                        }).then(function() {
                          console.log("RegisterRemoteMicrophoneEventListener Success");
                      }).catch(function() {
                          console.error("RegisterRemoteMicrophoneEventListener Failed");
                      })

                          // to register the window share by other users
                        //   videoConnectorCopy.RegisterRemoteWindowShareEventListener({ // at this point all the states are empty
                        //     onAdded: function(remoteWindowShare, participant){
                        //       // console.log(localParticipant)
                        //       console.log('remote window share on added')
                        //       console.log( particpantsArr)
                        //       setIsPpt(true)  // to show the ppt participant
                        //       // videoConnectorCopy.HideView({viewId: 'ppt'})
                        //       console.log(remoteWindowShare)
                        //       setRemoteWindowShare(remoteWindowShare);

                        //       // videoConnectorCopy.HideView({viewId: 'ppt'})
                        //       // videoConnectorCopy.AssignViewToRemoteWindowShare({
                        //       //   allowZoom: false, 
                        //       //   displayCropped: false, 
                        //       //   remoteWindowShare: remoteWindowShare, 
                        //       //   viewId: 'ppt'
                        //       // })

                        //       console.log('remoteShare added')
                        //       setActiveUser("ppt",false)
                        //       setTimeout(()=>{
                        //         console.log('ppt reloaded')
                        //         videoConnectorCopy.HideView({viewId: 'ppt'})
                        //         videoConnectorCopy.AssignViewToRemoteWindowShare({
                        //           allowZoom: false, 
                        //           displayCropped: false, 
                        //           remoteWindowShare: remoteWindowShare, 
                        //           viewId: 'ppt'
                        //         })
                        //       },500)
                              
                        //       setShowSelf(true) // to show the local participant in participant list
                        //     }, 
                        //     onRemoved: function(remoteWindowShare, participant){
                        //       console.log("Remote share stopped")
                        //       videoConnectorCopy.HideView({viewId: 'ppt'})
                        //       console.log(localParticipant)
                        //       setRemoteWindowShare(null);
                        //       setIsPpt(false)
                        //     }, 
                        //     onStateUpdated: function(remoteWindowShare, participant, state){
                        //       console.log("Remote share stopped-update")
                        //       console.log(state)
                        //       videoConnectorCopy.HideView({viewId: 'ppt'})
                        //     }
                        //   }).then(function() {
                        //     console.log("RegisterRemoteWindowShareEventListener Success");
                        // }).catch(function() {
                        //     console.error("RegisterRemoteWindowShareEventListener Failed");
                        // })
                        registerRemoteWindowShareEventListenerUtil();

                  } else {
                      console.error("ConnectCall Failed");
                  }
              }).catch(function() {
                  console.error("ConnectCall Failed");
              });
      }
      const registerRemoteWindowShareEventListenerUtil = () => {
        videoConnectorCopy?.RegisterRemoteWindowShareEventListener({ // at this point all the states are empty
          onAdded: function(remoteWindowShare, participant){
            // console.log(localParticipant)
            console.log('remote window share on added')
            console.log( particpantsArr)
            setIsPpt(true)  // to show the ppt participant
            // videoConnectorCopy.HideView({viewId: 'ppt'})
            console.log(remoteWindowShare)
            setRemoteWindowShare(remoteWindowShare);

            // videoConnectorCopy.HideView({viewId: 'ppt'})
            // videoConnectorCopy.AssignViewToRemoteWindowShare({
            //   allowZoom: false, 
            //   displayCropped: false, 
            //   remoteWindowShare: remoteWindowShare, 
            //   viewId: 'ppt'
            // })

            console.log('remoteShare added')
            setActiveUser("ppt",false)
            setTimeout(()=>{
              console.log('ppt reloaded')
              videoConnectorCopy.HideView({viewId: 'ppt'})
              videoConnectorCopy.AssignViewToRemoteWindowShare({
                allowZoom: false, 
                displayCropped: false, 
                remoteWindowShare: remoteWindowShare, 
                viewId: 'ppt'
              })
            },500)
            
            setShowSelf(true) // to show the local participant in participant list
          }, 
          onRemoved: function(remoteWindowShare, participant){
            console.log("Remote share stopped")
            if(role === UserRole.host){
              setActiveUser(localParticipant.userId)
            }
            videoConnectorCopy.HideView({viewId: 'ppt'})
            console.log(localParticipant)
            setRemoteWindowShare(null);
            setIsPpt(false)
          }, 
          onStateUpdated: function(remoteWindowShare, participant, state){
            console.log("Remote share stopped-update")
            console.log(state)
            videoConnectorCopy.HideView({viewId: 'ppt'})
          }
        }).then(function() {
            console.log("RegisterRemoteWindowShareEventListener Success");
        }).catch(function() {
            console.error("RegisterRemoteWindowShareEventListener Failed");
        })
      };

      const registerParticipantEventListenerUtil = () => {
        videoConnectorCopy?.RegisterParticipantEventListener(
          {
            onJoined: function(participant) { 
              /* Participant Joined */ 
              console.log(participant,'joined')
              if(participant.name.includes('Moderator')){
                return;
              }
              if(participant.name.includes('ClosedCaption')){
                console.log('closed caption joined',participant);
                return;
              }
              if(isGUIDValid(participant.name)){
                return;
              }
              // console.log("A participant joined")
              if(participant.isLocal){
                console.log('local participant',participant,'joined ');
                setLocalParticipant(participant);
                // videoConnectorCopy.HideView({viewId: "user-init"})
                console.log('prev2',participant.userId,localCameraObj);
                // videoConnectorCopy.HideView({viewId: participant.userId})
                assignCameraToId(participant.userId, localCameraObj, true, videoConnectorCopy)
                // videoConnectorCopy.SelectLocalCamera({localCamera: localCameraObj});
                // if(role === 'host'){
                //   setActiveUser(participant.userId);
                // }
                // TODO : Remove dependency on name
                if(participant.name.includes('Main Presenter')){
                  console.log('CCToggle','main presenter joined');
                  dispatch({
                    type: 'SET_CC',
                    payload: true
                  });
                  dispatch({
                    type: 'SEND_COMMAND_TO_GROUP',
                    payload: {
                      command: '[SET-CC]',
                      message: true // message can only be a string so will have to req.message.toLowerCase() === 'true'
                    }
                  })
                }
              }
              setParticipants((parr)=>[...parr,participant]);
            },
            onLeft: function(participant)   { 
              if(participant.isLocal){
                // leaveCall();
                // window.location.reload();
                // navigate("/dashboard")
                // dispatch({
                //   type: 'SET_ERROR_POPUP',
                //   payload: {message: 'Poor Network Detected, We recommend you to reload the page' }
                // });
              }
              if(participant.name.includes('Main Presenter')){
                console.log('CCToggle','main presenter left');
                dispatch({
                  type: 'SET_CC',
                  payload: false
                });
                // dispatch({
                //   type: 'SEND_COMMAND_TO_GROUP',
                //   payload: {
                //     command: '[SET-CC]',
                //     message: false // message can only be a string so will have to req.message.toLowerCase() === 'true'
                //   }
                // })
              }
              console.log('prev2',participant, 'left')
              videoConnectorCopy.HideView({viewId: participant.userId});
              // console.log("participant left")
              setParticipants((pArr)=>pArr.filter((p)=>p.userId!==participant.userId));
            },
            onDynamicChanged: function(participants) { 
              /* Ordered array of participants according to rank */
              console.log(participants)
              // setParticipants(participants)
              // participants.forEach(item=>{
              //   if(item.isLocal){
              //     videoConnectorCopy.HideView({viewId: "user-init"})
              //     videoConnectorCopy.HideView({viewId: item.userId})
              //     assignCameraToId(item.userId, localCameraObj, true, videoConnectorCopy)
              //   }
              // })
              // const localParticipant = participants.filter(item=>item.isLocal === true)
              // setLocalParticipant(localParticipant)
              // console.log(localParticipant)
             },
            onLoudestChanged: function(participant, audioOnly) {
              
               /* Current loudest speaker */
               console.log('prev loudest active user',activeUser);
               console.log('prev loudest',participant);
              // dispatch({type:'SET_LOUDEST_PARTICIPANT',payload:participant});
              // debouncedHandleSwitchLoudest(participant); 
              
                //  console.log('loudest')
              //  if auto presentation is on
             }
          }).then(function() {
            console.log("RegisterParticipantEventListener Success");
            // registerRemoteCamera()
            
          }).catch(function() {
            console.log("RegisterParticipantEventListener Failed");
          });
      }
    
      const stopShareScreen = () => {
        videoConnectorCopy.SelectLocalWindowShare({localWindowShare: null})
        .then(res=>{
          console.log(res);
          dispatch({
            type: 'SET_IS_LOCAL_SCREEN_SHARED',
            payload: false
          })
        }).catch(err=>{ 
          console.log(err)
        });
      }

      const shareScreen = () => {
        if(remoteWindowShare !== null){
          dispatch({
            type: 'SET_ERROR_POPUP',
            payload: {message: 'Please wait for the other person to stop sharing' }
          })
          return; 
        }
        // let wasApsOn = false;
        // if(aps){
        //   dispatch({
        //     type: 'SET_AUTOMATIC_PRESENTATION_SWITCH',
        //     payload: false
        //   });
        //   wasApsOn = true;
        // }
        // dispatch({type:'SET_ACTIVE_SCREEN',payload:''}) // send signal to set div id as empty ""
        
        // const localUser = particpantsArr.filter(item=>{return item.isLocal })
        // console.log(localUser[0].userId)
        var isSafari = navigator.userAgent.indexOf('Chrome') < 0 && navigator.userAgent.indexOf('Safari') >= 0;
        // console.log('share screen triggered')
        videoConnectorCopy.RegisterLocalWindowShareEventListener({
            onAdded: function(localWindowShare) { /* New window is available for sharing */
              if (localWindowShare) {/* This is the window the user wants to share */  
              console.log(localWindowShare)
                // localWindowShare.SetFrameInterval({interval:9});
                setIsPpt(true)
                videoConnectorCopy.SelectLocalWindowShare({localWindowShare:localWindowShare}).then(res=>{
                 // send signal to turn off other's window share if turned on.
                  dispatch({
                    type: 'SEND_COMMAND_TO_GROUP',
                    payload: { command: '[STOP-SCREENSHARE]'}
                  });     
                  console.log(res)
                  videoConnectorCopy.HideView({viewId: 'vidyoContainer'})
                  videoConnectorCopy.HideView({viewId: 'ppt'})
                  setShowSelf(true) // To show self in participants
                  // dispatch({
                  //   type: 'SEND_COMMAND_TO_GROUP',
                  //   payload: { command: '[SWITCH-ACTIVESCREEN]', message: 'ppt' }
                  // });
                  setActiveUser("ppt")

                  videoConnectorCopy.AssignViewToLocalWindowShare({
                    allowZoom: true,
                    displayCropped: false,
                    localWindowShare: localWindowShare,
                    viewId: "ppt"
                   })

                   dispatch({
                    type: 'SET_IS_LOCAL_SCREEN_SHARED',
                    payload: true
                  })
                  // videoConnectorCopy.ShowWindowSharePreview({preview: true})
                }).catch(()=>{
                  setIsPpt(false);
                });
                console.log(videoConnectorCopy)
              }else {
                dispatch({
                  type: 'SET_IS_LOCAL_SCREEN_SHARED',
                  payload: false
                })
              }
              // if(wasApsOn){
              //   dispatch({
              //     type: 'SET_AUTOMATIC_PRESENTATION_SWITCH',
              //     payload: true
              //   });
              // }
            },
            onRemoved: function(localWindowShare) { 
              /* Existing window is no longer available for sharing */
                console.log('local screen share stopped')
                setIsPpt(false)
                dispatch({
                  type: 'SET_IS_LOCAL_SCREEN_SHARED',
                  payload: false
                })
                // dispatch({
                //   type: 'SEND_COMMAND_TO_GROUP',
                //   payload: { command: '[SWITCH-ACTIVESCREEN]', message: localParticipant.userId }
                // });
                setActiveUser(localParticipant.userId)
             },
            onSelected: function(localWindowShare) {
              /* Window was selected and triggers when screen share stops */
              console.log('local screen share seleceted',{localWindowShare});
              // console.log(localParticipant)
              if(localWindowShare === null){
                if(role === UserRole.host){
                  setActiveUser(localParticipant.userId)
                }
              }
              setIsPpt(false)
              videoConnectorCopy.HideView({viewId: 'vidyoContainer'})
              videoConnectorCopy.HideView({viewId: 'ppt'})
              // videoConnectorCopy.HideView({viewId: "user-init"})
              // videoConnectorCopy.HideView({viewId:localUser[0].userId})
            },
            onStateUpdated: function(localWindowShare, state) { 
                /* window share state has been updated */ 
                console.log('local screen share state updated')
                console.log(state)
            }
          }).then(function() {
            console.log("prev1 RegisterLocalWindowShareEventListener Success");
          }).catch(function() {
            console.error("prev1 RegisterLocalWindowShareEventListener Failed");
            // console.log('prev finally',{aps,wasApsOn});
            // if(wasApsOn){
            //   dispatch({
            //     type: 'SET_AUTOMATIC_PRESENTATION_SWITCH',
            //     payload: true
            //   });
            // }
          })

      }

      const handleShareMonitor = () => {
        /* Monitor event listener */
            videoConnectorCopy.RegisterLocalMonitorEventListener({
              onAdded: function(localMonitor) { /* New monitor is available for sharing */
              console.log(localMonitor)
                if (localMonitor) {/* This is the monitor that should be shared */
                  videoConnectorCopy.SelectLocalMonitor({localMonitor:localMonitor});
                }
              },
              onRemoved:  function(localMonitor) { /* Existing monitor is no longer available for sharing */ },
              onSelected: function(localMonitor) {
                /* Monitor was selected */
                if (localMonitor && localMonitor.GetPreviewFrameDataUriAsync) {
                    localMonitor.GetPreviewFrameDataUriAsync({
                        maxWidth: 300,
                        maxHeight: 300,
                        onComplete: function(response) {
                            // Assign an image source in the UI with the preview frame
                        }
                    });
                } else {
                    // Unassign the image source in the UI from the preview frame
                }
              },
              onStateUpdated: function(localMonitor, state) { /* Monitor state has been updated */ }
            }).then(function() {
              console.log("RegisterLocalMonitorEventListener Success");
            }).catch(function() {
              console.error("RegisterLocalMonitorEventListener Failed");
            });
      }

      const registerLocalSpeaker = (selectedSpeaker) => {
        /* Speaker event listener */
        if(selectedSpeaker?.name.toLowerCase().includes('test')){
          return;
        }
        videoConnectorCopy.SelectLocalSpeaker({localSpeaker: selectedSpeaker})
        dispatch({
          type: 'SET_SELECTED_LOCAL_SPEAKER',
          payload: selectedSpeaker
        });
      }

      const registerLocalMic = (selectedMic) =>{
        if(selectedMic?.name.toLowerCase().includes('test')){
          return;
        }
        videoConnectorCopy.SelectLocalMicrophone({localMicrophone: selectedMic});
        dispatch({
          type: 'SET_SELECTED_LOCAL_MICROPHONE',
          payload: selectedMic
        });
      }

      
      const setCamera = (camera) => {
        if(camera?.name.toLowerCase().includes('test')){
          return;
        }
        videoConnectorCopy.SelectLocalCamera({localCamera: camera});
        dispatch({
          type: 'SET_SELECTED_LOCAL_CAMERA',
          payload: camera
        });
      }
      
      const leaveCall = () =>{
        if(videoConnectorCopy){
          videoConnectorCopy.Disconnect().then(res=>{
            // videoConnectorCopy.Destruct
            const e = {target: {value: null}}
            // videoConnectorCopy.SetCameraPrivacy({privacy: true});
            // if(!cameraPrivacy){
            //   handleCameraPrivacy()
            // }

            // if(!micPrivacy){
            //   handleMute()
            // }

            // if(!speakerPrivacy){
            //   handleSpeakerPrivacy()
            // }

            // registerLocalCamera(e)
            // window.location = "/dashboard"
            videoConnectorCopy.UnregisterLocalCameraEventListener()
            videoConnectorCopy.HideView({viewId: 'vidyoContainer'})
            navigate("/dashboard")
          }, err=>{
            console.log(err)
          });
        }
      }
      const handleMute = () =>{
        try{
          videoConnectorCopy.SetMicrophonePrivacy({
            privacy: !micPrivacy
          }).then(res=>{
            
            if(micPrivacy){ // if it was set before and now you unmuted.
              if(role !== 'host'){
                dispatch({
                  type: 'SEND_COMMAND_TO_GROUP',
                  payload: { command: '[PARTICIPANT-UNMUTED]', message: '' }
                });
                dispatch({ type: '[RECEIVE_MUTE_ALL]', payload: false });
              }
            }
            setMicPrivacy(!micPrivacy)
            console.log('Microphone privacy set Success!')
          })
        }catch(err){
          setErr(err)
        }
        if(err){
          console.log(err)
          console.log("Microphone privacy set failed!")
        }

      }

      const handleCameraPrivacy = () =>{
        let errFlag = false;
        try{
          videoConnectorCopy.SetCameraPrivacy({
            privacy: !cameraPrivacy
          }).then(res=>{
            // setCameraPrivacy(!cameraPrivacy)
            setCameraPrivacy((cameraPrivacy)=>{return !cameraPrivacy})
            let div = {}
            // toDO: Need to do fix for many other cases
            if(start){  // start is flag to show if call has started
              // fixing in the vidyoContainer div id
               div = document.getElementById(localParticipant.userId)
            }
            else{
               div = document.getElementById("user-init")
            }
              const videoTag = div.getElementsByTagName("video")
              const videoWrapper = div.getElementsByClassName("video-wrapper")
              if(videoWrapper !== null && videoWrapper[0] !== undefined){
                videoWrapper[0].style.display = !cameraPrivacy ? "none" : "block"
              }
              
            
            console.log("Camera privacy set Successfully!")
          })
        }catch(err){
          errFlag = err
        }
        if(errFlag){
          console.log("Camera privacy set failed!")
        }
      }
      const handleSpeakerPrivacy = () =>{
        let errFlag = false
        // const speakerPrivacyFlag = !speakerPrivacy
        
        try{
          videoConnectorCopy.SetSpeakerPrivacy({
            privacy: !speakerPrivacy
          }).then(res=>{
            setSpeakerPrivacy(!speakerPrivacy)
            console.log("Speaker Privacy set Successfully!")
          })
        }catch(err){
          errFlag = err
        }
        if(errFlag){
          console.log("Set speaker privacy Successful!")
        }
      }
    // const handleDivSwitch = () => {
    //   // to fix one participant's camera in the center
    //   // videoConnectorCopy.HideView({viewId: 'vidyoContainer'})
    //   // videoConnectorCopy.SelectVideoContentShare({localCamera: localCameraObj})
    // }
    // const onLoudestChange =(partici) => {

    // }

    useEffect(()=>{
      return ()=>{
        if(videoConnectorCopy!==null && !cameraPrivacy)
        videoConnectorCopy.SetCameraPrivacy({privacy: true});
      }
    },[videoConnectorCopy])

    useEffect(()=>{
    console.log('useVidyo')
    return ()=>{
      // console.log('clean up',start);
      if(start){
        leaveCall();
        hostOnActiveScreenSignalSent.current = false;
      }
    }
        
    },[start])

    useEffect(()=>{
      console.log('parr',particpantsArr);
      dispatch({
        type: 'SET_JOINED_USERS_LIST',
        payload: [...particpantsArr]
      });

      if(particpantsArr.length > 0 && !hostOnActiveScreenSignalSent.current){
        particpantsArr.forEach(item=>{
          if(item.isLocal){
            if(role === 'host'){
              setActiveUser(item.userId,true);
              hostOnActiveScreenSignalSent.current = true;
            }
          }
        })
      };
    
    },[particpantsArr])

    const muteParticipant = (participant1) =>{
      console.log("mute clicker for")
      videoConnectorCopy.SendPrivateChatMessage({participant: participant1, message: "SHARE_CAMERA"})
    }
    const cameraParticipant = (participant) =>{
      // videoConnectorCopy.SendChatMessage({participant: participant, message:`CAMERA_OFF`});
    }

    const assignCameraToId = (divId, camera, local, vidyo) => {
      console.log('assign camera id',divId,camera,local);
      
      vidyo.HideView({viewId: divId})
      if(local){
        // eslint-disable-next-line no-debugger
        // debugger;
        vidyo.HideView({viewId: "user-init"})
        // console.log(divId)
        vidyo.AssignViewToLocalCamera({
          viewId: divId,
          localCamera: camera,
          displayCropped: true,
          allowZoom: false
        });
      }
      else {
        vidyo.AssignViewToRemoteCamera({
          viewId: divId,
          remoteCamera: camera,
          displayCropped: true,
          allowZoom: false
        })
      }
    }

    useEffect(()=>{
      console.log('prev use Effect called ',aps);
      registerParticipantEventListenerUtil();
    },[videoConnectorCopy,aps,localCameraObj])

    useEffect(()=>{
      registerRemoteWindowShareEventListenerUtil();
    },[localParticipant]);

    const setActiveUser = (id,sendSignal=true,showLoader=true) =>{
      console.log('prev setActiveUser called with',{id,sendSignal,showLoader,activeUser,actualPrevActiveUser:activeUserRef.current});
        if(id===""){
          return;
        }
        const prevDiv = document.getElementById(activeUserRef.current)
        console.log('prevDiv',activeUser,prevDiv);
        if(id===null){
          return;
        }
        if(id === 'ppt'){
          if(!isPpt)
          {
            setIsPpt(true)
          }
          if(sendSignal){
            dispatch({
              type:'SEND_COMMAND_TO_GROUP',
              payload: {command: '[SWITCH-SHAREDWINDOW]',message:""}
            });

            // dispatch({
            //   type: 'SET_ACTIVE_SCREEN',
            //   payload: id
            // });

            SwitchStream(false,activeSession.SessionId,'[SWITCH-SHAREDWINDOW]','',showLoader).then(res=>{
              console.log(res)
            }).catch(err=>{
              console.log(err)
            })
          }
        }else {
            if(sendSignal){
              // user is host because only host can sendSignal
              if(localParticipant.userId === id){
                dispatch({
                  type:'SEND_COMMAND_TO_GROUP',
                  payload: {command: "[SWITCH-CAMERA]",message:""}
                });
                SwitchStream(true,activeSession.SessionId,'[SWITCH-CAMERA]','',showLoader).then(res=>{
                  console.log(res)
                }).catch(err=>{
                  console.log(err)
                })
              }else{
                dispatch({
                  type:'SEND_COMMAND_TO_GROUP',
                  payload: {command: "[SWITCH-TO-REMOTE-CAM]",message:id}
                });
                SwitchStream(true,activeSession.SessionId,'[SWITCH-TO-REMOTE-CAM]',id,showLoader).then(res=>{
                  console.log(res)
                }).catch(err=>{
                  console.log(err)
                })
              }
            }
        }
        if(prevDiv!=null){
          const prevParentDiv = prevDiv.parentNode
          // prevParentDiv.classList.remove('root--active')
          prevParentDiv.style.height = "12.5rem"
          prevParentDiv.style.width = "21.5rem"
          // prevParentDiv.style.position = "relative"
        }else{
          console.log('prev div is null')
        }
        
        
        setActive(id)
        if(sendSignal){
          dispatch({
            type: 'SEND_COMMAND_TO_GROUP',
            payload: { command: '[SWITCH-ACTIVESCREEN]', message: id }
          });
        }
        
          setPositionActive(id)
        // const divVid = document.getElementById(id)
        // const div1 = document.getElementById("vidyoContainer")
        // if(divVid !== null){
        //   let div = {}
        //   if(div1.parentNode){
        //      div = div1.parentNode.parentNode
        //   }
          
        //   var rect = div.getBoundingClientRect();
        //   const divActive = divVid.parentNode

        //   console.log(divActive)
        //   divActive.style.height = (div.offsetHeight -9) + 'px'
        //   console.log(div.offsetHeight)
        //   divActive.style.width = (div.offsetWidth - 2)  + 'px'
        //   console.log(div.offsetWidth)
        //   divActive.style.top = rect.top + 'px'
        //   // divActive.style.bottom = div.offsetbottom
        //   divActive.style.left = rect.left + 'px'
        //   // divActive.style.right = div.offsetRight + 'px'
        // }
    }

    const setPositionActive = (id) => {
      const divVid = document.getElementById(id)
      const div1 = document.getElementById("vidyoContainer")
      if(divVid !== null){
        let div = {}
        if(div1.parentNode){
           div = div1.parentNode.parentNode
        }
        
        var rect = div.getBoundingClientRect();
        const divActive = divVid.parentNode

        console.log(divActive)
        divActive.style.height = (div.offsetHeight -9) + 'px'
        console.log(div.offsetHeight)
        divActive.style.width = (div.offsetWidth - 2)  + 'px'
        console.log(div.offsetWidth)
        divActive.style.top = rect.top + 'px'
        // divActive.style.bottom = div.offsetbottom
        divActive.style.left = rect.left + 'px'
        // divActive.style.right = div.offsetRight + 'px'
      }else{
        console.log('divVid is null')
      }
    }

    const sendCameraDetails = () => {
        const message = JSON.stringify(localCameraObj)
        console.log('sending message')
        videoConnectorCopy.SendChatMessage({message: message})
    }

    var GlobalccEnabled = false;
    var GlobalclosedCaptionServiceUrl = "";
    var GlobaljwtToken = "";
    var GlobalsubscriptionToken = "";

    async function enabledCC(vidyoConnector) {
      try {
      const response = await fetch("https://getservices.platform.vidyo.io");
      const closedCaption = (await response.json()).closedCaption;
      const closedCaptionEnabled = closedCaption.isServiceAvailable;
      const closedCaptionServiceUrl = closedCaption.url;
      if (!closedCaptionEnabled) {
        console.warn("CC: Service is not available")
        return;
      }
      GlobalclosedCaptionServiceUrl = closedCaptionServiceUrl;
      console.log("CC: Services Url: " + closedCaptionServiceUrl);
      const jwtTokenRes = (await vidyoConnector.GetJWTToken());
      // console.log('jwt',jwtTokenRes);
      const jwtToken = jwtTokenRes.jwtToken;
      const refreshToken = jwtTokenRes.refreshToken;
      console.log("CC: Get JWT Token Success", jwtToken);
      localStorage.setItem('jwtToken',jwtToken);
      localStorage.setItem('refreshToken',refreshToken);
      // GlobaljwtToken = jwtToken;
      // const startServiceResponse = await fetch(closedCaptionServiceUrl + "/startClosedCaptioning",
      // {headers: { "Authorization": "Bearer " + jwtToken}});
      // const startServiceResJSON = await startServiceResponse.json();
      // const status = startServiceResJSON.status;
      // const subscriptionToken = startServiceResJSON.data.subscriptionToken;
      // if (status !== "success" || !subscriptionToken) {
      // console.warn("CC: Service has not started or token invalid. Status: " + status);
      // return;
      // }
      // console.log("CC: Start CC Service Subscription Token", subscriptionToken);
      // GlobalsubscriptionToken = subscriptionToken;
      // await vidyoConnector.SubscribeToTopic({
      // topicSubscriptionToken: subscriptionToken,
      // messageCallback: (data) => {
      // console.log("CC: Data Received: " + data);
      // },
      // statusCallback: (status, message, code) => {
      // if (status === "SUBSCRIPTION_SUBSCRIBED") {
      //   GlobalccEnabled = true;
      // // $("#ccButton").addClass("ccOff").removeClass("ccOn");
      // } else if (status === "SUBSCRIPTION_UNSUBSCRIBED") {
      //   GlobalccEnabled = false;
      // // $("#ccButton").addClass("ccOn").removeClass("ccOff");
      // }
      //   console.log("CC: Subscribe Status: " + status + ". Message: " + message + ". Code: " + code);
      // },
      // });
      setIsJWTReady(true);
      } catch (error) {
        console.error("CC: Failed to enabled CC", error);
      }
    }
    
    async function disableCC(vidyoConnector) {
      try {
      console.log("CC: [Disable CC] JWT Token: " + GlobaljwtToken
      + ".\nUnsubscribe with Subscription Token: " + GlobalsubscriptionToken
      + ".\nService: " + GlobalclosedCaptionServiceUrl);
        const stopServiceResponse = await fetch(GlobalclosedCaptionServiceUrl + "/stopClosedCaptioning",
        {headers: { "Authorization": "Bearer " + GlobaljwtToken}});
        console.log("CC: Stop CC Service Response", (await stopServiceResponse.json()));
        await vidyoConnector.UnsubscribeFromTopic({ topicSubscriptionToken: GlobalsubscriptionToken });
        console.log("CC: Topic unsubscribed.");
      } catch (error) {
        console.error("CC: Failed to disable CC", error);
      }
    }
    useEffect(()=>{
      if(start && videoConnectorCopy){
        enabledCC(videoConnectorCopy);
      }
      return () =>{
        if(GlobalccEnabled && videoConnectorCopy){
          disableCC(videoConnectorCopy);
        }
      }
    },[videoConnectorCopy,start]);

    const [loudestSpeaker, energyEventsBuffer, speakers] = useLoudestSpeaker(
      0.5,
      3,
      start,
      localParticipant
    );

    useEffect(()=>{
      // when the host turns on the switch, 
      // this is to trigger the switch to loudest 
      // this won't happen automatically if the loudestParticipant is same 
      // before and after the switch has been turned on.
      console.log("loudest speaker is ",loudestSpeaker?.name,loudestSpeaker?.userId)
      // // eslint-disable-next-line no-debugger
      // debugger;
      if(aps){
        // console.log('active user ref is',activeUserRef.current);
        if(loudestSpeaker!==undefined && activeUserRef.current !== loudestSpeaker.userId){
          debouncedHandleSwitchLoudest(loudestSpeaker);
        }
      }
    },[aps, debouncedHandleSwitchLoudest, loudestSpeaker])

    // useEffect(()=>{
    //   // will this receive the latest value of loudest speaker? and be able to update it?
      
    //   const updateLoudestSpeaker = (participant,energy) => {
    //     if(loudestSpeaker.current.userId === participant.userId){
    //       setLoudestSpeaker({...loudestSpeaker.current,audioEnergy:energy});
    //       return;
    //     }
    //     if(energy > loudestSpeaker.current.audioEnergy && loudestSpeaker.current.userId !== participant.userId){
    //       setLoudestSpeaker({userId:participant.userId,audioEnergy:energy});
    //       debouncedHandleSwitchLoudest(participant);
    //     }
    //   }

    //   videoConnectorCopy?.RegisterRemoteMicrophoneEnergyListener({
    //     onEnergy: (mic,participant,audioEnergy)=>{
    //       console.log('on remote energy change',mic,participant,audioEnergy);
    //       updateLoudestSpeaker(participant,audioEnergy);

    //       // if(loudestSpeaker.current.userId === participant.userId){
    //       //   setLoudestSpeaker({...loudestSpeaker.current,audioEnergy:audioEnergy});
    //       //   return;
    //       // }

    //       // if(audioEnergy > loudestSpeaker.current.audioEnergy && loudestSpeaker.current.userId !== participant.userId){
            
    //       //   console.log('user changed to',participant.userId,"from",loudestSpeaker.current.userId);

    //       //   setLoudestSpeaker({userId:participant.userId,audioEnergy:audioEnergy});
    //       //   debouncedHandleSwitchLoudest(participant);
    //       // }
    //     }
    //   })

    //   videoConnectorCopy?.RegisterLocalMicrophoneEnergyListener({
    //     onEnergy: (localMic,audioEnergy)=>{
    //       console.log('on local energy change',localMic,audioEnergy);
          
    //       updateLoudestSpeaker(localParticipant,audioEnergy);

    //       // update energy
    //       // if(loudestSpeaker.current.userId === localParticipant.userId){
    //       //   setLoudestSpeaker({...loudestSpeaker.current,audioEnergy:audioEnergy});
    //       //   return;
    //       // }
          
    //       // if(audioEnergy > loudestSpeaker.current.audioEnergy && loudestSpeaker.current.userId !== localParticipant.userId){
            
    //       //   console.log('user changed to',localParticipant.userId,"from",loudestSpeaker.current.userId);

    //       //   setLoudestSpeaker({userId:localParticipant.userId,audioEnergy:audioEnergy});
    //       //   debouncedHandleSwitchLoudest(localParticipant);
    //       // }
    //     }
    //   })
      
    // },[localParticipant,videoConnectorCopy])
      
    return {onVidyoClientLoaded, 
            startVidyoConnector2, 
            setCamera, 
            leaveCall, 
            handleMute, 
            handleSpeakerPrivacy, 
            handleCameraPrivacy, 
            registerLocalMic, 
            registerLocalSpeaker, 
            handleShareMonitor, 
            startVideoCall, 
            showSelf, 
            start, 
            particpantsArr, 
            shareScreen, 
            videoConnectorCopy, 
            localCameraObj, 
            micPrivacy, 
            speakerPrivacy, 
            cameraPrivacy, 
            microPhoneMute, 
            audioDevices, 
            videoDevices, 
            speakerDevices,
            // handleDivSwitch,
            muteParticipant,
            cameraParticipant,
            localParticipant,
            activeUser,
            setActiveUser,
            isPpt,
            setPositionActive,
            stopShareScreen,
            remoteWindowShare,
            isJWTReady
          }
}
