/* eslint-disable  @typescript-eslint/consistent-type-imports */
import { BASE_URL } from '../http/api/services';
export interface CreateDurationResponseType {
  Id: number;
}

export const userDurationCreate = async (
  token: string,
  sessionId: number
): Promise<any | undefined> => {
  return await fetch(`${BASE_URL}/UserDurationUsage/Create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      SessionId: sessionId
    })
  })
    .then(async (response) => await response.json())
    .then((json) => json.Payload)
    .catch((error) => {
      console.error(error);
      return [];
    });
};
