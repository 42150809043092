import { type UserDetailType } from '../../common/types/user';
import { type UserRole } from '../reducers/user';

export enum UserType {
  // GET_TOKEN = "GET_TOKEN",
  SET_ROLE = 'SET_ROLE',
  GET_USER_DETAILS = 'GET_USER_DETAILS',
  SET_USER_DETAILS = 'SET_USER_DETAILS'
}
export type UserAction =
  // UserLogin |
  UserSetRoleAction | UserSetUserDetailsAction;

// export interface UserLogin {
//     type: typeof UserType.GET_TOKEN,
//     payload: { un: string, pw: string },
// }
export interface UserSetRoleAction {
  type: typeof UserType.SET_ROLE;
  payload: { role: UserRole };
}

export interface UserSetUserDetailsAction {
  type: typeof UserType.SET_USER_DETAILS;
  payload: { userDetails: UserDetailType };
}

export const setRole = (role: UserRole): UserAction => ({
  type: UserType.SET_ROLE,
  payload: { role }
});

export const setUserDetails = (userDetails: UserDetailType): UserAction => ({
  type: UserType.SET_USER_DETAILS,
  payload: { userDetails }
});

// export const getToken = (un: string, pw: string): UserAction =>
// ({
//     type: UserType.GET_TOKEN,
//     payload: { un, pw },
// });
