/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import styles from './teams-login.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils';
import {
  ACCESS_TOKEN,
  ORG_ID,
  OrganisationId,
  TEAMS_CHAT_ID,
  TEAMS_EMAIL_ID,
  USER_KEY
} from '../../common/constants';
import { type RootState } from '../../redux/reducers';
import { ITokenForTeamsRes, getTokenforTeams } from '../../http/teams';
import { getDataFromMagicToken, type magicTokenResponseType } from '../../http/cohost';
import { setUserDetails } from '../../redux/actions';
import { emptyUserDetails } from '../../redux/reducers/user';
import { getOrgDetail } from '../../http/organisation';

// import Message from "./Message.jsx";
// import Agenda from "./Agenda.jsx";
// import { generatetoken, RoleComponent, startSession } from "./data";
// import { setToken, setUserkey, setRole, setSessionId, setEventKey, setorgid } from "./token";
// import { app } from "@microsoft/teams-js";

export const TeamsLogin: React.FC = () => {
  const dispatch = useDispatch();
  const teamsContext = useSelector(({ SIGNALR }: RootState) => SIGNALR.teamsContext);

  const [debugMode, setDebugMode] = useState(false);
  const [debugValue, setDebugValue] = useState<any>();

  const [username, setUsername] = useState('');
  // const [password, setPassword] = useState("Sidak@123");
  // const [eventkey, _setEventkey] = useState("B9379841-13D9-4494-9A1D-E1A53AD794FE");
  // const [sessionid, _setSessionid] = useState("2661");
  // const [orgid, _setOrgid] = useState("795D68B3-49A8-4747-BEFD-17ADDCDE0844")
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    async function checkForSavedLogin(): Promise<any> {
      try {
        await handleSubmit(true);
      } catch (err) {
        localStorage.clear();
        setUserDetails(emptyUserDetails);
      }
    }
    if (teamsContext?.chat?.id === getLocalStorageItem(TEAMS_CHAT_ID)) {
      checkForSavedLogin()
        .then(() => {})
        .catch(() => {});
    }
  }, [teamsContext]);

  function handleUsernameChange(event: any): any {
    setUsername(event.target.value);
    setErrorMessage('');
  }

  const handleSubmit = async (savedLogin: boolean): Promise<any> => {
    // e.preventDefault();
    // console.log('yay', e);
    if (username.toLowerCase() === 'debug') {
      setDebugMode(!debugMode);
      setDebugValue(teamsContext);
      console.log('context', teamsContext);
      return;
    }

    if (username.toLowerCase() === 'ls') {
      setDebugValue(localStorage);
      return;
    }
    getTokenforTeams(
      teamsContext?.chat?.id,
      savedLogin ? getLocalStorageItem(TEAMS_EMAIL_ID) : username,
      'https://forum360prodapp.azurewebsites.net/api'
    )
      .then(() => {})
      .catch(() => {});

    getTokenforTeams(
      teamsContext?.chat?.id,
      savedLogin ? getLocalStorageItem(TEAMS_EMAIL_ID) : username,
      'https://mgmt.prod.forum360.co/api'
    )
      .then(() => {})
      .catch(() => {});

    getTokenforTeams(
      teamsContext?.chat?.id,
      savedLogin ? getLocalStorageItem(TEAMS_EMAIL_ID) : username,
      'https://azapp-mgmt-test-aet-001.azurewebsites.net/api'
    )
      .then(() => {})
      .catch(() => {});

    getTokenforTeams(
      teamsContext?.chat?.id,
      savedLogin ? getLocalStorageItem(TEAMS_EMAIL_ID) : username,
      'https://azapp-mgmt-prod-001.azurewebsites.net/api'
    )
      .then(() => {})
      .catch(() => {});
    try {
      const teamsTokenRes = await getTokenforTeams(
        teamsContext?.chat?.id,
        savedLogin ? getLocalStorageItem(TEAMS_EMAIL_ID) : username
      );
      const teamsToken = teamsTokenRes?.Payload?.Token;
      const decodedString = atob(teamsToken?.replace('SEE___', '') ?? '');
      const dataArr = decodedString.split(':');
      // email : orgKey : eventKey : appId : sessionID : productKey : Role
      const newDecodedString = dataArr.slice(0, 4).join(':');
      const newEncodedString = btoa(newDecodedString);
      // const newEncodedString = "YXNodXRvc2hAdmVlcnNhdGVjaC5jb206Nzk1ZDY4YjMtNDlhOC00NzQ3LWJlZmQtMTdhZGRjZGUwODQ0Ojk5MjU2ZDYzLTAzY2YtNDY5MS04NzliLTczYTk4ODM5ZTNjNToy";
      const magicLinkToken = 'SEE___' + newEncodedString;

      const productKey = dataArr[5];
      const eventKey = dataArr[2];
      const sessionId = parseInt(dataArr[4]);
      const orgKey = dataArr[1];
      const roleOfUser = dataArr[6];
      const res = await getOrgDetail(orgKey);
      setLocalStorageItem(OrganisationId, res?.Payload.Id.toString() ?? '');
      console.log({ orgKey, productKey, eventKey, sessionId, roleOfUser });

      if (magicLinkToken.length >= 7) {
        getDataFromMagicToken(magicLinkToken)
          .then((res) => {
            console.log('res', res);
            if (res.Success === false) {
              throw new Error('Meeting has expired');
            }
            const response: magicTokenResponseType = res.Payload;
            console.log('response', response);
            if (!savedLogin) {
              setLocalStorageItem(ACCESS_TOKEN, response.AccessToken);
              setLocalStorageItem(TEAMS_EMAIL_ID, username);
              setLocalStorageItem(TEAMS_CHAT_ID, teamsContext?.chat?.id);
            }
            setLocalStorageItem(USER_KEY, response.userKey);
            setLocalStorageItem(ORG_ID, orgKey);

            dispatch({
              type: 'GET_WAITING_ROOM_DETAILS',
              payload: {
                productKey,
                // eventKey:'3a692bba-3ab1-4a3e-b529-4d5ae96abf20',
                eventKey,
                sessionId
                // userDetails:''
              }
            }); // this will populate the active session state in redux.
          })
          .catch((err) => {
            console.log('err', err);
            // dispatch({
            //   type: 'SET_ERROR_POPUP',
            //   payload: { message: err.message ?? 'Something went wrong' }
            // });
            setErrorMessage(err.message ?? 'Something went wrong');
            // setError(true);
          });
      } else {
        // dispatch({
        //   type: 'SET_ERROR_POPUP',
        //   payload: { message: 'Access Denied , Please try a different email' }
        // });
        if (!savedLogin) {
          setErrorMessage('Access Denied, Please try a different email');
        }
      }
    } catch (err) {
      setDebugValue(err);
      if (savedLogin) {
        throw new Error('handle Submit failed');
      } else {
        setErrorMessage('Please try a different email');
      }
    }

    // api call to get token & userKey
    // setLocalStorageItem(ACCESS_TOKEN,"mTjnMortpmuRxRpAHxG5VLuSDPRQVdF4Ef5cxeEl-U_BCs37VvxqwTd6BdYQDw1uHscWK3U53Zk_Wfke_-uC1OTYoRJjfncZxmavQ2azskffEYR5Tx1ZDNVRrSg1Lg7KHYFJzgXIe_HH4vrBbGy6-inytaSjgntKc2fAk19UvPg2Yrp3oDFRaAi13gzwpqNIvsgI_p0vnc12BaHZ_p77Re9_Gew3UyTwaxJ3H1VJ5glPglFvewP4qvcejnysbMyl-t5TJLrH_Z9bGGzXQLnQ4bFnamMA25kIaJDWSEVnMvplqaY_i8p9tjEffrGM_5U-sR5uxsUvNT8Wo8bwv4M43BAIIvGb8OVd4WNqeUL_4f6mGJmzDwNN5Nj2KQOPb9pUZTjzkRhOiWTlnOho170YKIwRvuOsFOPYIY3vVfZr5oR3mXlfxus1uuI7Mz1DgwyGZo3lw7QzdRLbSN99fYu05EXMIh-4y9tzO0z-tgGrbc4");
    // setLocalStorageItem(USER_KEY,"456830c3-9e93-4117-9e3e-608be3714020");
    // api call to get userDetails ?
    // api call will return us orgKey , productKey , eventKey and sessionID
    // setLocalStorageItem(ORG_ID,"795d68b3-49a8-4747-befd-17addcde0844");
  };

  return (
    <div className={styles.main}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '72%',
          marginTop: '16.9px'
        }}
      >
        <div>
          <h2 className={styles['login-heading']}>Access Resources for your meeting</h2>
          <div className={styles['login-form']}>
            <input
              autoFocus
              type="text"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Enter your email Address"
              className={styles['login-input']}
            />
            {/* <div className={styles["login-meeting-id"]}>{teamsContext?.chat?.id}</div> */}
            {debugMode && (
              <>
                <div style={{ color: 'white' }}>Debug mode enabled</div>
                <pre style={{ color: 'white', width: '250px' }}>
                  {JSON.stringify(debugValue, null, 2)}
                </pre>
              </>
            )}
            {/* {errorMessage.length > 0 && (
              <p className={styles['error-message']}>{localStorage.getItem('accessToken')}</p>
            )} */}
            {errorMessage.length > 0 && (
              <div className={styles['error-message']}>{errorMessage}</div>
            )}
            <button
              data-automation-id="teams-login"
              onClick={() => {
                handleSubmit(false)
                  .then(() => {})
                  .catch(() => {});
              }}
              className={styles['login-button']}
            >
              Sign in
            </button>
          </div>
        </div>
        <div className={styles['login-newContainer']}>
          <h2 className={styles['login-newHeading']}>New to Relate?</h2>
          <div className={styles['login-newButtonContainer']}>
            <a href="https://forum360.com.au/" target="_blank" rel="noreferrer">
              <button className={styles['login-newButton']}>Click here</button>
            </a>
          </div>
          <div className={styles['login-subHeading']}>
            Unlock the magic of data driven sales meetings.
          </div>
        </div>
      </div>
    </div>
  );
};
