/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect } from 'react';

export function useOutside(refs: any[], callback: any): any {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    console.log('ref', refs);

    function handleClickOutside(event: Event): void {
      let isOutside = true;

      for (let i = 0; i < refs.length; i++) {
        if (refs[i].current?.contains(event.target)) {
          isOutside = false;
        }
      }

      if (isOutside) {
        callback();
        // event.stopPropagation();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs]);
}
