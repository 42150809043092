/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import styles from './Feedback.module.scss';
import Icon from './Icon.png';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Questions } from './Questions/Questions';
import { Comments } from './Comments/Comments';
import { Agenda } from './Agenda';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import { Answered } from './Answered';
import { Button } from '../../../../ui-kit/Button/Button';
import { BASE_URL } from '../../../../http/api/services';
import { UserRole } from '../../../../redux/reducers/user';
import {
  fetchQuestions,
  liveAnswer,
  liveAgenda,
  getCommentData
} from '../../../../utils/http-request';

export interface countProps {
  countAgenda: any;
}
export const Feedback: React.FC<countProps> = ({ countAgenda }: countProps) => {
  const [currentScreen, setCurrentScreen] = useState('questions');
  const [currentTab, setCurrentTab] = useState('agenda');
  const [agendaQuestion, setAgendaQuestion] = useState<any>([]);
  const [answeredQuestion, setAnsweredQuestion] = useState<any>([]);
  const [questions, setQuestions] = useState<any[]>([]);
  const [view, setView] = useState('More');
  const [count, setCount] = useState(0);
  const [agendaCount, setAgendaCount] = useState(0);
  const [viewLessVisible, setViewLessVisible] = useState(false);
  const [viewMoreVisible, setViewMoreVisible] = useState(true);
  const [countComments, setCountComments] = useState(0);
  const role = useSelector(({ USER }: RootState) => USER.role);

  function handleQuestionsClick(): void {
    setCurrentScreen('questions');
  }
  function handleCommentsClick(): void {
    setCurrentScreen('comments');
  }

  function setAgenda(): void {
    setCurrentTab('agenda');
  }

  function setAnswered(): void {
    setCurrentTab('answered');
  }

  const questionsChanged = useSelector(({ APPSTATE }: RootState) => APPSTATE.questionsChanged);
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const dispatch = useDispatch();
  const commandFromGroup = useSelector(({ SIGNALR }: RootState) => SIGNALR.commandFromGroup);
  useEffect(() => {
    if (commandFromGroup.command === '[COMMENT-NEW]') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      void getCommentData(
        localStorage.accessToken,
        activeSession.EventDetails.EventKey,
        activeSession.SessionId,
        localStorage.orgId
      )
        .then((data) => {
          console.log('data in Feedback', data);
          if (Boolean(data) && Boolean(data.GetCommentDetail))
            setCountComments(data?.GetCommentDetail?.length);
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else if (
      commandFromGroup.command === '[QUESTION-NEW]' ||
      commandFromGroup.command === '[PRIORITY-UPDATE]'
    ) {
      fetchQuestions(
        localStorage.accessToken,
        activeSession.EventDetails.EventKey,
        activeSession.SessionId
      )
        .then((questionsData) => {
          console.log('questiondata', questionsData);
          if (Boolean(questionsData) && Boolean(questionsData.Audiences)) {
            setQuestions([...questionsData.Audiences]);
            setCount(questionsData.Audiences.length);
          }

          setAgendaQuestion(
            questionsData.Planneds.filter(
              (question: any) => question.QuestionStatus === 901 || question.QuestionStatus === 900
            )
          );
          setAnsweredQuestion(
            questionsData.Planneds.filter(
              (question: any) => question.QuestionStatus === 902 || question.QuestionStatus === 909
            )
          );
          setAgendaCount(questionsData.Planneds.length);
          countAgenda(questionsData.Planneds.length);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [commandFromGroup]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    void getCommentData(
      localStorage.accessToken,
      activeSession.EventDetails.EventKey,
      activeSession.SessionId,
      localStorage.orgId
    )
      .then((data) => {
        console.log('data in Feedback', data);
        setCountComments(data?.GetCommentDetail?.length);
        if (data?.GetCommentDetail?.length === 0) setCountComments(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
    fetchQuestions(
      localStorage.accessToken,
      activeSession.EventDetails.EventKey,
      activeSession.SessionId
    )
      .then((questionsData) => {
        console.log('questiondata', questionsData);
        if (Boolean(questionsData) && Boolean(questionsData.Audiences)) {
          setQuestions([...questionsData.Audiences]);
          setCount(questionsData.Audiences.length);
        }

        const newQuestions = questionsData.Audiences;
        setQuestions(newQuestions);
        setAgendaQuestion(
          questionsData.Planneds.filter(
            (question: any) => question.QuestionStatus === 901 || question.QuestionStatus === 900
          )
        );
        setAnsweredQuestion(
          questionsData.Planneds.filter(
            (question: any) => question.QuestionStatus === 902 || question.QuestionStatus === 909
          )
        );
        setAgendaCount(questionsData.Planneds.length);
        countAgenda(questionsData.Planneds.length);
        // dispatch({ type: 'SET_QUESTIONS_CHANGED', payload: false });
      })
      .catch((err) => {
        console.log(err);
      });

    // }
    console.log('questions data', questions);
  }, [questionsChanged]);

  function viewButton(): void {
    if (view === 'Less') {
      setView('More');
      setViewLessVisible(false);
      setViewMoreVisible(true);
    }

    if (view === 'More') {
      setView('Less');
      setViewLessVisible(true);
      setViewMoreVisible(false);
    }
  }
  return (
    <>
      {(role === UserRole.moderator || role === UserRole.host) && (
        <div className={styles.feedbackcontainer}>
          <button
            name="questions"
            className={styles.feedbackbutton}
            onClick={handleQuestionsClick}
            style={currentScreen === 'questions' ? {} : { border: 'none' }}
          >
            <span className={currentScreen === 'questions' ? styles.h3 : styles.h4}>Questions</span>
            <div className={`${styles.messagecount} ${styles.questionsshow}`}>
              <span className={styles.count}>{count}</span>
            </div>
          </button>
          <button
            name="comments"
            className={styles.feedbackbutton}
            style={currentScreen === 'comments' ? {} : { border: 'none' }}
            onClick={handleCommentsClick}
          >
            <span className={currentScreen === 'comments' ? styles.h3 : styles.h4}>Comments</span>
            <div className={`${styles.messagecount} ${styles.questionsshow}`}>
              <span className={styles.count}> {countComments}</span>
            </div>
          </button>
        </div>
      )}
      {currentScreen === 'questions' && (
        <div className={styles.questiontab}>
          {(role === UserRole.host || role === UserRole.moderator) && questions.length > 0 && (
            <div className={styles.mainCont}>
              <div className={view === 'More' ? styles.qnaless : styles.qna}>
                x{questions.length > 0 && <Questions question={questions} />}
              </div>
              {questions.length > 0 && (
                <div
                  className={styles.view}
                  style={{
                    visibility: viewMoreVisible ? 'visible' : 'hidden'
                  }}
                >
                  {' '}
                  <button onClick={viewButton} className={styles.viewType}>
                    View More
                  </button>{' '}
                </div>
              )}
            </div>
          )}
          {(role === UserRole.host || role === UserRole.moderator) && (
            <div
              className={styles.viewLess}
              style={{ visibility: viewLessVisible ? 'visible' : 'hidden' }}
            >
              {questions.length > 0 && (
                <button onClick={viewButton} className={styles.viewLess}>
                  View Less
                </button>
              )}
            </div>
          )}
          <div className={styles.tabs}>
            <div className="d-flex">
              <button
                className={currentTab === 'agenda' ? styles.selectedTab : styles.notSelectedTab}
                onClick={setAgenda}
              >
                Agenda
              </button>
              <button
                className={`${styles.answered} ${
                  currentTab === 'answered' ? styles.selectedTab : styles.notSelectedTab
                }`}
                onClick={setAnswered}
              >
                Answered
              </button>
            </div>

            <div className={styles.addedquestion}>{agendaCount} questions added</div>
          </div>

          {currentTab === 'agenda' && (
            <Agenda agendaQuestions={agendaQuestion} liveAnswer={liveAnswer} />
          )}
          {currentTab === 'answered' && answeredQuestion.length > 0 && (
            <Answered answeredQuestions={answeredQuestion} liveAnswer={liveAgenda} />
          )}
        </div>
      )}
      {currentScreen === 'comments' && (
        <>
          <div className={styles.qna}>
            <Comments countComments={setCountComments} />
          </div>
          <div></div>
        </>
      )}
    </>
  );
};
