/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AppStateType } from '../redux/actions';
import {
  getEventsByProductKey,
  getProductsByOrgKey,
  getSessionDetails,
  getUpcomingEvents,
  getUserDetails
} from '../http/events';
import { setUpcomingEvents, setUpcomingSessions } from '../redux/actions/app-state';
import {
  type UserDetailResponseType,
  type UpcomingEventResponse,
  type Session,
  type EventType
} from '../common/types';
import { type SessionDetailsResponse } from '../common/types/session';
import { getEventStatus, getFormattedDate, getFormattedTime } from '../common/utils/time';
import { type upcomingSessionType } from '../types/upcoming-sessions';
import { getLocalStorageItem } from '../utils';
import { EVENT_KEY, ORG_ID, SESSION_ID_LIST } from '../common/constants';

// export type SessionWithTimeZone = Session & { TimeZone: string };
export type SessionWithEventDetails = Session & { EventDetails: EventType };
// export type SessionWithEventIndex = Session
function* workerUpcomingEvents({ payload }: any): any {
  try {
    yield put({ type: 'SET_LOADER', payload: { val: 'upcoming Events', add: true } });

    const orgKey = yield getLocalStorageItem(ORG_ID);
    const products = yield call(getProductsByOrgKey, orgKey);
    const allUpcomingEvents = yield all(
      products.Payload.map((p: any) => {
        return call(getEventsByProductKey, orgKey, p.ProductKey);
      })
    );
    console.log(allUpcomingEvents);

    const upcomingEvents =
      // : UpcomingEventResponse
      allUpcomingEvents.flatMap((e: any) => {
        return e.Payload;
      });

    // trying to add productKey (but we have it in EventProducts)
    // allUpcomingEvents.flatMap((e: any,idx:number) => {
    //   return {...e.Payload,ProductKey:products[idx]};
    // });

    // yield call(getUpcomingEvents);

    // const  upcomingSessions: Session[] = upcomingEvents.Payload.forEach((e)=>{
    //   e.Sessions.forEach((s)=>{
    //     upcomingSessions.push(s);
    //   });
    // });
    yield put(setUpcomingEvents(upcomingEvents.Payload));

    // export type SessionWithTimeZone = Session & {TimeZone: string};
    const upcomingSessions: SessionWithEventDetails[] = upcomingEvents.flatMap(
      (e: { Sessions: any[] }) => {
        return e.Sessions.map((s: any) => {
          return {
            ...s,
            // TimeZone: e.Timezone.StandardName,
            EventDetails: e
          };
        });
      }
    );

    // const timeZones: string[] = upcomingEvents.Payload.flatMap((e) => e.Sessions);
    // const upcomingSessionsWithHostDetails;
    // const promises = upcomingSessions.map((s)=>{
    //   call(getUserDetails,s.SpeakerId)
    // })
    // console.log(upcomingSessions);

    // yield put(setUpcomingSessions())

    const detailsOfAllSessions: SessionDetailsResponse[] = yield all(
      upcomingSessions.map((s) => {
        return call(getSessionDetails, s.SessionId);
      })
    );

    const userDetailsforEverySession: UserDetailResponseType[] = yield all(
      detailsOfAllSessions.map((s) => {
        return call(getUserDetails, s.Payload.SpeakerUserKey);
      })
    );

    const finalSessions: upcomingSessionType[] = detailsOfAllSessions
      .map((val, idx) => {
        return {
          // Date: new Date(val.Payload.EventDate).toDateString(),
          Date: getFormattedDate(val.Payload.SessionDate),
          // Date: getFormattedDate(upcomingSessions[idx].EventDetails.Sessions[0].SessionDate),
          SessionDate: val.Payload.SessionDate,
          // SessionDate: upcomingSessions[idx].EventDetails.Sessions[0].SessionDate,
          Duration: val.Payload.Duration,
          TimeZoneName: upcomingSessions[idx].EventDetails.Timezone.StandardName,
          Time: getFormattedTime(
            val.Payload.SessionDate,
            upcomingSessions[idx].EventDetails.Timezone.StandardName,
            val.Payload.Duration
          ),
          // Time: getFormattedTime(
          //   upcomingSessions[idx].EventDetails.Sessions[0].SessionDate,
          //   upcomingSessions[idx].EventDetails.Timezone.StandardName,
          //   val.Payload.Duration
          // ),
          Title: val.Payload.Title, // or Name ?
          Speaker: val.Payload.Speaker,
          SpeakerName: userDetailsforEverySession[idx].Payload.FullName,
          Product: upcomingSessions[idx].EventDetails.EventProducts.map((p) => p.ProductName).join(
            ','
          ),
          SessionStatus: val.Payload.SessionStatus,
          DateStarted: val.Payload.DateStarted,
          Status: getEventStatus(
            val.Payload.SessionStatus,
            val.Payload.SessionDate,
            val.Payload.Duration
          ),
          // Status: getEventStatus(val.Payload.Status,upcomingSessions[idx].EventDetails.Sessions[0].SessionDate,val.Payload.Duration),
          Type: upcomingSessions[idx].EventDetails.EventTypeID === 402 ? 'Public' : 'Private',
          HoldingImage: upcomingSessions[idx].EventDetails.HoldingImage,
          SpeakerImage: userDetailsforEverySession[idx].Payload.Avatar,
          EventDetails: upcomingSessions[idx].EventDetails,
          CoHosts: val.Payload.CoHosts,
          SessionId: val.Payload.SessionId,
          DProducer: val.Payload.DProducer,
          Location: val.Payload.Location
        };
      })
      .sort((a, b) => {
        return new Date(a.SessionDate).getTime() - new Date(b.SessionDate).getTime();
      })
      // filter out in person and teams events
      .filter((s) => s.Location === 'Forum360')
      // filter for cohost sessions
      .filter((s) => {
        if (getLocalStorageItem(EVENT_KEY) === '') {
          return true;
        } else {
          const sessionIds = JSON.parse(getLocalStorageItem(SESSION_ID_LIST));
          return sessionIds.includes(s.SessionId);
        }
      });

    // filter out past events that are more than 1 hour old after the meeting end time
    // .filter((s) => {
    //   if (s.SessionStatus === 3) {
    //     return false;
    //   }
    //   return new Date(s.SessionDate).getTime() + s.Duration * 1000 >= new Date().getTime();
    // });
    // filter out events that have been in progress for more than 1 hour after the meeting end time
    // .filter((s) => {
    //   const timeDiff = new Date().getTime() - new Date(s.DateStarted).getTime();
    //   if(s.SessionStatus !== 2){
    //     return true;
    //   }
    //   return (
    //    (timeDiff > 0) && (timeDiff <= ((s.Duration * 1000) + 3600000))
    //   );
    // });

    // console.log(userDetailsforEverySession);

    yield put(setUpcomingSessions(finalSessions));

    // console.log('uc',upcomingEvents)
    // const userDetails:UserDetailResponseType = yield call(getUserDetails,upcomingEvents.Payload.);
    // console.log(userDetails);

    // yield put(setSkeletonShown(AppointmentModuleWidgetsAlias.ALLERGIES, true));
    // const allergies: AllergiesData[] = yield getAllergies(patientId);
    // yield put(setAllergiesWidgetData(allergies));
  } catch (e) {
    console.log(e);
  } finally {
    //   catch ({ status, headers: { 'x-correlation-id': xCorrId } }) {
    //     // yield catchExceptions({ status, xCorrId } as CatchExceptionsParamsType);
    //   }
    // yield put(setSkeletonShown(AppointmentModuleWidgetsAlias.ALLERGIES, false));
    yield put({ type: 'SET_LOADER', payload: { val: 'upcoming Events', add: false } });
  }
}

export function* getUpcomingEventsSaga() {
  yield takeLatest(AppStateType.GET_UPCOMING_EVENTS, workerUpcomingEvents);
}
