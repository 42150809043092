/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Col, Nav, Row } from 'react-bootstrap';
import { Messaging } from './Tabs/Messaging/Messaging';
import { Feedback } from './Tabs/Feedback/Feedback';
import messaging from './messaging.svg';
import feedback from './feedback.svg';
import analytics from './analytics.svg';
// import resources from './resources.svg';
import sideTools from './side-tools.svg';
import resources from './Tabs/Resources/resources.svg';
import './Sidebar.scss';
import { Resources } from './Tabs/Resources/Resources';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../redux/reducers';
import { UserRole } from '../../redux/reducers/user';
import { Analytics } from './Tabs/Analytics/Analytics';
import { checkListIcon, cross, infoIcon } from '../../assets';
import stylesTeams from './Sidebar-teams.module.scss';
import stylesZoom from './Sidebar-zoom.module.scss';
import { endSession } from '../../http/meeting';
import { setModalShown } from '../../redux/actions/app-state';
import { ModalVariants } from '../../redux/reducers/app-state';
import { ACCESS_TOKEN } from '../../common/constants';
export interface FooterProps {
  tools?: any;
}
export const Sidebar: React.FC<FooterProps> = ({ tools }: FooterProps) => {
  const baseClassName = 'side-bar';
  const dispatch = useDispatch();
  const { teamsContext, activeSession } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const role = useSelector(({ USER }: RootState) => USER.role);
  const [agendaCount, setAgendaCount] = useState(0);
  const [tabBackground, setTabBackground] = useState(
    role === UserRole.cohost ? 'Feedback' : 'Messaging'
  );
  const [styles, setStyles] = useState<any>({});

  function countingAgenda(data: number): void {
    setAgendaCount(data);
  }

  useEffect(() => {
    if (teamsContext !== null) {
      // it's zoom or teams
      // styles = stylesTeams
      if (teamsContext.platform === 'zoom') {
        setStyles(stylesZoom);
      } else {
        setStyles(stylesTeams);
      }
    }
  }, [teamsContext]);

  const sidebarJSX = (
    <div className={`${baseClassName}__sidebox ${styles[`${baseClassName}__sidebox`]}`}>
      <div className={`${baseClassName}__heading-sidebar`}>
        <div className={`${baseClassName}__side-tools`}>
          <img src={sideTools} className="img-tools" />
          <span className={`${baseClassName}__relate`}>
            {window.location.href.includes('ngrok') ? 'Ng-rok Rules' : 'Relate Tools'}
          </span>
        </div>
        {teamsContext == null && (
          <div>
            <img
              src={checkListIcon}
              alt="ham-burger-menu"
              className={`${baseClassName}__option-ham`}
              style={role !== UserRole.host ? { display: 'none' } : { cursor: 'pointer' }}
              onClick={() => {
                dispatch({ type: 'SET_CHECKLIST_SHOWN', payload: true });
              }}
            />
            <img
              alt="close"
              style={{ height: '2.5rem' }}
              src={cross}
              onClick={tools !== undefined ? tools : () => {}}
            />
          </div>
        )}
      </div>
      <div className="few-to-many-btns">
        {teamsContext !== null &&
          teamsContext?.platform === 'zoom' &&
          activeSession.EventDetails.MeetingFormat === 1 && // FTM // check for vimeo only & MediaFile as well ?
          role === UserRole.host && (
            <button
              onClick={() => {
                console.log(window.location);
                // further open different links for teams and zoom as they have different instructions.
                const token =
                  'So___' +
                  btoa(
                    `${activeSession.EventDetails.EventKey}:${
                      localStorage.getItem(ACCESS_TOKEN) ?? ''
                    }:${activeSession.SessionId}`
                  );

                const link = window.location.origin + '/custom-streaming-zoom/' + token;
                // window.open(link, '_blank', 'noopener, noreferrer');
                zoomSdk?.openUrl({ url: link });
              }}
              className="blue"
            >
              &#x1F6C8; Open Custom Streaming
            </button>
          )}
        {/* {teamsContext !== null &&
          teamsContext?.platform === 'zoom' &&
          activeSession.EventDetails.MeetingFormat === 1 && // FTM // check for vimeo only & MediaFile as well ?
          role === UserRole.host && (
            <button
              onClick={() => {
                dispatch(setModalShown(ModalVariants.EndMeeting));
              }}
              className="red"
            >
              End Meeting
            </button>
          )} */}
        {teamsContext !== null &&
          teamsContext?.platform !== 'zoom' &&
          activeSession.EventDetails.MeetingFormat === 1 && // FTM // check for vimeo only & MediaFile as well ?
          role === UserRole.host && (
            <button
              onClick={() => {
                console.log(window.location);
                // further open different links for teams and zoom as they have different instructions.
                const token =
                  'Sa___' +
                  btoa(
                    `${activeSession.EventDetails.EventKey}:${
                      localStorage.getItem(ACCESS_TOKEN) ?? ''
                    }:${activeSession.SessionId}`
                  );

                const link = window.location.origin + '/custom-streaming-teams/' + token;
                window.open(link, '_blank', 'noopener, noreferrer');
                zoomSdk?.openUrl({ url: link });
              }}
              className="blue"
            >
              {/* <img src={infoIcon} /> */}
              {/* &#9432; */}
              &#x1F6C8; Open Custom Streaming app
            </button>
          )}
        {teamsContext !== null &&
          // teamsContext?.platform !== 'zoom' &&
          activeSession.EventDetails.MeetingFormat === 1 && // FTM // check for vimeo only & MediaFile as well ?
          role === UserRole.host && (
            <button
              onClick={() => {
                dispatch(setModalShown(ModalVariants.EndMeeting));
              }}
              className="red"
            >
              End Meeting
            </button>
          )}
      </div>
      {/* <Tabs
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
              
              >

              <Tab eventKey="home" title="Home" tabClassName='abc'>
                  Tab content for Home
              </Tab>
              <Tab eventKey="profile" title="Profile">
                  Tab content for Profile
              </Tab>
              <Tab eventKey="contact" title="Contact" disabled>
                  Tab content for Contact
              </Tab>
              </Tabs> */}
      {role !== UserRole.cohost && (
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row className="side-tabs">
            <Row>
              <Nav variant="pills" className="flex-row tabs">
                {
                  <Nav.Item
                    className="message"
                    onClick={() => {
                      setTabBackground('Messaging');
                    }}
                  >
                    <Nav.Link eventKey="first">
                      {' '}
                      <div
                        className={`d-flex messaging tools-tab ${
                          tabBackground === 'Messaging' ? 'active-tab' : 'inactive-tab'
                        }`}
                      >
                        <div>
                          <img style={{ height: '1.5rem' }} src={messaging} />
                        </div>
                        <div
                          className={`tab-select messaging-tab  ${
                            tabBackground === 'Messaging' ? 'set-message' : 'unset-message'
                          }`}
                        >
                          Messaging
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                }
                {
                  <Nav.Item
                    className="feedback"
                    onClick={() => {
                      setTabBackground('Feedback');
                    }}
                  >
                    <Nav.Link eventKey="second">
                      <div
                        className={`d-flex tools-tab ${
                          tabBackground === 'Feedback' ? 'active-tab' : 'inactive-tab'
                        }`}
                      >
                        <div>
                          <img style={{ height: '2rem' }} src={feedback} />
                        </div>
                        <div className={`tab-select `}>Feedback</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                }
                {
                  <Nav.Item
                    className="analytics"
                    onClick={() => {
                      setTabBackground('Analytics');
                    }}
                  >
                    <Nav.Link eventKey="third">
                      <div
                        className={`d-flex tools-tab ${
                          tabBackground === 'Analytics' ? 'active-tab' : 'inactive-tab'
                        }`}
                      >
                        <div>
                          <img style={{ height: '2rem' }} src={analytics} />
                        </div>
                        <div className={`tab-select `}>Analytics</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                }
                {
                  <Nav.Item
                    className="resources"
                    onClick={() => {
                      setTabBackground('Resources');
                    }}
                  >
                    <Nav.Link eventKey="fourth">
                      <div
                        className={`d-flex tools-tab ${
                          tabBackground === 'Resources' ? 'active-tab' : 'inactive-tab'
                        }`}
                      >
                        <div>
                          <img style={{ height: '2rem' }} src={resources} />
                        </div>
                        <div className="tab-select">Resources</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                }
              </Nav>
            </Row>
            <Col sm={12} className={`content ${styles.content}`}>
              <Tab.Content>
                {(role === UserRole.host || role === UserRole.moderator) && (
                  <Tab.Pane eventKey="first">
                    <Messaging />
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="second" style={{ height: '100%' }}>
                  <Feedback countAgenda={countingAgenda} />
                </Tab.Pane>
                {(role === UserRole.host || role === UserRole.moderator) && (
                  <Tab.Pane
                    eventKey="third"
                    // style={{ overflowY: 'scroll' }}
                    className={`${tabBackground === 'Analytics' ? 'analyticsscroll' : 'noscroll'}`}
                  >
                    <Analytics />
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="fourth" className="scroll-content">
                  <Resources />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
      {role === UserRole.cohost && (
        <Tab.Container id="left-tabs-example" defaultActiveKey="second">
          <Row className="side-tabs">
            <Row>
              <Nav variant="pills" className="flex-row tabs">
                {
                  <Nav.Item
                    className="feedback"
                    onClick={() => {
                      setTabBackground('Feedback');
                    }}
                  >
                    <Nav.Link eventKey="second">
                      <div
                        className={`d-flex tools-tab ${
                          tabBackground === 'Feedback' ? 'active-tab' : 'inactive-tab'
                        }`}
                      >
                        {/* <div>
                              <img src={feedback} />
                            </div> */}
                        <div className={`feedback-tab tab-select `}>
                          Questions <span className="agendaCount">{agendaCount}</span>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                }

                {
                  <Nav.Item
                    className="resources"
                    onClick={() => {
                      setTabBackground('Resources');
                    }}
                  >
                    <Nav.Link eventKey="fourth">
                      <div
                        className={`d-flex tools-tab ${
                          tabBackground === 'Resources' ? 'active-tab' : 'inactive-tab'
                        }`}
                      >
                        <div>
                          <img style={{ height: '2rem' }} src={resources} />
                        </div>
                        <div className="resources-tab tab-select">Resources</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                }
              </Nav>
            </Row>
            <Col sm={12} className={`content ${styles.content}`}>
              <Tab.Content>
                {
                  <Tab.Pane eventKey="first">
                    <Messaging />
                  </Tab.Pane>
                }
                <Tab.Pane eventKey="second" style={{ height: '100%' }}>
                  <Feedback countAgenda={countingAgenda} />
                </Tab.Pane>
                {
                  <Tab.Pane
                    eventKey="third"
                    // style={{ overflowY: 'scroll' }}
                    // className="analyticsscroll"
                    className={`${tabBackground === 'Analytics' ? 'analyticsscroll' : 'noscroll'}`}
                  >
                    <Analytics />
                  </Tab.Pane>
                }
                <Tab.Pane eventKey="fourth" className="scroll-content">
                  <Resources />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </div>
  );
  if (teamsContext?.platform === 'zoom') {
    return (
      <>
        <div className={styles.zoomContainer}>{sidebarJSX}</div>
        {/* <h1 className="text">Hello</h1> */}
      </>
    );
  }
  return sidebarJSX;
  // const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
};
