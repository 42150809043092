import { useCallback, useEffect, useRef, useState } from 'react';
import { normalizeEnergyLevel } from '../utils/microphone';

// const MicEnergyThreshold = 0.8;
// const NumOfConsequentEventsToSwitch = 20;
// const MainPresenterId = 'localId';

interface Participant {
  userId: string;
  name?: string;
}

interface ParticipantWithEnergyLevels extends Participant {
  energyLevel: number;
  normalizedEnergyLevel: number;
}

// interface Energy {
//     value:number,
//     normalizedValue:number
// }

const updateEnergyLevelInSpeakerArray = (
  speakers: ParticipantWithEnergyLevels[],
  participant: Participant,
  audioEnergy: number
): ParticipantWithEnergyLevels[] => {
  const newSpeakers = speakers.filter((sp) => {
    // return true;
    return sp.userId !== participant.userId;
  });
  newSpeakers.push({
    ...participant,
    energyLevel: audioEnergy,
    normalizedEnergyLevel: normalizeEnergyLevel(audioEnergy)
  });
  return newSpeakers.sort((a, b) => {
    return b.normalizedEnergyLevel - a.normalizedEnergyLevel;
  });
};

export const useLoudestSpeaker = (
  MicEnergyThreshold = 0.8,
  NumOfConsequentEventsToSwitch = 20,
  start: boolean,
  localParticipant: Participant
): [Participant | undefined, string[], ParticipantWithEnergyLevels[]] => {
  // start should be useless because we can attach listeners before joining the meeting
  // but we just keep it now.
  // { userID , energyValue, normalizedEnergyValue }
  const [loudestSpeaker, setLoudestSpeaker] = useState<Participant>();
  const [energyEventsBuffer, setEnergyEventsBuffer] = useState<string[]>([]);
  const [speakers, setSpeakers] = useState<ParticipantWithEnergyLevels[]>([]);
  //   const [partMap,setPartMap] = useState<Record<string,Participant>>({});

  const CollectEnergyEventAndDecideAutoSwitchToLoudest = useCallback(
    (energy: number, speakerID: string): any => {
      if (energy >= MicEnergyThreshold) {
        if (
          energyEventsBuffer.length > 0 &&
          energyEventsBuffer[energyEventsBuffer.length - 1] !== speakerID
        ) {
          // just to be on the safeside.
          setEnergyEventsBuffer((prevBuffer) => []);
        }
        setEnergyEventsBuffer((prevBuffer) => [...prevBuffer, speakerID]);
        if (energyEventsBuffer.length >= NumOfConsequentEventsToSwitch - 1) {
          setEnergyEventsBuffer((prevBuffer) => []);

          // Assuming loudestParticipantChangedTo is a function you want to call
          // loudestParticipantChangedTo(speakerID);
          setLoudestSpeaker({ userId: speakerID });
        }
      }
    },
    [MicEnergyThreshold, NumOfConsequentEventsToSwitch, energyEventsBuffer]
  );

  useEffect(() => {
    console.log('loudest speaker is buffer:', energyEventsBuffer);
  }, [energyEventsBuffer]);

  useEffect(() => {
    console.log('loudest speaker is final:', loudestSpeaker);
  }, [loudestSpeaker]);

  useEffect(() => {
    if (!start) return;
    const videoConnectorCopy = window.vidyoConnector;
    videoConnectorCopy?.RegisterRemoteMicrophoneEnergyListener({
      onEnergy: (mic: any, participant: Participant, audioEnergy: number) => {
        CollectEnergyEventAndDecideAutoSwitchToLoudest(
          normalizeEnergyLevel(audioEnergy),
          participant.userId
        );

        setSpeakers((prevSpeakers) => {
          const newSpeakers = updateEnergyLevelInSpeakerArray(
            prevSpeakers,
            participant,
            audioEnergy
          );
          return newSpeakers;
        });

        // speakersWithEnergies[participant?.userId] = audioEnergy;
        // const key = participant.userId;
        // if(key !== null){
        //   setspeakersWithEnergies((prevSpeakerWithEnergies)=>({...prevSpeakerWithEnergies, [key]:audioEnergy}));
        // }
        // console.log('on remote energy change',mic,participant,audioEnergy);
        // updateLoudestSpeaker(participant,audioEnergy);

        // if(loudestSpeaker.current.userId === participant.userId){
        //   setLoudestSpeaker({...loudestSpeaker.current,audioEnergy:audioEnergy});
        //   return;
        // }

        // if(audioEnergy > loudestSpeaker.current.audioEnergy && loudestSpeaker.current.userId !== participant.userId){

        //   console.log('user changed to',participant.userId,"from",loudestSpeaker.current.userId);

        //   setLoudestSpeaker({userId:participant.userId,audioEnergy:audioEnergy});
        //   debouncedHandleSwitchLoudest(participant);
        // }
      }
    });
  }, [CollectEnergyEventAndDecideAutoSwitchToLoudest, start]);

  useEffect(() => {
    if (!start) return;

    const videoConnectorCopy = window.vidyoConnector;
    videoConnectorCopy?.RegisterLocalMicrophoneEnergyListener({
      onEnergy: (localMic: any, audioEnergy: number) => {
        console.log('on local energy change', localMic, audioEnergy);
        CollectEnergyEventAndDecideAutoSwitchToLoudest(
          normalizeEnergyLevel(audioEnergy),
          localParticipant.userId
        );
        // const newSpeakers = speakers.filter((sp) => {
        //   // return true;
        //   return sp.userId !== MainPresenterId;
        // });
        // newSpeakers.push({
        //   name: 'Main Presenter',
        //   userId: MainPresenterId,
        //   energyLevel: audioEnergy,
        //   normalizedEnergyLevel: normalizeEnergyLevel(audioEnergy)
        // });
        // newSpeakers.sort((a, b) => {
        //   return a.normalizedEnergyLevel - b.normalizedEnergyLevel;
        // });
        setSpeakers((prevSpeakers) => {
          const newSpeakers = updateEnergyLevelInSpeakerArray(
            prevSpeakers,
            {
              name: 'Local',
              userId: localParticipant.userId
            },
            audioEnergy
          );
          return newSpeakers;
        });
        // updateLoudestSpeaker(localParticipant,audioEnergy);
        // speakersWithEnergies.local = audioEnergy;
        // setspeakersWithEnergies((prevSpeakerWithEnergies)=>({...prevSpeakerWithEnergies,local:audioEnergy}));
        // update energy
        // if(loudestSpeaker.current.userId === localParticipant.userId){
        //   setLoudestSpeaker({...loudestSpeaker.current,audioEnergy:audioEnergy});
        //   return;
        // }

        // if(audioEnergy > loudestSpeaker.current.audioEnergy && loudestSpeaker.current.userId !== localParticipant.userId){

        //   console.log('user changed to',localParticipant.userId,"from",loudestSpeaker.current.userId);

        //   setLoudestSpeaker({userId:localParticipant.userId,audioEnergy:audioEnergy});
        //   debouncedHandleSwitchLoudest(localParticipant);
        // }
      }
    });
  }, [CollectEnergyEventAndDecideAutoSwitchToLoudest, localParticipant.userId, start]);

  return [loudestSpeaker, energyEventsBuffer, speakers];
};
