/* globals zoomSdk */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useState, type ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../redux/reducers';
import { getUserRole } from '../utils/user-role';
import { UserRole } from '../redux/reducers/user';
import './teams-adapter.css';
import { startSession } from '../http/meeting';
import { saveCarbon } from '../components/Footer/Footer';
import { getLocalStorageItem } from '../utils';
import { ACCESS_TOKEN } from '../common/constants';
import { getSentimentGraphDataForPastXMin } from '../http/sentiment-graph';
import { ZoomLogin } from '../components/ZoomLogin/ZoomLogin';
import { apis, invokeZoomAppsSdk } from '../apis';

export interface IZoomAdapterProps {
  children: ReactNode;
}

export const ZoomAdapter: React.FC<IZoomAdapterProps> = (props: IZoomAdapterProps) => {
  const { children } = props;
  const [readyToConnect, setReadyToConnect] = useState(false);
  const dispatch = useDispatch();
  const { activeSession, startForWL } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const { role, userDetails } = useSelector(({ USER }: RootState) => USER);

  useEffect(() => {
    const htmlEl = document.querySelector('html');
    // if(htmlEl?.style?.fontSize !== undefined){
    //   htmlEl.style.fontSize = "unset";
    // }
    if (htmlEl?.style !== undefined) {
      htmlEl.style.fontSize = '14.7px';
      // htmlEl.style.overflow = "hidden";
    }
    // document.head.style.fontSize = "unset";
  }, []);

  useEffect(() => {
    if (activeSession.SessionId !== 0 && role !== UserRole.empty) {
      if (role === UserRole.host) {
        startSession(activeSession.EventDetails.EventKey, activeSession.SessionId)
          .then(() => {
            console.log('session started successfully');
            dispatch({ type: 'SET_START_FOR_WL', payload: true });
            setReadyToConnect(true);
          })
          .catch(() => {
            console.log('could not start session');
          });
      } else {
        setReadyToConnect(true);
      }
    }
  }, [activeSession, role]);

  useEffect(() => {
    if (activeSession.SessionId !== 0 && userDetails.UserKey !== '') {
      const role = getUserRole(activeSession, userDetails.UserKey);
      dispatch({ type: 'SET_ROLE', payload: { role } });

      const groupId =
        'room_' + activeSession.EventDetails.EventKey + '_' + activeSession.SessionId.toString();
      dispatch({ type: 'CONNECT_TO_GROUP', payload: { groupId, role } });

      saveCarbon(getLocalStorageItem(ACCESS_TOKEN), activeSession.EventDetails.EventId)
        .then(() => {})
        .catch(() => {});

      if (activeSession.Status === 'In Progress') {
        dispatch({ type: 'SET_START_FOR_WL', payload: true });
      }
    }
  }, [activeSession, userDetails]);

  useEffect(() => {
    let interval: any = 0;
    if (activeSession.SessionId !== 0 && startForWL && role !== UserRole.cohost) {
      dispatch({
        type: 'GET_SENTIMENT_GRAPH_DATA',
        payload: { sessionId: activeSession.SessionId }
      });
      interval = setInterval(() => {
        dispatch({
          type: 'GET_SENTIMENT_GRAPH_DATA',
          payload: { sessionId: activeSession.SessionId }
        });
      }, 60000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [startForWL, role, activeSession]);

  useEffect(() => {
    // zoom: set any non null value of teamsContext??
    async function configureApp() {
      const configResponse = await zoomSdk.config({
        version: '0.16.19',
        popoutSize: { width: 360, height: 1020 },
        capabilities: [
          ...apis.map((api) => api.name),
          // demo events
          'onSendAppInvitation',
          'onShareApp',
          'onActiveSpeakerChange',
          'onMeeting',
          'onLiveTranscript',

          // connect api and event
          'connect',
          'onConnect',
          'postMessage',
          'onMessage',

          // in-client api and event
          'authorize',
          'onAuthorized',
          'promptAuthorize',
          'getUserContext',
          'onMyUserContextChange',
          'sendAppInvitationToAllParticipants',
          'sendAppInvitation'
        ]
      });
      console.log('App configured ! ', configResponse);
      zoomSdk
        .getMeetingUUID()
        .then((result: { meetingUUID: string }) => {
          console.log('meeting context received ! ', result);
          dispatch({
            type: 'SET_TEAMS_CONTEXT',
            payload: {
              chat: {
                // add meeting id from zoom here
                // '19:meeting_MDgwNDQ2YjctOTI3YS00OWUyLWE1MGMtOTA3MmZiMDQ3Y2Ji@thread.v2'
                id: result.meetingUUID
              },
              platform: 'zoom',
              configResponse
            }
          });
        })
        .catch((err: any) => {
          console.log('err zoom : could not get context', err);
          // console.log(JSON.stringify(err))
          // console.log("how?",JSON.stringify(err), err.toString(),err.message);

          dispatch({
            type: 'SET_TEAMS_CONTEXT',
            payload: {
              chat: {
                error: err.toString()
                // add meeting id from zoom here
                // id: '19:meeting_MDgwNDQ2YjctOTI3YS00OWUyLWE1MGMtOTA3MmZiMDQ3Y2Ji@thread.v2' // context.meetingID
              },
              platform: 'zoom',
              configResponse
            }
          });
        });
    }

    configureApp();
  }, []);

  return <>{readyToConnect ? children : <ZoomLogin />}</>;
};
