import React, { useEffect } from 'react';
import styles from './FooterForum.module.scss';

import imgLogo from '../assets/relateLogo.png';
import { currentYear } from '../common/constants';
export const FooterForum: React.FC = () => {
  return (
    <>
      <div className={`${styles.footerContainer} ${styles.login_subHeading}`}>
        <div className={styles.logo}>
          <span className={styles.powered}>Powered by</span>
          <img style={{ height: '3.5rem' }} alt="Forum 360" src={imgLogo} />
        </div>
        <div style={{ fontSize: '0.84rem' }}>
          © Copyright Relate Research and Technology {currentYear}
        </div>
      </div>
    </>
  );
};

export default FooterForum;
