import qs from 'qs';
import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';
// import { type UserDetailResponseType, type UpcomingEventResponse } from '../common/types';
// import { type SessionDetailsResponse } from '../common/types/session';

export const updateUserAddress = async (
  UserKey: string,
  Address: string
): Promise<any | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/Admin_User/UpdateAddress`,
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
        // AppId: '4'
      },
      data: {
        UserKey,
        Address
      }
    },
    false
  );
};
export const sendforgotPasswordEmail = async (email: string): Promise<any | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/Account/ResetPasswordToken`,
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
        // AppId: '4'
      },
      data: {
        email
      }
    },
    false
  );
};
