import qs from 'qs';
import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';

export interface GetOrgDetailResponseType {
  Success: boolean;
  Payload: GetOrgDetailResponsePayloadType;
  ResponseCode: number;
}

export interface GetOrgDetailResponsePayloadType {
  Id: number;
  OrgKey: string;
  OrgName: string;
  OrgLogo: string;
  ShortDesc: string;
  FullDesc: string;
  OrganizationalDisclaimer: string;
  DateModified: string;
  IsDeleted: boolean;
  VsForum360: boolean;
  VsTeams: boolean;
  VsZoom: boolean;
  ThemeColour: string;
  MarketOwner: boolean;
  ProductKeys: string;
  ProductNames: string;
  OrgEmail: string;
  OrgPhone: string;
  InvestorCenter: string;
  FundPerformance: string;
  Announcements: string;
  ResearchProviders: string;
  CompanyWebsite: string;
  Contact: string;
  TermsAndConditions: string;
  Privacy: string;
  SubscriptionLevel: number;
  IsDistributor: boolean;
  HasDistributors: boolean;
  OnsiteSupport: boolean;
  AnF360Production: boolean;
  RestrictMonitorSharing: boolean;
  ApiDetail: string;
  ApiStatus: number;
  Distributors: Distributor[];
}

export interface Distributor {
  DistributorKey: string;
  DistributorId: number;
  DistributorName: string;
}

export const getOrgDetail = async (
  orgKey: string
): Promise<GetOrgDetailResponseType | undefined> => {
  const data = qs.stringify({
    OrgKey: orgKey
  });
  return await httpRequest({
    url: `${BASE_URL}/Organisation/GetOrgDetail`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
