import { sendMessage } from '@microsoft/signalr/dist/esm/Utils';
import { BASE_URL } from '../../../http/api/services';
import { httpRequest } from '../../../utils';

export const Welcome = async (sessionId: number): Promise<any | undefined> => {
  console.log('check welcome inside welcome');
  return await httpRequest({
    url: `${BASE_URL}/PA_Event/SendWelcome`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      OrgId: localStorage.orgId
    },
    data: JSON.stringify({
      SessionId: sessionId
    })
  });
};

export const Rate = async (sessionId: number): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/PA_Event/SendReviewRate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      OrgId: localStorage.orgId
    },
    data: JSON.stringify({
      SessionId: sessionId
    })
  });
};

export const Delay = async (sessionId: number): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/PA_Event/MeetingDelayNotify`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      OrgId: localStorage.orgId
    },
    data: JSON.stringify({
      SessionId: sessionId
    })
  });
};

export const TechnicalDifficulties = async (
  sessionId: number,
  eventKey: string
): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/PA_Event/SendEventMessage`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      OrgId: localStorage.orgId
    },
    data: JSON.stringify({
      EventKey: eventKey,
      Subject: 'Alert: Technical Difficulties',
      Body: 'We are currently experience technical difficulties. We are working to resolve this as quickly as possible and will provide an update shortly.',
      IsTechnical: true,
      SessionId: sessionId
    })
  });
};
