import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  Welcome,
  Rate,
  TechnicalDifficulties,
  Delay
} from '../common/components/Modal/MessageFunctions';
import { useSelector } from 'react-redux';
import { type RootState } from '../redux/reducers';
import { AppStateType } from '../redux/actions';
import { store } from '../redux/store';
function* sendMessages({ payload }: any): any {
  // const activeSession = useSelector('')
  const data = store.getState();
  console.log(data, 'invke');
  if (payload.modalToShow === 'Welcome') {
    const welcome = yield call(Welcome, data.SIGNALR.activeSession.SessionId);
  }
  if (payload.modalToShow === 'TechnicalDifficulty') {
    const difficulty = yield call(
      TechnicalDifficulties,
      data.SIGNALR.activeSession.SessionId,
      data.SIGNALR.activeSession.EventDetails.EventKey
    );
  }
  if (payload.modalToShow === 'Rate') {
    const rate = yield call(Rate, data.SIGNALR.activeSession.SessionId);
  }
  if (payload.modalToShow === 'MeetingDelay') {
    const delay = yield call(Delay, data.SIGNALR.activeSession.SessionId);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* messageUsers() {
  yield takeLatest(AppStateType.SET_MODAL_MESSAGE, sendMessages);
}
