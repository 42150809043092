import React from 'react';
import styles from './tooltip.module.scss';
import { ChildProcess } from 'child_process';

export enum TooltipVariants {
  Footer = 'footer',
  MeetingControls = 'meetingControls',
  // Messaging = 'messaging',
  Sidebar = 'sidebar'
}

export interface ToolTipProps {
  variant: TooltipVariants;
  show: boolean;
  Sections?: Array<{
    fields?: Array<{
      icon?: string;
      label?: string;
      deviceId?: string;
      name?: string;
      handleClick?: () => any;
      highlighted?: boolean;
      node?: React.ReactNode;
    }>;
  }>;
  children?: React.ReactNode;
  css?: React.CSSProperties;
  handleClick: (field: any) => any;
}

export const Tooltip: React.FC<ToolTipProps> = ({
  variant,
  show,
  Sections,
  children,
  css,
  handleClick
}: ToolTipProps) => {
  return (
    <div
      className={`${styles.tooltip} ${styles[variant]}`}
      style={!show ? { ...css, display: 'none' } : css}
    >
      {children ??
        Sections?.map((section, index) => {
          return (
            <>
              <div className={styles.section} key={index}>
                {section?.fields?.map((field, index) => {
                  if (React.isValidElement(field?.node)) {
                    return field.node;
                  }
                  return (
                    <div
                      key={field.deviceId}
                      style={field?.highlighted ?? false ? { background: '#232CFF' } : {}}
                      className={styles.field}
                      onClick={field?.handleClick}
                    >
                      {field?.icon != null && <img src={field.icon} alt={field?.name} />}
                      <div onClick={() => handleClick(field)}>{field?.name}</div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
    </div>
  );
};
