/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import './Resources.scss';
import addComment from './addComment.svg';
import thumbdislike from './thumbdislike.svg';
import thumblike from './thumblike.svg';
import { CustomerForm } from './customerform';
import { Keypoints } from './Keypoints';
import { Transcription } from './Transcription';
import { Carbon } from './Carbon';
import { Fulfilment } from './Fulfilment';
import { MeetingDetails } from './MeetingDetails';
import { Research } from './Research';
import { BASE_URL } from '../../../../http/api/services';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import { UserRole } from '../../../../redux/reducers/user';
async function getproductlinks(token: any, eventKey: string) {
  try {
    const response = await fetch(`${BASE_URL}/SalesMeetingNotes/OrgProductSetupLinks/${eventKey}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        OrgId: localStorage.orgId
      }
    });
    const json = await response.json();
    return json.Payload;
  } catch (error) {
    return [];
  }
}
export const Resources: React.FC = () => {
  const role = useSelector(({ USER }: RootState) => USER.role);
  const { activeSession, teamsContext } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  useEffect(() => {
    void getproductlinks(
      localStorage.accessToken,
      activeSession.EventDetails.EventProducts[0].ProductKey
    ).then((data) => {
      console.log('getProductData', data);
    });
  }, []);
  return (
    <>
      <div className="resource">
        <div className="feedback-heading">Your Feedback and Resources</div>

        <CustomerForm />
      </div>

      {/* <div className="points">
        <Keypoints />
      </div> */}

      {teamsContext === null && (
        <div className="points">
          <Transcription />
        </div>
      )}

      <div className="points fulfil" style={{ zIndex: '20000' }}>
        <Fulfilment />
      </div>

      {role !== UserRole.cohost && (
        <div style={{ zIndex: '10000' }} className="meeting-details">
          <MeetingDetails />
        </div>
      )}

      <div className="points" style={{ position: 'relative', zIndex: '20001' }}>
        <Carbon />
      </div>

      <div>
        <Research />
      </div>
    </>
  );
};
