/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  type SentimentGraphResponseType,
  getSentimentGraphDataForPastXMin
} from '../http/sentiment-graph';

function* workerGetSentimentGraphData({ payload }: any): any {
  try {
    const sentimentGraphRes: SentimentGraphResponseType = yield call(
      getSentimentGraphDataForPastXMin,
      payload.sessionId,
      1
    );
    yield put({ type: 'UPDATE_SENTIMENT_GRAPH', payload: sentimentGraphRes });
  } catch (e) {
    console.log('error in get room credentials', e);
  }
}
export function* getSentimentGraphDataSaga() {
  yield takeLatest('GET_SENTIMENT_GRAPH_DATA', workerGetSentimentGraphData);
}
