import React, { useEffect, useState } from 'react';
import userIcon from './User.svg';
import styles from './Participant.module.scss';
import { useDispatch } from 'react-redux';
import { mic, micMute } from '../../../assets';
export enum ParticipantVariants {
  default = 'default',
  active = 'active'
}
export interface ParticipantProps {
  participantName: string;
  divId: string;
  handleParticipantMute: any;
  participant: any;
  active: boolean;
  setActive: any;
  isPPT: boolean;
  isMuted: boolean;
}
export const Participant: React.FC<ParticipantProps> = ({
  participant,
  participantName,
  divId,
  handleParticipantMute,
  active,
  setActive,
  isMuted,
  isPPT
}: ParticipantProps) => {
  const [showUserIcon, setShowUserIcon] = useState(false);
  // const [active, setActive] = useState(false)
  const [elem, setElem] = useState({});
  const dispatch = useDispatch();
  // useEffect(()=>{
  //   const element = document.getElementById(divId)
  //   if(element !== null) {setElem(element)}
  //   console.log(element?.querySelectorAll('*').length)
  //   if(element?.querySelectorAll('*') !== null && element?.querySelectorAll('*') !== undefined && element?.querySelectorAll('*').length > 5){
  //     setShowUserIcon(true)
  //     console.log('inside if condition')
  //   }
  //   else{
  //     setShowUserIcon(false)
  //   }
  // }, [elem])
  const getVariant = (active: boolean): string => {
    if (active) return `${styles.root} ${styles['root--active-participant']}`;
    else return `${styles.root}`;
  };
  return (
    <div
      className={getVariant(active)}
      onClick={() => {
        setActive(divId);
      }}
    >
      <div className={styles.vid} id={divId}>
        {/* {
          !showUserIcon && <div className={styles.userIconDiv}><img className={styles.userIcon} src={userIcon} alt="user_icon"/></div>
        } */}
      </div>
      <div className={styles.details}>
        <span>{isPPT ? 'Presentation' : participantName}</span>
        {!isPPT && (
          <span
            onClick={() => {
              console.log('user Mute clicked');
            }}
          >
            <img style={{ height: '1.25rem' }} src={isMuted ? micMute : mic} alt="muted" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 0C8.20435 0 7.44129 0.31607 6.87868 0.87868C6.31607 1.44129 6 2.20435 6 3V9C6 9.79565 6.31607 10.5587 6.87868 11.1213C7.44129 11.6839 8.20435 12 9 12C9.79565 12 10.5587 11.6839 11.1213 11.1213C11.6839 10.5587 12 9.79565 12 9V3C12 2.20435 11.6839 1.44129 11.1213 0.87868C10.5587 0.31607 9.79565 0 9 0ZM7.93934 1.93934C8.22064 1.65804 8.60218 1.5 9 1.5C9.39782 1.5 9.77936 1.65804 10.0607 1.93934C10.342 2.22064 10.5 2.60217 10.5 3V9C10.5 9.39783 10.342 9.77936 10.0607 10.0607C9.77936 10.342 9.39783 10.5 9 10.5C8.60217 10.5 8.22064 10.342 7.93934 10.0607C7.65804 9.77936 7.5 9.39783 7.5 9V3C7.5 2.60217 7.65804 2.22064 7.93934 1.93934ZM4.5 7.5C4.5 7.08579 4.16421 6.75 3.75 6.75C3.33579 6.75 3 7.08579 3 7.5V9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.70433 14.1896 6.93525 14.7873 8.25 14.953V16.5H6C5.58579 16.5 5.25 16.8358 5.25 17.25C5.25 17.6642 5.58579 18 6 18H9H12C12.4142 18 12.75 17.6642 12.75 17.25C12.75 16.8358 12.4142 16.5 12 16.5H9.75V14.953C11.0647 14.7873 12.2957 14.1896 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9V7.5C15 7.08579 14.6642 6.75 14.25 6.75C13.8358 6.75 13.5 7.08579 13.5 7.5V9C13.5 10.1935 13.0259 11.3381 12.182 12.182C11.3381 13.0259 10.1935 13.5 9 13.5C7.80653 13.5 6.66193 13.0259 5.81802 12.182C4.97411 11.3381 4.5 10.1935 4.5 9V7.5Z"
                fill="#F8F8F8"
                fillOpacity="0.6"
              />
            </svg> */}
          </span>
        )}
      </div>
    </div>
  );
};
