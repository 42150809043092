// import { postReaction, reactionMap } from '@/http/Booking/feedback'
// import { useAppSelector } from '@/redux/hooks'
import { postReaction, reactionMap } from './customerform';
import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import './toolbar.scss';
export const Toolbar: React.FC = () => {
  const { activeSession } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const { userDetails } = useSelector(({ USER }: RootState) => USER);
  // const { orgInfoByEventUrl } = useSelector((state:Roo) => state.)
  // const { selectedSession } = useAppSelector((state) => state.SIGNALR)
  // const { userInfo } = useAppSelector((state) => state.auth)

  const [currentEmoji, setCurrentEmoji] = useState<{
    emoji: string;
    id: number;
  } | null>(null);
  const clearEmojiTimeout = useRef<any>(null);

  useEffect(() => {
    return () => {
      if (clearEmojiTimeout.current != null) {
        clearTimeout(clearEmojiTimeout.current);
      }
    };
  }, []);

  const handleEmojiClick: any = async (emoji: string, label: string) => {
    // Clear any existing timeout
    if (clearEmojiTimeout.current != null) {
      clearTimeout(clearEmojiTimeout.current);
    }

    // Set the current emoji with a unique identifier
    setCurrentEmoji({ emoji, id: Date.now() });

    // Remove the emoji after the animation duration
    clearEmojiTimeout.current = setTimeout(() => {
      setCurrentEmoji(null);
    }, 1690); // Adjust this duration to match your animation

    console.log(emoji);
    await postReaction(
      activeSession.EventDetails.EventKey,
      userDetails.UserKey,
      activeSession.SessionId,
      reactionMap[label as keyof typeof reactionMap],
      activeSession.Speaker.UserKey
    );
    // Record the reaction in the database
    // or do something else
  };

  const Emoji: any = ({
    label,
    emoji,
    isEmoji
  }: {
    label: string;
    emoji: string;
    isEmoji: boolean;
  }) => (
    <div className="relative w-fit">
      {isEmoji ? (
        <button
          className={`emoji-button font-emoji text-3xl leading-6 p-2 relative transition-bg-color duration-600 inline-flex justify-center items-center align-middle rounded-full ease-in-out hover:bg-gray-200 active:bg-gray-400 active:duration-0`}
          //  ${
          //   emoji === '👍' ? 'first-button' : ''
          // } ${emoji === '👎' ? 'last-button' : ''}`}
          // style={{ backgroundColor: '#12161E' }}
          role="img"
          aria-label={label}
          aria-hidden={label != null ? 'false' : 'true'}
          onClick={() => handleEmojiClick(emoji, label)}
        >
          {emoji}
          {currentEmoji != null && currentEmoji.emoji === emoji && (
            <span
              key={currentEmoji.id}
              className="font-emoji absolute -top-10 left-0 right-0 mx-auto animate-flyEmoji duration-3000"
            >
              {currentEmoji.emoji}
            </span>
          )}
        </button>
      ) : (
        <span className="text-white font-emoji text-4xl leading-6 bg-transparent p-2 relative transition-bg-color duration-600 inline-flex justify-center items-center align-middle rounded-full ease-in-out">
          {emoji}
        </span>
      )}
    </div>
  );

  return (
    <>
      <div
        className="emoji-container rounded-lg mx-auto content-center"
        // style={{ backgroundColor: '#31343c' }}
      >
        <div className="grid items-center justify-center">
          {/* <div className="p-2"> */}
          <div
            className="grid items-center justify-start grid-flow-col"
            style={{ display: 'flex' }}
          >
            {REACTIONS.map((reaction) => (
              <Emoji
                key={reaction.emoji}
                emoji={reaction.emoji}
                label={reaction.label}
                isEmoji={!['', '|'].includes(reaction.emoji)}
              />
            ))}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

const REACTIONS = [
  {
    emoji: '👍',
    label: 'up'
  },
  {
    emoji: '🤔',
    label: 'excited'
  },
  {
    emoji: '😴',
    label: 'bored'
  },
  // {
  //   emoji: '|',
  //   label: 'separator',
  // },
  {
    emoji: '😕',
    label: 'confused'
  },
  {
    emoji: '👎',
    label: 'down'
  }
];
