import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';
import qs from 'qs';

export const startSession = async (
  eventKey: string,
  sessionId: number
): Promise<any | undefined> => {
  const data = qs.stringify({
    EventKey: eventKey,
    SessionId: sessionId
  });
  return await httpRequest({
    url: `${BASE_URL}/Session/Start`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
      //   OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
      //   AppId: '4'
    }
  });
};

export const endSession = async (eventKey: string, sessionId: number): Promise<any | undefined> => {
  const data = qs.stringify({
    EventKey: eventKey,
    SessionId: sessionId
  });
  return await httpRequest({
    url: `${BASE_URL}/Session/Complete`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
      //   OrgId: '795D68B3-49A8-4747-BEFD-17ADDCDE0844',
      //   AppId: '4'
    }
  });
};
