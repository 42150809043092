/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import './Research.scss';
import { Badge } from '../../../../ui-kit/Badges/Badge';
import link from './link.svg';
import close from '../../close.svg';
import plus from './plus.svg';
import { BASE_URL } from '../../../../http/api/services';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
export const Research: React.FC = () => {
  function openNewBackgroundTab(id: any, link: string) {
    console.log('Inised open function');
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    //  a.onclick = () =>{
    //   window.open('#','_blank');
    //   window.open(link,'_self');
    //  }
    console.log('link', link);
    if (link.length > 0) {
      // const evt = document.createEvent('MouseEvents');
      if (!link.includes('http')) {
        a.href = 'https://' + link;
        console.log('a.href', a.href);
      }
      // evt.initMouseEvent(
      //   'click',
      //   true,
      //   true,
      //   window,
      //   0,
      //   0,
      //   0,
      //   0,
      //   0,
      //   true,
      //   false,
      //   false,
      //   false,
      //   0,
      //   null
      // );
      // a.dispatchEvent(evt);
      a.click();
      zoomSdk?.openUrl({ url: a.href });
    }
  }
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);

  const [urlLinks, setUrlLinks] = useState({});
  const [keyLinks, setKeyLinks] = useState<any[]>([]);
  async function getproductlinks(token: any, eventKey: string) {
    try {
      const response = await fetch(
        `${BASE_URL}/SalesMeetingNotes/OrgProductSetupLinks/${eventKey}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            OrgId: localStorage.orgId
          }
        }
      );
      const json = await response.json();
      return json.Payload;
    } catch (error) {
      return [];
    }
  }
  useEffect(() => {
    getproductlinks(
      localStorage.accessToken,
      activeSession.EventDetails.EventProducts[0].ProductKey
    ).then((questionsData) => {
      setUrlLinks(questionsData);
      console.log('newurl data from Resources', questionsData);
      setKeyLinks(Object.keys(questionsData));
    });
  }, []);
  const [links, setLinks] = useState([
    'Investor Center',
    'Announcements',
    'Product Performance',
    'Company Website',
    'Research Providers',
    'Product Providers',
    'Product Specifications',
    'Product Information Page'
  ]);

  return (
    <>
      <div className="research">
        <div className="research-head">Research Key Links</div>
        <div>
          {keyLinks.length > 0
            ? keyLinks.map((val: string, key: any) => {
                return (
                  <>
                    <div>
                      <div
                      // href={urlLinks[val as keyof typeof urlLinks]}
                      // target="_blank"
                      // rel="noreferrer"
                      >
                        {' '}
                        <button
                          className="link-button"
                          onClick={() => {
                            openNewBackgroundTab('id', urlLinks[val as keyof typeof urlLinks]);
                          }}
                        >
                          {' '}
                          <img
                            alt="link"
                            style={{ height: '1.69rem', marginRight: '.25rem' }}
                            src={link}
                          />
                          {val}
                          {/* <img src={close} className="cross" /> */}
                        </button>
                      </div>
                    </div>
                  </>
                );
              })
            : ''}
        </div>

        {/* <div className="add-link">
          <img src={plus} className="plus-link" />
          <input className="research-head" />
        </div> */}
      </div>
    </>
  );
};
