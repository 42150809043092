/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { call, put, takeLatest } from 'redux-saga/effects';
import { getJoinToken } from '../http/vidyo';
import { type JoinTokenResponseType } from '../common/types/join-token';

function* workerGetRoomCredentials({ payload }: any): any {
  try {
    const roomCredentialsResponse: JoinTokenResponseType = yield call(
      getJoinToken,
      payload.eventKey,
      payload.sessionId
    );
    yield put({ type: 'SET_ROOM_CREDENTIALS', payload: roomCredentialsResponse.Payload });
  } catch (e) {
    console.log('error in get room credentials', e);
  }
}
export function* getRoomCredentialsSaga() {
  yield takeLatest('GET_ROOM_CREDENTIALS', workerGetRoomCredentials);
}
