import { getToken, getUserOrgs } from '../http/user';

// type tokenResponse =
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const login = async (
  un: string,
  pw: string,
  callBack: (res: any) => void,
  onFail: (err: any) => void,
  appId: string = '6'
) => {
  try {
    // let orgs = await getUserOrgs(un);
    // orgs = orgs.Payload;
    // console.log('orgs',orgs);
    const newUn = un.replace('+', '%2B');
    const res = await getToken(newUn, pw, appId);
    callBack(res);
  } catch (error) {
    onFail(error);
    console.log(error);
  }
};

// // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// export const getUserOrgs = async (
//   un: string,
//   callBack: (res: any) => void,
//   onFail: (err: any) => void
// ) => {
//   try {
//     const res = await getOrgsByUserName();
//     callBack(res);
//   } catch (error) {
//     onFail(error);
//     console.log(error);
//   }
// }
