/* eslint-disable  @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */

import React, { useEffect, useState } from 'react';
import stylesRelate from './Modal.module.scss';
import stylesTeams from './Modal-teams.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../../redux/reducers';
import { ModalVariants } from '../../../redux/reducers/app-state';
import { setModalMessage, setModalShown } from '../../../redux/actions/app-state';
import { httpRequest } from '../../../utils';
import { BASE_URL } from '../../../http/api/services';
import { Welcome, Rate, Delay, TechnicalDifficulties } from './MessageFunctions';
import { useNavigate } from 'react-router-dom';
import { endSession } from '../../../http/meeting';
// import { Rate } from './MessageFunctions';
// export const Welcome = async (sessionId: number): Promise<any | undefined> => {
//   console.log('check welcome inside welcome');
//   return await httpRequest({
//     url: `${BASE_URL}/PA_Event/SendWelcome`,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       OrgId: localStorage.orgId
//     },
//     data: JSON.stringify({
//       SessionId: sessionId
//     })
//   });
// };

// export const Rate = async (sessionId: number): Promise<any | undefined> => {
//   return await httpRequest({
//     url: `${BASE_URL}/PA_Event/SendReviewRate`,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       OrgId: localStorage.orgId
//     },
//     data: JSON.stringify({
//       SessionId: sessionId
//     })
//   });
// };

// export const Delay = async (sessionId: number): Promise<any | undefined> => {
//   return await httpRequest({
//     url: `${BASE_URL}/PA_Event/MeetingDelayNotify`,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       OrgId: localStorage.orgId
//     },
//     data: JSON.stringify({
//       SessionId: sessionId
//     })
//   });
// };

// export const TechnicalDifficulties = async (
//   sessionId: number,
//   eventKey: string
// ): Promise<any | undefined> => {
//   return await httpRequest({
//     url: `${BASE_URL}/PA_Event/SendEventMessage`,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       OrgId: localStorage.orgId
//     },
//     data: JSON.stringify({
//       EventKey: eventKey,
//       Subject: 'Alert: Technical Difficulties',
//       Body: 'We are currently experience technical difficulties. We are working to resolve this as quickly as possible and will provide an update shortly.',
//       IsTechnical: true,
//       SessionId: sessionId
//     })
//   });
// };

const getModalText = (modalShown: ModalVariants): string => {
  switch (modalShown) {
    case ModalVariants.LeaveMeeting:
      return 'Are you sure you want to leave the meeting?';
    case ModalVariants.EndMeeting:
      return 'Are you sure you want to End the meeting?';
    default:
      return `Send your message ${modalShown} to all attendees`;
  }
};

export const Modal: React.FC = () => {
  const { teamsContext } = useSelector(({ SIGNALR }: RootState) => SIGNALR);
  const [styles, setStyles] = useState<any>({});

  useEffect(() => {
    if (teamsContext !== null) {
      setStyles(stylesTeams);
    } else {
      setStyles(stylesRelate);
    }
  }, [teamsContext]);
  // async function welcomeMessage(token: string, sessionId: number): Promise<any> {
  //   return await fetch('https://mgmt-test.forum360.co/api/PA_Event/SendWelcome', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${token}`
  //     },
  //     body: JSON.stringify({
  //       SessionId: `${sessionId}`
  //     })
  //   });
  // }
  // async function sendRate(token: string, sessionId: number): Promise<any> {
  //   return await fetch('https://mgmt-test.forum360.co/api/PA_Event/SendReviewRate', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${token}`
  //     },
  //     body: JSON.stringify({
  //       SessionId: `${sessionId}`
  //     })
  //   })
  //     .then((response) => response.json())
  //     .then((result) => console.log(result))
  //     .catch((error) => {
  //       console.error(error);
  //       return [];
  //     });
  // }
  const navigate = useNavigate();
  const modalShown = useSelector(({ APPSTATE }: RootState) => APPSTATE.modalShown);
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const dispatch = useDispatch();
  const [confirmationMessage, setConfirmationMessage] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setConfirmationMessage('');
    }, 1000);
  }, [confirmationMessage]);

  function discardMessage(): void {
    // setShowPop('No');
    dispatch(setModalShown(ModalVariants.None));
  }
  function sendMessagePopup(): void {
    // if (sentMessage === 'Welcome') {
    //   void Welcome();
    // }
    // if (sentMessage === 'Rate and Review') {
    //   void Rate();
    // }
    // if (sentMessage === 'Meeting Delay') {
    //   void Delay();
    // }
    // if (sentMessage === 'Technical Difficulties') {
    //   void TechnicalDifficulties();
    // }
    console.log('check');
    if (modalShown === ModalVariants.Welcome) {
      console.log('check welcome');
      dispatch(setModalMessage(ModalVariants.Welcome));
      // void Welcome(activeSession.SessionId);
      // console.log("a",a);
    } else if (modalShown === ModalVariants.Rate) {
      // void Rate(activeSession.SessionId);

      // void Rate(activeSession.SessionId);
      dispatch(setModalMessage(ModalVariants.Rate));
    } else if (modalShown === ModalVariants.MeetingDelay) {
      // void Delay(activeSession.SessionId);
      dispatch(setModalMessage(ModalVariants.MeetingDelay));
    } else if (modalShown === ModalVariants.TechnicalDifficulty) {
      // void TechnicalDifficulties(activeSession.SessionId, activeSession.EventDetails.EventKey);
      dispatch(setModalMessage(ModalVariants.TechnicalDifficulty));
    }
    // setSentButton(true);
    setConfirmationMessage('Sent!');
    // setShowPop('No');
    // setTimeout(() => {
    //   setSentButton(false);
    // }, 1000);

    dispatch(setModalShown(ModalVariants.None));
  }
  function onEndMeetingButton(): void {
    endSession(activeSession.EventDetails.EventKey, activeSession.SessionId)
      .then(() => {
        console.log('session ended');
        setConfirmationMessage('Meeting Ended!');
        dispatch(setModalShown(ModalVariants.None));
        dispatch({
          type: 'SEND_COMMAND_TO_GROUP',
          payload: { command: '[ENDMEETING]' }
        });
        localStorage.clear();
        window.location.reload();
      })
      .catch((e) => {
        console.log('error in ending session', e);
      });
  }
  return (
    <>
      {!(modalShown === 'None') && (
        <div className={styles.modalContainer}>
          <div className={styles.messagepop}>
            <div className={styles.poptext}>{getModalText(modalShown)} </div>
            <div className={styles.popButtons}>
              <button className={styles.no} onClick={discardMessage}>
                No
              </button>
              <button
                className={styles.yes}
                onClick={() => {
                  if (modalShown === ModalVariants.LeaveMeeting) {
                    navigate('/dashboard');
                    dispatch(setModalShown(ModalVariants.None));
                  } else if (modalShown === ModalVariants.EndMeeting) {
                    onEndMeetingButton();
                    // dispatch(setModalShown(ModalVariants.None));
                  } else {
                    sendMessagePopup();
                  }
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {confirmationMessage?.length > 0 && (
        <div className={styles.modalContainer}>
          <button className={styles.sentPop}>{confirmationMessage}</button>
        </div>
      )}
    </>
  );
};
