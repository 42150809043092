/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect, useRef } from 'react';
import './Fulfilment.scss';
import Icon from '../Messaging/Icon.png';
import greentickimage from './greentick.svg';
import tickimage from './tick.svg';
import { BASE_URL } from '../../../../http/api/services';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import { getLocalStorageItem, httpRequest } from '../../../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { link } from 'fs/promises';
import { UserRole } from '../../../../redux/reducers/user';
import { useOutside } from '../../../../common/hooks/useOutside';
import { meetingFormat } from '../../../../pages/WaitingRoom/WaitingRoom';
import { quickMessageOptions1, ACCESS_TOKEN } from '../../../../common/constants';
import {
  Submit,
  sendAnswers,
  getQuestions,
  getProductTypeLinks,
  getproductlinks,
  sendFollowUpEmail,
  UserList,
  productLink,
  getAllProductTypeLinks
} from '../../../../utils/http-request';
import { getFormattedDate } from '../../../../common/utils/time';
import { getCategoryByProductType, getKeywordByProductType } from './mapping';

export const Fulfilment: React.FC = () => {
  const sendAnswer = (index: number, question?: any): void => {
    const tempTickArray = [...ticks];
    const tempAns = [...answers];
    tempTickArray[index] = true;
    const answerValue = { ...tempAns[index] };

    if (answerValue.Value === '') {
      return;
    }

    if (question?.Type === 'date') {
      answerValue.Value = getFormattedDate(new Date(answerValue.Value));
    }
    const answerSent = [answerValue];

    setTicksArray(tempTickArray);
    setTimeout(() => {
      const tempTickArray = [...ticks];
      void sendAnswers(
        activeSession.EventDetails.EventKey,
        userDetails.UserKey,
        answerSent,
        localStorage.accessToken
      ).then((data) => {
        const temp = [...answers];
        if (question?.Type === 'date' || question?.Type === 'checkbox') {
          // do nothing
        } else {
          temp[index].Value = '';
        }
        setAnswers(temp);
      });

      tempTickArray[index] = false;
      setTicksArray(tempTickArray);
    }, 1000);
  };
  const productListDropDownRef = useRef(null);
  const investListDropDownRef = useRef(null);
  useOutside([productListDropDownRef], () => {
    setProductList(false);
  });
  useOutside([investListDropDownRef], () => {
    setInvestList(false);
  });
  // const active = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const openNewBackgroundTab = (id: any, link: string): void => {
    console.log('Inised open function');
    // const a = document.createElement('a');
    // a.href = link;
    // a.target = '_blank';
    // //  a.onclick = () =>{
    // //   window.open('#','_blank');
    // //   window.open(link,'_self');
    // //  }
    // console.log('link', link);
    if (link.length > 0) {
      const evt = document.createEvent('MouseEvents');
      if (!link.includes('http')) {
        link = 'https://' + link;
        // console.log('a.href', a.href);
      }

      window.open(link, '_blank', 'noopener,noreferrer');
      zoomSdk?.openUrl({ url: link });
    }
  };
  const handleDateChange = (date: any): number => {
    setSelectedDate(date);
    setFulfilmentDate(date);
    return 1;
  };
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);

  const s: any = '';
  const [linkValue, setLinkValue] = useState('');
  const [userList, setUserList] = useState([]);
  const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
  const [investList, setInvestList] = useState(false);
  const [selectedDate, setSelectedDate] = useState(s);
  const [customSelectedDate, setcustomSelectedDate] = useState(s);
  const [productList, setProductList] = useState(false);
  const [newTick, setNewTick] = useState(false);
  const [product, setProduct] = useState('');
  const [purpose, setPurpose] = useState('');
  const [invest, setInvest] = useState('');
  const [meetingCall, setMeetingCall] = useState('');
  const [fulfilmentDate, setFulfilmentDate] = useState(null);
  const [meetingTick, setMeetingTick] = useState(false);
  const [linkData, setLinkData] = useState<any>({});
  const [answers, setAnswers] = useState<any[]>([]);
  const [ticks, setTicksArray] = useState<any[]>([]);
  const [answerButton, setAnswerButton] = useState<any[]>([]);
  const role = useSelector(({ USER }: RootState) => USER.role);
  const cq = useSelector(({ MEETING }: RootState) => MEETING.cq);
  const [answerValue, setAnswerValue] = useState<any[]>([]);
  const [customQuestionTick, setCustomQuestionTick] = useState(false);
  const [links, setLinks] = useState([]);
  const meetingFormat = '1';

  const questionsAsked =
    role === UserRole.host || role === UserRole.moderator
      ? 'Questions to ask attendees'
      : 'Questions and Resources';
  function showInvestList(): void {
    console.log('function touched');
    if (investList) {
      setInvestList(false);
    } else {
      setInvestList(true);
    }
  }
  const meetingCalling = (): void => {
    console.log('meeting users', meetingCall);
    if (meetingCall.length > 0) {
      console.log('meeting users Check inside api', meetingCall);
      setMeetingTick(true);
      setTimeout(() => {
        setMeetingTick(false);

        void sendFollowUpEmail(
          localStorage.accessToken,
          userDetails.UserKey,
          purpose,
          selectedDate,
          meetingCall,
          activeSession.SessionId,
          activeSession.EventDetails.OrgName
        );
        setMeetingCall('');
      }, 1000);
    }
  };
  const showProductList = (): void => {
    if (productList) {
      setProductList(false);
    } else {
      setProductList(true);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    void getQuestions(
      activeSession.EventDetails.EventKey,
      activeSession.EventDetails.MeetingFormat.toString(),
      localStorage.accessToken
    ).then((data) => {
      console.log('data', data);
      // setCustomQuestion(data);
      const i = 0;
      const temp: any[] = [];
      const tickarray: any[] = [];
      data.forEach((value: any, index: any) => {
        temp.push({ EventElementId: value.EventElementId, Value: '' });
        tickarray.push(false);
      });
      setAnswers(temp);
      setTicksArray(tickarray);
      console.log('data answers', answers);
      dispatch({
        type: 'SET_CUSTOM_FULLFILLMENT_QUESTIONS',
        payload: data
      });
      // const temp:[{EventElementId:string,Value:string}] = [];

      const tempArray: any[] = [];
      const tempAnswerArray: any[] = [];
      data.forEach((question: any, index: any) => {
        tempArray.push({ EventElementId: question.EventElementId, Value: '' });
        tempAnswerArray.push('');
      });
      setAnswerValue(tempAnswerArray);

      setAnswers(tempArray);
      // setCustomQuestion(cq);
      // console.log("cq in data",cq);
    });
    void getproductlinks(
      localStorage.accessToken,
      activeSession.EventDetails.EventProducts[0].ProductKey
    ).then((data) => {
      console.log('data links', data);
      const temp = {};
      setLinkData(data);
    });

    if ([3, 4].includes(activeSession.EventDetails.EventProducts[0].ProductType)) {
      void getAllProductTypeLinks(
        localStorage.accessToken,
        activeSession.EventDetails.EventKey
      ).then((data) => {
        console.log('data ProductTypeLinks', data);
        setLinks(
          data
            .filter((el: any) => {
              if (el.Disabled !== false) return false;
              if ([1, 2, 5, 6].includes(el.Type)) {
                return true;
              }
              return false;
            })
            .map((el: any) => ({
              ...el,
              Name: `${
                el.Type === 5 || el.Type === 6
                  ? getKeywordByProductType(
                      activeSession.EventDetails.EventProducts[0].ProductType
                    ).split(' ')[0]
                  : ''
              } ${el.Name}`
            }))
        );
      });
    } else {
      void getProductTypeLinks(localStorage.accessToken, activeSession.EventDetails.EventKey).then(
        (data) => {
          console.log('data ProductTypeLinks', data);
          setLinks(data);
        }
      );
    }

    void UserList(localStorage.accessToken, localStorage.orgId).then((data) => {
      setUserList(data);
    });
  }, []);
  function callInvest(option: any, value: string = 'None'): void {
    setInvest(option.Name);

    void Submit(
      activeSession.SessionId,
      getLocalStorageItem(ACCESS_TOKEN),

      option.EnactId,
      value
    );
  }
  function callProduct(productSelected: string): void {
    setProduct(productSelected);

    void productLink(
      getLocalStorageItem(ACCESS_TOKEN),
      productSelected,
      userDetails.UserKey,
      activeSession.SessionId
    );
  }

  function customMeetingCalling(): void {
    setCustomQuestionTick(true);

    setTimeout(() => {
      setCustomQuestionTick(false);

      void sendAnswers(
        activeSession.EventDetails.EventKey,
        userDetails.UserKey,
        answers,
        localStorage.accessToken
      ).then((data) => {
        const newtemp = [];
        let i = 0;
        for (i = 0; i < answers.length; i++) {
          newtemp.push({ EventElementId: answers[i].EventElementId, Value: '' });
        }
        setAnswers(newtemp);
      });
    }, 1000);
  }
  function change(e: any, value: number, index: number): void {
    console.log('checking checkbox', e, value);
    const temp = [...answers];
    // temp[index]
    let i = 0;
    for (i = 0; i < temp.length; i++) {
      if (temp[i].EventElementId === value) {
        console.log('Inside If');
        console.log('checking on', e);
        if (e === 'on') {
          if (temp[i].Value === '' || temp[i].Value === 'false') {
            temp[i].Value = 'true';
          } else {
            temp[i].Value = 'false';
          }
        } else {
          temp[i].Value = e;
          console.log(e, 'INside If Values');
        }
      }
    }

    setAnswers(temp);
  }
  return (
    <>
      <div className="fulfilment">
        <div className="form">{questionsAsked}</div>

        <div className="question-form">
          <div className="product-question">
            {`Would you like to research the 
            ${getCategoryByProductType(
              activeSession.EventDetails.EventProducts[0].ProductType
            ).toLowerCase()} 
            further?`}
          </div>
          <div className="question-secondform position-form" ref={productListDropDownRef}>
            <input
              value={product}
              placeholder={`${getCategoryByProductType(
                activeSession.EventDetails.EventProducts[0].ProductType
              )} Links`}
              className="type-fulfilment"
              onClick={showProductList}
            />
            <img
              src={Icon}
              className="dropdown-icon"
              // style={{ position: 'absolute', bottom: '15px', right: '0' }}
              alt="v"
              onClick={showProductList}
            />
            {productList && (
              <div className="productList">
                {activeSession.EventDetails.EventProducts[0].ProductType < 3 && (
                  <>
                    <div
                      className="invest-options"
                      id="product"
                      onClick={() => {
                        setProductList(false);
                        callProduct('Investor Center');
                        openNewBackgroundTab('product', linkData.InvestorCentre);
                      }}
                    >
                      Investor Center
                    </div>

                    <div
                      className="invest-options"
                      onClick={() => {
                        setProductList(false);
                        callProduct('Fund Performance');
                        openNewBackgroundTab('FundPerformance', linkData.FundPerfromance);
                      }}
                      id="FundPerformance"
                    >
                      Fund Performance
                    </div>

                    <div
                      className="invest-options"
                      onClick={() => {
                        setProductList(false);
                        callProduct('Announcements');
                        openNewBackgroundTab('Announcements', linkData.Announcements);
                      }}
                      id="Announcements"
                    >
                      Announcements
                    </div>

                    <div
                      className="invest-options"
                      onClick={() => {
                        setProductList(false);
                        callProduct('Research Provider');
                        openNewBackgroundTab('ResearchProviders', linkData.ResearchProviders);
                      }}
                      id="ResearchProviders"
                    >
                      Research Provider
                    </div>

                    <div
                      className="invest-options"
                      onClick={() => {
                        setProductList(false);
                        callProduct('Product Specifications');
                        openNewBackgroundTab(
                          'ProductSpecifications',
                          linkData.ProductSpecifications
                        );
                      }}
                      id="ProductSpecifications"
                    >
                      Product Specifications (eg PDS, IM)
                    </div>

                    <div
                      className="invest-options"
                      onClick={() => {
                        setProductList(false);
                        callProduct('Product Information Page');
                        openNewBackgroundTab('ProductInformation', linkData.ProductInformationPage);
                      }}
                      id="ProductInformation"
                    >
                      Product Information Page
                    </div>
                  </>
                )}
                {activeSession.EventDetails.EventProducts[0].ProductType >= 3 && (
                  <>
                    <div
                      className="invest-options"
                      onClick={() => {
                        setProductList(false);
                        callProduct(
                          `${getCategoryByProductType(
                            activeSession.EventDetails.EventProducts[0].ProductType
                          )} Information Center`
                        );
                        openNewBackgroundTab('ProductInformation', linkData.ProductInformationPage);
                      }}
                      id="ProductInformation"
                    >
                      {getCategoryByProductType(
                        activeSession.EventDetails.EventProducts[0].ProductType
                      )}{' '}
                      Information Center
                    </div>

                    <div
                      className="invest-options"
                      onClick={() => {
                        setProductList(false);
                        callProduct(
                          `${getCategoryByProductType(
                            activeSession.EventDetails.EventProducts[0].ProductType
                          )} Specifications`
                        );
                        openNewBackgroundTab(
                          'ProductSpecifications',
                          linkData.ProductSpecifications
                        );
                      }}
                      id="ProductSpecifications"
                    >
                      {getCategoryByProductType(
                        activeSession.EventDetails.EventProducts[0].ProductType
                      )}{' '}
                      Specifications
                    </div>

                    <div
                      className="invest-options"
                      onClick={() => {
                        setProductList(false);
                        callProduct('Company Information Center');
                        openNewBackgroundTab('CompanyWebsite', linkData.CompanyWebsite);
                      }}
                      id="CompanyInformationCenter"
                    >
                      Company Information Center
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="question-form">
          <div className="meeting-question">
            Would you like a follow up meeting with another expert? If so, with whom?
          </div>
          <div className="question-secondform">
            <select
              value={meetingCall}
              onChange={(e) => {
                setMeetingCall(e.target.value);
              }}
              className="type-fulfilment"
            >
              <option value="">Search Name</option>
              {userList?.map((question: any, index: React.Key | null | undefined) => (
                <option key={question.UserKey} value={question.UserKey}>
                  {question.UserName}
                </option>
              ))}
            </select>
          </div>
          <div className="question-secondform">
            <select
              className="type-fulfilment"
              value={purpose}
              onChange={(e) => {
                setPurpose(e.target.value);
              }}
            >
              <option value="">Select the purpose of the meeting</option>
              {quickMessageOptions1.map((question: any, index: React.Key | null | undefined) => (
                <option key={question.label} value={question.label}>
                  {question.label}
                </option>
              ))}
            </select>
          </div>
          <div className="question-secondform ">
            <div>
              <DatePicker
                // onKeyDown={e => {e.preventDefault()}}
                className="type-fulfilment"
                selected={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date);
                }}
                placeholderText="Select Date"
                isClearable
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <div className="expert">
            <img
              src={meetingTick ? greentickimage : tickimage}
              onClick={meetingCalling}
              style={{ width: '1.7rem', height: '16px' }}
              alt="tick"
            />
          </div>
        </div>
        <div className="question-form">
          <div className="invest-question">
            Should you decide, how will you{' '}
            {getKeywordByProductType(
              activeSession.EventDetails.EventProducts[0].ProductType
            ).toLowerCase()}{' '}
            this{' '}
            {getCategoryByProductType(
              activeSession.EventDetails.EventProducts[0].ProductType
            ).toLowerCase()}
            ?
          </div>
          <div className="invest-options-product" ref={investListDropDownRef}>
            <input
              value={invest}
              placeholder="List available fulfillment pathways"
              className="type-fulfilment"
              onClick={showInvestList}
            />
            <img src={Icon} className="dropdown-icon" onClick={showInvestList} alt="dropdown" />
            {investList && (
              <div className="investList">
                {links.length > 0 &&
                  links.map((options: any, index: number) => {
                    if (options.Url.length > 0) {
                      return (
                        <div
                          key={index}
                          className="invest-options"
                          onClick={() => {
                            setInvestList(false);
                            // TODO: different fun.for host here
                            callInvest(options);
                            openNewBackgroundTab('new', options.Url);
                          }}
                        >
                          {options.Name}
                        </div>
                      );
                    } else {
                      return (
                        <div key={index} className="invest-options">
                          {options.Name}
                          <input
                            placeholder={options.Name}
                            value={linkValue}
                            onChange={(e) => {
                              setLinkValue(e.target.value);
                            }}
                          />
                          <img
                            src={newTick ? greentickimage : tickimage}
                            onClick={() => {
                              setInvestList(false);
                              callInvest(options, linkValue);
                              setLinkValue('');
                              openNewBackgroundTab('new', linkValue);
                            }}
                            style={{ width: '16px', height: '16px' }}
                            alt="tick"
                          />
                        </div>
                      );
                    }
                  })}
                <div className="invest-options"> </div>
              </div>
            )}
          </div>

          {cq.length > 0 &&
            cq.map((question: any, index: any) => {
              if (question.Type === 'checkbox') {
                return (
                  <div className="customdate" key={question.EventElementId}>
                    <input
                      type="checkbox"
                      className="customcheck"
                      checked={answers[index]?.Value}
                      onChange={(e) => {
                        change(e.target.checked, question.EventElementId, index);
                      }}
                    />

                    {question.Label}
                    <img
                      src={ticks[index] === true ? greentickimage : tickimage}
                      onClick={() => {
                        sendAnswer(index, question);
                      }}
                      className="send-tick"
                      alt="tick"
                    />
                  </div>
                );
              } else if (question.Type === 'label') {
                return (
                  <div className="custom-header" key={question.EventElementId}>
                    {question.Label}
                  </div>
                );
              } else if (question.Type === 'date') {
                return (
                  <div className="date-align" key={question.EventElementId}>
                    <div>{question.Label}</div>
                    {/* <br /> */}
                    <DatePicker
                      className="type-fulfilment"
                      selected={answers[index]?.Value}
                      // value = {answers[index]?.Value}
                      onChange={(date) => {
                        setcustomSelectedDate(date);
                        change(date, question.EventElementId, index);
                      }}
                      placeholderText="Select Date"
                      isClearable
                      dateFormat="dd/MM/yyyy"
                    />
                    <img
                      src={ticks[index] === true ? greentickimage : tickimage}
                      onClick={() => {
                        sendAnswer(index, question);
                      }}
                      className="send-tick date-tick"
                      alt="tick"
                    />
                  </div>
                );
              } else {
                return (
                  <div className="meetings" key={question.EventElementId}>
                    {question.Label}
                    <br />
                    <input
                      placeholder="Start Typing..."
                      className="type-fulfilment custom-input"
                      value={answers[index]?.Value}
                      onChange={(e) => {
                        change(e.target.value, question.EventElementId, index);
                        // const temp = [...answers]
                        // temp[index].Value = e.target.value
                        // setAnswers(temp)
                      }}
                    />
                    <img
                      src={ticks[index] === true ? greentickimage : tickimage}
                      onClick={() => {
                        sendAnswer(index);
                      }}
                      className="send-tick"
                      alt="tick"
                    />
                  </div>
                );
              }
            })}
        </div>
      </div>
      {/* {productList && (
        <div className="productList">
          <div
            className="invest-options"
            onClick={() => {
              setProductList(false);
              callProduct('Investor Center');
            }}
          >
            Investor Center
          </div>
          <div
            className="invest-options"
            onClick={() => {
              setProductList(false);
              callProduct('Fund Performance');
            }}
          >
            Fund Performance
          </div>
          <div
            className="invest-options"
            onClick={() => {
              setProductList(false);
              callProduct('Announcements');
            }}
          >
            Announcements
          </div>
          <div
            className="invest-options"
            onClick={() => {
              setProductList(false);
              callProduct('Research Provider');
            }}
          >
            Research Provider
          </div>
          <div
            className="invest-options"
            onClick={() => {
              setProductList(false);
              callProduct('Product Specifications');
            }}
          >
            Product Specifications (eg PDS, IM)
          </div>
          <div
            className="invest-options"
            onClick={() => {
              setProductList(false);
              callProduct('Product Information Page');
            }}
          >
            Product Information Page
          </div>
        </div>
      )} */}
      {/* {investList && (
        <div className="investList">
          <div
            className="invest-options"
            onClick={() => {
              setInvestList(false);
              callInvest('Direct To Product User');
            }}
          >
            Direct To Product User
          </div>
          <div
            className="invest-options"
            onClick={() => {
              setInvestList(false);
              callInvest('Via a specialisit intermediary');
            }}
          >
            Via a specialisit intermediary
          </div>
          <div
            className="invest-options"
            onClick={() => {
              setInvestList(false);
              callInvest('Via a marketplace or investment platform');
            }}
          >
            Via a marketplace or investment platform
          </div>
        </div>
      )} */}
    </>
  );
};
