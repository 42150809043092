import React, { useEffect, useState } from 'react';
import styles from './event.module.scss';
import { Badge, BadgeVariants } from '../Badges/Badge';
import { Button, ButtonVariants } from '../Button/Button';
import { user } from '../../assets';
import { renderTime } from '../../common/utils/time';
import { useNavigate } from 'react-router';
import { trimToLength } from '../../utils/string-manip';
import { TrimTooltip } from '../TrimTooltip/TrimTooltip';

export interface EventProps {
  thumbnail?: string;
  // footer: boolean
  badgeType: BadgeVariants;
  eventInfo: {
    hostPic?: string;
    title: string;
    host: string;
    date: string;
    time: string;
    eventName: string;
  };
  // video?:stream,
  timestamp?: string;
  joinButtonMessage?: string;
  meetingDetails?: {
    roomName: string;
    roomPin: string;
    vidyoToken: string;
  };
  withFooter?: boolean;
  handleClick?: () => any;
}

export const Event: React.FC<EventProps> = ({
  thumbnail,
  badgeType,
  eventInfo,
  timestamp,
  withFooter,
  handleClick
}: EventProps) => {
  const [time, setTime] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (timestamp !== undefined) {
      const timeDiff = Math.floor((new Date().getTime() - new Date(timestamp).getTime()) / 1000);
      setTime(timeDiff);
    }
  }, [timestamp]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => time + 1);
      if (time % 69 === 0 && timestamp !== undefined) {
        // sync time every 69 seconds
        const timeDiff = Math.floor((new Date().getTime() - new Date(timestamp).getTime()) / 1000);
        setTime(timeDiff);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.event}>
      <div
        className={styles.display}
        onClick={() => {
          handleClick?.();
          // navigate('/waitingroom/1');
        }}
      >
        <div className={styles.thumbnail}>
          {thumbnail != null && <img src={thumbnail} alt="thumbnail" />}
        </div>
        <div className={styles.overlay}> </div>
        {/* {withFooter === true ? (
          <div className={styles.badge}>
            <Badge
              css={{ color: '#D50D55', background: '#FFF', width: '7.2rem' }}
              text="Happening Now"
            />
          </div>
        ) : (
          <div className={styles.badge}>
            <Badge badgeType={badgeType} />
          </div>
        )} */}
        {
          <div className={styles.badge}>
            <Badge badgeType={badgeType} />
          </div>
        }
        <div className={styles.eventDetails}>
          <div className="d-flex">
            {/* <div className={styles.hostPic}> */}
            {(eventInfo.hostPic ?? '').length > 0 ? (
              <img src={eventInfo.hostPic} alt="host-pic" className={styles.hostPic} />
            ) : (
              <img src={user} alt="host-pic" className={styles.hostPic} />
            )}
            {/* </div> */}
            <div className="d-flex justify-content-center flex-column">
              <div className={styles.title}>
                {<TrimTooltip text={eventInfo.title} length={30} />}
              </div>
              <div className={styles.subTitle1}>with {eventInfo.host} -</div>
              <div className={styles.subTitle1}>
                {<TrimTooltip text={eventInfo.eventName} length={30} />}
              </div>
              <div className={styles.subTitle2}>
                {eventInfo.date} | {eventInfo.time}
              </div>
            </div>
          </div>
        </div>
      </div>
      {withFooter === true && (
        <div className={styles.footer}>
          <div className={styles.footerHeadings}>
            <div className="d-flex justify-content-between">
              <div className={styles.footerTitle}>{eventInfo.title}</div>
              {timestamp !== undefined && (
                <div className={styles.footerTimestamp}>{renderTime(time)}</div>
              )}
            </div>
            <div className={styles.footerSubTitle}>with {eventInfo.host} - </div>
            <div className={styles.footerSubTitle}>{eventInfo.eventName}</div>
          </div>
          <Button
            pointer="join-meeting"
            variant={ButtonVariants.Modal}
            text={badgeType === BadgeVariants.HappeningNow ? 'Join Waiting Room' : 'Join'}
            onClick={() => {
              handleClick?.();
              // navigate('/waitingroom/1');
            }}
          />
        </div>
      )}
    </div>
  );
};
