import React, { useEffect, useState } from 'react';
import styles from './checklist.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addIcon, checkListIcon, cross } from '../../../assets';
import { type RootState } from '../../../redux/reducers';
import {
  getCustomChecklist,
  getGlobalChecklist,
  saveCustomCheckListItem,
  saveGlobalCheckListItem
} from '../../../http/checklist';

export interface CheckListItem {
  ItemId: number;
  Title: string;
  OrganisationId: number;
  UserId: number;
  UserName: string;
  SessionId: number;
  IsChecked: boolean;
}

const Checklist: React.FC = () => {
  const dispatch = useDispatch();
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
  const handleCheckBoxClick = (checked: boolean, custom: boolean, ci: CheckListItem): any => {
    if (custom) {
      saveCustomCheckListItem(
        // sessionId=
        activeSession.SessionId,
        // userKey=
        userDetails.UserKey,
        // isChecked=
        checked,
        // title=
        ci.Title,
        // itemId=
        ci.ItemId
      )
        .then(() => {})
        .catch(() => {});
    } else {
      saveGlobalCheckListItem(
        // sessionId=
        activeSession.SessionId,
        // userKey=
        userDetails.UserKey,
        // isChecked=
        checked,
        // title=
        ci.Title,
        // itemId=
        ci.ItemId
      )
        .then(() => {})
        .catch(() => {});
    }
  };
  const addNewCheckListItem = (title: string): any => {
    if (title === '') {
      setAddCustomCheckListError('Checklist item cannot be empty');
      return;
    }
    saveCustomCheckListItem(
      // sessionId=
      activeSession.SessionId,
      // userKey=
      userDetails.UserKey,
      // isChecked=
      false,
      // title=
      title,
      // itemId=
      undefined
    )
      .then(() => {
        setReRender(true);
        setNewCheckListItem('');
      })
      .catch(() => {});
  };
  const [newCheckListItem, setNewCheckListItem] = useState('');
  const [addCustomCheckListError, setAddCustomCheckListError] = useState('');
  const [rerender, setReRender] = useState(true);

  useEffect(() => {
    if (rerender && activeSession.SessionId !== 0) {
      // const res = await getSoftwareCheckList();
      getGlobalChecklist(activeSession.SessionId)
        .then((data) => {
          console.log('success');
          setSoftwareChecklist(data.Payload);
        })
        .catch(() => {
          console.log('fail');
        });
      getCustomChecklist(activeSession.SessionId, userDetails.UserKey)
        .then((data) => {
          setCustomChecklist(data.Payload);
          console.log('success');
        })
        .catch(() => {
          console.log('fail');
        });
      setReRender(false);
    }
  }, [rerender]);

  const [softwareChecklist, setSoftwareChecklist] = useState<CheckListItem[]>([]);
  const [customChecklist, setCustomChecklist] = useState<CheckListItem[]>([]);
  return (
    <div className={styles.blurBg}>
      <div className={styles.modalContainer}>
        <div className={styles.checklistContainer}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <img src={checkListIcon} alt="checklisticon" />
              <div className={styles.checklistHeading}>Checklist</div>
            </div>
            <div>
              <img
                src={cross}
                alt="close"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch({ type: 'SET_CHECKLIST_SHOWN', payload: false });
                }}
              />
            </div>
          </div>
          <div className="mt-3 ml-2">
            <div className="mt-2 ml-2">
              <div className={styles.checklistSubHeading}>Software</div>
            </div>
            <div>
              {softwareChecklist?.map((ci, idx) => {
                return (
                  <div className={styles.checklistItem} key={ci.ItemId}>
                    <div className={styles.checklistItemCheck}>
                      <input
                        type="checkbox"
                        defaultChecked={ci.IsChecked}
                        onChange={(e) => {
                          handleCheckBoxClick(e.target.checked, false, ci);
                        }}
                      />
                    </div>
                    <div className={styles.checklistItemText}>{ci.Title}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-3 ml-2">
            <div className="d-flex justify-content-between mt-2 align-items-center ml-2">
              <div className={styles.checklistSubHeading}>Custom</div>
              <div className={styles.checklistSupportText}>Enter new task</div>
            </div>
            <div>
              <div className={styles.checklistItem}>
                <div>
                  <img
                    src={addIcon}
                    alt="add"
                    onClick={() => {
                      addNewCheckListItem(newCheckListItem);
                    }}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    onChange={(e) => {
                      setNewCheckListItem(e.target.value);
                      setAddCustomCheckListError('');
                    }}
                    value={newCheckListItem}
                  />
                </div>
              </div>
              {/* <div className={styles.checklistItem}> */}
              <div
                className={styles.checklistItemText}
                style={{ color: 'red', paddingLeft: '3rem' }}
              >
                {addCustomCheckListError}
              </div>
              {/* </div> */}
              {customChecklist?.map((ci, idx) => {
                return (
                  <div className={styles.checklistItem} key={ci.ItemId}>
                    <div className={styles.checklistItemCheck}>
                      <input
                        type="checkbox"
                        defaultChecked={ci.IsChecked}
                        onChange={(e) => {
                          handleCheckBoxClick(e.target.checked, true, ci);
                        }}
                      />
                    </div>
                    <div className={styles.checklistItemText}>{ci.Title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
