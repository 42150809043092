import React, { useEffect, useState } from 'react';
import styles from './CommentsCard.module.scss';

export interface comment {
  commentdetail: any;
}

export const CommentsCard: React.FC<comment> = ({ commentdetail }: comment) => {
  const myDate = new Date(commentdetail.createDate);
  const hr = myDate.getHours();
  const min = myDate.getMinutes();
  const sec = myDate.getSeconds();
  // let str = myDate.toString("MMMM yyyy");
  // const str = commentdetail.createDate.tostring();
  return (
    <div className={styles.newChanges}>
      <div className="d-flex">
        <div className={styles.userName}>{commentdetail.UserName}</div>
        <div className={styles.date}>
          {hr}:{min}:{sec}
        </div>
      </div>
      <div className={styles.comments}>{commentdetail.comment}</div>
    </div>
  );
};
