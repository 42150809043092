/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken, getUserOrgs } from '../../http/user';
import { UserRole } from '../../redux/reducers/user';
import { setRole } from '../../redux/actions';
import { login } from '../../utils/token';
import styles from './login.module.scss';
import { Button, ButtonVariants } from '../../ui-kit/Button/Button';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../utils';
import {
  ACCESS_TOKEN,
  EVENT_KEY,
  ORG_ID,
  ORG_LOGO,
  ORG_NAME,
  OrganisationId,
  USER_KEY
} from '../../common/constants';
import { logo, microsoftLoginIcon } from '../../assets';
import { getOrgDetail } from '../../http/organisation';
import img from '../../assets/logoImage.png';
import star from '../../assets/Star.png';
import imgLogo from '../../assets/relateLogo.png';
import FooterForum from '../../pages/FooterForum';
export interface OrgType {
  UserId: string;
  OrgKey: string;
  OrgName: string;
}
export const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loc = useLocation();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [mfaScreen, setMfaScreen] = React.useState(false);
  const [mfaCode, setMfaCode] = React.useState('');
  const [organisations, setOrganisations] = React.useState<OrgType[]>([]);
  const [organisationSelectionScreen, setOrganisationSelectionScreen] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState('');

  const onFail = (err: any): void => {
    // console.log(err);
    setError(err !== undefined ? err?.data?.error_description : 'server error');
    // if(err.response.status === 401){
    //   alert('Invalid username or password');
    // }else{
    //   alert('Something went wrong');
    // }
  };
  if (organisationSelectionScreen) {
    return (
      <>
        <div className={styles.login_container} style={{ color: 'white', gap: '1rem' }}>
          <div></div>
          <div className={styles.login_containerMid}>
            <img className={styles.login_logo} src={imgLogo} width={215} alt="logo" />
            <div className={styles.login_heading}> Please Select your Organisation </div>
            <div className="w-100 pt-3 mb-5">
              <select
                className={styles.login_select_org}
                onChange={(e) => {
                  setError('');
                  setLocalStorageItem(ORG_ID, e.target.value.split(' ')[0]);
                  // setLocalStorageItem(OrganisationId, e.target.value.split(' ')[1]);
                  // setOrganisationSelectionScreen(false);
                }}
              >
                {organisations.map((org) => {
                  return (
                    <option key={org.OrgKey} value={`${org.OrgKey} ${org.UserId}`}>
                      {org.OrgName}
                    </option>
                  );
                })}
              </select>
            </div>
            {error.length > 0 && <div className={styles.login_error}>{error}</div>}
            <Button
              variant={ButtonVariants.Basic}
              onClick={async () => {
                try {
                  // if(loc.pathname === '/login'){
                  // }
                  const orgKey = getLocalStorageItem(ORG_ID);
                  const res = await getOrgDetail(orgKey);
                  setLocalStorageItem(OrganisationId, res?.Payload.Id.toString() ?? '');
                  setLocalStorageItem(ORG_LOGO, res?.Payload.OrgLogo ?? '');
                  setLocalStorageItem(ORG_NAME, res?.Payload.OrgName ?? '');
                  setLocalStorageItem(ACCESS_TOKEN, accessToken);

                  await login(
                    username,
                    password,
                    (res: any) => {
                      setLocalStorageItem(ACCESS_TOKEN, res.access_token);
                      // if (organisations.length === 1) {
                      // setLocalStorageItem(ACCESS_TOKEN, res.access_token);
                      //   // navigate('/dashboard');
                      // } else {
                      //   // setAccessToken(res.access_token);
                      //   // setOrganisationSelectionScreen(true);
                      // }
                      setLocalStorageItem(USER_KEY, res.userKey);
                      dispatch({ type: 'SET_USER_DATA', payload: res });

                      removeLocalStorageItem(EVENT_KEY); // remove logged in as cohost
                      setLocalStorageItem('new-login', `login ${Math.random()}`);
                      navigate('/dashboard');
                    },
                    (err) => {
                      console.log(err);
                      if (err.status === 400) {
                        setError('You do not have F360 Producer role in this organisation');
                      }
                    },
                    '7'
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
              text={'Continue'}
              css={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}
            />
          </div>
          <FooterForum />
        </div>
      </>
    );
  } else if (mfaScreen) {
    return (
      <div className={styles.login_container} style={{ color: 'white', gap: '1rem' }}>
        <div></div>
        <div className={styles.login_containerMid}>
          <img className={styles.login_logo} src={imgLogo} width={215} alt="logo" />
          <div className={styles.login_heading}> Two Factor Authentication </div>
          <input
            id="mfa"
            type="text"
            placeholder="6-digit Code"
            value={mfaCode}
            className={styles.login_mfa}
            onChange={(e) => {
              setMfaCode(e.target.value);
              setError('');
            }}
          />
          {error.length > 0 && <div className={styles.login_error}>{error}</div>}
          <Button
            variant={ButtonVariants.Basic}
            onClick={async () => {
              await login(
                username,
                password + ',' + mfaCode,
                (res: any) => {
                  if (organisations.length === 1) {
                    setLocalStorageItem(ACCESS_TOKEN, res.access_token);
                    navigate('/dashboard');
                  } else {
                    setAccessToken(res.access_token);
                    setOrganisationSelectionScreen(true);
                  }
                  setLocalStorageItem(USER_KEY, res.userKey);
                  dispatch({ type: 'SET_USER_DATA', payload: res });

                  if (organisations.length === 1) {
                    removeLocalStorageItem(EVENT_KEY); // remove logged in as cohost
                    setLocalStorageItem('new-login', `login ${Math.random()}`);
                  }
                },
                onFail
              );
              // navigate('/dashboard');
            }}
            text={'Verify'}
            css={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}
          />
          <div className={`${styles.login_subHeading} ${styles.login_mfa_subHeading}`}>
            We just sent you an email with your authentication code. Enter the code in the form
            above to verify your identity.
          </div>
          <div className="w-100">
            <div
              className={styles.login_resendCodeButton}
              onClick={async () => {
                await login(
                  username,
                  password,
                  () => {
                    // setMfaScreen(true);
                    setError('');
                    // navigate('/dashboard');
                  },
                  onFail
                );
                // navigate('/dashboard');
              }}
            >
              Re-send the code
            </div>
          </div>
        </div>
        {/* <div className={`${styles.login_subHeading} ${styles.login_copyright}`}>
          <div className={styles.logo}>
            <span className={styles.powered}>Powered by</span>
            <img src={imgLogo} />
          </div>
          © Copyright Forum Care Ltd 2023
        </div> */}
        <FooterForum />
      </div>
    );
  }
  return (
    <div className={styles.login_container} style={{ color: 'white', gap: '1rem' }}>
      <div></div>
      <div className={styles.login_containerMid}>
        {/* <label htmlFor="username">Username / Email</label> */}
        <img className={styles.login_logo} src={imgLogo} width={215} alt="logo" />
        <div className={styles.login_heading}> Relate </div>
        {/* <button> */}
        <img
          className={styles.login_microsoft}
          src={microsoftLoginIcon}
          style={{ height: '2.75rem' }}
          alt="login with microsoft"
          onClick={() => {
            navigate('/ms-login');
          }}
        />
        {/* </button> */}
        <div className={styles.login_or}> OR </div>

        <input
          id="username"
          type="text"
          placeholder="Enter your username"
          className={styles.login_username}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setError('');
          }}
        />
        {/* <label htmlFor="password">Password</label> */}
        <input
          id="password"
          type="password"
          placeholder="Enter your password"
          value={password}
          className={styles.login_password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError('');
          }}
        />
        {error.length > 0 && <div className={styles.login_error}>{error}</div>}
        <Button
          variant={ButtonVariants.Basic}
          onClick={async () => {
            try {
              let orgs = await getUserOrgs(username.replaceAll('+', '%2B'));
              orgs = orgs.Payload;
              console.log('orgs', orgs);
              if (orgs.length === 0) {
                setError('User does not exist');
                throw new Error('User does not exist');
              }
              setOrganisations(orgs);
              // setLocalStorageItem(OrganisationId, orgs[0].UserId);
              setLocalStorageItem(ORG_ID, orgs[0].OrgKey);

              const orgKey = orgs[0].OrgKey;
              const res = await getOrgDetail(orgKey);
              // if user is in a single org , we will not see the org selection screen so we do this here
              setLocalStorageItem(OrganisationId, res?.Payload.Id.toString() ?? '');
              setLocalStorageItem(ORG_LOGO, res?.Payload.OrgLogo ?? '');
              setLocalStorageItem(ORG_NAME, res?.Payload.OrgName ?? '');

              await login(
                username,
                password,
                () => {
                  // setLocalStorageItem('new-login',`login ${Math.random()}`);
                  // removeLocalStorageItem(EVENT_KEY); // remove logged in as cohost
                  setMfaScreen(true);
                  setError('');
                  // navigate('/dashboard');
                },
                onFail
              );
            } catch (err) {
              console.log(err);
            }
            // await
            // navigate('/dashboard');
          }}
          text={'Sign in'}
          css={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}
        />
        <div
          style={{ marginTop: '1.5rem', cursor: 'pointer' }}
          className={styles.login_or}
          onClick={() => {
            navigate('/forgot-password');
          }}
        >
          {' '}
          Forgot your password ?{' '}
        </div>
      </div>
      {/* <div className={`${styles.login_subHeading} ${styles.login_copyright}`}>
        <div className={styles.logo}>
          <span className={styles.powered}>Powered by</span>
          <img src={imgLogo} />
        </div>
        © Copyright Forum Care Ltd 2023
      </div> */}
      <FooterForum />
    </div>
  );
};
