import {
    PublicClientApplication, EventType
  } from '@azure/msal-browser';
  import { ACCESS_TOKEN, ACCOUNT, EXPIRES_ON, TIME_OF_AUTO_LOGOUT } from '../common/constants/local-storage-keys';
//   import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../common/utils/local-storage';
  import { setExpirationTimeout ,} from './msal-expiration';
//   import Cookies from 'universal-cookie';
import { getLocalStorageItem , setLocalStorageItem , removeLocalStorageItem} from '../utils';
  
  let USE_SILENT_MODE = false;
  
  function abandonSilentMode() {
    USE_SILENT_MODE = false;
  }
  
  function resetSilentMode() {
    USE_SILENT_MODE = (window?.env?.SSO_SILENT_LOGIN_ACTIVE || '').toLowerCase() === 'true';
  }
  
  resetSilentMode();
  
  export function msalUseSilentMode() {
    return USE_SILENT_MODE;
  }
  export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: process.env.REACT_APP_AUTHORITY_URL,
      redirectUri: process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
  
  export const loginRequest = {
    // scopes: [process.env.REACT_APP_SCOPES],
    scopes: ["api://6e3d5a60-f6e9-4b10-ae7f-8cd4dea9eaf9/All"]
  };
  
  export const logoutRequest = (instance) => {
    const userInfo = JSON.parse(localStorage.getItem('userInformation'));
    const homeAccountId = userInfo.account.homeAccountId;
    return {     
      account: instance.getAccountByHomeId(homeAccountId),     
      mainWindowRedirectUri: "https://localhost:3000",     
      postLogoutRedirectUri: "https://localhost:3000",   
    }
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
  };
  
  export const msalClient = new PublicClientApplication(msalConfig);
  
  msalClient.addEventCallback((message) => {
    const { eventType, payload } = message;
    // const cookies = new Cookies();
    
    const successEvents = [
      EventType.ACQUIRE_TOKEN_SUCCESS,
      EventType.LOGIN_SUCCESS,
      EventType.SSO_SILENT_SUCCESS,
    ];
    if (successEvents.includes(eventType)) {
      const data = payload;
      if (data && data.account) {
        resetSilentMode();
        const expiresOnFromResponse = String(data.expiresOn);
        sessionStorage.setItem('userInformation', data);
        setLocalStorageItem(ACCESS_TOKEN, data.accessToken);
        setLocalStorageItem(ACCOUNT, JSON.stringify(data.account));
        setLocalStorageItem(EXPIRES_ON, expiresOnFromResponse);
        setExpirationTimeout(msalClient, loginRequest, expiresOnFromResponse);
      }
    }
  
    if (eventType === EventType.SSO_SILENT_FAILURE) {
      // There is also can be not only InteractionRequiredAuthError but another type of error
      // after which is better to switch to normal flow
      abandonSilentMode();
    }
  });
  
  window.addEventListener('storage', ({ key, newValue, oldValue }) =>{
    if (key === ACCESS_TOKEN && !newValue && oldValue) {
      // removing ACCESS_TOKEN from storage means that user logged out from another tab
      // user should be redirected to the auth page
      window.location.reload(true);
    }
  });
  
  export function checkMsalExpiration() {
    const expiresOnFromStorage = getLocalStorageItem(EXPIRES_ON);
    expiresOnFromStorage && setExpirationTimeout(msalClient, loginRequest, expiresOnFromStorage);
  }
  