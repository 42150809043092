import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { trimToLength } from '../../utils/string-manip';

export interface TrimTooltipPropType {
  text: string;
  length?: number;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  // id?: string;
  // className?: string;
}
export const TrimTooltip: React.FC<TrimTooltipPropType> = ({
  text,
  placement,
  length
}: TrimTooltipPropType) => {
  const tooltip = <Tooltip>{text}</Tooltip>;
  if (length !== undefined && text.length <= length) {
    return <div>{text}</div>;
  }
  return (
    <OverlayTrigger placement={placement ?? 'top'} overlay={tooltip}>
      <div>{length !== undefined ? trimToLength(text, length) : text}</div>
    </OverlayTrigger>
  );
};
