import { httpRequest } from '../utils';
import { BASE_URL } from './api/services';

// Payload Response Type
export interface magicTokenResponseType {
  IsAuthenticated: boolean;
  AccessToken: string;
  access_token: string;
  token_type: string;
  TokenType: string;
  UserId: string;
  expires_in: string;
  '.expires': string;
  '.issued': string;
  userKey: string;
  userName: string;
  Attempt: number;
  HasActioned: boolean;
  EventKey: string;
  OrgKey: string;
}

export const getDataFromMagicToken = async (token: string): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/Account/MagicLoginWithEvent/${token}`,
    method: 'GET'
    // headers : {
    //     'Content-Type' : 'application/json'
    // }
  });
};

// Payload Response type
export interface CohostSessionListResponseType {
  SessionCoHost: SessionCoHost[];
  CohostName: string;
  EventDescription: EventDescription;
}

export interface SessionCoHost {
  Id: number;
  Title: string;
  SpeakerUserId: number;
  SessionDate: string;
  Duration: number;
  Description: string;
  MeetingId: string;
}

export interface EventDescription {
  ShortDesc: string;
  FullDesc: string;
  EventImage: string;
  EventEndDate: string;
}

export const getCohostSessionList = async (
  eventKey: string,
  userKey: string
): Promise<any | undefined> => {
  const data = {
    EventKey: eventKey,
    UserKey: userKey
  };

  return await httpRequest({
    url: `${BASE_URL}/Session/CohostSessionList/`,
    method: 'POST',
    data
  });
};
