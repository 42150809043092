import React, { useState } from 'react';
import { Button, ButtonVariants } from '../../../ui-kit/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { WaitingRoom } from '../../../pages/WaitingRoom/WaitingRoom';

export const SignalRTest: React.FC = () => {
  const dispatch = useDispatch();
  // const reduxFlag = useSelector(({SIGNALR}) => SIGNALR.signalRTestFlag);
  const chat = useSelector(({ SIGNALR }) => SIGNALR.chat);
  const [input, setinput] = useState('');

  return (
    <>
      <WaitingRoom />
      <div>SignalRTest</div>
      {chat.map((item: any, idx: number) => (
        <div key={idx} style={{ color: 'white' }}>
          {item}
        </div>
      ))}
      <input
        type="text"
        value={input}
        onChange={(e) => {
          setinput(e.target.value);
        }}
      />
      <Button
        text="invoke"
        onClick={() => {
          // setinput('');
          dispatch({ type: 'SET_FLAG', payload: input }); // send to others
          // dispatch({ type: 'SET_CHAT', payload: input }); // set to self
        }}
        variant={ButtonVariants.Basic}
      />
    </>
  );
};
