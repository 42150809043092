import React, { useEffect, useState } from 'react';
import styles from './Questions.module.scss';
import { Card } from '../../../../../common/components/Card/Card';
import { httpRequest } from '../../../../../utils';
import { BASE_URL } from '../../../../../http/api/services';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable  @typescript-eslint/no-explicit-any */

// export const fetchQuestions = async (token: string): Promise<any | undefined> => {
//   return await fetch(
//     `${BASE_URL}/PA_Event/InProgressQuestion/84573623-aa87-402c-b28d-24d1e181ecbe/2560`,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     }
//   )
//     .then(async (response) => await response.json())
//     .then((json) => {
//       return json.Payload;
//     })
//     .catch((error) => {
//       console.error(error);
//       return [];
//     });
// };

export const Welcome = async (): Promise<any | undefined> => {
  return await httpRequest({
    url: `${BASE_URL}/PA_Event/SendWelcome`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer -1leljERr74J_xVJlYOBVgnlwBcsXpfobmY8VW6Pvp-gFpzsDFsXQpJ3fSBX3k_tVZVLvwkE--Bd9wi9gLzI2tRsPxSqrM5zSP8IdN01j8m4-QSmhJG_CGV93z34ATgHQemf4FiiVh2LsAs6VdxOqVJKbb6t0MIyXRdd8js-WPEemNftAtXGLS22KmawdbvT6P3GBCrFwoghEnWmto7Tuaoj2hF6v4G9QjgmPavEYLvQzMvCQdTv0YRGtuUzmWlbv1TMsKOPGlVgKspIKwbSiz_DcatM7ph-6YNjSfAGlR-rIIrfc73j8y5wnkJQ0koBR1ZLhl5D6kqzcVrMxUUVhXfKXIfm6MxHYaKW4HQk6Kumhry5Ijrdoab05yXo5qufAi8gews5UBrrGkYMhaZYt9cUWdGO7YOgwvmFA38-iUXJXCqgzJUdPLSHZgxyO8g19yhFdHvVi07dTRwyyuY1_Jy9vNjhA5tP0pWSO1lKVbQ'
    },
    data: JSON.stringify({
      SessionId: '2560'
    })
  });
};

export interface AgendaProps {
  question: any;
}
export const Questions: React.FC<AgendaProps> = ({ question }: AgendaProps) => {
  async function moveToAgenda(question: any): Promise<any> {
    return await fetch(`${BASE_URL}/PA_Event/PlanQuestion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer -${localStorage.accessToken}`
      },
      body: JSON.stringify({
        QuestionKey: question.QuestionKey,
        Position: question.Ordering,
        IsMoveBackToIncoming: true,
        SessionId: 2560
      })
    });
  }
  const [questions, setQuestions] = useState(question);
  useEffect(() => {
    setQuestions(question);
  }, [question]);

  // const [agenda,setAgenda] = useState([]);
  // useEffect(() => {
  //   console.log('localStorage', localStorage);
  //   void fetchQuestions(localStorage.accessToken).then((questionsData) => {
  //     console.log('questiondata', questionsData);
  //     if( (Boolean(questionsData)) && (Boolean(questionsData.audiences)))
  //       setQuestions(questionsData.Planneds);
  //     agendainfo(questionsData.Planneds);
  //   });
  //   console.log('questions data', questions);
  // }, []);

  const data = [
    {
      name: 'Jocelyn Torff',
      time: '20:19:20',
      question:
        'There are many variations of passages of Lorem available. There are many variations of passages of Lorem available.'
    },
    {
      name: 'Aspen Rheil Madsen',
      time: '20:19:20',
      question:
        'There are many variations of passages of Lorem available. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.'
    },
    {
      name: 'Roger Vaccaro',
      time: '20:19:20',
      question: 'Do fixed income investments on a 30 year period have higher returns?'
    },
    {
      name: 'Jocelyn Torff',
      time: '20:19:20',
      question:
        'There are many variations of passages of Lorem available. There are many variations of passages of Lorem available.'
    },
    {
      name: 'Aspen Rheil Madsen',
      time: '20:19:20',
      question:
        'There are many variations of passages of Lorem available. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.'
    }
  ];
  return (
    <>
      {questions.length > 0 &&
        questions.map((question: any, index: React.Key | null | undefined) => (
          <div className={styles.questiontabs} key={index}>
            <Card question={question} moveToAgenda={moveToAgenda} />
          </div>
        ))}
      {/* <div className={styles.questiontabs}>
        
      </div>
      <div className={styles.questiontabs}>
        <Card />
      </div>
      <div className={styles.questiontabs}>
        <Card />
      </div>
      <div className={styles.questiontabs}>
        <Card />
      </div> */}
    </>
  );
};
