/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useCallback, useState } from 'react';
import DOMPurify from 'dompurify';
import './customerform.scss';
import addComment from './addComment.svg';
import thumbdislike from './thumbdislike.svg';
import thumblike from './thumblike.svg';
import qs from 'qs';
import { httpRequest } from '../../../../utils/http-request';
import { BASE_URL } from '../../../../http/api/services';
import tick from './tick.png';
import righttick from './Untitled.png';
import greentick from './greentick.png';
import { Value } from 'sass';
import tickimage from './tick.svg';
import greentickimage from './greentick.svg';
import thumblikeclick from './thumblikeclick.svg';
import thumbdislikeclick from './thumbdislikeclick.svg';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../../../redux/reducers';
import { UserRole } from '../../../../redux/reducers/user';
import { debounceLeading } from '../../../../utils/extend';
import { Toolbar } from './toolbar';
const data = qs.stringify({
  EventKey: '00000000-0000-0000-0000-000000000000',
  SessionId: '-48925757',
  Question: 'nostrud fugiat',
  UserKey: 'labore ex Duis culpa',
  AppId: '96895547'
});

export enum reactionMap {
  up = 1,
  down = 2,
  excited = 3,
  bored = 5,
  confused = 4
}

export const postReaction = async (
  eventKey: string,
  userKey: string,
  sessionId: number,
  indicator: reactionMap,
  speakerUserKey?: string
): Promise<any | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/Booking/feedback`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        EventKey: eventKey,
        SessionId: sessionId,
        indicator,
        UserKey: userKey,
        SpeakerUserKey: speakerUserKey ?? '0'
      }
    },
    false
  );
};
// export const ThumbsDown = async (
//   eventKey: string,
//   userKey: string,
//   sessionId: number
// ): Promise<any | undefined> => {
//   return await httpRequest({
//     url: `${BASE_URL}/Booking/feedback`,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data: {
//       EventKey: eventKey,

//       SessionId: sessionId,

//       indicator: '2',

//       UserKey: userKey,

//       SpeakerUserKey: '0'
//     }
//   });
// };
// export const ThumbsUp = async (
//   eventKey: string,
//   userKey: string,
//   sessionId: string | number
// ): Promise<any | undefined> => {
//   return await httpRequest({
//     url: `${BASE_URL}/Booking/feedback`,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data: {
//       EventKey: eventKey,

//       SessionId: sessionId,

//       indicator: '1',

//       UserKey: userKey,

//       SpeakerUserKey: '0'
//     }
//   });
// };
export const askQuestion = async (
  question: string,
  eventKey: string,
  sessionId: string | number,
  userKey: string,
  orgId: string
): Promise<any | undefined> => {
  if (question.length <= 1) {
    console.error("The question must be greater than 1 characters.");
    return; // Exit early if the condition is not met
  }
  return await httpRequest(
    {
      url: `${BASE_URL}/Question/AskQuestion`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        OrgId: orgId
        // Appid: '5'
      },
      data: {
        EventKey: eventKey,
        SessionId: sessionId,
        Question: question,
        UserKey: userKey,
        Appid: '5'
      }
    },
    false
  );
  // eslint-disable-next-line no-unreachable
};

export const commentView = async (
  comment: string,
  eventKey: string,
  sessionId: string | number,
  userKey: string,
  orgId: string
): Promise<any | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/Booking/feedback`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        OrgId: orgId,
        AppId: '4'
      },
      data: {
        EventKey: eventKey,
        SessionId: sessionId,
        Indicator: '0',
        Duration: '0',
        Message: comment,
        UserKey: userKey,
        SpeakerUserKey: '0'
      }
    },
    false
  );
};

export const reviewRate = async (
  review: string,
  eventKey: string,
  sessionId: string | number,
  userKey: string,
  orgId: string
): Promise<any | undefined> => {
  return await httpRequest(
    {
      url: `${BASE_URL}/User/ReviewRate`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        OrgId: orgId,
        AppId: '4'
      },
      data: {
        EventKey: eventKey,
        SessionId: sessionId,
        Rating: 0,
        Comment: review,
        SpeakerUserKey: userKey,
        ReqUserKey: userKey
      }
    },
    false
  );
};
export const CustomerForm: React.FC = () => {
  const dispatch = useDispatch();
  const activeSession = useSelector(({ SIGNALR }: RootState) => SIGNALR.activeSession);
  const userDetails = useSelector(({ USER }: RootState) => USER.userDetails);
  const [comment, setComment] = useState('');
  const [rate, setRate] = useState('');
  const [question, setQuestion] = useState('');
  const [commenttick, setCommentTick] = useState(false);
  const [questiontick, setQuestionTick] = useState(false);
  const [reviewTick, setReviewTick] = useState(false);
  const [liketag, setLikeTag] = useState(false);
  // const [feedback,setFeedBack] = useState()
  const [disliketag, setDislikeTag] = useState(false);
  const role = useSelector(({ USER }: RootState) => USER.role);
  const [data, setData] = useState(
    role === UserRole.host || role === UserRole.moderator
      ? 'Enter Session Notes'
      : 'Please provide feedback throughout. It is privately shared and extremely important to us.'
  );
  const [feedback, setFeedBack] = useState(
    role === UserRole.host || role === UserRole.moderator ? 'Customer Experience' : 'My Feedback'
  );

  // const tempData = (role === UserRole.host || role ===  UserRole.moderator)?'Enter Session Notes':'Please provide feedback throughout. It is privately shared and extremely important to us.'
  // setData(tempData);
  //  function like(): void {
  //   if (!liketag) {
  //     void postReaction(
  //       activeSession.EventDetails.EventKey,
  //       userDetails.UserKey,
  //       activeSession.SessionId,
  //       reactionMap.up,
  //       activeSession.Speaker.UserKey
  //     );
  //     // void ThumbsUp(
  //     //   activeSession.EventDetails.EventKey,
  //     //   userDetails.UserKey,
  //     //   activeSession.SessionId
  //     // );
  //     setLikeTag(true);
  //     setTimeout(() => {
  //       setLikeTag(false);
  //     }, 1000);
  //   }
  // }

  // function dislike(): void {
  //   if (!disliketag) {
  //     void postReaction(
  //       activeSession?.EventDetails?.EventKey,
  //       userDetails.UserKey,
  //       activeSession.SessionId,
  //       reactionMap.down,
  //       activeSession.Speaker.UserKey
  //     );
  //     // void ThumbsDown(
  //     //   activeSession?.EventDetails?.EventKey,
  //     //   userDetails.UserKey,
  //     //   activeSession?.SessionId
  //     // );
  //     setDislikeTag(true);
  //     setTimeout(() => {
  //       setDislikeTag(false);
  //     }, 1000);
  //   }
  // }
  const commentCall = useCallback(
    debounceLeading(async () => {
      if (comment.length > 1) {
        setCommentTick(true);
        await commentView(
          DOMPurify.sanitize(comment),
          activeSession.EventDetails.EventKey,
          activeSession.SessionId,
          userDetails.UserKey,
          localStorage.organisationId
        );
        setCommentTick(false);
        setComment('');
        // setTimeout(() => {
        // }, 1000);

        dispatch({ type: 'SEND_COMMAND_TO_GROUP', payload: { command: '[COMMENT-NEW]' } });
        dispatch({ type: 'SET_COMMAND_FROM_GROUP', payload: { command: '[COMMENT-NEW]' } });
      }
    }, 6900),
    [comment, dispatch, activeSession, userDetails]
  );

  const askUserQuestion = useCallback(
    debounceLeading(async () => {
      console.count('ask user question called.');
      if (question.length > 0) {
        setQuestionTick(true);
        await askQuestion(
          DOMPurify.sanitize(question),
          activeSession.EventDetails.EventKey,
          activeSession.SessionId,
          userDetails.UserKey,
          localStorage.organisationId
        );
        // what if the api call fails?
        setQuestionTick(false);
        setQuestion('');
        // setTimeout(() => {
        // }, 1000);
        dispatch({ type: 'SET_QUESTIONS_CHANGED', payload: true });
        dispatch({ type: 'SEND_COMMAND_TO_GROUP', payload: { command: '[QUESTION-NEW]' } });
      }
    }, 6900),
    [question, dispatch, activeSession, userDetails]
  );

  const Rate = useCallback(
    debounceLeading(async () => {
      if (rate.length > 0) {
        setReviewTick(true);
        await reviewRate(
          DOMPurify.sanitize(rate),
          activeSession.EventDetails.EventKey,
          activeSession.SessionId,
          userDetails.UserKey,
          localStorage.organisationId
        );
        setReviewTick(false);
        setRate('');
        // setTimeout(() => {
        // }, 1000);
      }
    }, 6900),
    [rate, dispatch, activeSession, userDetails]
  );

  return (
    <div className="customerexperience">
      <div className="d-flex">
        <img src={addComment} />
        <div className="customer-exp-heading">
          <div>{feedback}</div>
          <div className="customer-sessionnotes">{data}</div>
        </div>
      </div>
      <div className="formdata">
        <div className="labels">Comment</div>
        <div style={{ position: 'relative' }}>
          <input
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            className="custom-input"
            placeholder="Start typing..."
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                commentCall();
              }
            }}
          />
          <img
            alt="send"
            data-automation-id="comment-send"
            src={commenttick ? greentickimage : tickimage}
            onClick={commentCall}
            style={{ position: 'absolute', right: 0, bottom: '0.9rem', height: '1.7rem' }}
          />
          {/* <button onClick={comment} style={{position:'absolute',right:0,bottom:'2px'}} className="custom-form">Send</button> */}
        </div>
        <div className="labels">Question</div>
        <div style={{ position: 'relative' }}>
          <input
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            className="custom-input"
            placeholder="Start Typing..."
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                askUserQuestion();
              }
            }}
          />
          <img
            alt="send"
            data-automation-id="question-send"
            src={questiontick ? greentickimage : tickimage}
            onClick={askUserQuestion}
            style={{ position: 'absolute', right: 0, bottom: '0.9rem', height: '1.7rem' }}
          />
        </div>
        <div className="labels">Review</div>
        <div style={{ position: 'relative' }}>
          <input
            value={rate}
            onChange={(e) => {
              setRate(e.target.value);
            }}
            placeholder="Start Typing..."
            className="custom-input"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                Rate();
              }
            }}
          />
          <img
            alt="send"
            data-automation-id="review-send"
            src={reviewTick ? greentickimage : tickimage}
            onClick={Rate}
            style={{ position: 'absolute', right: 0, bottom: '0.9rem', height: '1.7rem' }}
          />
        </div>
        <div className="d-flex justify-content-space-between like">
          <Toolbar />
          {/* <img
            data-automation-id="review-dislike"
            alt="dislike"
            style={{ height: '1.75rem' }}
            src={disliketag ? thumbdislikeclick : thumbdislike}
            onClick={dislike}
          />
          <img
            style={{ height: '1.75rem' }}
            data-automation-id="review-like"
            alt="like"
            src={liketag ? thumblikeclick : thumblike}
            onClick={like}
          /> */}
        </div>
      </div>
    </div>
  );
};
